import { useForm, UseFormProps } from "react-hook-form";
import * as YUP from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const SourceFormSchema = YUP.object().shape({
  logo: YUP.mixed().optional().nullable(),
  name: YUP.string().required("Source name is required"),
  description: YUP.string().required("Description is required"),
  status: YUP.boolean().required("Active is required"),
});

export function useSourceForm(options?: UseFormProps<any>) {
  return useForm<any | any>({
    mode: "onSubmit",
    ...options,
    resolver: yupResolver(SourceFormSchema),
  });
}

export type LoginForm = ReturnType<typeof useSourceForm>;
