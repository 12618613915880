import { memo } from "react";
interface Props {
  className?: string;
}

function ApprovedIcon({ className }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.7747 2.94795C19.4743 2.64756 18.9872 2.64756 18.6868 2.94795L6.21765 15.4172L1.31315 10.5126C1.01279 10.2122 0.525724 10.2122 0.225293 10.5126C-0.0750978 10.813 -0.0750978 11.3 0.225293 11.6005L5.67378 17.049C5.97405 17.3492 6.46128 17.3493 6.76163 17.049L19.7747 4.0358C20.0751 3.73537 20.0751 3.24834 19.7747 2.94795Z"
        fill="white"
      />
    </svg>
  );
}
export default memo(ApprovedIcon);
