import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function EditPenIcon({ className, color }: Props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 21H21M12.9827 3.56857C12.9827 3.56857 12.9827 5.38482 14.7989 7.20107C16.6152 9.01732 18.4314 9.01732 18.4314 9.01732M5.79959 17.6534L9.61371 17.1085C10.1639 17.0299 10.6737 16.775 11.0667 16.382L20.2477 7.20107C21.2508 6.19798 21.2508 4.57165 20.2477 3.56857L18.4314 1.75232C17.4283 0.749228 15.802 0.749228 14.7989 1.75232L5.61796 10.9333C5.22498 11.3263 4.97006 11.8361 4.89146 12.3863L4.34658 16.2004C4.2255 17.048 4.952 17.7745 5.79959 17.6534Z"
        stroke={color || '#282D46'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
export default memo(EditPenIcon);
