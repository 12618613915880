import { memo } from "react";
interface Props {
    className?: string;
}
function CSVIcon({ className }: Props) {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.375 10.3125H20.2031V2.10938C20.2031 0.946266 19.2569 0 18.0938 0H8.25C8.06348 0 7.88466 0.0741094 7.75284 0.205969L2.12784 5.83097C1.99594 5.96283 1.92188 6.14166 1.92188 6.32812V21.8906C1.92188 23.0537 2.86814 24 4.03125 24H18.0938C19.2569 24 20.2031 23.0537 20.2031 21.8906V21.0938H21.375C21.7633 21.0938 22.0781 20.7789 22.0781 20.3906V11.0156C22.0781 10.6273 21.7633 10.3125 21.375 10.3125ZM7.54688 2.40061V4.92188C7.54688 5.30958 7.23145 5.625 6.84375 5.625H4.32248L7.54688 2.40061ZM3.32812 7.03125H6.84375C8.00686 7.03125 8.95312 6.08498 8.95312 4.92188V1.40625H18.0938C18.4815 1.40625 18.7969 1.72167 18.7969 2.10938V10.3125H3.32812V7.03125ZM18.7969 21.8906C18.7969 22.2783 18.4815 22.5938 18.0938 22.5938H4.03125C3.64355 22.5938 3.32812 22.2783 3.32812 21.8906V21.0938H18.7969V21.8906ZM20.6719 19.6875H3.32812V11.7188H20.6719V19.6875Z" fill="#282D46" />
            <path d="M7.7812 18.1641C8.32687 18.1641 8.81742 17.9841 9.19992 17.6435C9.27478 17.5768 9.34589 17.5035 9.41142 17.4254C9.61936 17.1774 9.58692 16.8078 9.33895 16.5999C9.09098 16.392 8.72147 16.4244 8.51348 16.6723C8.48442 16.707 8.45316 16.7393 8.42063 16.7683C8.25375 16.9169 8.03869 16.9922 7.78125 16.9922C7.07048 16.9922 6.49219 16.4139 6.49219 15.7031C6.49219 14.9923 7.07048 14.4141 7.78125 14.4141C8.03967 14.4141 8.28872 14.4901 8.50153 14.6339C8.76975 14.815 9.13392 14.7445 9.31505 14.4764C9.49622 14.2082 9.42567 13.844 9.15759 13.6628C8.7503 13.3876 8.27433 13.2422 7.78125 13.2422C6.42431 13.2422 5.32031 14.3461 5.32031 15.7031C5.32031 17.0601 6.42427 18.1641 7.7812 18.1641Z" fill="#282D46" />
            <path d="M12.1906 18.164C13.0365 18.164 13.7476 17.6199 13.8814 16.8701C13.9829 16.3014 13.7307 15.5605 12.7183 15.1871C12.2795 15.0252 11.8697 14.8494 11.7228 14.7854C11.6823 14.7461 11.6787 14.6966 11.684 14.6597C11.6973 14.5676 11.7755 14.4934 11.8987 14.4563C12.3374 14.3241 12.6705 14.5411 12.7372 14.5897C12.9105 14.8044 13.2171 14.8727 13.4691 14.7378C13.7545 14.5852 13.8621 14.2301 13.7095 13.9448C13.5074 13.5668 12.5914 13.0239 11.5607 13.3343C10.9997 13.5033 10.6025 13.9473 10.5241 14.4932C10.4502 15.0081 10.6735 15.5036 11.107 15.7863C11.1329 15.8031 11.1601 15.8179 11.1883 15.8305C11.2101 15.8403 11.7288 16.0711 12.3127 16.2865C12.5315 16.3672 12.7555 16.5088 12.7278 16.6642C12.7041 16.7964 12.5171 16.9921 12.1906 16.9921C11.8516 16.9921 11.5264 16.8567 11.3209 16.6299C11.1035 16.3901 10.733 16.372 10.4932 16.5893C10.2535 16.8066 10.2352 17.1771 10.4526 17.4169C10.8829 17.8917 11.5164 18.164 12.1906 18.164Z" fill="#282D46" />
            <path d="M16.1559 17.7181C16.2509 17.9854 16.5044 18.1642 16.7877 18.1642H16.7924C17.0776 18.1622 17.3309 17.9794 17.424 17.7056L18.6484 14.0198C18.7504 13.7127 18.5842 13.3811 18.277 13.2791C17.97 13.177 17.6383 13.3433 17.5363 13.6504L16.7774 15.9349L15.9795 13.6361C15.8733 13.3304 15.5393 13.1687 15.2338 13.2747C14.9281 13.3808 14.7663 13.7146 14.8724 14.0204L16.1559 17.7181Z" fill="#282D46" />
        </svg>

    );
}
export default memo(CSVIcon);
