import { FC } from "react";
import Card, { ICard } from "components/molecules/card/Card";
import { CardType } from "lib/helpers/constants/cardTypes";
import { IInfoCardProps } from "components/molecules/card/info-card/InfoCard";
import { JSX } from "react/jsx-runtime";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useTranslation } from "react-i18next";

export interface IProps {
}

const PendingCards: FC<IProps> = () => {
  const {themeColors} = useThemeContext();
  const {t} = useTranslation();


  const cardsData = [
    {
      title: `${t("Total Pendings")}`,
      color: themeColors.primary,
      count: '350',
      icon: IconType.PENDINGS_ICON,
      updatedate: new Date().toLocaleDateString(),
    //   percentageup:
    //   data?.won_percent >= 0 ? IconType?.UP_PERCENTAGE_ICON : null,
      
    },
    {
      title: `${t("Open Pendings")}`,
      color: themeColors.primary,
      count: '350',
      icon: IconType.OPEN_PENDINGS_ICON,
      updatedate: new Date().toLocaleDateString(),
   
    },
    {
      title: `${t("Processing Pendings")}`,
      count: '350',
      color: themeColors.primary,
      icon: IconType.PROCESSING_PENDINGS_ICON,
      updatedate: new Date().toLocaleDateString(),
    
    },
    {
      title: `${t("Done Pendings")}`,
      count: '350',
      color: themeColors.primary,
      icon: IconType.DONE_PENDING_ICON,
      updatedate: new Date().toLocaleDateString(),

    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 2xl:grid-cols-4 items-center justify-between gap-5">
      {cardsData?.map(
        (
          item: JSX.IntrinsicAttributes & ICard<IInfoCardProps>,
          index: number
        ) => (
          <Card<IInfoCardProps>
            cardType={CardType.INFO_CARD}
            {...item}
            key={`leads-cards-key-${index}`}
          />
        )
      )}
    </div>
  );
};

export default PendingCards;
