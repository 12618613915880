import { memo } from "react";

interface Props {
  className?: string;
}

function ContratsIcon({ className }: Props) {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="layer"
      className={className}
    >
      <path
        d="M6.4856 4.99935V4.16602C6.4856 2.7853 7.60488 1.66602 8.9856 1.66602H10.6523C12.033 1.66602 13.1523 2.7853 13.1523 4.16602V4.99935M1.4856 8.62225C1.4856 8.62225 4.08396 10.353 8.13365 10.7584M18.1523 8.62225C18.1523 8.62225 15.5539 10.353 11.5042 10.7584M4.81893 18.3327H14.8189C16.6599 18.3327 18.1523 16.8403 18.1523 14.9993V8.33268C18.1523 6.49173 16.6599 4.99935 14.8189 4.99935H4.81893C2.97798 4.99935 1.4856 6.49173 1.4856 8.33268V14.9993C1.4856 16.8403 2.97798 18.3327 4.81893 18.3327Z"
        stroke="#50B8E7"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
export default memo(ContratsIcon);
