import React, { Dispatch, useEffect, useState } from "react";
import IconButton from "components/atoms/button/IconButton";
import { Controller, useForm } from "react-hook-form";
import Creatable from "react-select/creatable";
import Input from "components/atoms/input/Input";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import { customStyles } from "lib/helpers/configs/customStyles";
import Label from "components/atoms/input/input-label/InputLabel";
import { usePartners } from "lib/hooks/queries/Partners/usePartnersForFilter";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useTranslation } from "react-i18next";

export interface IFilterProps {
  filter_search: string;
  filter_status: string;
  filter_product: string;
  filter_company: string;
  filter_commission: string;
  filter_mandated: string;
  filter_created_at: any;
}

const initialState: IFilterProps = {
  filter_search: "",
  filter_status: "",
  filter_product: "",
  filter_company: "",
  filter_commission: "",
  filter_mandated: "",
  filter_created_at: ""
};

export interface IFilterCostumersProps {
  setFilterData?: (args: any) => void;
  FilterData?: IFilterProps;
  setIsOpen?: Dispatch<React.SetStateAction<boolean>>;
  themeColors: {
    primary: string;
  };
}

const FilterCostumers: React.FC<IFilterCostumersProps> = ({
  setFilterData,
  FilterData,
  setIsOpen,
  themeColors
}) => {
  const [DateOfCreation, setDateOfCreation] = useState<Date | null>(
    FilterData?.filter_created_at || null
  );
  const { data: partnersData, isLoading } = usePartners();

  const [InsideData, setInsideData] = useState<IFilterProps>(
    FilterData || initialState
  );

  const { control, reset } = useForm({
    defaultValues: FilterData || initialState
  });

  useEffect(() => {
    setInsideData((prevFormData: IFilterProps) => ({
      ...prevFormData,
      filter_created_at: DateOfCreation
    }));
  }, [DateOfCreation]);

  const handleButtonClick = () => {
    if (setFilterData) {
      setFilterData(InsideData);
    }
    if (setIsOpen) {
      setIsOpen(false);
    }
  };

  const ResetData = () => {
    setInsideData(initialState);
    setDateOfCreation(null);
    reset(initialState);
  };

  const {t} = useTranslation();

  if (isLoading) {
    return <LoadingScreen />; 
  }

  return (
    <div className="flex flex-col gap-3">
      <div>
        <Label text={t('Status')} />
        <Controller
          name="filter_status"
          control={control}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder={t('Choose Status')}
              options={[
                { value: "Open (Consultant)", label: t('Open (Consultant)') },
                { value: "Open (Office)", label: t('Open (Office)') },
                { value: "Submitted", label: t('Submitted') },
                { value: "Accepted", label: t('Accepted') },
                { value: "Provisioned", label: t('Provisioned') },
                { value: "Rejected", label: t('Rejected') },
                { value: "Retreat", label: t('Retreat') },
                { value: "Canceled", label: t('Canceled') },
                { value: "Quality", label: t('Quality') }
              ]}
              value={
                field.value
                  ? {
                      value: field.value,
                      label: field.value
                    }
                  : null
              }
              className="cursor-pointer font-inter-regular react-select-container"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                field.onChange(selectedValue);
                setInsideData((prevState) => ({
                  ...prevState,
                  filter_status: selectedValue
                }));
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>

      <PrimaryDatePicker
        label={t('Date of creation')}
        setStartDate={setDateOfCreation}
        startDate={DateOfCreation}
        wrapperClassName="!left-[2px]"
      />

      <div>
        <Label text={t('Mandated')} />
        <Controller
          name="filter_mandated"
          control={control}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder={t('Choose Mandated')}
              options={[
                { label: t('Yes'), value: "yes" },
                { label: t('No'), value: "no" }
              ]}
              value={
                field.value !== null
                  ? {
                      value: field.value ? "yes" : "no",
                      label: field.value ? t("Yes") : t('No')
                    }
                  : null
              }
              className="cursor-pointer font-inter-regular react-select-container"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                const booleanValue = selectedValue === "yes" ? 1 : selectedValue === "no" ? 0 : null;
                field.onChange(booleanValue);
                setInsideData((prevState: any) => ({
                  ...prevState,
                  filter_mandated: booleanValue
                }));
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>

      <div>
        <Label text={t('Product')} />
        <Controller
          name="filter_product"
          control={control}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder={t('Choose Product')}
              options={[
                { label: t("KVG"), value: "KVG" },
                { label: t("VVG"), value: "VVG" },
                { label: t("Life"), value: "Life" },
                { label: t("Lawprotection"), value: "Lawprotection" },
                { label: t("Business"), value: "Business" },
                { label: t("Auto"), value: "Auto" },
              ]}
              value={
                field.value
                  ? {
                      value: field.value,
                      label: field.value
                    }
                  : null
              }
              className="cursor-pointer font-inter-regular react-select-container"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                field.onChange(selectedValue);
                setInsideData((prevState) => ({
                  ...prevState,
                  filter_product: selectedValue
                }));
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>

      <div>
        <Label text={t('Company')} />
        <Controller
          name="filter_company"
          control={control}
          render={({ field }) => {
            const options = partnersData?.partners?.map?.((partner: any) => ({
              label: partner?.name,
              value: partner?.name,
            })) || [];
            return (
              <Creatable<any, false>
                {...field}
                placeholder={t('Choose Company')}
                options={options}
                value={
                  field.value
                    ? {
                        value: field.value,
                        label: field.value
                      }
                    : null
                }
                className="cursor-pointer font-inter-regular react-select-container"
                classNamePrefix="react-select"
                onChange={(selectedOption) => {
                  const selectedValue = selectedOption
                    ? selectedOption.value
                    : null;
                  field.onChange(selectedValue);
                  setInsideData((prevState) => ({
                    ...prevState,
                    filter_company: selectedValue
                  }));
                }}
                styles={customStyles}
                isValidNewOption={() => false}
                menuPosition={"fixed"}
                menuPortalTarget={document.body}
              />
            );
          }}
        />
      </div>

      <Input
        label={t('Provision')}
        name="filter_commission"
        onChange={(e) => {
          const { name, value } = e.target;
          setInsideData((prevFormData: IFilterProps) => ({
            ...prevFormData,
            [name]: value
          }));
        }}
        value={InsideData.filter_commission}
      />

      <div className="pt-5">
        <div className="flex flex-row justify-end gap-3">
          <IconButton
            secondary={true}
            className="w-[110px]"
            onClick={ResetData}
          >
           {t('Reset')}
          </IconButton>
          <IconButton className="w-[110px]" onClick={handleButtonClick}>
            {t('Confirm')}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default FilterCostumers;