import { memo } from "react";
interface Props {
  className?: string;
}
function ViewIcon({ className }: Props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.58333 1.75C7.73201 1.75016 7.87502 1.8071 7.98313 1.90916C8.09124 2.01123 8.1563 2.15072 8.16502 2.29915C8.17373 2.44757 8.12544 2.59372 8.03001 2.70774C7.93458 2.82175 7.79922 2.89502 7.65158 2.91258L7.58333 2.91667H2.91667V11.0833H11.0833V6.41667C11.0835 6.26799 11.1404 6.12498 11.2425 6.01687C11.3446 5.90876 11.4841 5.8437 11.6325 5.83498C11.7809 5.82627 11.9271 5.87456 12.0411 5.96999C12.1551 6.06541 12.2284 6.20078 12.2459 6.34842L12.25 6.41667V11.0833C12.2501 11.3777 12.1389 11.6612 11.9388 11.877C11.7387 12.0928 11.4643 12.225 11.1708 12.2471L11.0833 12.25H2.91667C2.62233 12.2501 2.33884 12.1389 2.12301 11.9388C1.90719 11.7387 1.77499 11.4643 1.75292 11.1708L1.75 11.0833V2.91667C1.74991 2.62233 1.86107 2.33884 2.06121 2.12301C2.26134 1.90719 2.53566 1.77499 2.82917 1.75292L2.91667 1.75H7.58333ZM11.2251 1.95008C11.3301 1.84546 11.4709 1.78472 11.6191 1.7802C11.7672 1.77568 11.9115 1.82771 12.0227 1.92573C12.1338 2.02375 12.2035 2.16041 12.2176 2.30794C12.2316 2.45548 12.189 2.60284 12.0983 2.72008L12.0499 2.7755L6.27492 8.54992C6.16994 8.65454 6.02908 8.71528 5.88094 8.7198C5.73281 8.72432 5.5885 8.67229 5.47734 8.57427C5.36617 8.47625 5.29649 8.33959 5.28243 8.19206C5.26838 8.04452 5.31101 7.89716 5.40167 7.77992L5.45008 7.72508L11.2251 1.95008Z"
        fill="#282D46"
      />
    </svg>
  );
}
export default memo(ViewIcon);
