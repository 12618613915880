import { FC } from "react";
import { IconType } from "../../../../lib/helpers/constants/iconTypes";
import Form from "../../../atoms/form/Form";
import Input from "../../../atoms/input/Input";
import DefaultContainer from "../../../templates/default-container/DefaultContainer";
import FormStepNavigationButtons from "../../../molecules/form-step-navigation-buttons/FormStepNavigationButtons";
import PasswordStrengthMeter from "../../../molecules/password-strength-meter/PasswordStrengthMeter";
import Icon from "../../../atoms/icon/Icon";
import Button from "../../../atoms/button/Button";
import Tooltip from "components/molecules/tooltip/Tooltip";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";
import { Fragment } from "react/jsx-runtime";
import ErrorState from "components/atoms/error-state/ErrorState";
import { useVerifyEmailExist } from "lib/hooks/queries/VerifyEmailExist/useVerifyEmailExist";
import Creatable from "react-select/creatable";
import { Controller } from "react-hook-form";
import { numbersCode } from "lib/helpers/constants/numbersCode";
import Label from "components/atoms/input/input-label/InputLabel";
import { customStyles } from "lib/helpers/configs/customStyles";
import Flag from "react-world-flags";

export interface ISignupStepProps {}
const SignupStep: FC<ISignupStepProps> = () => {
  const { mutate: verifyEmail, isError } = useVerifyEmailExist();

  const {
    isHovered,
    onMouseEnter,
    onMouseLeave,
    showPassword,
    toggleShowPassword,
    updateFormData,
    currentStep,
    errors,
    getValues,
    watch,
    handleSubmit,
    register,
    control
  } = useSignupCustomHooks({
    initalStep: 0
  });
  const options = numbersCode.map((code) => ({
    value: code.value,
    label: code.label,
    icon: code.icon
  }));

  const onSubmit = (data: Record<string, any>) => {
    verifyEmail({ email: getValues("email") });
    updateFormData(data);
  };

  const password = watch("password", "");
  const name = watch("name");

  const isAtLeast8Chars = password.length >= 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[@$!%*?&]/.test(password);
  const isNotPersonalInfo = password !== name;

  const formatOptionLabel = (data: any, { context }: any) => {
    if (context === "menu") {
      return (
        <div className="flex items-center">
          <Flag code={data.icon} height={14} width={14} />
          <span className="ml-2">{data.label}</span>
        </div>
      );
    } else {
      return (
        <div className="text-center mx-auto">
           {data.label}
        </div>
      )
    }
  };

  return (
    <Form
      className="flex flex-col justify-between h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <h1 className="text-bluebutton uppercase font-bold text-[14px] text-center mt-[40px] mb-[8px]">
          STEP {currentStep}/6
        </h1>
        <span className="text-[#282D46] font-inter-bold text-[25px] flex justify-center">
          Create Account
        </span>
      </div>
      <DefaultContainer className="flex flex-col gap-[15px] 2xl:gap-[24px]">
        <Input
          label="First name"
          placeholder="First name"
          type="text"
          {...register("name")}
          error={errors.name}
        />
        <Input
          label="Last name"
          placeholder="Last name"
          type="text"
          {...register("last_name")}
          error={errors.last_name}
        />
        <Input
          label="Email"
          placeholder="myemail@company.net"
          type="email"
          {...register("email")}
          error={errors.email}
        />
        <div>
          <Label text="Phone" />
          <div className="grid grid-cols-[100px,auto] gap-[16px] items-center">
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <Creatable<any, false>
                {...field}
                placeholder=""
                options={options}
                isValidNewOption={() => false}
                value={
                  options.find((option) => option.value === field.value) || options[0]
                }
                defaultValue={options[0]}
                className="cursor-pointer font-inter-regular react-select-container"
                classNamePrefix="react-select"
                onChange={(newValue: any, actionMeta: any) => {
                  if (
                    actionMeta.action === "select-option" &&
                    newValue?.value
                  ) {
                    field.onChange(newValue.value);
                  }
                }}
                styles={customStyles}
                formatOptionLabel={formatOptionLabel}
              />
            )}
          />
            <Input
              placeholder="Telephone number"
              {...register("phone")}
              classes={{ errorClassName: "left-[-111px]" }}
              error={errors.phone}
            />
          </div>
        </div>
        <div className="relative" onMouseLeave={onMouseLeave}>
          <Input
            label="Password"
            placeholder="Must be at least 8 characters"
            type={showPassword ? "text" : "password"}
            icon={
              showPassword
                ? IconType.HIDDEN_PASSWORD_ICON
                : IconType.SHOW_PASSWORD_ICON
            }
            toggleShowPassowrd={toggleShowPassword}
            error={errors.password}
            {...register("password")}
          />
          <Button onMouseEnter={onMouseEnter}>
            <Icon
              iconType={IconType.ALERT_ICON}
              className="absolute right-[-30px] bottom-[43px]"
              color="#D0D5DD"
            />
          </Button>
          <Tooltip
            isOpen={isHovered}
            className="absolute right-0 w-[387px] h-auto bg-white p-[25px] rounded-[25px] shadow-custom z-[99] top-[85px]"
            content={
              <Fragment>
                <h1 className="font-inter-medium text-primaryTextColor mb-[11px]">
                  Security guidelines
                </h1>
                <ul className="list-disc text-[14px]  ml-4">
                  <li
                    className={
                      isAtLeast8Chars
                        ? "text-[#2DE28B] line-through"
                        : "text-[#667085]"
                    }
                  >
                    Use at least 8 characters
                  </li>
                  <li
                    className={`${
                      hasUpperCase &&
                      hasLowerCase &&
                      hasNumber &&
                      hasSpecialChar
                        ? "text-[#2DE28B] line-through"
                        : "text-[#667085]"
                    }`}
                  >
                    Mix uppercase, lowercase, numbers, & symbols
                  </li>
                  <li
                    className={`${
                      isNotPersonalInfo
                        ? "text-[#2DE28B] line-through"
                        : "text-[#667085]"
                    }`}
                  >
                    Avoid common words or personal info
                  </li>
                </ul>
              </Fragment>
            }
          />
          <PasswordStrengthMeter password={watch("password")} />
        </div>
        <ErrorState error={isError}>
          Sorry this email is already in use. Please try another email!
        </ErrorState>
      </DefaultContainer>
      <FormStepNavigationButtons
        onPrevStep={true}
        titles={{ prevsteptitle: "Login" }}
        navigateToLogin={true}
      />
    </Form>
  );
};
export default SignupStep;
