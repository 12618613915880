import { memo } from "react";
interface Props {
  className?: string;
  color?:   string;
}
function UpArrowIcon({ className, color }: Props) {
  return (
    <svg
      width="13"
      height="7"
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.26262 6.70711C0.902136 6.34662 0.874406 5.77939 1.17943 5.3871L1.26262 5.29289L6.26262 0.292893C6.6231 -0.0675907 7.19033 -0.0953203 7.58263 0.209705L7.67683 0.292893L12.6768 5.29289C13.0674 5.68342 13.0674 6.31658 12.6768 6.70711C12.3163 7.06759 11.7491 7.09532 11.3568 6.7903L11.2626 6.70711L6.96973 2.415L2.67683 6.70711C2.31635 7.06759 1.74912 7.09532 1.35683 6.7903L1.26262 6.70711Z"
        fill={color || '#7E84A3'}
      />
    </svg>
  );
}
export default memo(UpArrowIcon);
