import { memo } from "react";
interface Props {
  className?: string;
}
function UpgradePlanIcon({ className }: Props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.04204 2.25391L3.03345 9.46422C2.79816 9.74659 2.68055 9.88774 2.67875 10.007C2.67797 10.0582 2.68883 10.1088 2.71052 10.1551C2.73221 10.2015 2.76415 10.2423 2.80393 10.2744C2.89673 10.3494 3.08053 10.3494 3.44812 10.3494H8.36741L7.69279 15.7464L13.7014 8.53609C13.9367 8.25372 14.0544 8.11258 14.0562 7.99332C14.0569 7.94215 14.0461 7.89148 14.0244 7.84515C14.0027 7.79881 13.9707 7.75802 13.9309 7.72586C13.8382 7.65091 13.6544 7.65091 13.2868 7.65091H8.36741L9.04204 2.25391Z"
        fill="white"
        fillOpacity="0.01"
        stroke="white"
        strokeWidth="1.49884"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default memo(UpgradePlanIcon);
