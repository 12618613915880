import IconButton from "components/atoms/button/IconButton";
import Headline from "components/atoms/headline/Headline";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import DashboardBoxTitle from "components/atoms/title/DashboardBoxTitle";
import Accordion from "components/molecules/accordion/Accordion";
import AccordionBody from "components/molecules/accordion/AccordionBody/AccordionBody";
import AccordionTitle from "components/molecules/accordion/AccordionTitle/AccordionTitle";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import Flag from "react-world-flags";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import NewCompany from "./new-company/NewCompany";
import AddTemplate from "./add-template/AddTemplate";
import { useResources } from "lib/hooks/queries/Documents/useResources";
import { useGetTemplates } from "lib/hooks/queries/Documents/useGetTemplates";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import NewOverview from "./new-overview/NewOverview";
import NewBaseOverview from "./new-overview/NewBaseOverview";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import UpdateCompany from "./update-company/UpdateCompany";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";

export interface InsuranceDocumentsViewProps { }

const InsuranceDocuments: FC<InsuranceDocumentsViewProps> = () => {
  const { t } = useTranslation();

  const [ResourceSearch, setResourceSearch] = useState("");
  const { data: resources, isLoading: isLoadingResources } = useResources({
    search: ResourceSearch,
  });

  const [TemplateSearch, setTemplateSearch] = useState("");
  const { data: templates, isLoading: isLoadingTemplates } = useGetTemplates({
    search: TemplateSearch,
  });

  const [NewCompanyModalOpen, setNewCompanyModalOpen] = useState(false);
  const [AddTemplateModalOpen, setAddTemplateModalOpen] = useState(false);

  const [OverViewDocumentModalOpen, setOverViewDocumentModalOpen] = useState(null);

  const [OverViewModalOpen, setDocumentModalOpen] = useState(null);
  const [UpdateCompanyModalOpen, setUpdateCompanyModalOpen] = useState(null);

  const hasPermissions = usePermissions([
    P["Costumer Admin"],
    P["Costumer Menager"],
    P["Leads Admin"],
    P["Leads Menager"],
    P["Appointment Admin"],
    P["Appointment Menager"],
  ]);

  const token: any = localStorage.getItem("token");

  const DownloadFile = (url: any) => {
    const file = url?.file;
    const fileName = url?.name ?? "";

    if (!file) {
      console.error("File is undefined or empty");
      return;
    }

    fetch(`https://mainhub.insusales.com/api/insurance-management/${file}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/octet-stream",
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            console.error("Server returned an error:", json);
            throw new Error(`Server returned an error: ${json.message}`);
          });
        }
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          return response.json().then((json) => {
            console.error("Server returned JSON instead of file:", json);
            throw new Error(
              `Server returned JSON instead of file: ${json.message}`
            );
          });
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) =>
        console.error("There was an error with the fetch operation:", error)
      );
  };

  return (
    <>
      <Headline title={t("Documents")} />
      <div className="flex gap-5">
        <div className="basis-2/3">
          <DashboardBoxLayout className="mt-[26px]">
            <ConditionalRender condition={hasPermissions}>
            <div className="flex gap-4 justify-between items-center">
              <DashboardBoxTitle title={`Resources`} />
              <IconButton
                onClick={() => setNewCompanyModalOpen(true)}
                icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
              >
                {t("New Company")}
              </IconButton>
            </div>
            </ConditionalRender>
            <div className="flex-1 my-[25px]">
              <Input
                icon={IconType.SEARCH_ICON}
                className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
                classes={{ icon: "left-4 !top-[30%]" }}
                placeholder={t("Search")}
                value={ResourceSearch}
                onChange={(e) => setResourceSearch(e.target.value)}
              />
            </div>
            {isLoadingResources ? (
              <div className="relative py-10">
                {" "}
                <LoadingScreen />
              </div>
            ) : (
              <NoDataBoundary
                condition={resources && (resources as any).data.length > 0}
                fallback={
                  <p className="text-[#7D8592] font-inter-regular mx-auto my-20 flex justify-center">
                    {t("There is no")} data {t("in this section.")}
                  </p>
                }
              >
                {(resources as any)?.data.map((item: any, index: number) => (
                  <Accordion
                    classes={{ wrapper: "!border-[#F2F4F7] p-[25px]" }}
                    key={index}
                  >
                    <AccordionTitle subTitleClass="w-full">
                      <div className="flex gap-4 items-center w-full relative">
                        <ConditionalRender condition={item.file}>
                          <img src={`https://mainhub.insusales.com/api/insurance-management/${item.file}`} alt="" className="w-[44px] h-[44px] flex-none" />
                        </ConditionalRender>
                        <div className="flex-1 text-start">
                          <p className="font-inter-semibold text-primaryTextColor">
                            {item.name}
                          </p>
                          <span className="text-sm font-inter-regular text-[#7D8592]">
                            {new Date(item.created_at).toLocaleDateString("de")}
                          </span>
                        </div>
                        <div className="pe-10">
                          <a
                            href={item.link_to}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconButton>{t("Login to portal")}</IconButton>
                          </a>
                        </div>
                        <ConditionalRender condition={hasPermissions}>
                        <div className="absolute top-[-17px] right-[-35px] cursor-pointer" onClick={() => { setUpdateCompanyModalOpen(item) }}>
                          <Icon iconType={IconType.OPTIONS_ICON} />
                        </div>
                        </ConditionalRender>
                      </div>
                    </AccordionTitle>
                    <AccordionBody>
                      <div className="flex flex-col gap-5">
                        {item.overview_docs.map((doc: any, index: number) => (
                          <div key={index}>
                            <div className="text-xs text-primaryTextColor font-inter-semibold pb-3">
                              {doc.name}
                            </div>
                            <div className="flex gap-3">
                              {doc?.files !== null &&
                                doc.files.map((file: any, index: number) => (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://mainhub.insusales.com/api/insurance-management/${file.file}`}
                                  >
                                    <div className="flex gap-2 bg-[#F2F4F7] px-5 py-3 rounded-[8px] items-center">
                                      <Flag
                                        code={
                                          file.country_flag === "en"
                                            ? "us"
                                            : file.country_flag
                                        }
                                        height={30}
                                        width={30}
                                      />
                                      <span className="text-sm text-primaryTextColor font-inter-regular">
                                        {file.name}
                                      </span>
                                    </div>
                                  </a>
                                ))}
                                <ConditionalRender condition={hasPermissions}>
                                <div
                                  className="h-[44px] w-[44px] bg-[#F2F4F7] rounded-[8px] flex items-center justify-center cursor-pointer"
                                  onClick={() => {
                                    setOverViewDocumentModalOpen(doc.id);
                                  }}>
                                  <Icon
                                    iconType={IconType.PLUS_ICON}
                                    color="#50B8E7"
                                  />
                                </div>
                                </ConditionalRender>
                            </div>
                          </div>
                        ))}
                        <ConditionalRender condition={hasPermissions}>
                        <div className="flex gap-2 items-center">
                          <Icon iconType={IconType.PLUS_ICON} color="#50B8E7" />
                          <span
                            onClick={() => {
                              setDocumentModalOpen(item.id);
                            }}
                            className="font-inter-semibold text-[--theme] cursor-pointer"
                          >
                            {t("Add new insurance overview")}
                          </span>
                        </div>
                        </ConditionalRender>
                      </div>
                    </AccordionBody>
                  </Accordion>
                ))}
              </NoDataBoundary>
            )}
          </DashboardBoxLayout>
        </div>
        <div className="basis-1/3">
          <DashboardBoxLayout className="mt-[26px]">
            <div className="flex justify-between gap-4 items-center">
              <DashboardBoxTitle title={`Templates`} />
              <ConditionalRender condition={hasPermissions}>
              <div
                className="bg-[--theme] max-w-[50px] w-full h-[30px] grid place-items-center rounded-[8px] cursor-pointer"
                onClick={() => {
                  setAddTemplateModalOpen(true);
                }}
              >
                <Icon iconType={IconType.PLUS_ICON} />
              </div>
              </ConditionalRender>
            </div>
            <div className="flex-1 my-[25px]">
              <Input
                icon={IconType.SEARCH_ICON}
                className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
                classes={{ icon: "left-4 !top-[30%]" }}
                placeholder={t("Search")}
                value={TemplateSearch}
                onChange={(e) => setTemplateSearch(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-6">
              {isLoadingTemplates ? (
                <div className="relative py-10">
                  <LoadingScreen />
                </div>
              ) : (
                <NoDataBoundary
                  condition={templates && templates.length > 0}
                  fallback={
                    <p className="text-[#7D8592] font-inter-regular mx-auto my-20 flex justify-center">
                      {t("There is no")} data {t("in this section.")}
                    </p>
                  }
                >
                  {templates?.map((item: any, index: number) => (
                    <div className="flex gap-3 items-center" key={index}>
                      <Icon
                        iconType={
                          item.file.split(".")[item.file.split('.').length - 1] === "pdf"
                            ? IconType.FILE_PDF_ICON
                            : item.file.split(".")[item.file.split('.').length - 1] === "csv" ||
                              item.file.split(".")[item.file.split('.').length - 1] === "xlsx" ||
                              item.file.split(".")[item.file.split('.').length - 1] === "xls"
                              ? IconType.UPLOADED_CSV_ICON
                              : item.file.split(".")[item.file.split('.').length - 1] === "doc" ||
                                item.file.split(".")[item.file.split('.').length - 1] === "docx"
                                ? IconType.FILE_WORD_ICON
                                : IconType.FILE_JPG_ICON
                        }
                        className="flex-none"
                      />
                      <div className="flex-1">
                        <p className="text-primaryTextColor font-inter-medium">
                          {item.name}
                        </p>
                        <span className="text-xs text-[#7D8592] font-inter-regular">
                          {new Date(item.created_at).toLocaleDateString("de")}
                        </span>
                      </div>
                      <div>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://mainhub.insusales.com/api/insurance-management/${item.file}`}
                        >
                          <Icon
                            iconType={IconType.SHOW_PASSWORD_ICON}
                            color="#7D8592"
                            className="flex-none"
                          />
                        </a>
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          DownloadFile(item);
                        }}
                      >
                        <Icon
                          iconType={IconType.UPLOAD_FILE_ICON}
                          color="#7D8592"
                          className="flex-none"
                        />
                      </div>
                    </div>
                  ))}
                </NoDataBoundary>
              )}
            </div>
          </DashboardBoxLayout>
        </div>
      </div>
      <ConditionalRender condition={NewCompanyModalOpen}>
        <NewCompany
          onConfirm={() => {
            setNewCompanyModalOpen(false);
          }}
          clearData={true}
          hocisOpen={NewCompanyModalOpen}
          hocCurrentStep={1}
          hocToggleModal={() => setNewCompanyModalOpen(false)}
          hocTitle={t("Add New Company")}
          hocClasses={{
            modalClassName: "!max-w-[484px] w-full",
          }}
        />
      </ConditionalRender>
      <ConditionalRender condition={AddTemplateModalOpen}>
        <AddTemplate
          onConfirm={() => {
            setAddTemplateModalOpen(false);
          }}
          clearData={true}
          hocisOpen={AddTemplateModalOpen}
          hocCurrentStep={1}
          hocToggleModal={() => setAddTemplateModalOpen(false)}
          hocTitle={t("Add Template")}
          hocClasses={{
            modalClassName: "!max-w-[484px] w-full",
          }}
        />
      </ConditionalRender>
      <ConditionalRender condition={OverViewDocumentModalOpen !== null}>
        <NewOverview
          onConfirm={() => {
            setOverViewDocumentModalOpen(null);
          }}
          clearData={true}
          id={OverViewDocumentModalOpen as unknown as string}
          hocisOpen={OverViewDocumentModalOpen !== null}
          hocCurrentStep={1}
          hocToggleModal={() => setOverViewDocumentModalOpen(null)}
          hocTitle={t("Add Service Overview File")}
          hocClasses={{
            modalClassName: "!max-w-[484px] w-full",
          }}
        />
      </ConditionalRender>
      <ConditionalRender condition={OverViewModalOpen !== null}>
        <NewBaseOverview
          onConfirm={() => {
            setDocumentModalOpen(null);
          }}
          clearData={true}
          id={OverViewModalOpen as unknown as string}
          hocisOpen={OverViewModalOpen !== null}
          hocCurrentStep={1}
          hocToggleModal={() => setDocumentModalOpen(null)}
          hocTitle={t("Add new insurance overview")}
          hocClasses={{
            modalClassName: "!max-w-[484px] w-full",
          }}
        />
      </ConditionalRender>
      <ConditionalRender condition={UpdateCompanyModalOpen !== null}>
        <UpdateCompany
          onConfirm={() => {
            setUpdateCompanyModalOpen(null);
          }}
          clearData={true}
          data={UpdateCompanyModalOpen}
          hocisOpen={UpdateCompanyModalOpen !== null}
          hocCurrentStep={1}
          hocToggleModal={() => setUpdateCompanyModalOpen(null)}
          hocTitle={t("Update Company")}
          hocClasses={{
            modalClassName: "!max-w-[484px] w-full",
          }}
        />
      </ConditionalRender>
    </>
  );
};

export default InsuranceDocuments;
