import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { forwardRef } from "react";

interface ILeadTypeBoardProps {
  data?: Array<JSX.Element>;
  borderColor: string;
  bgColor: string;
  title: string;
  openCreateModal: () => void;
  containerClassName?: string;
}

const TypeBoard = forwardRef<HTMLDivElement, ILeadTypeBoardProps>(
  (
    { data, borderColor, bgColor, title, openCreateModal, containerClassName },
    ref
  ) => {
    return (
      <div
        className={`w-[350px] border-2 max-h-[490px] verticalOverflowScrollStyle overflow-y-scroll  rounded-[12px] border-[#282D4614] border-dashed p-3 ${containerClassName}`}
        style={{ backgroundColor: bgColor }}
      >
        <div
          className="flex flex-row gap-2 justify-between items-center pb-4 mb-4 border-b-2 rounded-[2px]"
          style={{ borderBottomColor: borderColor }}
        >
          <div className="flex-none">
            <span className="font-inter-medium text-sm text-secondaryTextColor">
              {title} ({data ? data.length : 0})
            </span>
          </div>
          <div className="flex-none cursor-pointer" onClick={openCreateModal}>
            <Icon iconType={IconType.CIRCLE_PLUS_ICON} />
          </div>
        </div>
        <div className="flex flex-col gap-3">{data}</div>
      </div>
    );
  }
);

export default TypeBoard;
