import React, { FC, useEffect, useState } from "react";
import { AuthContext, IAuthContext } from "./AuthContext";

import { verifyToken } from "api/User";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

export interface IAuthContextProviderProps {
  children: React.ReactNode;
}

const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [SelectedLanguage, setSelectedLanguage] = useState('en')

  const [user, setUser]: any = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { i18n } = useTranslation();

  async function Authenticate() {
    try {
      const userToken: any = await localStorage.getItem("token");

      if (userToken !== null) {
        verifyToken(userToken)
          .then((data) => {
            if (data.status) {
              setIsAuthenticated(true);
              setUser(data.user);
              i18n.changeLanguage(data?.user?.user_information.language || 'en');
              setSelectedLanguage(data?.user?.user_information.language || 'en');
              setIsLoading(false);
              axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
              if (pathname === "/") {
                navigate("/leads");
              }
            }
          })
          .catch(() => {
            setIsAuthenticated(false);
            navigate("/");
            setUser(null);
            localStorage.removeItem("token");
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    } catch (error: any) {
      logout();
      setIsLoading(false);
      console.error(error);
    }
  }

  async function logout() {
    const userToken: any = await localStorage.getItem("token");
    try {
      verifyToken(userToken).then(() => {
        localStorage.removeItem("token");
        setUser(null);
        setIsAuthenticated(false);
        setIsLoading(false);
        return navigate("/");
      });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    Authenticate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ContextValues: IAuthContext = {
    isAuthenticated,
    setIsAuthenticated,
    isLoading,
    SelectedLanguage,
    setSelectedLanguage,
    user,
    setUser,
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <AuthContext.Provider value={ContextValues}>
      <>{children}</>
    </AuthContext.Provider>
  );
};
export default AuthContextProvider;
