

import React, { FC } from 'react'
import ResetPasswordProcces from 'components/organisms/resetPassword/ResetPasswordProcces'


export interface IResetPasswordProps { }


const ResetPassword: FC<IResetPasswordProps> = () => {

    

    return (
        <ResetPasswordProcces />
    )
}

export default ResetPassword
