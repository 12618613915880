import { useQuery } from "react-query";
import { getAllSources } from "api/Sources";

export const useAllSources = () => {
    const { data, isLoading } = useQuery<Array<any> | any>(["sources"],
        () => {
            return getAllSources();
        },
    );

    return {
        data,
        isLoading,
    };
};
