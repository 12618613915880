import DashboardBoxTitle from "components/atoms/title/DashboardBoxTitle";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import { customStylesLightBorder } from "lib/helpers/configs/customStyles";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { PermissionsTypes } from "lib/helpers/constants/permissions";
import PendingsChart from "../AllCharts/PendingsChart";
import { useAppointmentFeedbacks } from "lib/hooks/queries/Statistics/AppointmentFeedbacks/useAppointmentFeedbacks";
import FilterBorderedWrapper from "components/atoms/filter-bordered-wrapper/FilterBorderedWrapper";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import Filter from "../Dropdowns/Filter";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";

export interface IStatisticsProps {
    fetchedUsers: string[]
}

const AppointmentStatistics: FC<IStatisticsProps> = ({ fetchedUsers }) => {


    const { t } = useTranslation();

    const [Filters, setFilters] = useState({
        worker: 'All agents',
        filter_on: 'Today',
    })

    const { data, isLoading } = useAppointmentFeedbacks(Filters);

    const [hoveredSlice, setHoveredSlice] = useState<string | null>(null);

    const hasAdminPermissions = usePermissions([PermissionsTypes["Leads Admin"]]);
    const hasMenagerPermissions = usePermissions([PermissionsTypes["Leads Menager"]]);

    const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);


    return (
        <DashboardBoxLayout className="mt-[26px]">
            <div className="flex flex-row gap-3 items-center justify-between mb-5">
                <div className="flex-1">
                    <DashboardBoxTitle
                        title={`Appointments`}
                    />
                </div>

                <div className="flex-none">
                    <div className={`${filterTableOpen && "relative"}`}>
                        <FilterBorderedWrapper
                            className="h-[48px] w-[48px] cursor-pointer"
                            onClick={() => {
                                setFilterTableOpen(!filterTableOpen);
                            }}
                        >
                            <Icon iconType={IconType.FILTER_ICON} />
                        </FilterBorderedWrapper>
                        <ConditionalRender condition={filterTableOpen}>
                            <DynamicDropdown
                                setIsOpen={setFilterTableOpen}
                                showCloseButton={true}
                                title={t('Filter')}
                                className="sm:!w-[350px]"
                            >
                                <Filter
                                    setIsOpen={setFilterTableOpen}
                                    setFilterData={setFilters}
                                    FilterData={Filters}
                                />
                            </DynamicDropdown>
                        </ConditionalRender>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-3 items-center mb-5 md:flex-row">
                <ConditionalRender condition={hasAdminPermissions || hasMenagerPermissions}>
                    <Creatable<any, false>
                        placeholder={t('Choose Salesman')}
                        options={[
                            ...[
                                { name: t('All agents'), id: 'All agents' },
                                ...fetchedUsers,
                            ].map((user: any) => ({
                                label: user?.name,
                                value: user?.id,
                            })),
                        ]}
                        value={{
                            value: Filters.worker,
                            label: (fetchedUsers as any).find((user: any) => user.id === Filters.worker)?.name || t('All agents'),
                        }}
                        className="cursor-pointer font-inter-regular react-select-container min-w-[300px]"
                        classNamePrefix="react-select"
                        onChange={(newValue: any) => {
                            setFilters((prevFilters) => ({
                                ...prevFilters,
                                worker: newValue.value,
                            }));
                        }}
                        styles={customStylesLightBorder}
                        isValidNewOption={() => false}
                        menuPosition={"fixed"}
                        menuPortalTarget={document.body}
                    />
                </ConditionalRender>

            </div>

            <div className="flex flex-col gap-20 items-center mb-5 md:flex-row relative">
                {isLoading ? <div className="py-10"> <LoadingScreen /></div> : (
                    <ConditionalRender condition={data !== undefined}>
                        <div className="basis-1/3">
                            <PendingsChart
                                setHoveredSlice={setHoveredSlice}
                                data={[
                                    { name: 'Won', y: ((data as any).data?.won / (data as any).data?.total) * 100, z: 100 },
                                    { name: 'Follow Up', y: ((data as any).data?.follow_up / (data as any).data?.total) * 100, z: 100 },
                                    { name: 'Lost', y: ((data as any).data?.lost / (data as any).data?.total) * 100, z: 100 },
                                    { name: 'Open', y: ((data as any).data?.open / (data as any).data?.total) * 100, z: 100 },
                                    { name: 'Pending', y: ((data as any).data?.pending / (data as any).data?.total) * 100, z: 100 },
                                ]}

                                color={['#2CC95B']}
                            />
                        </div>
                        <div className="basis-2/3">
                            <div className="grid-cols-2 grid gap-3">
                                {[
                                    { status: 'Won', count: (data as any).data?.won },
                                    { status: 'Follow Up', count: (data as any).data?.follow_up },
                                    { status: 'Lost', count: (data as any).data?.lost },
                                    { status: 'Pending', count: (data as any).data?.pending },
                                    { status: 'Open', count: (data as any).data?.open },
                                    { status: 'Total Appointments', count: (data as any).data?.total },
                                ].map((item: { status: string, count: number }, index: number) => (
                                    <div
                                        onMouseEnter={() => setHoveredSlice(item.status)}
                                        onMouseLeave={() => setHoveredSlice(null)}
                                        key={index}
                                        className={`flex justify-between gap-3 items-center transition-colors rounded-[8px] py-[10px] px-[20px]
                                            ${item.status === 'Total Appointments' ? 'col-span-2' : 'col-span-1'} ${item.status === hoveredSlice
                                                ? 'text-white bg-[#2CC95B] border-[#2CC95B]'
                                                : 'text-primaryTextColor border border-[#F2F4F7]'} `
                                        }>
                                        <div className="font-inter-medium">
                                            {t(item.status)}
                                        </div>
                                        <div className="font-inter-bold">
                                            {item.count}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </ConditionalRender>
                )}
            </div>
        </DashboardBoxLayout>
    );
};

export default AppointmentStatistics;
