import { memo } from "react";
interface Props {
    className?: string;
    color?: string
}
function DocumentMenuIcon({ className, color }: Props) {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke={color ? color : '#282D46'} d="M22.47 18.8216L21.47 14.9616L18.32 3.37162C18.2519 3.11596 18.0853 2.89769 17.8567 2.76462C17.628 2.63154 17.3559 2.59451 17.1 2.66162L13.23 3.66162C13.1376 3.55926 13.0251 3.47711 12.8995 3.42032C12.7739 3.36353 12.6379 3.33333 12.5 3.33162H2.5C2.23478 3.33162 1.98043 3.43697 1.79289 3.62451C1.60536 3.81205 1.5 4.0664 1.5 4.33162V20.3316C1.5 20.5968 1.60536 20.8512 1.79289 21.0387C1.98043 21.2263 2.23478 21.3316 2.5 21.3316H12.5C12.7652 21.3316 13.0196 21.2263 13.2071 21.0387C13.3946 20.8512 13.5 20.5968 13.5 20.3316V12.3316L15.7 20.5516C15.7586 20.7698 15.8893 20.9617 16.0709 21.096C16.2525 21.2304 16.4742 21.2994 16.7 21.2916C16.7864 21.3014 16.8736 21.3014 16.96 21.2916L21.79 20.0016C21.9177 19.9675 22.0374 19.9084 22.1421 19.8277C22.2468 19.747 22.3344 19.6464 22.4 19.5316C22.5055 19.3104 22.5302 19.0592 22.47 18.8216ZM6.47 19.3716H3.47V17.3716H6.47V19.3716ZM6.47 15.3716H3.47V9.37162H6.47V15.3716ZM6.47 7.37162H3.47V5.37162H6.47V7.37162ZM11.47 19.3716H8.47V17.3716H11.47V19.3716ZM11.47 15.3716H8.47V9.37162H11.47V15.3716ZM11.47 7.37162H8.47V5.37162H11.47V7.37162ZM13.72 5.63162L16.62 4.85162L17.14 6.78162L14.24 7.56162L13.72 5.63162ZM16.31 15.2916L14.76 9.49162L17.66 8.71162L19.21 14.5116L16.31 15.2916ZM17.31 19.1516L16.79 17.2216L19.69 16.4416L20.21 18.3716L17.31 19.1516Z"/>
        </svg>
    );
}
export default memo(DocumentMenuIcon);
