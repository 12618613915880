import React, { FC } from 'react'
import LoginTitle from '../../../atoms/title/LoginTitle'
import Paragraph from '../../../atoms/paragraph/Paragraph'
import Button from '../../../atoms/button/Button'

import Icon from '../../../atoms/icon/Icon'
import { IconType } from '../../../../lib/helpers/constants/iconTypes'
import BlueLink from '../../../atoms/link/BlueLink'

export interface ICheckEmailProps { }


const CheckEmail: FC<ICheckEmailProps> = () => {
    

    return (
        <div className='flex flex-col gap-8'>
            <div>
                <div className='pb-2'>
                    <LoginTitle
                        title='Please check your e-mail!'
                    />
                </div>
                <Paragraph
                    paragraph='A password recovery link has been sent to your e-mail address.'
                />
            </div>
            <div className='flex flex-col gap-6'>
                <Icon iconType={IconType.CHECK_EMAIL_ICON} className='max-w=[200px] h-auto m-auto' />
                <div className='pt-5'>
                    <a href='/'>
                        <Button className='bg-bluebutton text-white p-[15px] w-full rounded-[8px]'>
                        Return to the login screen.
                        </Button>
                    </a>
                </div>
                <div className='text-center'>
                    <span className='text-[14px] font-inter-regular'>No e-mail received?  </span><BlueLink to='/forgot-password' title='Resend e-mail.' />
                </div>
            </div>

        </div>
    )
}

export default CheckEmail