import { ForwardRefRenderFunction, HTMLAttributes, forwardRef } from "react";
import Icon from "../../atoms/icon/Icon";
import { IconType } from "../../../lib/helpers/constants/iconTypes";
import { Controller, Control, DeepMap, FieldError } from "react-hook-form";
import ErrorMessage from "../../atoms/error-message/ErrorMessage";
import Input from "../../atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import { Fragment } from "react/jsx-runtime";
import UploadedFile from "../uploaded-file/UploadedFile";
import { useTranslation } from "react-i18next";




export interface IImageDropZoneClasses {
  container?: string;
  label?: string;
  input?: string;
  wrapperIcon?: string;
  dragTextClass?: string;
}

export interface IFileDropZoneProps extends HTMLAttributes<HTMLDivElement> {
  control: Control;
  name: string;
  error?: DeepMap<any, FieldError>;
  classes?: Partial<IImageDropZoneClasses>;
  label?: string;
  file?: File | any;
  setFile?: (args: File) => void
  formats?: string
  themesColor?: {
    primary: string;
  }
}

const FileDropZone: ForwardRefRenderFunction<
  HTMLInputElement,
  IFileDropZoneProps
> = (
  {
    control,
    error,
    name,
    label,
    file,
    setFile,
    themesColor,
    formats,
    classes = { container: "", label: "", input: "", wrapperIcon: '', dragTextClass: '' },
    ...rest
  },
  ref
) => {


    const { t } = useTranslation();
    return (
      <Fragment>
        {label && <Label htmlFor={name} text={label} />}
        <Controller
          control={control}
          name={name}
          defaultValue={""}
          render={({ field: { onChange } }) => (
            <div
              className={`flex justify-center relative ${classes.container}`}
              {...rest}
            >
              <div className="flex items-center justify-center w-full">
                <label
                  className={`flex flex-col-reverse cursor-pointer w-full h-[133px] border-[1px] rounded-[10px] border-border border-dashed bg-dropzonebg relative ${classes.label}`}
                >
                  <div className="flex flex-col items-center justify-center">
                    <div className={`bg-[--theme] w-[40px] absolute top-[20%] h-[40px] rounded-[10px] flex items-center justify-center ${classes.wrapperIcon}`}>
                      <Icon iconType={IconType.UPLOAD_FILE_ICON} />
                    </div>
                    <div className="mb-3 text-center">
                      <p className={`text-primaryTextColor font-inter-regular mb-1 ${classes.dragTextClass || 'text-[14px]'}`}>
                        {t(`Drag & Drop or`)}{" "}
                        <span className={`text-[--theme] underline`}>
                          {t('choose file')}
                        </span>{" "}
                        {t('to upload')}
                      </p>
                      <p className="text-[#A2A1A8] text-[11px] font-inter-regular">
                        {t('Supported formats: pdf, doc, docx, csv. Maximum size: 10 MB')}.
                      </p>
                    </div>
                  </div>
                  <Input
                    type="file"
                    onChange={(e) => {
                      if (setFile) {
                        setFile(e.target.files as any)
                      }
                    }}
                    accept={formats ? formats : '.pdf, .doc, .docx, .csv'}
                    id={`${name}-input"}`}
                    className={`opacity-0 ${classes.input}`}
                    ref={ref}
                  />
                </label>
              </div>
              <ErrorMessage error={error} />
            </div>
          )}
        />
        {
          (file && file.length) && (
            <div className="pt-3">
              <UploadedFile
                icon={file[0].name.split('.')[1] === 'pdf'
                  ? IconType.FILE_PDF_ICON
                  : file[0].name.split('.')[1] === 'csv' ||
                    file[0].name.split('.')[1] === 'xlsx' ||
                    file[0].name.split('.')[1] === 'xls'
                    ? IconType.UPLOADED_CSV_ICON
                    : file[0].name.split('.')[1] === 'doc' || file[0].name.split('.')[1] === 'docx'
                      ? IconType.FILE_WORD_ICON
                      : IconType.FILE_JPG_ICON
                }
                title={file[0].name}
                size={`${(Math.round(+file[0].size / 1024) / 1000).toFixed(2)} MB`}
                isEditable={false}

              />

            </div>
          )
        }
      </Fragment>
    );
  };

export default forwardRef(FileDropZone);
