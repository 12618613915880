import { markAsReadNotifcation } from "api/Notifications"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"


export const useMarkAsReadNotification = () => {
    const queryClient = useQueryClient();
    return useMutation<any, any, number>(
        (id:number) => markAsReadNotifcation(id),
        {
            onSuccess: () => {
                queryClient.refetchQueries('notifications')
            },
            onError: () => {
                toast.error('Something went wrong')
            }
        },
    )
}