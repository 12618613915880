import React from "react";
import Button from "components/atoms/button/Button";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { AccordionTitleProps } from "lib/types/accordion.types";
import { Fragment } from "react/jsx-runtime";

const AccordionTitle: React.FC<AccordionTitleProps> = ({
  children,
  accordionTitleClass = "",
  subTitleClass = "",
  open,
  onClick,
  customComponent,
}) => {
  const iconType = open ? IconType.UP_ARROW_ICON : IconType.DOWN_ARROW_ICON;

  const buttonClass =
    "w-full flex justify-between items-center  bg-transparent lg:mx-auto " +
    accordionTitleClass;

  return (
    <Fragment>
      <Button type="button" className={buttonClass} onClick={onClick}>
        <h1 className={`text-[#667085] text-[14px] font-inter-regular ${subTitleClass}`}>
          {children}
        </h1>
        <Icon iconType={iconType} />
      </Button>
      {customComponent && customComponent}
    </Fragment>
  );
};

export default AccordionTitle;
