import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function ProcessingPendingIcon({ className,color }: Props) {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.9217 0H2.89062C1.39154 0 0.171875 1.21967 0.171875 2.71875V20.0623C0.171875 20.8378 0.802673 21.4686 1.57812 21.4686H7.73431C7.99323 21.4686 8.20306 21.2587 8.20306 20.9998C8.20306 20.7409 7.99323 20.5311 7.73431 20.5311H1.57812C1.31958 20.5311 1.10938 20.3209 1.10938 20.0623V2.71875C1.10938 1.73657 1.90845 0.9375 2.89062 0.9375H16.8704C16.4553 1.41504 16.2029 2.03778 16.2029 2.71875V17.825C16.2029 18.2574 15.8512 18.6092 15.4187 18.6092C15.0807 18.6092 14.7817 18.3937 14.6746 18.0731L13.9681 15.9534C13.7687 15.355 13.2108 14.9531 12.5802 14.9531C11.7734 14.9531 11.1172 15.6094 11.1172 16.416V16.7811H8.68756C8.42865 16.7811 8.21881 16.9911 8.21881 17.2498C8.21881 17.5087 8.42865 17.7186 8.68756 17.7186H11.1172V20.0506C11.1172 20.7427 11.2521 21.4177 11.5184 22.0563L12.2079 23.7114C12.283 23.8914 12.4572 24 12.6408 24C12.7009 24 12.762 23.9885 12.8208 23.9639C13.0599 23.8643 13.1729 23.5898 13.0733 23.3509L12.3837 21.6958C12.1655 21.1719 12.0547 20.6184 12.0547 20.0508V16.4161C12.0547 16.1263 12.2903 15.8906 12.5802 15.8906C12.8067 15.8906 13.0072 16.0349 13.0788 16.2499L13.7852 18.3695C14.02 19.0735 14.6764 19.5467 15.4187 19.5467C16.3681 19.5467 17.1404 18.7744 17.1404 17.825V9.78827L17.7668 10.4134C18.8845 11.5289 19.4999 13.0129 19.4999 14.5919V19.5747C19.4999 20.8654 19.2075 22.164 18.6545 23.3302C18.5436 23.564 18.6434 23.8436 18.8772 23.9546C19.111 24.0656 19.3906 23.9659 19.5016 23.7319C20.1137 22.441 20.4374 21.0035 20.4374 19.5747V14.5919C20.4374 12.7621 19.7242 11.0425 18.4291 9.74982L17.1404 8.4635V8.01562H20.2342C21.0096 8.01562 21.6404 7.38483 21.6404 6.60938V2.71875C21.6404 1.21967 20.421 0 18.9217 0ZM20.7029 6.60938C20.7029 6.86774 20.4927 7.07812 20.2342 7.07812H17.1404V2.71875C17.1404 1.73657 17.9395 0.9375 18.9217 0.9375C19.9039 0.9375 20.7029 1.73657 20.7029 2.71875V6.60938Z"
        fill={color || '#50B8E7'}
      />
    </svg>
  );
}
export default memo(ProcessingPendingIcon);
