import { useQuery } from "react-query"
import { getPartnersForFilter } from "api/Partners";


export const usePartners = (search?: string) => {
    return useQuery<Array<any> | any>(
      'partners',
        () => getPartnersForFilter(),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}