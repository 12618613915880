import Table from 'components/molecules/table/Table';
import {FC, useMemo} from 'react'
import { Column } from 'react-table';
export interface IAppointementManagmentListProps<T>{
 data: T;
 columns: any[];
}
const AppointementManagmentList:FC<IAppointementManagmentListProps<any>> = ({columns,data}) => {

  
  const cols = useMemo<Column<any>[]>(
    () =>

      columns.filter((cols: any) => cols.isChecked)

    , [columns]

  ) as Column<object>[];
  return (
    <Table columns={cols ?? []} data={data ?? []} isColumnSorted={true} search={''} />
  )
}
export default AppointementManagmentList