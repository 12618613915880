export enum PermissionsTypes {
    "Leads Salesman" = "Leads Salesman",
    "Leads Menager" = "Leads Menager",
    "Leads Admin" = "Leads Admin",
    "Appointment Salesman" = "Appointment Salesman",
    "Appointment Menager" = "Appointment Menager",
    "Appointment Admin" =  "Appointment Admin",
    "Costumer Salesman" = 'Costumer Salesman',
    "Costumer Menager" = 'Costumer Menager',
    "Costumer Admin" = 'Costumer Admin',
    "Hr Salesman" = 'Hr Salesman',
    "Hr Menager" = 'Hr Menager',
    "Hr Admin" = 'Hr Admin',
}