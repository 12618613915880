import React, { useEffect, useState } from "react";
import IconButton from "components/atoms/button/IconButton";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import { getUsersByPermission } from "api/User";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import { Controller, useForm } from "react-hook-form";
import Creatable from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import Label from "components/atoms/input/input-label/InputLabel";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useTranslation } from "react-i18next"

export interface IFilterProps {
  date_of_creation?: Date | null;
  filter_worker?: string;
}

const initialState: IFilterProps = {
  date_of_creation: null,
  filter_worker: undefined,
};

export interface IFilterTableDropdownProps {
  setFilterData?: (args: any) => void;
  filterData?: IFilterProps;
  setIsOpen?: (isOpen: boolean) => void;
  themeColors: {
    primary: string;
  };
}

const DropDownFilter: React.FC<IFilterTableDropdownProps> = ({
  setFilterData,
  filterData,
  setIsOpen,
  themeColors,
}) => {
  const [dateOfCreation, setDateOfCreation] = useState<Date | null>(
    filterData?.date_of_creation ?? null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0); // Key state to force re-render

  const [insideData, setInsideData] = useState<IFilterProps>({
    filter_worker: filterData?.filter_worker ?? undefined,
    date_of_creation: filterData?.date_of_creation ?? null,
  });

  const { control, setValue, reset } = useForm({
    defaultValues: initialState,
  });

  const [fetchedUsers, setFetchedUsers] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const {t} = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    getUsersByPermission({
      permissions: [P["Leads Salesman"]],
    }).then((res) => {
      setFetchedUsers(
        res.users.map((user: any) => ({
          label: user.name,
          value: user.id,
        }))
      );
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setInsideData((prev) => ({
      ...prev,
      date_of_creation: dateOfCreation,
    }));
  }, [dateOfCreation]);

  useEffect(() => {
    if (filterData) {
      setDateOfCreation(filterData?.date_of_creation as any);
      setValue("filter_worker", filterData.filter_worker);
    }
  }, [filterData, setValue]);

  const handleButtonClick = () => {
    if (setFilterData) {
      setFilterData({
        ...insideData,
        date_of_creation: dateOfCreation,
      });
    }
    if (setIsOpen) {
      setIsOpen(false);
    }
  };

  const resetData = () => {
    setInsideData(initialState);
    setDateOfCreation(null);
    reset(initialState);
    setKey((prevKey) => prevKey + 1); // Change key to force re-render
  };

  useEffect(() => {
    reset(insideData);
    setValue("filter_worker", insideData.filter_worker);
  }, [insideData, reset, setValue]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div key={key} className="flex flex-col gap-3">
          <div>
            <div className="relative">
              <Label text={t("Workers")} />
              <Controller
                name="filter_worker"
                control={control}
                render={({ field }) => (
                  <Creatable<any, false>
                    {...field}
                    placeholder={t("Choose Worker")}
                    options={fetchedUsers}
                    value={
                      field.value
                        ? {
                            value: field.value,
                            label:
                              fetchedUsers.find(
                                (user) => user.value === field.value
                              )?.label || field.value,
                          }
                        : null // Ensure value is null to show placeholder
                    }
                    className="cursor-pointer font-inter-regular react-select-container"
                    classNamePrefix="react-select"
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption
                        ? selectedOption.value
                        : undefined; // Use undefined to clear the form value
                      field.onChange(selectedValue);
                      setInsideData((prevState) => ({
                        ...prevState,
                        filter_worker: selectedValue,
                      }));
                    }}
                    styles={customStyles}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                  />
                )}
              />
            </div>
          </div>
          <PrimaryDatePicker
            label={t("Date of creation")}
            setStartDate={setDateOfCreation}
            startDate={dateOfCreation}
            wrapperClassName="!left-[2px]"
          />
          <div className="pt-5 flex justify-end gap-3">
            <IconButton
              secondary={true}
              className="w-[110px]"
              onClick={resetData}
            >
              {t("Reset")}
            </IconButton>
            <IconButton
              type="submit"
              className="w-[110px]"
              onClick={handleButtonClick}
            >
              {t("Confirm")}
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};

export default DropDownFilter;
