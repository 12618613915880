import { memo } from "react";

interface Props {
  className?: string;
}

function VisaIcon({ className }: Props) {
  return (
    <svg
      width="37"
      height="25"
      viewBox="0 0 37 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="0.867623"
        y="0.465279"
        width="35.0197"
        height="24.1247"
        rx="4.28018"
        fill="white"
      />
      <rect
        x="0.867623"
        y="0.465279"
        width="35.0197"
        height="24.1247"
        rx="4.28018"
        stroke="#F2F4F7"
        strokeWidth="0.778215"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6331 16.5317H9.49586L7.8932 10.2376C7.81713 9.94812 7.65561 9.69216 7.41802 9.57152C6.8251 9.26836 6.17174 9.02709 5.45898 8.9054V8.66308H8.90189C9.37706 8.66308 9.73344 9.02709 9.79284 9.44984L10.6244 13.99L12.7606 8.66308H14.8384L11.6331 16.5317ZM16.0264 16.5317H14.008L15.67 8.66308H17.6884L16.0264 16.5317ZM20.2998 10.8428C20.3592 10.419 20.7156 10.1766 21.1314 10.1766C21.7847 10.1158 22.4964 10.2375 23.0904 10.5396L23.4468 8.84545C22.8528 8.60313 22.1995 8.48145 21.6065 8.48145C19.6475 8.48145 18.222 9.57137 18.222 11.084C18.222 12.2348 19.2317 12.839 19.9445 13.203C20.7156 13.566 21.0126 13.8083 20.9532 14.1713C20.9532 14.7157 20.3592 14.958 19.7663 14.958C19.0535 14.958 18.3408 14.7766 17.6885 14.4734L17.3321 16.1686C18.0448 16.4707 18.816 16.5924 19.5287 16.5924C21.7253 16.6522 23.0904 15.5633 23.0904 13.929C23.0904 11.8708 20.2998 11.7502 20.2998 10.8428ZM30.1545 16.5317L28.5519 8.66308H26.8304C26.4741 8.66308 26.1177 8.9054 25.9989 9.26836L23.0311 16.5317H25.109L25.5237 15.382H28.0767L28.3143 16.5317H30.1545ZM27.1273 10.7819L27.7202 13.7475H26.0581L27.1273 10.7819Z"
        fill="#172B85"
      />
    </svg>
  );
}
export default memo(VisaIcon);
