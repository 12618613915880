import { useMutation } from "react-query";
import { login } from "../../../../api/User";
import { useMultiStepFormContext } from "../../../context/MultiStepFormContext/MultiStepFormContext";


export const useLogin = () => {
    const {nextFormStep , updateFormData} = useMultiStepFormContext();
    return {
        ...useMutation(login, {
            onSuccess: (res) => {
                nextFormStep();
                updateFormData({...res});
                
            },
            onError: (err) => {
               return 
            },
        }),
    };
};