import { getCustomerProduct } from "api/Customers";
import { UseQueryOptions, useQuery } from "react-query";


interface DocumentParams {
  costumer_id: any;
}

export const useCustomerProduct = (
  params: DocumentParams,
  options?: UseQueryOptions<any, any, unknown, string[]>
) => {
  const queryInfo = useQuery(
    ['customerProducts', 'coustomers'],
    () => getCustomerProduct(params),
    {
      onError: (err) => {
        console.error(err);
      },
      ...options,
    }
  );

  return queryInfo;
};