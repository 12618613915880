import Headline from "components/atoms/headline/Headline";
import { FC, useState } from "react";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import DashboardBoxTitle from "components/atoms/title/DashboardBoxTitle";
import IconButton from "components/atoms/button/IconButton";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Input from "components/atoms/input/Input";
import FilterBorderedWrapper from "components/atoms/filter-bordered-wrapper/FilterBorderedWrapper";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import ViewChangeButtonGroup from "components/molecules/view-change/ViewChangeButtonGroup";
import CreateSource from "./create-source/CreateSource";
import SourceGrid from "./source-grid/SourceGrid";
import SourceList from "./source-list/SourceList";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import ConfigureTableDropdown from "../leads/dropdowns/ConfigureTableDropdown";
import { sourceTableHeaders } from "lib/helpers/constants/sourceTableHeaders";
import { useSources } from "lib/hooks/queries/Source/useSources";
import PaginationControls from "components/molecules/pagination-controls/PaginationControls";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import FilterSourceTable from "./dropdowns/FilterSourceTable";
import LeadsCards from "../leads/leads-cards/LeadsCards";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useTranslation } from "react-i18next";

export interface ISourceProps {}
export interface IFilterSourceProps {
  search?: string;
  status?: string;
  date_of_creation?: string;
}

const Source: FC<ISourceProps> = () => {
  const [ActiveView, setActiveView] = useState<string>("listView");
  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);
  const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);
  const { themeColors } = useThemeContext();
  const [isModalOpen,setIsModalOpen] = useState<boolean>(false);

  const [filterData, setFilterData] = useState<IFilterSourceProps>({
    search: "",
    status: "",
    date_of_creation: undefined,
  });

  const {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage,
  } = useSources(filterData);


  const {t} = useTranslation();

  const [cardsOrder, setCardsOrder] = useState(sourceTableHeaders);

  return (
    <>
      <Headline title={t('Sources')} />
      <LeadsCards />
      <DashboardBoxLayout className="mt-[26px]">
        <div className="flex flex-row gap-3 items-center justify-between mb-5">
          <div className="flex-1">
            <DashboardBoxTitle
              title={`${t('Sources')} (${data?.pages[0]?.sources?.total ?? ""})`}
            />
          </div>
          <div className="flex-none">
            <IconButton
              icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
              onClick={
                () => setIsModalOpen(true)
              }
            >
              {t('New Source')}
            </IconButton>
          </div>
        </div>
        <div className="flex flex-col gap-3 items-center mb-5 md:flex-row">
          <div className="flex-1">
            <Input
              icon={IconType.SEARCH_ICON}
              className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
              classes={{ icon: "left-4 !top-[30%]" }}
              placeholder={t('Search')}
              value={filterData?.search ?? ""}
              onChange={(e) =>
                setFilterData((prevFormData: any) => ({
                  ...prevFormData,
                  search: e.target.value,
                }))
              }
              maxLength={20}
            />
          </div>
          <div className="flex flex-row gap-2">
            <div className="flex-none">
              <div className={`relative`}>
                <FilterBorderedWrapper
                  className="cursor-pointer h-[48px] w-[48px] "
                  onClick={() => {
                    setConfigureTableOpen(!configureTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_BARS_ICON} />
                </FilterBorderedWrapper>
                {configureTableOpen && (
                  <DynamicDropdown
                    setIsOpen={setConfigureTableOpen}
                    showCloseButton={true}
                    title="Configure Table"
                  >
                    <ConfigureTableDropdown
                      setCardsOrder={setCardsOrder}
                      cardsOrder={cardsOrder}
                    />
                  </DynamicDropdown>
                )}
              </div>
            </div>
            <div className="flex-none">
              <div className={`${filterTableOpen && "relative"}`}>
                <FilterBorderedWrapper
                  className="h-[48px] w-[48px] cursor-pointer"
                  onClick={() => {
                    setFilterTableOpen(!filterTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={filterTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setFilterTableOpen}
                    showCloseButton={true}
                    title="Filter"
                    className="sm:!w-[350px]"
                  >
                    <FilterSourceTable
                      setFilterData={setFilterData}
                      FilterData={filterData}
                      setIsOpen={setFilterTableOpen}
                      themeColors={themeColors}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex-none">
              <ViewChangeButtonGroup
                setSelectedTab={setActiveView}
                ActiveTab={ActiveView}
              />
            </div>
          </div>
        </div>
        <ConditionalRender condition={ActiveView === "kundenView"}>
          <NoDataBoundary
            condition={data?.pages?.[0]?.sources?.data?.length > 0}
            fallback={<EmptyEntity name="Sources" />}
          >
            <SourceGrid data={data?.pages?.[0]?.sources?.data ?? []} />
          </NoDataBoundary>
        </ConditionalRender>

        <ConditionalRender condition={ActiveView !== "kundenView"}>
          <div className="relative">
            {isLoading && <LoadingScreen />}
            <NoDataBoundary
              condition={data?.pages?.[0]?.sources?.data?.length > 0}
              fallback={<EmptyEntity name="Sources" />}
            >
              <SourceList
                key={currentPage}
                data={data?.pages?.[0]?.sources?.data ?? []}
                dataCols={cardsOrder}
              />
            </NoDataBoundary>
          </div>
        </ConditionalRender>
      </DashboardBoxLayout>
      <ConditionalRender condition={!!data}>
        <PaginationControls
          itemsPerPage={itemsPerPage}
          handleItemsPerPageChange={(value: number) =>
            handleItemsPerPageChange(value)
          }
          from={data?.pages[0]?.sources?.from}
          to={data?.pages[0]?.sources?.to}
          total={data?.pages[0]?.sources?.total}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </ConditionalRender>
      <ConditionalRender condition={isModalOpen as boolean}>
        <CreateSource
          onConfirm={() => setIsModalOpen(false)}
          themeColors={themeColors}
          hocClasses={{modalClassName: 'max-w-[551px]'}}
          hocCurrentStep={1}
          hocToggleModal={
            () => setIsModalOpen(false)
          }
          hocisOpen={isModalOpen}
          hocTitle="Create New Source"
          setHocCurrentStep={() => {}}
          clearData={true}
          />  
      </ConditionalRender>
    </>
  );
};

export default Source;
