import { memo } from "react";
interface Props {
    className?: string;
    color?: string;
}
function PlusIcon({ className, color }: Props) {
    return (
        <svg className={className} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.49327 0.883379C8.43551 0.38604 8.01284 0 7.5 0C6.94772 0 6.5 0.447715 6.5 1V6H1.5L1.38338 6.00673C0.88604 6.06449 0.5 6.48716 0.5 7C0.5 7.55228 0.947715 8 1.5 8H6.5V13L6.50673 13.1166C6.56449 13.614 6.98716 14 7.5 14C8.05228 14 8.5 13.5523 8.5 13V8H13.5L13.6166 7.99327C14.114 7.93551 14.5 7.51284 14.5 7C14.5 6.44772 14.0523 6 13.5 6H8.5V1L8.49327 0.883379Z" fill={color ? color : 'white'} />
        </svg>

    );
}
export default memo(PlusIcon);
