import { memo } from "react";
interface Props {
  className?: string;
  color?:string;
}
const EmailIcon = ({ className, color }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="2.08325"
        y="3"
        width="20.8333"
        height="18"
        rx="4"
        stroke={color || '#282D46'}
        strokeWidth="1.5"
      />
      <path
        d="M2.08325 7L10.0635 13.1289C11.5004 14.2323 13.4995 14.2323 14.9363 13.1289L22.9166 7"
        stroke={color || '#282D46'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(EmailIcon);
