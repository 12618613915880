import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function RightArrowIcon({ className, color }: Props) {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5527 11.3871L22.4606 11.2929L16.9226 6.29289C16.4901 5.90237 15.7888 5.90237 15.3562 6.29289C14.957 6.65338 14.9263 7.22061 15.2641 7.6129L15.3562 7.70711L19.0026 11H6.17107C5.55936 11 5.06348 11.4477 5.06348 12C5.06348 12.5128 5.49105 12.9355 6.0419 12.9933L6.17107 13H19.0026L15.3562 16.2929C14.957 16.6534 14.9263 17.2206 15.2641 17.6129L15.3562 17.7071C15.7555 18.0676 16.3838 18.0953 16.8183 17.7903L16.9226 17.7071L22.4606 12.7071C22.8599 12.3466 22.8906 11.7794 22.5527 11.3871Z"
        fill={color || "#fff"}
      />
    </svg>
  );
}
export default memo(RightArrowIcon);
