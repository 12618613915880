import { useQuery } from "react-query"
import { getPartnersForFilter } from "api/Partners";


export const useRefetchPartnersForFilter = (search?: string) => {
    return useQuery<Array<any> | any>(
      'partners',
        () => getPartnersForFilter(),
        {
            onError(error: any) {
                console.error(error)
            },
        enabled: false
        }
    )
}