
import FilterBorderedWrapper from 'components/atoms/filter-bordered-wrapper/FilterBorderedWrapper'
import Icon from 'components/atoms/icon/Icon'
import { IconType } from 'lib/helpers/constants/iconTypes'
import React from 'react'
interface IViewChangeButtonGroupProps {
    setSelectedTab: (arg0: string) => void,
    ActiveTab?: string
}


const ViewChangeButtonGroup: React.FC<IViewChangeButtonGroupProps> = ({ setSelectedTab, ActiveTab }) => {
    return (
        <FilterBorderedWrapper className='!p-[4px] h-[48px]'>
            <div className='flex flex-row gap-1 h-full'>
                <div onClick={() => { setSelectedTab('listView') }} className={`aspect-square h-full w-full flex items-center justify-center rounded-[8px] ${ActiveTab === 'listView' ? "bg-[#282D4614] " : "cursor-pointer"}`}>
                    <Icon
                        iconType={IconType.LIST_VIEW_ICON}
                        color={ActiveTab === 'listView' ? '#000' : '#6C737F'}
                    />

                </div>
                <div onClick={() => { setSelectedTab('kundenView') }} className={`aspect-square h-full w-full flex items-center justify-center rounded-[8px] ${ActiveTab === 'kundenView' ? "bg-[#282D4614]" : "cursor-pointer"}`}>
                    <Icon
                        iconType={IconType.GRID_VIEW_ICON}
                        color={ActiveTab === 'kundenView' ? '#000' : '#6C737F'}
                    />
                </div>
            </div>
        </FilterBorderedWrapper>
    )
}

export default ViewChangeButtonGroup