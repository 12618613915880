import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender';
import FilterBorderedWrapper from 'components/atoms/filter-bordered-wrapper/FilterBorderedWrapper';
import Icon from 'components/atoms/icon/Icon';
import Input from 'components/atoms/input/Input';
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen';
import NoDataBoundary from 'components/atoms/no-data-boundary/NoDataBoundary';
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle'
import DynamicDropdown from 'components/molecules/dynamic-dropdown/DynamicDropdown';
import DashboardBoxLayout from 'components/templates/dashboard-box-layout/DashboardBoxLayout'
import { customStylesLightBorder } from 'lib/helpers/configs/customStyles';
import { IconType } from 'lib/helpers/constants/iconTypes';
import { useLeaderboard } from 'lib/hooks/queries/Statistics/Leaderboard/useLeaderboard';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Creatable from "react-select/creatable";
import Filter from '../Dropdowns/Filter';

const Leaderboard = () => {

    const { t } = useTranslation();

    const [Filters, setFilters] = useState({
        type_of_statistic: 'Total Customers',
        filter_on: 'Today',
    })

    const { data, isLoading } = useLeaderboard(Filters)
    
    const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);

    return (

        <DashboardBoxLayout className="mt-[26px]">
            <div className="flex flex-row gap-3 items-center justify-between mb-5">
                <div className="flex-1">
                    <DashboardBoxTitle
                        title={`Leaderboard`}
                    />
                </div>
                <div className="flex-none">
                    <div className={`${filterTableOpen && "relative"}`}>
                        <FilterBorderedWrapper
                            className="h-[48px] w-[48px] cursor-pointer"
                            onClick={() => {
                                setFilterTableOpen(!filterTableOpen);
                            }}
                        >
                            <Icon iconType={IconType.FILTER_ICON} />
                        </FilterBorderedWrapper>
                        <ConditionalRender condition={filterTableOpen}>
                            <DynamicDropdown
                                setIsOpen={setFilterTableOpen}
                                showCloseButton={true}
                                title={t('Filter')}
                                className="sm:!w-[350px]"
                            >
                                <Filter
                                    setIsOpen={setFilterTableOpen}
                                    setFilterData={setFilters}
                                    FilterData={Filters}
                                />
                            </DynamicDropdown>
                        </ConditionalRender>
                    </div>
                </div>
            </div>
            <div className='pb-10'>
                <Creatable<any, false>
                    placeholder={t('')}
                    options={[
                        {
                            label: t('Total Customers'),
                            value: 'Total Customers'
                        },
                        {
                            label: t('Customers From Leads'),
                            value: 'Customers From Leads'
                        },
                        {
                            label: t('Customers From Appointment'),
                            value: 'Customers From Appointment'
                        },
                        {
                            label: t('Total Provision'),
                            value: 'Total Provision'
                        },
                        {
                            label: t('Total Won Appointments'),
                            value: 'Total Won Appointments'
                        },
                        {
                            label: t('Won Appointments From Leads'),
                            value: 'Won Appointments From Leads'
                        }
                    ]}
                    value={{ value: Filters.type_of_statistic, label: t(Filters.type_of_statistic) }}
                    className="cursor-pointer font-inter-regular react-select-container w-full min-w-[150px]"
                    classNamePrefix="react-select"
                    onChange={(newValue: any) => {
                        setFilters((prevFilters) => ({
                            ...prevFilters,
                            type_of_statistic: newValue.value,
                        }));
                    }}
                    styles={customStylesLightBorder}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                />
            </div>
            <div className=' text-primaryTextColor font-inter-medium'>
                {t('Agents List')} ({(data as any)?.data?.length})
            </div>
            <div className='py-5'>

                <Input
                    icon={IconType.SEARCH_ICON}
                    className="w-full xl:!w-[412px] border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
                    classes={{ icon: "left-4 !top-[30%]" }}
                    placeholder={t("Search")}
                />
            </div>
            {isLoading ? <div className='relative py-10'><LoadingScreen /></div> : (
                <div className='border border-[#F2F4F7] rounded-[8px]'>
                    <div className='bg-[#F2F4F7] rounded-t-[8px] py-2 px-4 flex gap-4 text-primaryTextColor font-inter-medium'>
                        <div className='basis-1/4'>

                            {t('Rank')}
                        </div>
                        <div className='basis-2/4'>
                            {t('Agent Name')}
                        </div>
                        <div className='basis-1/4 text-center'>
                            {
                                {
                                    'Total Customers': t('Customers'),
                                    'Customers From Leads': t('Customers'),
                                    'Customers From Appointment': t('Customers'),
                                    'Total Provision': t('Provision'),
                                    'Total Won Appointments': t('Appointments'),
                                    'Won Appointments From Leads': t('Appointments'),
                                }[Filters.type_of_statistic]
                            }

                        </div>
                    </div>
                    <NoDataBoundary
                        condition={data && (data as any).data.length > 0}
                        fallback={
                            <p className="text-[#7D8592] font-inter-regular mx-auto my-20 flex justify-center">
                                {t("There is no")} data {t("in this section.")}
                            </p>
                        }
                    >

                        {(data as any)?.data?.map((item: any, index: number) => (
                            <div key={index} className='border-t border-t-[#F2F4F7] flex gap-4 h-[75px] items-center px-4 text-primaryTextColor font-inter-semibold'>
                                <div className={`basis-1/4 ${index > 3 ? 'ps-3' : ''}`}>
                                    {index < 3
                                        ? {
                                            0: <Icon iconType={IconType.LEADERBOARD_FIRST} />,
                                            1: <Icon iconType={IconType.LEADERBOARD_SECOND} />,
                                            2: <Icon iconType={IconType.LEADERBOARD_THIRD} />,

                                        }[index]
                                        : index + 1
                                    }

                                </div>
                                <div className='basis-2/4'>
                                    {item.from_user}
                                </div>
                                <div className='basis-1/4 text-center'>
                                    {item.total}
                                </div>
                            </div>
                        ))}
                    </NoDataBoundary>
                </div>
            )}

        </DashboardBoxLayout>
    )
}

export default Leaderboard