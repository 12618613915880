import IconButton from "components/atoms/button/IconButton";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface ICallAppointmentProps<T> {
  onConfirm?: () => void;
  onCancel?: () => void;
  data: T;
  setIsUpdateModalOpen: (value: boolean) => void;
  setCurrentFormStep: (step: number) => void;
}

const CallAppointment: FC<ICallAppointmentProps<any>> = ({
  onConfirm,
  onCancel,
  data,
  setIsUpdateModalOpen,
  setCurrentFormStep
}) => {

  const {t} = useTranslation()
  return (
    <>
      <div className="mb-0 flex flex-col gap-[37px]">
        <h1 className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-[5px] text-[#282D46] font-inter-semibold text-[22px]">
          {t('Do you really want to call')}{" "}
          {(data?.info?.first_name || (data?.first_name ?? "Not available")) ??
            "Not available"}
          <span>
            {(data?.info?.last_name || (data?.last_name ?? "Not available")) ??
              "Not available"}
          </span>
        </h1>
        <p className="flex flex-col   font-inter-medium text-[#282D46] mt-[10px] text-[20px]">
          {(data?.info?.phone_number || (data?.phone ?? "Not available")) ??
            "Not available"}
        </p>
        <div className="flex justify-end gap-5">
          <IconButton
            secondary={true}
            type="button"
            onClick={() => {
              setCurrentFormStep(1);
            }}
            className="w-full max-w-[150px] text-[13px] font-inter-bold"
          >
            {t('Cancel')}
          </IconButton>
          <a
            href={`tel:${
              data?.info?.phone_number || (data?.phone ?? "Not available")
            }`}
            onClick={() => {
              onConfirm && onConfirm();
              setIsUpdateModalOpen(false);
              setCurrentFormStep(1);
            }}
            className={`w-full max-w-[150px] bg-[--theme] text-center !text-white rounded-[8px] py-2 lg:py-3 px-5  font-inter-medium text-sm xl:text-base `}
          >
            {t('Call')}
          </a>
        </div>
      </div>
    </>
  );
};

export default CallAppointment;
