import { useInfiniteQuery } from "react-query";
import { toast } from "react-toastify";
import { ISource } from "lib/types/sources.types";
import { usePagination } from "lib/hooks/shared/usePagination";
import { getSources } from "api/Sources";
import { IFilterSourceProps } from "components/organisms/source/Source";

export const useSources = (FilterData: IFilterSourceProps) => {
  const {
    currentPage,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage
  } = usePagination(1, 10);

  const { data, isLoading, hasNextPage, hasPreviousPage } = useInfiniteQuery<Array<ISource> | any>(
    ["sources", currentPage, itemsPerPage, FilterData],
    ({ pageParam = currentPage }) => {
      return getSources({ page: pageParam, number_per_page: itemsPerPage, search: FilterData.search, status: FilterData.status, date_of_creation: FilterData.date_of_creation });
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.sources?.next_page_url
          ? new URL(lastPage.sources.next_page_url).searchParams.get("page")
          : false;
      },
      getPreviousPageParam: (firstPage, allPages) => {
        const currentPage = allPages[allPages.length - 1];
        const prevPageUrl = currentPage?.sources?.prev_page_url;
        return prevPageUrl
          ? new URL(prevPageUrl).searchParams.get("page")
          : false;
      },
      onError: () => {
        toast.error(`Something went wrong`);
      }
    }
  );

  return {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    itemsPerPage,
    handleNextPage,
    handlePreviousPage,
    handleItemsPerPageChange
  };
};
