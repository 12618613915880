
import { memo } from "react";

interface Props {
    className?: string;
    color?: string;
}
function ListViewIcon({ className, color }: Props) {
    return (
        <svg className={className} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.32357 11.5339C3.78382 11.5339 4.1569 11.1608 4.1569 10.7005C4.1569 10.2403 3.78382 9.86719 3.32357 9.86719C2.86332 9.86719 2.49023 10.2403 2.49023 10.7005C2.49023 11.1608 2.86332 11.5339 3.32357 11.5339ZM3.32357 6.53385C3.78382 6.53385 4.1569 6.16077 4.1569 5.70052C4.1569 5.24027 3.78382 4.86719 3.32357 4.86719C2.86332 4.86719 2.49023 5.24027 2.49023 5.70052C2.49023 6.16077 2.86332 6.53385 3.32357 6.53385ZM3.32357 16.5339C3.78382 16.5339 4.1569 16.1608 4.1569 15.7005C4.1569 15.2403 3.78382 14.8672 3.32357 14.8672C2.86332 14.8672 2.49023 15.2403 2.49023 15.7005C2.49023 16.1608 2.86332 16.5339 3.32357 16.5339Z" fill="white" fillOpacity="0.01" />
            <path d="M17.4902 10.7005H7.49023M17.4902 5.70052H7.49023M17.4902 15.7005H7.49023M4.1569 10.7005C4.1569 11.1608 3.78382 11.5339 3.32357 11.5339C2.86332 11.5339 2.49023 11.1608 2.49023 10.7005C2.49023 10.2403 2.86332 9.86719 3.32357 9.86719C3.78382 9.86719 4.1569 10.2403 4.1569 10.7005ZM4.1569 5.70052C4.1569 6.16077 3.78382 6.53385 3.32357 6.53385C2.86332 6.53385 2.49023 6.16077 2.49023 5.70052C2.49023 5.24027 2.86332 4.86719 3.32357 4.86719C3.78382 4.86719 4.1569 5.24027 4.1569 5.70052ZM4.1569 15.7005C4.1569 16.1608 3.78382 16.5339 3.32357 16.5339C2.86332 16.5339 2.49023 16.1608 2.49023 15.7005C2.49023 15.2403 2.86332 14.8672 3.32357 14.8672C3.78382 14.8672 4.1569 15.2403 4.1569 15.7005Z" stroke={color ? color : "#6C737F"} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}
export default memo(ListViewIcon);