import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function UploadFileIcon({ className, color }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_5961_76710)">
        <path
          d="M14.1654 6.66667L9.9987 2.5L5.83203 6.66667"
          fill={color || "white"}
          fillOpacity="0.01"
        />
        <path
          d="M17.5 12.5V13.5C17.5 14.9002 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9002 17.5 13.5 17.5H6.5C5.09983 17.5 4.39983 17.5 3.865 17.2275C3.39461 16.9878 3.01217 16.6054 2.7725 16.135C2.5 15.6002 2.5 14.9002 2.5 13.5V12.5M14.1667 6.66667L10 2.5M10 2.5L5.83333 6.66667M10 2.5V12.5"
          stroke={color || "white"}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5961_76710">
          <rect width="20" height="20" fill={color || "white"} />
        </clipPath>
      </defs>
    </svg>
  );
}
export default memo(UploadFileIcon);
