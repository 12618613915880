import React, { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}
function CloseXsmIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2C5.3525 2 2 5.3525 2 9.5C2 13.6475 5.3525 17 9.5 17C13.6475 17 17 13.6475 17 9.5C17 5.3525 13.6475 2 9.5 2ZM13.25 12.1925L12.1925 13.25L9.5 10.5575L6.8075 13.25L5.75 12.1925L8.4425 9.5L5.75 6.8075L6.8075 5.75L9.5 8.4425L12.1925 5.75L13.25 6.8075L10.5575 9.5L13.25 12.1925Z"
        fill="#7D8592"
      />
      <mask
        id="mask0_3143_32216"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="15"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 2C5.3525 2 2 5.3525 2 9.5C2 13.6475 5.3525 17 9.5 17C13.6475 17 17 13.6475 17 9.5C17 5.3525 13.6475 2 9.5 2ZM13.25 12.1925L12.1925 13.25L9.5 10.5575L6.8075 13.25L5.75 12.1925L8.4425 9.5L5.75 6.8075L6.8075 5.75L9.5 8.4425L12.1925 5.75L13.25 6.8075L10.5575 9.5L13.25 12.1925Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3143_32216)" />
    </svg>
  );
}

export default memo(CloseXsmIcon);
