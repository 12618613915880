import React, { FC } from 'react'
import ForgotPasswordProcces from '../../components/organisms/forgotPassword/ForgotPasswordProcces'


export interface IForgotPasswordProps { }


const ForgotPassword: FC<IForgotPasswordProps> = () => {
    return (
        <ForgotPasswordProcces />
    )
}

export default ForgotPassword