import { ISourceCreate, PaginationParams } from "lib/types/sources.types";
import { apiRequest } from "./Api";

export const createNewSource = (data?: ISourceCreate) =>
  apiRequest<ISourceCreate, unknown>("POST", "leads/sources/store", data);

export const getSources = (params?: PaginationParams) =>
  apiRequest<any, any>("GET", "leads/sources", null, undefined, params);

export const updateSourceStatus = (params: any) => {
  return apiRequest<FormData, any>("POST", `leads/sources/change-status`, params, undefined);
};

export const DeleteSourceById = (id: any) => {
  return apiRequest<FormData, any>("DELETE", `leads/sources/delete/${id}`);
};

export const updateSource = (params: any) => {

  return apiRequest<FormData, any>("PUT", `leads/sources/edit/${params.id}`, params, undefined);
};

export const updateSourceAccess = (params: any) => {

  return apiRequest<FormData, any>("POST", `leads/sources/accessbility`, params, undefined);
};

export const getAllSources = () =>
  apiRequest<any, any>("GET", "leads/sources/for-filter");