import React, { FC, HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'


export interface IBlueLinkProps extends HTMLAttributes<HTMLHeadingElement> {
    to: string;
    title: string;
}

const BlueLink: FC<IBlueLinkProps> = ({ to, title, ...rest }) => {
    return (
        <Link to={to} className={`no-underline text-[14px] text-[#50B8E7] font-inter-medium ${rest.className}`}>{title}</Link>
    )
}

export default BlueLink