import Headline from "components/atoms/headline/Headline";
import { FC, Key, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import SecurityList from "./security-list/SecurityList";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Input from "components/atoms/input/Input";
import FilterBorderedWrapper from "components/atoms/filter-bordered-wrapper/FilterBorderedWrapper";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import ViewChangeButtonGroup from "components/molecules/view-change/ViewChangeButtonGroup";
import ConfigureTableDropdown from "../leads/dropdowns/ConfigureTableDropdown";
import Button from "components/atoms/button/Button";
import SecurityGridList from "./security-grid-list/SecurityGridList";
import TabsSwitch from "components/molecules/tabs/TabsSwitch";
import { useRequestLeads } from "lib/hooks/queries/Leads/useRequestLeads";
import PaginationControls from "components/molecules/pagination-controls/PaginationControls";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useRequestFeedBackForExport } from "lib/hooks/mutations/Leads/useRequestFeedBackForExport";
import ActivityHistoryList from "./activity-history-list/ActivityHistoryList";
import DropDownFilter from "./dropdownfilter/DropDownFilter";
import { useActivityLogRequest } from "lib/hooks/queries/Leads/useActivityLogRequest";
import { useModalContext } from "lib/context/ModalContext/ModalContext";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useTranslation } from "react-i18next"

export interface ISecurityProps {}

const Security: FC<ISecurityProps> = () => {
  const { confirm } = useModalContext();
  const { themeColors } = useThemeContext();
  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);
  const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);
  const [activeView, setActiveView] = useState<string>("listView");
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const {t} = useTranslation();
  const [filterData, setFilterData] = useState<any>({
    search: "",
    filter_worker: undefined,
    date_of_creation: undefined,
  });

  const {
    data: activityLogData,
    isLoading: activityLogLoading,
    hasNextPage: hasNextPageActivityLog,
    hasPreviousPage: hasPreviousPageActivityLog,
    handleItemsPerPageChange: handleItemsPerPageChangeActivityLog,
    handleNextPage: handleNextPageActivityLog,
    itemsPerPage: itemsPerPageActivityLog,
    handlePreviousPage: handlePreviousPageActivityLog,
  } = useActivityLogRequest({
    cacheTime: 300000,
    enabled: selectedTab === 1 ? true : false,
  });

  const {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage,
  } = useRequestLeads(
    filterData.search,
    "export",
    ["pending"],
    filterData.filter_worker,
    filterData.date_of_creation
  );
  const { mutate: requestForFeedback } = useRequestFeedBackForExport();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const columns = [
    {
      id: 1,
      Header: `${t("Date")}`,
      Cell: ({ row: { original } }: { row: { original: any } }) => {
        const date = new Date(original.created_at);
        const formattedDate = `${date.getFullYear()}-${String(
          date.getMonth() + 1
        ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
        return <div>{formattedDate}</div>;
      },
      isChecked: true,
    },
    {
      id: 2,
      Header:  `${t("Time")}`,
      accessor: "time",
      Cell: ({ row: { original } }: { row: { original: any } }) => {
        const date = new Date(original.created_at);
        const formattedTime = `${String(date.getHours()).padStart(
          2,
          "0"
        )}:${String(date.getMinutes()).padStart(2, "0")}:${String(
          date.getSeconds()
        ).padStart(2, "0")}`;
        return <div>{formattedTime ?? "Not Available"}</div>;
      },
      isChecked: true,
    },
    {
      id: 3,
      Header:  `${t("Name")}`,
      accessor: (row: any) => row.name || "Not Available",
      isChecked: true,
    },
    {
      id: 4,
      Header:  `${t("Request details")}`,
      accessor: "requestdetails",
      Cell: ({ row: { original } }: { row: { original: any } }) => {
        const text = `${t("Asked for permission to")}${
          original?.type ?? t("Not Available")
        } ${t("Leads")}`;
        return (
          <div className="flex items-center gap-2">
            <Button onClick={() => alertInfoModal(original)}>
              <Icon
                iconType={IconType.ALERT_ICON}
                color={themeColors.primary}
              />
            </Button>
            <p
              className="capitalize overflow-hidden overflow-ellipsis whitespace-nowrap w-[280px]"
              title={text}
            >
              {text}
            </p>
          </div>
        );
      },
      isChecked: true,
    },
    {
      id: 5,
      Header:  `${t("Employee")}`,
      accessor: "employee",
      Cell: ({ row: { original } }: { row: { original: any } }) => {
        return (
          <div className="flex items-center gap-[10px]">
            <img
              src="https://t3.ftcdn.net/jpg/02/22/85/16/360_F_222851624_jfoMGbJxwRi5AWGdPgXKSABMnzCQo9RN.jpg"
              alt="security"
              className="w-[32px] h-[32px] object-cover rounded-full"
            />
            {original.from_user ?? "Not Available"}
          </div>
        );
      },
      isChecked: true,
    },
    {
      id: 6,
      Header:  `${t("Action")}`,
      accessor: "action",
      isChecked: true,
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <>
          <div className="flex items-center gap-[13px] lg:flex-row 2xl:flex-row w-full">
            <div className="flex items-center gap-[13px] md:flex-col lg:flex-row w-[max-content]">
              <Button
                onClick={() =>
                  requestForFeedback({
                    request_id: original?.id,
                    feedback: "decline",
                  })
                }
                className="text-[#979797] w-[max-content] 2xl:w-[50%] p-3 bg-[#979797] whitespace-nowrap	 bg-opacity-10  font-inter-medium rounded-[8px] text-[11px]"
              >
                {t("Decline request")}
              </Button>
              <Button
                onClick={() =>
                  requestForFeedback({
                    request_id: original?.id,
                    feedback: "accept",
                  })
                }
                className="text-white p-3 bg-[#66BB6A]  w-[max-content] 2xl:w-[50%] font-inter-medium text-[11px] rounded-[8px] whitespace-nowrap	"
              >
                {t("Accept request")}
              </Button>
            </div>
            <Icon iconType={IconType.OPTIONS_ICON} />
          </div>
        </>
      ),
    },
  ];

  const [cardsOrder, setCardsOrder] = useState(columns);

  const alertInfoModal = async (original: any) => {
    try {
      await confirm({
        title: `${t("Request For")}`,
        classes: {
          modal: "md:min-w-[450] md-max-w-full lg:max-w-[550px] w-full",
        },
        showCloseButton: true,
        showBackButton: true,
        customComponent: (props) => {
          return (
            <Fragment>
              <ul className="list-disc text-left text-[14px] ml-4  grid grid-cols-2 gap-1 capitalize font-inter-medium">
                <li className="font-bold mb-3">
                  {t("From")} {original?.data?.[0]?.from ?? t("Not Available")}
                </li>
                <li className="font-bold">
                  {t("To")} {original?.data?.[0]?.to ?? t("Not Available")}
                </li>
                {Array.isArray(original?.data?.[0]?.fields)
                  ? original?.data?.[0]?.fields.map(
                      (field: string, index: Key | null | undefined) => (
                        <li key={index}>{field.replace(/_/g, " ")}</li>
                      )
                    )
                  : JSON.stringify(
                      original?.data?.[0]?.fields ?? t("Not Available")
                    )}
              </ul>
            </Fragment>
          );
        },
      });
    } catch (err) {
      return null;
    }
  };

  return (
    <div>
      <Headline title={t("Security")} />
      <DashboardBoxLayout className="mt-[26px]">
        <TabsSwitch
          ActiveTab={selectedTab}
          setSelectedTab={(id) => setSelectedTab(id)}
          tabs={[
            {
              id: 0,
              name: `${t('Reqeusts')} ( ${
                data?.pages?.[0]?.data?.data?.length ?? "0"
              } )`,
            },
            { id: 1, name: "Activity History" },
          ]}
        ></TabsSwitch>
        <ConditionalRender condition={selectedTab === 0}>
          <div>
            <div className="flex flex-col md:flex-row gap-3 items-center mb-5">
              <div className="flex-1">
                <Input
                  icon={IconType.SEARCH_ICON}
                  className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
                  classes={{ icon: "left-4 !top-[30%]" }}
                  placeholder={t("Search")}
                  value={filterData?.search ?? ""}
                  onChange={(e) =>
                    setFilterData((prevFormData: any) => ({
                      ...prevFormData,
                      search: e.target.value,
                    }))
                  }
                  maxLength={20}
                />
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex-none">
                  <div className={`relative`}>
                    <FilterBorderedWrapper
                      className="cursor-pointer h-[48px] w-[48px] "
                      onClick={() => {
                        setConfigureTableOpen(!configureTableOpen);
                      }}
                    >
                      <Icon iconType={IconType.FILTER_BARS_ICON} />
                    </FilterBorderedWrapper>
                    {configureTableOpen && (
                      <DynamicDropdown
                        setIsOpen={setConfigureTableOpen}
                        showCloseButton={true}
                        title="Configure Table"
                      >
                        <ConfigureTableDropdown
                          setCardsOrder={setCardsOrder}
                          cardsOrder={cardsOrder}
                        />
                      </DynamicDropdown>
                    )}
                  </div>
                </div>
                <div className="flex-none">
                  <div className={`${filterTableOpen && "relative"}`}>
                    <FilterBorderedWrapper
                      className="h-[48px] w-[48px] cursor-pointer"
                      onClick={() => {
                        setFilterTableOpen(!filterTableOpen);
                      }}
                    >
                      <Icon iconType={IconType.FILTER_ICON} />
                    </FilterBorderedWrapper>
                    {filterTableOpen && (
                      <DynamicDropdown
                        setIsOpen={setFilterTableOpen}
                        showCloseButton={true}
                        title={t("Filter")}
                        className="sm:!w-[350px]"
                      >
                        <DropDownFilter
                          setFilterData={setFilterData}
                          filterData={filterData}
                          setIsOpen={setFilterTableOpen}
                          themeColors={themeColors}
                        />
                      </DynamicDropdown>
                    )}
                  </div>
                </div>
                <div className="flex-none">
                  <ViewChangeButtonGroup
                    setSelectedTab={setActiveView}
                    ActiveTab={activeView}
                  />
                </div>
              </div>
            </div>
            {activeView === "kundenView" ? (
              <NoDataBoundary
                condition={data?.pages?.[0]?.data?.data?.length > 0}
                fallback={<EmptyEntity name="Leads" />}
              >
                <SecurityGridList data={data?.pages?.[0]?.data?.data ?? []} />
              </NoDataBoundary>
            ) : (
              <div className="relative">
                {isLoading && <LoadingScreen />}
                <NoDataBoundary
                  condition={data?.pages?.[0]?.data?.data?.length > 0}
                  fallback={<EmptyEntity name="Security" />}
                >
                  <SecurityList
                    key={currentPage}
                    data={data?.pages?.[0]?.data?.data ?? []}
                    dataCols={cardsOrder}
                  />
                </NoDataBoundary>
              </div>
            )}
          </div>
        </ConditionalRender>
        <ConditionalRender condition={selectedTab === 1}>
          <div className="m-5 mt-[50px]">
            <ActivityHistoryList
              data={activityLogData ?? []}
              isLoading={activityLogLoading}
            />
          </div>
        </ConditionalRender>
      </DashboardBoxLayout>
      <ConditionalRender
        condition={!!data && activeView !== "kundenView" && selectedTab === 0}
      >
        <PaginationControls
          itemsPerPage={itemsPerPage}
          handleItemsPerPageChange={(value: number) =>
            handleItemsPerPageChange(value)
          }
          from={data?.pages[0]?.data?.from}
          to={data?.pages[0]?.data?.to}
          total={data?.pages[0]?.data?.total}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </ConditionalRender>
      <ConditionalRender
        condition={!!data && activeView !== "kundenView" && selectedTab === 1}
      >
        <PaginationControls
          itemsPerPage={itemsPerPageActivityLog}
          handleItemsPerPageChange={(value: number) =>
            handleItemsPerPageChangeActivityLog(value)
          }
          from={activityLogData?.pages[0]?.data?.from}
          to={activityLogData?.pages[0]?.data?.to}
          total={activityLogData?.pages[0]?.data?.total}
          hasNextPage={hasNextPageActivityLog}
          hasPreviousPage={hasPreviousPageActivityLog}
          handleNextPage={handleNextPageActivityLog}
          handlePreviousPage={handlePreviousPageActivityLog}
        />
      </ConditionalRender>
    </div>
  );
};

export default Security;
