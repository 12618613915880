import React, { memo } from 'react'


interface Props {
    className?: string;
    color?: string;
}
function Check2Icon({ className, color }: Props) {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill={color || "#50B8E7"} />
            <path d="M8.97869 17.0007V15.8871L12.424 12.3175C12.7919 11.9297 13.0952 11.59 13.3338 11.2983C13.5758 11.0033 13.7564 10.7232 13.8757 10.4581C13.995 10.1929 14.0547 9.91122 14.0547 9.61293C14.0547 9.27486 13.9751 8.98319 13.8161 8.73793C13.657 8.48935 13.4399 8.29877 13.1648 8.16619C12.8897 8.0303 12.5798 7.96236 12.2351 7.96236C11.8705 7.96236 11.5523 8.03693 11.2805 8.18608C11.0088 8.33523 10.8 8.54569 10.6541 8.81747C10.5083 9.08925 10.4354 9.40743 10.4354 9.77202H8.96875C8.96875 9.15223 9.11127 8.61032 9.39631 8.14631C9.68134 7.68229 10.0724 7.32268 10.5696 7.06747C11.0668 6.80895 11.6319 6.67969 12.2649 6.67969C12.9046 6.67969 13.468 6.80729 13.9553 7.0625C14.4458 7.31439 14.8286 7.65909 15.1037 8.09659C15.3788 8.53078 15.5163 9.02131 15.5163 9.56818C15.5163 9.94602 15.4451 10.3156 15.3026 10.6768C15.1634 11.0381 14.9197 11.4408 14.5717 11.8849C14.2237 12.3258 13.7398 12.861 13.12 13.4908L11.0966 15.6087V15.6832H15.6804V17.0007H8.97869Z" fill="white" />
        </svg>
    )
}

export default memo(Check2Icon)