import { storeLeads } from "api/Campaign";
import { queryClient } from "lib/helpers/configs/queryClient";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useStoreLeads = () => {
  return useMutation(storeLeads, {
    onSuccess: () => {
      queryClient.invalidateQueries("campaigns");
      toast.success("Leads stored successfully");
    },
    onError: (error) => {
      toast.error("Failed to store leads");
    },
  });
};
