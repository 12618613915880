import { uploadLeadFiles } from "api/Leads";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useUploadLeadFiles = (id?: string,
    options?: UseMutationOptions<any, any, any, unknown>
) => {
    const queryClient = useQueryClient();
    return useMutation(uploadLeadFiles, {
        onSuccess: () => {
            toast.success("File uploaded successfully!")
            queryClient.refetchQueries('leadsDocument')
        },
        onError: () => {
            toast.error("File upload failed! Please try again later.")
        },
        ...options
    })
}