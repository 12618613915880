import { exportLead } from "api/Leads"
import { useMutation } from "react-query"
import { toast } from "react-toastify"



export const useExportLeads = () => {
    return useMutation(exportLead,{
        onSuccess: () => {
         toast.success('Exported  Successfully')
        },
        onError: () => {
            toast.error('Export Failed')
        }
    })
}