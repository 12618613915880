import { showCustomerDocument } from "api/Customers";
import { UseQueryOptions, useQuery } from "react-query";



export const useShowCustomerFile = (
  link: string,
  options?: UseQueryOptions<any, any, unknown, string[]>
) => {
  const queryInfo = useQuery(
    [''],
    () => showCustomerDocument(link),
    {
      onError: (err) => {
        console.error(err);
      },  
      ...options
    }
  );

  return queryInfo;
};