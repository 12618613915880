import { useForm, UseFormProps } from "react-hook-form";
import * as YUP from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const LoginFormSchema = YUP.object().shape({
    email: YUP.string()
    .required("Email is required")
    .email("Email is not valid")
    .min(6, "Email should be more than 6 letters"),
    password: YUP.string()
    .required("Password is required")
    .min(6, "Password should be more than 6 letters"),
    });

export function useLoginForm(options?: UseFormProps<any>) {
    return useForm<any | any>({
        mode: "onSubmit",
        ...options,
        resolver: yupResolver(LoginFormSchema),
    });
}

export type LoginForm = ReturnType<typeof useLoginForm>;