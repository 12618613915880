
import update from "immutability-helper";
import type { FC } from "react";
import { memo, useCallback } from "react";
import { useDrop } from "react-dnd";


import { DraggableItem } from "../draggable-item/DraggableItem";

const ItemTypes = {
    CARD: "card",
};

export interface ContainerState {

    setCardsOrder: (args: any) => void;
    cardsOrder: any;

}

export const DragContainer: FC<ContainerState> = memo(function DragContainer({
    cardsOrder,
    setCardsOrder,
}) {
    const findCard = useCallback(
        (id: string) => {
            const card = cardsOrder.filter((c: any) => `${c.id}` === id)[0] as {
                id: number;
                Header: string;
            };
            return {
                card,
                index: cardsOrder.indexOf(card),
            };
        },
        [cardsOrder]
    );

    const moveCard = useCallback(
        (id: string, atIndex: number) => {
            const { card, index } = findCard(id);
            setCardsOrder(
                update(cardsOrder, {
                    $splice: [
                        [index, 1],
                        [atIndex, 0, card],
                    ],
                })
            );
        },
        [findCard, cardsOrder, setCardsOrder]
    );

    const HandleCheckboxChange = (e: any) => {


        const myNextList = [...cardsOrder]

        const changedCard = myNextList.find(
            card => card.id === parseInt(e.target.id)
        );

        changedCard.isChecked = e.target.checked;

        setCardsOrder(myNextList);
    }

    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }))

    return (
        <div ref={drop} className='flex flex-col gap-5'>
            {cardsOrder.map((card: any) => (
                (typeof card.Header.length !== 'undefined' && card.Header.length !== 0) && (
                    <DraggableItem
                        key={card.id}
                        id={`${card.id}`}
                        text={card.Header}
                        isChecked={card.isChecked}
                        moveCard={moveCard}
                        findCard={findCard}
                        HandleCheckboxChange={HandleCheckboxChange}
                    />
                )
            ))}
        </div>
    )
})

