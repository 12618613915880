import { updateCustomerFamilyMember } from "api/Customers";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useUpdateCustomerFamilyMember = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCustomerFamilyMember, {
    onSuccess: () => {
      queryClient.invalidateQueries("customersFamilyMembers");
      toast.success("Family member updated successfully");
    },
    onError: () => {
      toast.error("Failed to update family member");
    },
  });
};
