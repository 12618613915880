import { FC } from "react";
import FormStepNavigationButtons from "../../../molecules/form-step-navigation-buttons/FormStepNavigationButtons";
import Form from "../../../atoms/form/Form";
import DefaultContainer from "../../../templates/default-container/DefaultContainer";
import { useForm } from "react-hook-form";
import Input from "../../../atoms/input/Input";
import Icon from "../../../atoms/icon/Icon";
import { IconType } from "../../../../lib/helpers/constants/iconTypes";
import { useCreateAccount } from "lib/hooks/mutations/CreateAccount/useCreateAccount";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";
import ErrorState from "components/atoms/error-state/ErrorState";

export interface ICardInfoStepProps {}

const CardInfoStep: FC<ICardInfoStepProps> = () => {
  const { updateFormData, formData, currentStep } = useSignupCustomHooks({
    initalStep: undefined
  });
  const { mutate: createAccount, isLoading, isError } = useCreateAccount();

  const { handleSubmit } = useForm();

  const onSubmit = (data: Record<string, any>) => {
    const combinedPhoneNumber = `${formData.code} ${formData.phone}`;
    const updatedFormData = {
      ...formData,
      phone: combinedPhoneNumber,
      ...data
    };
    delete updatedFormData.workers;
    delete updatedFormData.code;
    updateFormData(updatedFormData);
    createAccount(updatedFormData);
  };
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-between h-full"
    >
      <div>
        <h1 className="text-bluebutton uppercase font-bold text-[14px] text-center mt-[40px] mb-[8px]">
          STEP {currentStep}/6
        </h1>
        <span className="text-[#282D46] font-inter-bold text-[25px] flex justify-center">
          Your card info
        </span>
      </div>
      <DefaultContainer className="flex flex-col gap-[13px]">
        <div className="flex flex-col gap-[10px]">
          <details
            open
            className={`p-3 rounded-[6px] cursor-pointer border-[1px] border-[#50B8E7] bg-[#50B8E7] bg-opacity-10`}
          >
            <summary className="flex items-center justify-between !mb-[11px]">
              <div className="text-primaryTextColor font-inter-medium text-[12px]">
                Choose your payment card
              </div>
              <div className="flex items-center g ap-3">
                <Icon iconType={IconType.VISA_ICON} />
                <Icon iconType={IconType.STRIPE_ICON} />
                <Icon iconType={IconType.MASTER_CARD_ICON} />
              </div>
            </summary>
            <div className="grid grid-cols-[auto,87px] gap-[13px]">
              <Input
                label="Name on the card"
                placeholder="Name on the card"
                className="p-[7px]"
                classes={{ label: "!text-[11px] font-inter-semibold" }}
              />
              <Input
                classes={{ label: "!text-[11px] font-inter-semibold" }}
                label="Expiration date"
                placeholder="Expiration date"
                className="p-[7px]"
              />
              <Input
                classes={{ label: "!text-[11px] font-inter-semibold" }}
                label="Card number"
                placeholder="Card number"
                className="p-[7px]"
                // {...register("karten_nummer")}
              />
              <Input
                classes={{ label: "!text-[11px] font-inter-semibold" }}
                label="CVV"
                placeholder="*****"
                className="p-[7px]"
              />
            </div>
          </details>
          {/* <details
            className={`p-3 rounded-[6px] cursor-pointer border-[1px] border-[#E4E7EC]`}
          >
            <summary className="flex items-start justify-between !mb-[0px]">
              <div>
                <h1 className="text-primaryTextColor font-inter-medium">
                  Apple Pay
                </h1>
                <p className="text-[12px] text-[#667085]">
                  Unlimited users and unlimited individual data.
                </p>
              </div>
              <div className="flex items-center gap-3">
                <Icon iconType={IconType.APPLE_PAY_ICON} />
              </div>
            </summary>
          </details> */}
          <details
            className={`p-3 rounded-[6px] cursor-pointer border-[1px] border-[#E4E7EC]`}
          >
            <summary className="flex items-start justify-between !mb-[0px]">
              <div>
                <h1 className="text-primaryTextColor font-inter-medium">
                  Paypal
                </h1>
                <p className="text-[12px] text-[#667085]">
                  After submitting your order, you will be redirected to the
                  PayPal website.
                </p>
              </div>
              <div className="flex items-center gap-3">
                <Icon iconType={IconType.PAYPAL_ICON} />
              </div>
            </summary>
          </details>
        </div>
        <p className="flex items-center gap-2   text-[12px] text-[#828282] font-inter inter-regular">
          <Icon iconType={IconType.LOCK_ICON} className="p-1" />
          We protect your payment information through encryption to ensure
          bank-level security.
        </p>
      </DefaultContainer>
      <ErrorState
        error={isError}
        className="lg:max-w-[600px] max-w-[800px] 2xl:max-w-[650px] mx-auto"
      >
        Something went wrong. Please try again.
      </ErrorState>
      <FormStepNavigationButtons isLoading={isLoading} />
    </Form>
  );
};

export default CardInfoStep;
