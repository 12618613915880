import { useMultiStepFormContext } from '../../../lib/context/MultiStepFormContext/MultiStepFormContext';
import InitialLogin from './login-steps/InitialLogin'
import SuccessfulLogin from './login-steps/SuccessfulLogin';
import TwoFactorAuth from './login-steps/TwoFactorAuth';

const LoginProcces = () => {

  const { currentStep } = useMultiStepFormContext();

  
  return (
    <>
      {
        {
          1: <InitialLogin />,
          2: <TwoFactorAuth />,
          3: <SuccessfulLogin />
        }[currentStep]
      }
    </>
  )
}

export default LoginProcces