import React, { useMemo } from 'react';
import { getConnectedEdges, Handle, Position, useNodeId, useStore } from 'reactflow';

const selector = (s: any) => ({
    nodeInternals: s.nodeInternals,
    edges: s.edges,
});

const CustomHandle = (props: any) => {
    const { nodeInternals, edges } = useStore(selector);
    const nodeId = useNodeId();

    const isHandleConnectable = useMemo(() => {
        if (typeof props.isConnectable === 'function') {
            const node = nodeInternals.get(nodeId);
            const connectedEdges = getConnectedEdges([node], edges);

            return props.isConnectable({ node, connectedEdges });
        }

        if (typeof props.isConnectable === 'number') {
            const node = nodeInternals.get(nodeId);
            const connectedEdges = getConnectedEdges([node], edges);

            return connectedEdges.length < props.isConnectable;
        }

        return props.isConnectable;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeInternals, edges, nodeId, props.isConnectable]);

    return (
        <Handle
            {...props} isConnectable={isHandleConnectable}
            type="target"
            position={Position.Top}
            className="w-[65px] h-[65px] !top-[-33px] rounded-full !bg-transparent z-[-1]"
        />
    );
};

export default CustomHandle;