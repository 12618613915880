import { memo } from "react";
interface Props {
    className?: string;
    color?: string;
}
function TooltipThreeDotsIcon({ className, color }: Props) {
    return (

        <svg
 
            viewBox="0 0 2 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className} >
            <circle cx="1" cy="1" r="1" transform="rotate(-90 1 1)" fill={color ? color : '#282D46'} />
            <circle cx="1" cy="5" r="1" transform="rotate(-90 1 5)" fill={color ? color : '#282D46'} />
            <circle cx="1" cy="9" r="1" transform="rotate(-90 1 9)" fill={color ? color : '#282D46'} />
        </svg>

    );
}
export default memo(TooltipThreeDotsIcon);
