import Statistics from "components/organisms/statistics/Statistics";
import { FC } from "react";

export interface IStatisticsViewProps { }

const StatisticsView: FC<IStatisticsViewProps> = () => {
  return <Statistics />
};

export default StatisticsView;
