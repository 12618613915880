import Headline from "components/atoms/headline/Headline";
import { FC, useState } from "react";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Input from "components/atoms/input/Input";
import FilterBorderedWrapper from "components/atoms/filter-bordered-wrapper/FilterBorderedWrapper";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import FilterTableDropdown from "../leads/dropdowns/FilterTableDropdown";
import ViewChangeButtonGroup from "components/molecules/view-change/ViewChangeButtonGroup";
import ConfigureTableDropdown from "../leads/dropdowns/ConfigureTableDropdown";
import TabsSwitch from "components/molecules/tabs/TabsSwitch";
import EmployeesManagementList from "./employees-management-list/EmployeesManagementList";
import IconButton from "components/atoms/button/IconButton";
import { useNavigate } from "react-router-dom";
import Button from "components/atoms/button/Button";
import { useEmployees } from "lib/hooks/queries/Employee/useEmployees";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import PaginationControls from "components/molecules/pagination-controls/PaginationControls";
import { useTranslation } from "react-i18next";

export interface IEmployeesManagementViewProps {}

const EmployeesManagementView: FC<IEmployeesManagementViewProps> = () => {
  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);
  const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);
  const [activeView, setActiveView] = useState<string>("listView");
  const {t} = useTranslation();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const {
    currentPage,
    data,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    hasNextPage,
    hasPreviousPage,
    isLoading,
    itemsPerPage,
  } = useEmployees();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();

  const columns = [
    {
      id: 1,
      Header: `${t("Name")}`,
      accessor: "name",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <>{original.name}</>
      ),
      isChecked: true,
    },
    {
      id: 2,
      Header: `${t("Role")}`,
      accessor: "role",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <>{original.role.name}</>
      ),
      isChecked: true,
    },
    {
      id: 3,
      Header: `${t("Email")}`,
      accessor: "email",
      isChecked: true,
    },
    {
      id: 4,
      Header: `${t("Phone")}`,
      accessor: "phone",
      isChecked: true,
    },
    {
      id: 5,
      Header: `${t("Joining Date")}`,
      accessor: "created_at",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <>
          {new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(new Date(original.created_at))}
        </>
      ),
      isChecked: true,
    },
    {
      id: 6,
      Header: `${t("Status")}`,
      accessor: "status",
      Cell: ({ row: { original } }: any) => <p>Active</p>,
      isChecked: true,
    },
    {
      id: 7,
      Header: "",
      accessor: "action",
      Cell: ({ row: { original } }: any) => (
        <Button onClick={() => navigate(`employe-profile/${original.id}`)}>
          <Icon iconType={IconType.OPTIONS_ICON} />
        </Button>
      ),
      isChecked: true,
    },
  ];

  const [cardsOrder, setCardsOrder] = useState(columns);

  return (
    <div>
      <Headline title={t("Employees Management")} />
      <DashboardBoxLayout className="mt-[26px]">
        <TabsSwitch
          ActiveTab={selectedTab}
          setSelectedTab={(id) => setSelectedTab(id)}
          tabs={[{ id: 0, name: `${t('All Employees')} (${data?.pages?.[0]?.data?.data?.length ?? ""})` }]}
        >
          <div className="flex items-center gap-[18px]">
            <IconButton
              onClick={() => navigate("/hr/create")}
              disabled
              icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
            >
              {t("New Employee")}
            </IconButton>
          </div>
        </TabsSwitch>
        <div className="flex flex-row gap-3 items-center mb-5">
          <div className="flex-1">
            <Input
              icon={IconType.SEARCH_ICON}
              className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
              classes={{ icon: "left-4 !top-[30%]" }}
              placeholder={t("Search")}
            />
          </div>
          <div className="flex-none">
            <div className={`relative`}>
              <FilterBorderedWrapper
                className="cursor-pointer h-[48px] w-[48px] "
                onClick={() => {
                  setConfigureTableOpen(!configureTableOpen);
                }}
              >
                <Icon iconType={IconType.FILTER_BARS_ICON} />
              </FilterBorderedWrapper>
              {configureTableOpen && (
                <DynamicDropdown
                  setIsOpen={setConfigureTableOpen}
                  showCloseButton={true}
                  title={t("Configure Table")}
                >
                  <ConfigureTableDropdown
                    setCardsOrder={setCardsOrder}
                    cardsOrder={columns}
                  />
                </DynamicDropdown>
              )}
            </div>
          </div>
          <div className="flex-none">
            <div className={`${filterTableOpen && "relative"}`}>
              <FilterBorderedWrapper
                className="h-[48px] w-[48px] cursor-pointer"
                onClick={() => {
                  setFilterTableOpen(!filterTableOpen);
                }}
              >
                <Icon iconType={IconType.FILTER_ICON} />
              </FilterBorderedWrapper>
              {filterTableOpen && (
                <DynamicDropdown
                  setIsOpen={setFilterTableOpen}
                  showCloseButton={true}
                  title={t("Filter")}
                  className="sm:!w-[350px]"
                >
                  <FilterTableDropdown />
                </DynamicDropdown>
              )}
            </div>
          </div>
          <div className="flex-none">
            <ViewChangeButtonGroup
              setSelectedTab={setActiveView}
              ActiveTab={activeView}
            />
          </div>
        </div>
        <div className="relative">
          <div className="relative">
            {isLoading && <LoadingScreen />}
            <NoDataBoundary
              condition={data?.pages?.[0]?.data?.data?.length > 0}
              fallback={<EmptyEntity name="Employee" />}
            >
              <EmployeesManagementList
                data={data?.pages?.[0]?.data?.data ?? []}
                dataCols={cardsOrder}
                key={currentPage}
              />
            </NoDataBoundary>
          </div>
        </div>{" "}
      </DashboardBoxLayout>
      <PaginationControls
        itemsPerPage={itemsPerPage}
        handleItemsPerPageChange={(value: number) =>
          handleItemsPerPageChange(value)
        }
        from={data?.pages[0]?.data?.from}
        to={data?.pages[0]?.data?.to}
        total={data?.pages[0]?.data?.total}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
      />
    </div>
  );
};

export default EmployeesManagementView;
