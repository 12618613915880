import { switchAppointment } from "api/Appointment";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useSwitchAppointment = () => {
  const queryClient = useQueryClient();
  return useMutation(switchAppointment, {
    onSuccess: () => {
      queryClient.invalidateQueries('appointments');
      toast.success("Appointment Converted Successfully");
    },
    onError: () => {
      toast.error(`Something went wrong`);
    },
  });
};
