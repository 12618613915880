import { storeNewDocument } from "api/Customers";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useStoreNewCustomerDocument = () => {
  const queryClient = useQueryClient();

  return useMutation(storeNewDocument, {
    onSuccess: () => {
      toast.success("Created Successfully");
      queryClient.invalidateQueries("customerDocument");
    },
    onError: () => {
      toast.error("Failed to store document");
    },
  });
};
