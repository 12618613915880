import { useState } from "react";

export const useShowPassowrd = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordByIndex, setShowPasswordByIndex] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleShowPasswordByIndex = (index: number) => {
    setShowPasswordByIndex((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return {
    showPassword,
    toggleShowPassword,
    showPasswordByIndex,
    toggleShowPasswordByIndex,
  };
};
