import { assignLeads } from "api/Leads";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useAssignLeads = () => {
  const queryClient = useQueryClient();
  return useMutation(assignLeads, {
    onSuccess: () => {
      queryClient.invalidateQueries('leads');
      toast.success("Leads  assigned successfully");
    },
    onError: () => {
      toast.error(`Something went wrong`);
    },
  });
};
