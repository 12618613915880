import ToggleSwitch from "components/molecules/toggle-switch/ToggleSwitch";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

export interface ICampaignCardProps {
  id: number;
  name?: string;
  created_at?: string;
  leads_count?: number;
  assigned_to?: string;
  status: number;
  updated_at?: string;
  late?: number;
  unedited?: number;
  New?: number;
  changeStatus?: (id: any) => {};
}

const CampaignCard: FC<ICampaignCardProps> = ({
  id,
  name,
  created_at,
  leads_count,
  late,
  status,
  unedited,
  New,
  assigned_to,
  updated_at,
  changeStatus,
}) => {
  const [openOptions, setOpenOptions] = useState<boolean>(false);

  const {t} = useTranslation();

  return (
    <div className="w-full relative flex flex-col max-w-screen-sm border gap-2 border-solid border-gray-200 rounded-2xl  p-3">
      <div className="flex pb-2 items-center justify-between">
        <div className="flex flex-row items-center px-1 pt-3">
          <div className="flex flex-col">
            <div className="font-inter-medium text-sm/[16px] pb-3 px-1">
              {name}
            </div>
            <div className="font-inter-regular text-sm/[11px] px-1 text-secondaryTextColor">
              {created_at ? new Date(created_at).toLocaleDateString() : ""}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <ToggleSwitch
            defaultChecked={status === 1}
            classes={{ label: "!mb-0" }}
            onChange={() => {
              if (changeStatus) {
                changeStatus({
                  campagne_id: id,
                });
              }
            }}
          />

          <button
            type="button"
            className="px-2"
            onClick={() => {
              setOpenOptions(!openOptions);
            }}
          >
          </button>
        </div>
      </div>
      <div className="flex py-3  gap-2 items-center flex-start ">
        <div className="rounded-2xl text-center 2xl:px-6  xl:px-4 lg:px-5 md:px-8  p-2  font-inter-semibold text-sm/[10px] bg-[#EF444433] text-[#EF4444] w-auto">
          {late} {t('Late')}
        </div>
        <div className="rounded-2xl text-center 2xl:px-12  xl:px-3 lg:px-6 md:px-16  p-2 font-inter-semibold text-sm/[10px] bg-[#50B8E733] text-[#50B8E7] w-auto">
          {unedited} {t("Unedited")}
        </div>
        <div className="rounded-2xl text-center 2xl:px-6 xl:px-4 lg:px-5 md:px-8  p-2  font-inter-semibold text-sm/[10px] bg-[#66BB6A33] text-[#66BB6A] w-auto">
          {New} {t('New')}
        </div>
      </div>
      <div className="flex flex-row justify-between text-sm/[14px] p-2 pt-3 font-inter-regular items-center">
        <div>{t('Total leads')}</div>
        <div>{leads_count}</div>
      </div>
      <div className="flex flex-row justify-between text-sm p-2 font-inter-regular">
        <div>{t('Assigned to')}</div>
        <div>{assigned_to}</div>
      </div>
      <div className="flex flex-row justify-between text-sm p-2 font-inter-regular">
        <div>{t('Last updated')}</div>
        <div>{updated_at ? new Date(updated_at).toLocaleDateString() : ""}</div>
      </div>
    </div>
  );
};

export default CampaignCard;
