export interface IBusinessDirection {
  value: string;
  label: string;
}

export const businessDirections: Array<IBusinessDirection> = [
  {
    value: "",
    label: "",
  },
  {
    value: "Engineering",
    label: "Engineering",
  },
  {
    value: "Finance",
    label: "Finance",
  },
  {
    value: "Marketing",
    label: "Marketing",
  },
  {
    value: "Sales",
    label: "Sales",
  },
  {
    value: "Customer Service",
    label: "Customer Service",
  },
  {
    value: "Operations",
    label: "Operations",
  },
  {
    value: "Human Resources",
    label: "Human Resources",
  },
  {
    value: "Legal",
    label: "Legal",
  },
  {
    value: "Other",
    label: "Other",
  },
];
