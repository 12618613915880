import { memo } from "react";
interface Props {
  className?: string;
}
const EmployeeJobIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 6V5C8.5 3.34315 9.84315 2 11.5 2H13.5C15.1569 2 16.5 3.34315 16.5 5V6M2.5 10.3475C2.5 10.3475 5.61804 12.4244 10.4777 12.9109M22.5 10.3475C22.5 10.3475 19.382 12.4244 14.5223 12.9109M6.5 22H18.5C20.7091 22 22.5 20.2091 22.5 18V10C22.5 7.79086 20.7091 6 18.5 6H6.5C4.29086 6 2.5 7.79086 2.5 10V18C2.5 20.2091 4.29086 22 6.5 22Z"
        stroke="#282D46"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.5 12.1599V13.1599C14.5 13.1699 14.5 13.1699 14.5 13.1799C14.5 14.2699 14.49 15.1599 12.5 15.1599C10.52 15.1599 10.5 14.2799 10.5 13.1899V12.1599C10.5 11.1599 10.5 11.1599 11.5 11.1599H13.5C14.5 11.1599 14.5 11.1599 14.5 12.1599Z"
        stroke="#282D46"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(EmployeeJobIcon);
