import Button from 'components/atoms/button/Button'
import Form from 'components/atoms/form/Form'
import Icon from 'components/atoms/icon/Icon'
import Input from 'components/atoms/input/Input'
import Paragraph from 'components/atoms/paragraph/Paragraph'
import LoginTitle from 'components/atoms/title/LoginTitle'
import { IconType } from 'lib/helpers/constants/iconTypes'
import { useResetPasswordForm } from 'lib/hooks/forms/useResetPasswordForm'
import { useResetPassword } from 'lib/hooks/mutations/ResetPassword/useResetPassword'
import { useVerifyPasswordToken } from 'lib/hooks/mutations/VerifyPasswordToken/useVerifyPasswordToken'
import { useShowPassowrd } from 'lib/hooks/shared/useShowPasswod'
import React, { FC, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'


export interface IResetPasswordProps { }

const ResetPassword: FC<IResetPasswordProps> = () => {
    const { showPassword, toggleShowPassword } = useShowPassowrd();

    const { mutate: verifyPasswordToken, isLoading } = useVerifyPasswordToken();

    let { id } = useParams();
    useLayoutEffect(() => {
        let data = { 'token': id }
        verifyPasswordToken(data)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    const { register, formState: { errors }, handleSubmit, getValues } = useResetPasswordForm();
    const { mutate: resetPassword } = useResetPassword();

    const OnSubmit = () => {
        const data = getValues()

        Object.assign(data, { 'token': id });

        resetPassword(data)
    }
    return (
        <div className='flex flex-col gap-8'>
            <div>
                <div className='pb-2'>
                    <LoginTitle title='Reset your password.' />
                </div>
                <Paragraph paragraph='Please enter your new password.' />
            </div>
            <Form onSubmit={handleSubmit(OnSubmit)}>
                <div className='flex flex-col gap-6'>
                    <div className="relative">

                        <Input
                            label="Passwort"
                            placeholder="••••••••"
                            type={showPassword ? "text" : "password"}
                            autoComplete='new-password'
                            {...register('password')}
                            error={errors.password}
                        />

                        <Button onClick={toggleShowPassword} type="button" className="absolute right-[12px] top-[40px]">
                            <Icon
                                iconType={IconType.HIDDEN_PASSWORD_ICON}

                            />
                        </Button>

                    </div>
                    <div className="relative">
                        <Input
                            label='Confirm password'
                            placeholder='••••••••'
                            type={showPassword ? "text" : "password"}
                            autoComplete='new-password'
                            {...register('password_confirmation')}
                            error={errors.confirmPassword}
                        />

                        <Button onClick={toggleShowPassword} type="button" className="absolute right-[12px] top-[40px]">
                            <Icon
                                iconType={IconType.HIDDEN_PASSWORD_ICON}

                            />
                        </Button>

                    </div>

                    <div className='pt-5'>
                        <Button type='submit' className='bg-bluebutton text-white p-[15px] w-full rounded-[8px] disabled:opacity-50' disabled={isLoading}>
                            Anmelden
                        </Button>

                    </div>

                </div>
            </Form>

        </div>
    )
}

export default ResetPassword