import { FunctionComponent, PropsWithChildren, memo, useState } from "react";
import { ConfirmParams, ConfirmationData } from "./types";
import { ModalContext, ModalContextType } from "./ModalContext";
import DynamicModal from "../../../components/molecules/dynamic-modal/DynamicModal";

interface ModalContextProviderState extends ConfirmationData {}
export interface IModalContextProvider extends PropsWithChildren {}

const voidFunction = () => {};
const defaultText = "";

const ModalContextProvider: FunctionComponent<IModalContextProvider> = ({
  children,
}) => {
  const [hasChanges,setHasChanges] = useState<boolean>(false);
  const [state, setState] = useState<ModalContextProviderState>({
    text: defaultText,
    showCloseButton: true,
    isOpen: false,
    hideFooter: false,
    onConfirm: voidFunction,
    onCancel: voidFunction,
    buttonText: "Confirm",
    classes: { button: '' }
  });

  function confirm(params: ConfirmParams) {
    const { text: txt, onCancelComponent, ...restParams } = params;
    const text = txt || defaultText;
    return new Promise((resolve) => {
      setState((prev) => ({
        ...restParams,
        text,
        isOpen: true,
        onConfirm: () => {
          setState((prev) => ({ ...prev, isOpen: false, text }));
          resolve(true);
        },
        onCancel: () => {
          setState((prev) => ({ ...prev, isOpen: false, text }));
        },
        
        onCancelComponent,
      }));
    });
  }
  
  

  const contextValues: ModalContextType = {
    confirm: confirm,
    hasChanges,
    setHasChanges,
  };

  return (
    <ModalContext.Provider value={contextValues}>
      <DynamicModal confirmationData={state} />
      {children}
    </ModalContext.Provider>
  );
};

export default memo(ModalContextProvider);
