import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function LeadsIcon({ className, color }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.8248 11.5526C21.2254 10.9531 20.2549 10.9529 19.6553 11.5526C16.2842 14.9238 22.1758 9.03207 13.6871 17.5208C10.9355 20.2723 6.40158 18.5025 6.49352 14.7335C6.52149 13.5873 6.99597 12.497 7.82961 11.6633L13.7978 5.69511C14.3973 5.09572 14.3975 4.12519 13.7978 3.52565L11.4115 1.13927C10.812 0.539723 9.84158 0.539672 9.24203 1.13927C8.39106 1.99029 4.02237 6.35894 3.10021 7.28109C1.10343 9.27788 0.00240706 11.9328 3.75399e-06 14.7566C-0.00475172 20.4237 4.50911 25.305 10.5461 25.3104C13.4494 25.3104 16.1302 24.1893 18.1288 22.1907L24.2111 16.1084C24.8106 15.509 24.8108 14.5385 24.2111 13.939L21.8248 11.5526ZM10.3267 2.22403L12.7131 4.61041L9.72407 7.5995L7.3377 5.21312L10.3267 2.22403ZM17.0441 21.106C13.5136 24.6365 7.69328 24.6864 4.17321 21.1537C0.657077 17.625 0.662344 11.8884 4.18492 8.3658L6.25294 6.29778L8.63931 8.68416L6.74485 10.5786C4.43793 12.8854 4.34584 16.5536 6.57125 18.7791C8.78428 20.9921 12.463 20.9142 14.7717 18.6055L16.6662 16.711L19.0526 19.0974L17.0441 21.106ZM20.1374 18.0128L17.751 15.6264L20.7401 12.6373L23.1265 15.0237L20.1374 18.0128Z"
        fill={color || '#7D8592'}
      />
    </svg>
  );
}
export default memo(LeadsIcon);
