import { memo } from "react";
interface Props {
  className?: string;
}
function AssignedIcon({ className }: Props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 10.5C16.5 12.1569 15.1569 13.5 13.5 13.5C11.8431 13.5 10.5 12.1569 10.5 10.5C10.5 8.84315 11.8431 7.5 13.5 7.5C15.1569 7.5 16.5 8.84315 16.5 10.5Z"
        stroke="#7D8592"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 23.25C21 26.1495 17.6415 28.5 13.5 28.5C9.3585 28.5 6 26.1495 6 23.25C6 20.3505 9.3585 18 13.5 18C17.6415 18 21 20.3505 21 23.25Z"
        stroke="#7D8592"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.75 7.5C27.75 7.08579 27.4142 6.75 27 6.75C26.5858 6.75 26.25 7.08579 26.25 7.5H27.75ZM26.25 10.5C26.25 10.9142 26.5858 11.25 27 11.25C27.4142 11.25 27.75 10.9142 27.75 10.5H26.25ZM27.75 10.5C27.75 10.0858 27.4142 9.75 27 9.75C26.5858 9.75 26.25 10.0858 26.25 10.5H27.75ZM26.25 13.5C26.25 13.9142 26.5858 14.25 27 14.25C27.4142 14.25 27.75 13.9142 27.75 13.5H26.25ZM27 11.25C27.4142 11.25 27.75 10.9142 27.75 10.5C27.75 10.0858 27.4142 9.75 27 9.75V11.25ZM24 9.75C23.5858 9.75 23.25 10.0858 23.25 10.5C23.25 10.9142 23.5858 11.25 24 11.25V9.75ZM27 9.75C26.5858 9.75 26.25 10.0858 26.25 10.5C26.25 10.9142 26.5858 11.25 27 11.25V9.75ZM30 11.25C30.4142 11.25 30.75 10.9142 30.75 10.5C30.75 10.0858 30.4142 9.75 30 9.75V11.25ZM26.25 7.5V10.5H27.75V7.5H26.25ZM26.25 10.5V13.5H27.75V10.5H26.25ZM27 9.75H24V11.25H27V9.75ZM27 11.25H30V9.75H27V11.25Z"
        fill="#7D8592"
      />
    </svg>
  );
}
export default memo(AssignedIcon);
