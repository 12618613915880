import React, { FunctionComponent, HTMLAttributes, useState } from "react";
import Icon from "../icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Button from "../button/Button";
import { Fragment } from "react/jsx-runtime";

export interface IErrorState extends HTMLAttributes<HTMLDivElement> {
  error: Error | null | any;
  children: React.ReactNode;
  wrapperOfIconAndTextClass?: string;
}

const ErrorState: FunctionComponent<IErrorState> = ({
  error,
  children,
  wrapperOfIconAndTextClass,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  if (error) {
    return (
      <Fragment>
        {isVisible ? (
          <p
            {...rest}
            className={`bg-[#FFF6F6] text-[#D61616] w-full p-4 font-inter-bold text-[14px] justify-between rounded-[8px] flex mt-[21px] items-center ${rest.className}`}
          >
            <span className={`flex items-center gap-[14px] ${wrapperOfIconAndTextClass}`}>
              <Icon iconType={IconType.ALERT_ICON} color="#D61616" />
              {children}
            </span>
            <Button onClick={() => setIsVisible(false)}>
              <Icon iconType={IconType.CLOSE_ICON} className="p-1" />
            </Button>
          </p>
        ) : null}
      </Fragment>
    );
  }

  return null;
};

export default ErrorState;
