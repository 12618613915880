import { useToolTip } from "lib/hooks/shared/useToolTip";
import { FC, HTMLAttributes } from "react";

export interface ITooltipProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "content"> {
  content: React.ReactNode;
  isOpen: boolean;
}

const Tooltip: FC<ITooltipProps> = ({ content, isOpen, ...rest }) => {
  const { preventClickPropagation, handleCloseTooltipbyIndex } = useToolTip();
  if (isOpen) {
    return (
      <div onClick={handleCloseTooltipbyIndex}>
        <div onClick={preventClickPropagation} {...rest}>
          {content}
        </div>
      </div>
    );
  }
  return null;
};

export default Tooltip;
