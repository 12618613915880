import InsuranceDocuments from "components/organisms/insurance-documents/InsuranceDocuments";
import { FC } from "react";

export interface InsuranceDocumentsViewProps {}

const InsuranceDocumentsView: FC<InsuranceDocumentsViewProps> = () => {
  return <InsuranceDocuments />;
};

export default InsuranceDocumentsView;
