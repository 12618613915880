import { useInfiniteQuery } from "react-query";
import { toast } from "react-toastify";
import { usePagination } from "lib/hooks/shared/usePagination";
import { getRequestsData } from "api/Leads";

export const useRequestLeads = (
  search?: string,
  type?: string,
  status?: any,
  filter_worker?: any,
  date_of_creation?: any
) => {
  const {
    currentPage,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage
  } = usePagination(1, 10);

  const { data, isLoading, hasNextPage, hasPreviousPage } = useInfiniteQuery<
    Array<any> | any
  >(
    ["security", currentPage, itemsPerPage, search, type, status, filter_worker, date_of_creation],
    ({ pageParam = currentPage }) => {
      return getRequestsData({
        page: pageParam,
        number_per_page: itemsPerPage,
        search: search,
        type: type,
        status: status,
        filter_worker: filter_worker,
        date_of_creation: date_of_creation
      });
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data?.next_page_url
          ? new URL(lastPage.data.next_page_url).searchParams.get("page")
          : false;
      },
      getPreviousPageParam: (firstPage, allPages) => {
        const currentPage = allPages[allPages.length - 1];
        const prevPageUrl = currentPage?.data?.prev_page_url;
        return prevPageUrl
          ? new URL(prevPageUrl).searchParams.get("page")
          : false;
      },
      onError: () => {
        toast.error(`Something went wrong`);
      }
    }
  );

  return {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    itemsPerPage,
    handleNextPage,
    handlePreviousPage,
    handleItemsPerPageChange
  };
};
