import { createNewAddress } from "api/Appointment";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useCreateNewAddress = () => {
  const queryClient = useQueryClient();
  return useMutation(createNewAddress, {
    onSuccess: () => {
      queryClient.invalidateQueries("appointments");
      toast.success("Address Created Successfully");
    },
    onError: () => {
      toast.error("Something went wrong");
    },
  });
};
