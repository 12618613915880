import { apiRequest } from "./Api";

export const getPartnersForFilter = () =>
  apiRequest<any, any>("GET", "partners/for-filter");

export const getPartners = (params?: any) =>
  apiRequest<any, any>("GET", "partners", null, undefined, params);


export const createNewPartner = (data?: any) =>
  apiRequest<any, unknown>("POST", "partners/store", data);


export const updatePartner = (params: any) => {

  return apiRequest<FormData, any>("PUT", `partners/update/${params.id}`, params, undefined);
};

export const deletePartner = (id: any) => {
  return apiRequest<FormData, any>("DELETE", `partners/delete/${id}`);
};



export const updatePartnerStatus = (params: any) => {
  return apiRequest<FormData, any>("PUT", `partners/update-status/${params.id}`);
};


export const getStatisticCardsData = () =>
  apiRequest<any, any>("GET", "partners/info-cards");
