import { createNewSource } from "api/Sources";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useCreateSources = () => {
  const queryClient = useQueryClient();
  return useMutation(createNewSource, {
    onSuccess: () => {
      queryClient.invalidateQueries('sources');
      toast.success("Source Created Successfully");
    },
    onError: () => {
      toast.error(`Something went wrong`);
    },
  });
};
