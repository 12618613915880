import { memo } from "react";
interface Props {
    className?: string;
    color?: string;
}
const DragHandleDotsIcon = ({ className, color }: Props) => {
    return (
        <svg className={className} width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 4C10.3956 4 10.7822 3.8827 11.1111 3.66294C11.44 3.44318 11.6964 3.13082 11.8478 2.76537C11.9991 2.39992 12.0387 1.99778 11.9616 1.60982C11.8844 1.22186 11.6939 0.865492 11.4142 0.585787C11.1345 0.306082 10.7781 0.115601 10.3902 0.0384303C10.0022 -0.0387401 9.60009 0.000866562 9.23463 0.152242C8.86918 0.303617 8.55682 0.559962 8.33706 0.88886C8.1173 1.21776 8 1.60444 8 2C8 2.53043 8.21072 3.03914 8.58579 3.41421C8.96086 3.78929 9.46957 4 10 4ZM10 14C9.60444 14 9.21776 14.1173 8.88886 14.3371C8.55996 14.5568 8.30362 14.8692 8.15224 15.2346C8.00087 15.6001 7.96126 16.0022 8.03843 16.3902C8.1156 16.7781 8.30608 17.1345 8.58579 17.4142C8.86549 17.6939 9.22186 17.8844 9.60982 17.9616C9.99778 18.0387 10.3999 17.9991 10.7654 17.8478C11.1308 17.6964 11.4432 17.44 11.6629 17.1111C11.8827 16.7822 12 16.3956 12 16C12 15.4696 11.7893 14.9609 11.4142 14.5858C11.0391 14.2107 10.5304 14 10 14ZM10 7C9.60444 7 9.21776 7.1173 8.88886 7.33706C8.55996 7.55682 8.30362 7.86918 8.15224 8.23463C8.00087 8.60009 7.96126 9.00222 8.03843 9.39018C8.1156 9.77814 8.30608 10.1345 8.58579 10.4142C8.86549 10.6939 9.22186 10.8844 9.60982 10.9616C9.99778 11.0387 10.3999 10.9991 10.7654 10.8478C11.1308 10.6964 11.4432 10.44 11.6629 10.1111C11.8827 9.78224 12 9.39556 12 9C12 8.46957 11.7893 7.96086 11.4142 7.58579C11.0391 7.21071 10.5304 7 10 7Z" fill={color ? color : "#7D8592"} />
            <path d="M2 4C2.39556 4 2.78224 3.8827 3.11114 3.66294C3.44004 3.44318 3.69639 3.13082 3.84776 2.76537C3.99914 2.39992 4.03874 1.99778 3.96157 1.60982C3.8844 1.22186 3.69392 0.865492 3.41421 0.585787C3.13451 0.306082 2.77814 0.115601 2.39018 0.0384303C2.00222 -0.0387401 1.60009 0.000866562 1.23463 0.152242C0.869182 0.303617 0.556825 0.559962 0.337062 0.88886C0.117299 1.21776 1.07779e-06 1.60444 1.07779e-06 2C1.07779e-06 2.53043 0.210715 3.03914 0.585788 3.41421C0.960861 3.78929 1.46957 4 2 4ZM2 14C1.60444 14 1.21776 14.1173 0.888861 14.3371C0.559963 14.5568 0.303617 14.8692 0.152242 15.2346C0.000866562 15.6001 -0.0387401 16.0022 0.0384303 16.3902C0.115601 16.7781 0.306083 17.1345 0.585788 17.4142C0.865493 17.6939 1.22186 17.8844 1.60982 17.9616C1.99778 18.0387 2.39992 17.9991 2.76537 17.8478C3.13082 17.6964 3.44318 17.44 3.66294 17.1111C3.8827 16.7822 4 16.3956 4 16C4 15.4696 3.78929 14.9609 3.41421 14.5858C3.03914 14.2107 2.53043 14 2 14ZM2 7C1.60444 7 1.21776 7.1173 0.888861 7.33706C0.559963 7.55682 0.303617 7.86918 0.152242 8.23463C0.000866562 8.60009 -0.0387401 9.00222 0.0384303 9.39018C0.115601 9.77814 0.306083 10.1345 0.585788 10.4142C0.865493 10.6939 1.22186 10.8844 1.60982 10.9616C1.99778 11.0387 2.39992 10.9991 2.76537 10.8478C3.13082 10.6964 3.44318 10.44 3.66294 10.1111C3.8827 9.78224 4 9.39556 4 9C4 8.46957 3.78929 7.96086 3.41421 7.58579C3.03914 7.21071 2.53043 7 2 7Z" fill={color ? color : "#7D8592"} />
        </svg>

    )
}

export default memo(DragHandleDotsIcon)