import LeadsView from "components/organisms/leads/LeadsView";
import { FC } from "react";

export interface ILeadsProps { }

const Leads: FC<ILeadsProps> = () => {
    return <LeadsView />;
};

export default Leads;
