import { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}

const TotalPartnersIcon = ({ className , color}: Props) => {
  return (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.1101 1.44643C16.3915 1.44643 19.8748 4.92968 19.8748 9.21114H21.2865C20.781 -2.96571 3.43454 -2.9563 2.93359 9.21114H4.34536C4.34536 4.92963 7.82861 1.44643 12.1101 1.44643Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M21.9219 15.3859C23.6171 13.6241 22.3443 10.6144 19.8741 10.6216C17.4039 10.6144 16.131 13.6246 17.8263 15.3859C17.2631 15.6984 16.7782 16.1354 16.409 16.6596C15.9405 15.8347 15.2414 15.1572 14.4001 14.7151C16.8688 12.6108 15.3923 8.51657 12.1094 8.50391C8.82641 8.51661 7.35013 12.6114 9.81874 14.7151C8.97738 15.1572 8.27836 15.8347 7.8098 16.6596C7.44067 16.1353 6.95578 15.6984 6.39253 15.3859C8.08778 13.6241 6.81498 10.6144 4.34472 10.6216C1.87455 10.6144 0.60161 13.6246 2.29695 15.3859C0.99361 16.1089 0.109375 17.4991 0.109375 19.0921V21.9157H24.1094V19.0921C24.1094 17.4991 23.2251 16.1089 21.9219 15.3859ZM18.4623 13.4451C18.4623 12.6666 19.0956 12.0333 19.8741 12.0333C21.747 12.1109 21.7465 14.7796 19.8741 14.8568C19.0956 14.8568 18.4623 14.2235 18.4623 13.4451ZM9.99173 12.0333C9.99173 10.8656 10.9417 9.91567 12.1094 9.91567C14.9187 10.032 14.9179 14.0351 12.1094 14.151C10.9417 14.151 9.99173 13.201 9.99173 12.0333ZM2.9329 13.4451C2.9329 12.6666 3.56622 12.0333 4.34467 12.0333C6.21756 12.1109 6.217 14.7796 4.34467 14.8568C3.56622 14.8568 2.9329 14.2235 2.9329 13.4451ZM7.1682 20.5039H1.52114V19.0921C1.67667 15.3454 7.01403 15.3483 7.1682 19.0921V20.5039ZM15.6388 20.5039H8.57996V19.0921C8.77436 14.4088 15.4461 14.4124 15.6388 19.0921V20.5039ZM22.6976 20.5039H17.0506V19.0921C17.2061 15.3454 22.5434 15.3483 22.6976 19.0921V20.5039Z"
        fill={color || '#50B8E7'}
      />
    </svg>
  );
};

export default memo(TotalPartnersIcon);
