import { memo } from "react";
interface Props {
  className?: string;
}
function FilterIcon({ className }: Props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.94492 2.73242H18.0036C18.7361 2.73242 19.4431 3.00542 19.9906 3.49963C21.2251 4.61409 21.3363 6.53392 20.239 7.78769L15.4651 13.2407V19.7051C15.4651 20.0046 15.4101 20.3009 15.3038 20.5788L15.2144 20.7836C14.6279 21.9749 13.2014 22.4578 12.0284 21.8621L11.1367 21.4094C10.1235 20.8949 9.48344 19.8431 9.48344 18.6927V13.2427L4.70955 7.78769C4.27158 7.2873 4.01004 6.65566 3.96211 5.99204L3.9541 5.76978C3.9541 4.09229 5.29314 2.73242 6.94492 2.73242ZM18.0036 4.75733H6.94492C6.39432 4.75733 5.94798 5.21062 5.94798 5.76978C5.94798 6.01775 6.03758 6.25709 6.1998 6.44242L11.2255 12.1843C11.3877 12.3696 11.4773 12.609 11.4773 12.857V18.6927C11.4773 19.0762 11.6907 19.4267 12.0284 19.5982L12.9201 20.051C13.1082 20.1465 13.3369 20.0691 13.431 19.8781C13.4574 19.8244 13.4712 19.7652 13.4712 19.7051V12.857C13.4712 12.609 13.5608 12.3696 13.723 12.1843L18.7487 6.44242C19.1145 6.0245 19.0774 5.38455 18.6659 5.01306C18.4834 4.84833 18.2478 4.75733 18.0036 4.75733Z"
        fill="#282D46"
      />
    </svg>
  );
}
export default memo(FilterIcon);
