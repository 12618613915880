import { makeDonePending } from "api/Pendings"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"


export const useMakeDonePending = () => {
    const queryClinet =  useQueryClient();
    return useMutation(makeDonePending,{
        onSuccess: () => {
            queryClinet.invalidateQueries('customerPending');
            toast.success('Pending is done')
        },
        onError: () => {
            toast.error('Something went wrong!')
        }
    })
}