import CustomSelect from 'components/atoms/custom-select/CustomSelect'
import Label from 'components/atoms/input/input-label/InputLabel'
import TextArea from 'components/atoms/text-area/TextArea'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Step2ChoosePartner = () => {

    const {t} = useTranslation()
    return (
        <div className='flex flex-col gap-5'>
            <div>
                <Label>
                    {t('Partner name')}
                </Label>
                <CustomSelect
                    placeholder='Choose partner'
                />
            </div>
            <div>
                <Label>
                    {t('Note')}
                </Label>
                <TextArea
                    placeholder={t('Write note...')}
                />
            </div>
        </div>
    )
}

export default Step2ChoosePartner