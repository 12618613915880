import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
const CostumerAccesIcon = ({ className, color }: Props) => {
  return (
    <div>
      <svg
        className={className}
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.57312 2.66406C3.2647 2.66406 1.39335 4.45492 1.39335 6.66406C1.39335 7.96473 2.04204 9.1204 3.04671 9.85094C1.87622 10.3515 0.851028 11.1961 0.109696 12.3064C-0.0974659 12.6167 -0.00258659 13.0289 0.321615 13.2271C0.645817 13.4254 1.07657 13.3346 1.28374 13.0243C2.27441 11.5406 3.87483 10.6654 5.57305 10.6654C7.27128 10.6653 8.87175 11.5404 9.86253 13.024C10.0697 13.3342 10.5005 13.425 10.8247 13.2267C11.1489 13.0284 11.2437 12.6162 11.0365 12.306C10.2952 11.1959 9.27003 10.3513 8.09963 9.85088C9.10424 9.12033 9.7529 7.96469 9.7529 6.66406C9.7529 4.45492 7.88155 2.66406 5.57312 2.66406ZM5.57307 3.99609C7.11202 3.99609 8.35959 5.19 8.35959 6.66276C8.35959 8.13552 7.11202 9.32943 5.57307 9.32943C4.03412 9.32943 2.78656 8.13552 2.78656 6.66276C2.78656 5.19 4.03412 3.99609 5.57307 3.99609ZM14.384 4.08139C13.3752 2.88361 11.7587 2.38616 10.2502 2.81703C9.88155 2.92233 9.67189 3.2937 9.78192 3.64651C9.89196 3.99932 10.28 4.19997 10.6487 4.09467C11.6098 3.82016 12.6421 4.13784 13.2993 4.91813C13.9689 5.71313 14.1225 6.83919 13.6884 7.79371C13.2602 8.73518 12.3427 9.33069 11.3414 9.33081C11.3208 9.33081 11.3004 9.33167 11.2802 9.33335C11.2353 9.33244 11.1903 9.332 11.1452 9.33203C10.7604 9.33233 10.4488 9.63104 10.4491 9.99923C10.4494 10.3674 10.7615 10.6657 11.1463 10.6654C12.8444 10.6641 14.4451 11.5394 15.4349 13.024C15.6418 13.3343 16.0725 13.4254 16.3968 13.2274C16.7212 13.0293 16.8164 12.6172 16.6094 12.3068C15.8791 11.2115 14.8724 10.3745 13.7225 9.87154C14.2502 9.47676 14.6821 8.9498 14.9659 8.32577C15.606 6.91817 15.3805 5.26446 14.384 4.08139Z"
          fill="#282D46"
        />
      </svg>
    </div>
  );
};

export default memo(CostumerAccesIcon);
