import { apiRequest } from "./Api";


export const getLeadFeedbacks = (params?: {
    worker?: any,
    from_date_of_creation?: any,
    to_date_of_creation?: any,
    filter_on?: any
}) =>
    apiRequest<any, any>("GET", "leads/statistic/feedbacks", null, undefined, params);

export const getAppointmentFeedbacks = (params?: {
    worker?: any,
    from_date_of_creation?: any,
    to_date_of_creation?: any,
    filter_on?: any
}) =>
    apiRequest<any, any>("GET", "appointment/statistic/feedbacks", null, undefined, params);

export const getCustomerFeedbacks = (params?: {
    worker?: any,
    from_date_of_creation?: any,
    to_date_of_creation?: any,
    filter_on?: any,
    product?: any,
    company?: any
}) =>
    apiRequest<any, any>("GET", "costumers/statistic/product-feedbacks", null, undefined, params);

export const getLeaderboard = (params?: {
    from_date_of_creation?: any,
    to_date_of_creation?: any,
    filter_on?: any,
    type_of_statistic?: any
}) =>
    apiRequest<any, any>("GET", "global-statistic/top-users-by-performance", null, undefined, params);