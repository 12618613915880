
import { requestVerificationEmail } from "api/User"
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext"
import { useMutation } from "react-query"



export const useSendEmailVerificationRequest = () => {
    const {nextFormStep} = useMultiStepFormContext();
  return useMutation(requestVerificationEmail,{
    onSuccess: () => {
        nextFormStep();
    },
    onError: (err) => {
        console.error(err)
    }
  })
}