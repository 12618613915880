import React, { FC } from 'react'


export interface ILoginContainerProps {
    children: React.ReactNode;
}
const LoginContainer: FC<ILoginContainerProps> = ({ children }) => {
    return (
        <div className='bg-white max-w-[550px] sm:shadow-[0_4px_40px_0px_rgba(0,0,0,0.05)] w-full rounded-[10px] py-[55px] px-[30px] sm:px-[40px] flex flex-col gap-[50px]'>{children}</div>
    )
}

export default LoginContainer