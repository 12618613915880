import { updateLead } from "api/Leads";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useUpdateLeads = (id?: string, 
options?: UseMutationOptions<any, any, any, unknown>
) => {
    const queryClient = useQueryClient();
    return useMutation(updateLead, {
        onSuccess: (res) => {
            toast.success("Lead updated successfully!");
            queryClient.invalidateQueries('leads')
        },
        onError: () => {
            toast.error("Lead update failed! Please try again later.");
        },
        ...options
    })
}