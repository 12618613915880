import { memo } from "react";
interface Props {
  className?: string;
}
function UploadCSV({ className }: Props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="10" fill="#50B8E7" />
      <g clipPath="url(#clip0_2925_61321)">
        <path
          d="M24.1654 16.6667L19.9987 12.5L15.832 16.6667"
          fill="white"
          fillOpacity="0.01"
        />
        <path
          d="M27.5 22.5V23.5C27.5 24.9002 27.5 25.6002 27.2275 26.135C26.9878 26.6054 26.6054 26.9878 26.135 27.2275C25.6002 27.5 24.9002 27.5 23.5 27.5H16.5C15.0998 27.5 14.3998 27.5 13.865 27.2275C13.3946 26.9878 13.0122 26.6054 12.7725 26.135C12.5 25.6002 12.5 24.9002 12.5 23.5V22.5M24.1667 16.6667L20 12.5M20 12.5L15.8333 16.6667M20 12.5V22.5"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2925_61321">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default memo(UploadCSV);
