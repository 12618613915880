import { DragContainer } from 'components/molecules/drag-container/DragContainer';
import React from 'react'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'

export interface IConfigureTableDropdownProps {
    setCardsOrder: (args1: any) => void
    cardsOrder: any
}

const ConfigureTableDropdown: React.FC<IConfigureTableDropdownProps> = ({ cardsOrder, setCardsOrder }) => {
    const opts = {
        enableMouseEvents: true,
    }
    return (
        <DndProvider backend={TouchBackend} options={opts}>
            <DragContainer cardsOrder={cardsOrder} setCardsOrder={setCardsOrder} />
        </DndProvider >
    )
}


export default ConfigureTableDropdown