import { apiRequest } from "./Api";


export const getNotifications = async (params: any) => 
    apiRequest<any,any>('GET', 'notification', null, undefined, params);


export const getRealtimeNotifications = async () => 
    apiRequest<any,any>('GET', 'notification/unread-cnt');

export const markAsReadNotifcation = (id: number) => 
    apiRequest<any,any>('PUT', `notification/read/${id}`);


export const markAsReadAllNotifcation = () => 
    apiRequest<any,any>('POST', `notification/read-all`);