import { AccordionBodyProps } from "lib/types/accordion.types";
import { FC } from "react";

const AccordionBody: FC<AccordionBodyProps> = ({
  open,
  children,
  accordionBodyClass = "",
  ...rest
}) => {
  const bodyStyle = `${open ? "h-auto" : "h-0"} ${
    open
      ? "visible overflow-visible scale-y-100 mt-[31px]"
      : "invisible overflow-hidden scale-y-0"
  } transition-transform transition-height duration-200`;

  return (
    <div
      className={`w-full text-[#667085]  text-[14px] font-inter-regular  ${bodyStyle} ${accordionBodyClass} ${rest.className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default AccordionBody;
