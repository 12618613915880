import { getStatisticCardsData } from "api/Partners"
import { useQuery } from "react-query"


export const useParnterStatisticCard = () => {
    return useQuery<Array<any> | any>(
      ['partners', 'info-card'],
        () => getStatisticCardsData(),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}