import { updatePartnerStatus } from "api/Partners";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useUpdatePartnerStatus = () => {
    const queryClient = useQueryClient();
    return useMutation(updatePartnerStatus, {
        onSuccess: () => {
            toast.success("Source status updated successfully!");
            queryClient.invalidateQueries('partners')
        },
        onError: () => {
            toast.error("Source status update failed! Please try again later.");
        }
    })
}