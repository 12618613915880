import { Fragment } from "react/jsx-runtime";
import { useMultiStepFormContext } from "../../../lib/context/MultiStepFormContext/MultiStepFormContext";
import { IconType } from "../../../lib/helpers/constants/iconTypes";
import { sideSteps } from "../../../lib/helpers/constants/sideSteps";
import Icon from "../../atoms/icon/Icon";
import Paragraph from "../../atoms/paragraph/Paragraph";

interface StepIndicatorProps {}

const StepIndicator: React.FC<StepIndicatorProps> = () => {
  const { currentStep } = useMultiStepFormContext();
  return (
    <Fragment>
      {sideSteps.map((item, index) => (
        <div
          key={`step-key-${index}`}
          className="flex items-center gap-3 mt-[36px]"
        >
          <div className="relative">
            {index === currentStep - 1 ? (
              <Icon iconType={IconType.CRICLE_COLORFUL_ICON} />
            ) : index < currentStep - 1 ? (
              <>
                <Icon iconType={IconType.CRICLE_COLORFUL_ICON} color="white" />
                <Icon
                  iconType={IconType.VERIFY_ICON}
                  className="absolute top-[9px] right-[6px]"
                />
              </>
            ) : (
              <Icon iconType={IconType.CRICLE_ICON} />
            )}
            {index !== sideSteps.length - 1 && (
              <Icon
                iconType={
                  index < currentStep - 1
                    ? IconType.VERTICAL_COLORFUL_ICON
                    : IconType.VERTICAL_ARROW_ICON
                }
                className="absolute top-[30px] right-[12px]"
              />
            )}
          </div>
          <Paragraph
            paragraph={item.label}
            className={`text-white font-inter-semibold text-[14px] ${index >= currentStep ? "opacity-50" : ""}`}
            />
        </div>
      ))}
    </Fragment>
  );
};

export default StepIndicator;
