import { ISidebarContext, SidebarContext } from "./SidebarContext";
import React, { FC, useEffect, useState } from "react";
import { useAuthContext } from "../AuthContextContext/AuthContext";

export interface ISidebarContextProviderProps {
    children: React.ReactNode;
}


const SidebarContextProvider: FC<ISidebarContextProviderProps> = ({ children }) => {
    const ctx = useAuthContext();
    const userSidebarToggle = ctx.user?.company?.isToggled || true; 
    const [isToggled, setIsToggled] = useState(userSidebarToggle);

    const toggleSidebar = () => setIsToggled((prev: boolean) => !prev);

    // Use useEffect to update the CSS variable when the component mounts or userSidebarToggle changes
    useEffect(() => {
        document.documentElement.style.setProperty('--sidebar-toggle', isToggled ? '1' : '0');
    }, [isToggled]);

    const ContextValues: ISidebarContext = {
        isToggled,
        toggleSidebar,
    };

    return (
        <SidebarContext.Provider value={ContextValues}>
            {children}
        </SidebarContext.Provider>
    );
}

export default SidebarContextProvider;


