import { useMemo } from "react";

export function useStatusStyle(status: string) {
  return useMemo(() => {
    switch (status) {
      case "Open (Consultant)":
          return {
            backgroundColor: "rgba(255, 193, 7, 0.1)",
            color: "#FFC107"
          };
        case "Open (Office)":
          return {
            backgroundColor: "rgba(255, 193, 7, 0.1)",
            color: "#FFC107"
          };
        case "Submitted":
          return {
            backgroundColor: "rgba(159, 159, 159, 0.1)",
            color: "#9F9F9F"
          };
        case "Accepted":
          return {
            backgroundColor: "rgba(100, 199, 100, 0.1)",
            color: "#64C764"
          };
        case "Provisioned":
          return {
            backgroundColor: "rgba(25, 135, 84, 0.1)",
            color: "#198754"
          };
        case "Rejected":
          return {
            backgroundColor: "rgba(220, 53, 69, 0.1)",
            color: "#DC3545"
          };
        case "Retreat":
          return {
            backgroundColor: "rgba(158, 7, 7, 0.1)",
            color: "#9E0707"
          };
        case "Canceled":
          return {
            backgroundColor: "rgba(233, 29, 29, 0.1)",
            color: "#E91D1D"
          };
        case "Quality":
          return {
            backgroundColor: "rgba(80, 184, 231, 0.1)",
            color: "#50B8E7"
          };
      default:
        return {};
    }
  }, [status]);
}

export function useStatusBorderStyle(status: string) {
  return useMemo(() => {
    switch (status) {
      case "Provisioniert":
        return {
          borderColor: "#2DE28B",
          color: "#2DE28B",
          borderWidth: "1px",
          borderStyle: "solid"
        };
      case "Quality":
        return {
          borderColor: "#50B8E7",
          color: "#50B8E7",
          borderWidth: "1px",
          borderStyle: "solid"
        };
      case "Pending":
        return {
          borderColor: "#EFBE12",
          color: "#EFBE12",
          borderWidth: "1px",
          borderStyle: "solid"
        };
      case "Canceled":
        return {
          borderColor: "#CC7272",
          color: "#CC7272",
          borderWidth: "1px",
          borderStyle: "solid"
        };
      case "open":
        return {
          borderColor: "#282D46",
          color: "#282D46",
          borderWidth: "1px",
          borderStyle: "solid"
        };
      default:
        return {};
    }
  }, [status]);
}
