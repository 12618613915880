import Table from 'components/molecules/table/Table';
import {FC} from 'react'
import { Column } from 'react-table';

export interface  IEmployeeProfileListProps<T>{
    data: T;
    columns: Column<any>[];
}

const EmployeeProfileList:FC<IEmployeeProfileListProps<any>> = ({columns,data}) => {
  return (
    <Table columns={columns} data={data} isColumnSorted={true} search={''} />
  )
}

export default EmployeeProfileList