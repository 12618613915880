import { useQuery } from "react-query"
import { getSelfProfile } from "api/Employee";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";


export const useEmployeSelfProfile = (
    id: any
) => {
const permissions = usePermissions([P["Hr Admin"]]);
return useQuery<Array<any> | any>(
  'employes-profile',
    () => getSelfProfile(permissions ? id : null),
    {
        onError(error: any) {
            console.error(error)
        }
    }
)
}