import { IconType } from "./iconTypes";
import { PermissionsTypes as P } from "./permissions";

export interface ISideNavLinks {
  id: string;
  label: string;
  path: string;
  icon?: string;
  children?: ISideNavLinks[];
  permissions?: P[];
}

export const sideNavLinks: Array<ISideNavLinks> = [
  // {
  //   id: "1",
  //   label: "Home",
  //   path: "/dashboard",
  //   permissions: [
  //     P["Leads Admin"],
  //     P["Leads Menager"],
  //     P["Leads Salesman"],
  //     P["Appointment Admin"],
  //     P["Appointment Menager"],
  //     P["Appointment Salesman"],
  //   ],
  //   icon: IconType.HOME_ICON,
  // },
  {
    id: "2",
    label: "HR",
    path: "/hr",
    icon: IconType.HR_ICON,
    permissions: [P["Hr Admin"]],
    children: [
      {
        id: "1",
        label: "Files",
        path: "/files",
        permissions: [P["Hr Admin"]],
        icon: IconType.HR_ICON,
      },
    ],
  },
  // {
  //   id: "3",
  //   label: "Projects",
  //   path: "/projects",
  //   permissions: [P["Leads Admin"], P["Leads Menager"], P["Leads Salesman"]],
  //   icon: IconType.PROJECT_ICON,
  // },
  // {
  //   id: "4",
  //   label: "Team",
  //   path: "/team",
  //   permissions: [P["Leads Admin"], P["Leads Menager"], P["Leads Salesman"]],
  //   icon: IconType.TEAM_ICON,
  // },
  // {
  //   id: "5",
  //   label: "Calendar",
  //   path: "/calendar",
  //   permissions: [P["Leads Admin"], P["Leads Menager"], P["Leads Salesman"]],
  //   icon: IconType.CALENDAR_ICON,
  // },
  {
    id: "6",
    label: "Appointments",
    path: "/appointments",
    icon: IconType.EVENTS_ICON,
    permissions: [
      P["Appointment Salesman"],
      P["Appointment Admin"],
      P["Appointment Menager"],
    ],
    children: [
      {
        id: "1123",
        label: "Own Appointments",
        path: "/appointments/own-appointments",
        permissions: [P["Appointment Admin"], P["Appointment Menager"]],
        icon: IconType.EVENTS_ICON,
      },
    ],
  },
  {
    id: "7",
    label: "Customers",
    path: "/customers",
    permissions: [
      P["Costumer Admin"],
      P["Costumer Menager"],
      P["Costumer Salesman"],
    ],
    icon: IconType.CUSTOMERS_ICON,
    children: [
      {
        id: "11123",
        label: "Security",
        path: "/customers/security",
        permissions: [P["Costumer Admin"]],
        icon: IconType.CUSTOMERS_ICON,
      },
    ],
  },
  {
    id: "8",
    label: "Leads",
    path: "/leads",
    permissions: [P["Leads Admin"], P["Leads Menager"], P["Leads Salesman"]],
    icon: IconType.LEADS_ICON,
    children: [
      {
        id: "122",
        label: "Sources",
        path: "/leads/source",
        permissions: [
          P["Leads Admin"],
          P["Leads Menager"],
          // P["Leads Salesman"],
        ],
        icon: IconType.LEADS_ICON,
      },
      {
        id: "2213",
        label: "Campaigns",
        path: "/leads/campaign",
        permissions: [
          P["Leads Admin"],
          P["Leads Menager"],
          // P["Leads Salesman"],
        ],
        icon: IconType.LEADS_ICON,
      },
      {
        id: "3213",
        label: "Security",
        path: "/leads/security",
        permissions: [P["Leads Admin"]],
        icon: IconType.LEADS_ICON,
      },
    ],
  },
  {
    id: "9",
    label: "Partners",
    path: "/partners",
    icon: IconType.PARTNERS_ICON,
    permissions: [
      P["Hr Admin"],
      P["Hr Menager"],
      P["Hr Admin"],
      P["Costumer Admin"],
      P["Costumer Salesman"],
      P["Costumer Menager"],
      P["Leads Salesman"],
      P["Leads Menager"],
      P["Leads Admin"],
      P["Appointment Admin"],
      P["Appointment Menager"],
      P["Appointment Menager"]
    ],
  },
  // {
  //   id: "10",
  //   label: "Statistic",
  //   path: "/statistic",
  //   permissions: [P["Leads Admin"], P["Leads Menager"], P["Leads Salesman"]],
  //   icon: IconType.STATISTIC_ICON,
  // },
  {
    id: "11",
    label: "Pendings",
    path: "/pendings",
    permissions: [P["Leads Admin"], P["Leads Menager"], P["Leads Salesman"],P["Appointment Menager"],P["Appointment Admin"],P["Appointment Salesman"],P["Costumer Admin"],P["Costumer Menager"],P['Costumer Salesman']],
    icon: IconType.PENDINGS_ICON,
  },
  // {
  //   id: "12",
  //   label: "Health",
  //   path: "/health",
  //   permissions: [P["Leads Admin"], P["Leads Menager"], P["Leads Salesman"]],
  //   icon: IconType.INSURANCE_ICON,
  // },
  // {
  //   id: "13",
  //   label: "Chat",
  //   path: "/chat",
  //   permissions: [P["Leads Admin"], P["Leads Menager"], P["Leads Salesman"]],
  //   icon: IconType.CHAT_ICON,
  // },
  // {
  //   id: "14",
  //   label: "Settings",
  //   path: "/settings",
  //   permissions: [P["Leads Admin"], P["Leads Menager"], P["Leads Salesman"]],
  //   icon: IconType.SETTINGS_ICON,
  // },
  {
    id: "16",
    label: "Statistics",
    path: "/statistics",
    permissions: [P["Leads Admin"], P["Leads Menager"], P["Leads Salesman"]],
    icon: IconType.STATISTIC_ICON,
  },
  {
    id: "17",
    label: "Documents",
    path: "/insurance-documents",
    permissions: [P["Leads Admin"], P["Leads Menager"], P["Leads Salesman"]],
    icon: IconType.DOCUMENT_MENU_ICON,
  },
];
