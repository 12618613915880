import  { FC } from "react";
import Icon from "../../../atoms/icon/Icon";
import { IconType } from "../../../../lib/helpers/constants/iconTypes";
import Button from "../../../atoms/button/Button";
import Paragraph from "../../../atoms/paragraph/Paragraph";
import { useMultiStepFormContext } from "../../../../lib/context/MultiStepFormContext/MultiStepFormContext";
import { IUserRegiserRequest } from "lib/types/user.types";
import { useCheckEmailVerification } from "lib/hooks/mutations/CheckEmailVerification/useCheckEmailVerification";
import ErrorState from "components/atoms/error-state/ErrorState";

export interface IEmailVerificationStepProps {}

const CheckEmailVerificationStep: FC<IEmailVerificationStepProps> = () => {
  const {formData} = useMultiStepFormContext<IUserRegiserRequest>();
  const {mutate: checkEmailVerification , isError } = useCheckEmailVerification();
  
  const onSubmit = () => {
    checkEmailVerification({email:formData.email})
  }

  
  return (
    <div className="flex items-center gap-[34px] h-screen max-h-[100%] p-[44px] bg-signupbg">
      <div className="bg-white max-w-[90%] w-full mx-auto h-full rounded-xl p-[55px] flex justify-center items-center">
        <div className="flex justify-center items-center flex-col gap-[14px]">
          <Icon iconType={IconType.COLORFUL_LOGO_ICON} className="mb-[60px]" />
          <h1 className="text-[#282D46] font-inter-regular text-center text-[52px] w-full">
            Hello,<span className="font-inter-semibold">{formData.name}</span>
          </h1>
          <h1 className="text-[#282D46] max-w-[1128px] font-inter-medium text-center text-[52px] w-full">
          Confirm your email address to start your experience with HUB
          </h1>
          <Paragraph
            className="text-[#5E5E5E] max-w-[995px] font-inter-medium text-center !text-[24px]  w-full"
            paragraph="For security reasons, please help us by verifying your email address. Please check within 28 days of first logging in to avoid deactivation of your account."
          />
          <ErrorState error={isError} className="max-w-[500px]">
           Please check your email and verify your email address!
          </ErrorState>
          <Button
            onClick={onSubmit}
            className="bg-bluebutton text-white p-[15px] w-[269px] font-inter-semibold rounded-[8px] mt-[10px]"
          >
            Confirm email address
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CheckEmailVerificationStep;
