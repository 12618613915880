import React, { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}
function CloseIcon({ className , color }: Props) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0417 1.24251C15.6017 0.826073 14.9132 0.825324 14.4723 1.2408L8.50075 6.86811L2.52918 1.2408C2.08829 0.825324 1.39979 0.826073 0.959794 1.24251L0.894367 1.30444C0.417309 1.75595 0.418137 2.51573 0.896176 2.96621L6.76877 8.50024L0.896176 14.0343C0.418137 14.4848 0.417311 15.2445 0.894369 15.696L0.959796 15.758C1.39979 16.1744 2.08829 16.1752 2.52919 15.7597L8.50075 10.1324L14.4723 15.7597C14.9132 16.1752 15.6017 16.1744 16.0417 15.758L16.1071 15.696C16.5842 15.2445 16.5834 14.4848 16.1053 14.0343L10.2327 8.50024L16.1053 2.96621C16.5834 2.51573 16.5842 1.75595 16.1071 1.30443L16.0417 1.24251Z"
        fill={color || "#282D46"}
      />
    </svg>
  );
}

export default memo(CloseIcon);
