import EmployeeProfileView from 'components/organisms/employee-profile/EmployeeProfileView'
import {FC} from 'react'

export interface IEmployeeProfileProps {}

const EmployeeProfile:FC<IEmployeeProfileProps> = () => {
  return (
   <EmployeeProfileView/>
  )
}

export default EmployeeProfile