import { storeBaseOverview } from "api/Documents";
import { queryClient } from "lib/helpers/configs/queryClient";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useBaseOverview = () => {

    const { t } = useTranslation()

    return useMutation(storeBaseOverview, {
        onSuccess: () => {
            queryClient.invalidateQueries("resources");

            toast.success(t("Created successfully"));
        },
        onError: () => {
            toast.error(t("Failed to create"));
        },
    });
};
