import { useQuery } from "react-query"
import { getPermissionsModule } from "../../../../api/User";
import { IModulesResponse } from "../../../types/permission.module.types";
import { modulesKeys } from "lib/hooks/forms/keys/modulesKeys";


export const useModules = (search?: string) => {
    return useQuery<Array<IModulesResponse> | any>(
        modulesKeys.modules(search),
        () => getPermissionsModule(),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}