import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import Icon from 'components/atoms/icon/Icon';
import Input from 'components/atoms/input/Input';
import ImageDropZone from 'components/molecules/image-dropzone/ImageDropZone';
import { IconType } from 'lib/helpers/constants/iconTypes';
import withModal from 'lib/helpers/hoc/withModal'
import { useTemplates } from 'lib/hooks/mutations/Documents/useTemplates';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface INewCompanyProps {
    onConfirm: () => void;
}

const NewCompany: React.FC<INewCompanyProps> = ({ onConfirm }) => {

    const { t } = useTranslation()

    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({ defaultValues: { company_name: '', company_link: '', company_logo: null } });

    const { mutateAsync, isLoading } = useTemplates()

    const onSubmit = (data: any) => {

        const finalData = {
            name: data.company_name,
            link_to: data.company_link,
            type: 'Ressources',
            company_logo: data.company_logo
        }

        mutateAsync(finalData).then(() => {
            onConfirm()
        })

    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-0 flex flex-col gap-7">
                <div className="relative flex justify-start">

                    <ImageDropZone
                        label={t("Company logo")}
                        {...register("company_logo")}
                        rules={{ required: t('Company Logo is required*') }}
                        control={control as any}
                        classes={{
                            container: "max-w-[150px] mr-auto",
                            subContainer: "ml-0",
                            wrapperLabelClassName: "!w-[100px] !h-[100px]",
                        }}
                        acceptParameters="image/png, image/jpg, image/jpeg"
                    />
                    {errors.company_logo?.message && (
                        <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[0px] 2xl:bottom-[-20px]  left-[2px]">
                            <>{t(errors.company_logo?.message as string)}</>
                        </div>
                    )}
                </div>
                <Controller
                    control={control}
                    name='company_name'
                    rules={{ required: t('Company Name is required') }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            value={field.value}
                            label={t('Company Name')}
                            className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
                            placeholder={t('Enter Company Name')}
                            error={(errors as any)?.company_name}
                        />
                    )}


                />
                <Controller
                    control={control}
                    name='company_link'
                    rules={{ required: t('Company Link is required') }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            value={field.value}
                            label={t('Company Link')}
                            className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
                            placeholder={t('Enter Company Link')}
                            error={(errors as any)?.company_link}
                        />
                    )}


                />

                <div className='flex-none flex justify-end'>
                    <IconButton
                        type='submit'
                        disabled={isLoading}
                        className='w-auto'
                        icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
                    >
                        {t('Add New Company')}
                    </IconButton>
                </div>
            </div>
        </Form>
    );
}

export default withModal(NewCompany)