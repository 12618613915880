import Campaign from "components/organisms/campaign/Campaign";
import { FC } from "react";

export interface ICampaignViewProps {}

const CampaignView: FC<ICampaignViewProps> = () => {
  return <Campaign />;
};

export default CampaignView;
