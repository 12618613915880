import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getCustomerFeedbacks } from "api/Statistics";


interface FiltersProps {
    worker?: string,
    from_date_of_creation?: string,
    to_date_of_creation?: string,
    filter_on?: string,
    product?: string,
    company?: string
}
export const useCustomerFeedbacks = (
    filters?: FiltersProps
) => {

    const { data, isLoading } = useQuery<Array<any>>(
        ['customer', filters?.worker, filters?.from_date_of_creation, filters?.to_date_of_creation, filters?.filter_on,filters?.product,filters?.company],
        () => {
            return getCustomerFeedbacks({
                worker: filters?.worker === 'All agents' ? null : filters?.worker,
                product: filters?.product === 'All products' ? null : filters?.product,
                company: filters?.company === 'All companies' ? null : filters?.company,
                from_date_of_creation: filters?.filter_on === 'Individual' ? filters?.from_date_of_creation : null,
                to_date_of_creation: filters?.filter_on === 'Individual' ?  filters?.to_date_of_creation : null,
                filter_on: filters?.filter_on === 'Individual' ? null : filters?.filter_on,
            });
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
