import  { FC } from 'react'
import SignupProcess from '../../components/organisms/signup/SignupProcess'

export interface ISignupProps {}

const Signup:FC<ISignupProps> = () => {
  return (
   <SignupProcess/>
  )
}

export default Signup