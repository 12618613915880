import {
  ForwardRefRenderFunction,
  TextareaHTMLAttributes,
  forwardRef
} from "react";
import { DeepMap, FieldError } from "react-hook-form";
import ErrorMessage from "../error-message/ErrorMessage";
import Label from "../input/input-label/InputLabel";

export interface ITextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: DeepMap<any, FieldError>;
  label?: string;
}
const TextArea: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  ITextAreaProps
> = ({ label, error, ...rest }, ref) => {
  return (
    <div className="relative">
      {label && <Label text={label} />}
      <textarea
        ref={ref}
        {...rest}
        className={`w-full outline-0 indent-2  placeholder-[#667085] font-inter-regular rounded-[8px] border-[1px] border-inputborder min-h-[111px] p-[10px] ${rest.className}`}
      />
      <ErrorMessage error={error} />
    </div>
  );
};

export default forwardRef(TextArea);
