import { memo } from "react";
interface Props {
  className?: string;
  color?: string;

}
function DownArrowIcon({ className, color }: Props) {
  return (
    <svg
      width="13"
      height="7"
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.26262 0.292893C0.902136 0.653377 0.874406 1.22061 1.17943 1.6129L1.26262 1.70711L6.26262 6.70711C6.6231 7.06759 7.19033 7.09532 7.58263 6.7903L7.67683 6.70711L12.6768 1.70711C13.0674 1.31658 13.0674 0.683418 12.6768 0.292893C12.3163 -0.0675907 11.7491 -0.0953203 11.3568 0.209705L11.2626 0.292893L6.96973 4.585L2.67683 0.292893C2.31635 -0.0675907 1.74912 -0.0953203 1.35683 0.209705L1.26262 0.292893Z"
        fill={color ? color : "#7E84A3"}
      />
    </svg>
  );
}
export default memo(DownArrowIcon);
