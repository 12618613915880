import { ICampaignResponse, IUpdateStatus, ICsv, IStore, PaginationParams } from "lib/types/campaign.types";

import { apiRequest } from "./Api";

export const dataOfCsv = (data: ICsv) =>
  apiRequest<ICsv, any>("POST", "leads/campaign/csv-data", data);

export const createCampaign = (data: IStore) =>
  apiRequest<IStore, any>("POST", "leads/campaign/store", data, {
    "content-type": "multipart/form-data"
  });

export const storeLeads = (data: any) =>
  apiRequest<FormData, any>("POST", "leads/campaign/store-leads", data);

export const getCampaigns = (params?: PaginationParams) =>
  apiRequest<ICampaignResponse, unknown>("GET", "leads/campaign", undefined, undefined, params);

export const updateStatus = (data: IUpdateStatus) =>
  apiRequest<IUpdateStatus, any>("POST", "leads/campaign/change-status", data);

export const getAllCampaigns = () =>
  apiRequest<any, any>("GET", "leads/campaign/for-filter");


export const storeAdditionalFile = (data: any) =>
  apiRequest<IStore, any>("POST", "leads/campaign/store-new-file", data, {
    "content-type": "multipart/form-data"
  });
