import { createNewAppointment } from "api/Appointment";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useCreateAppointments = () => {
  const queryClient = useQueryClient();
  return useMutation(createNewAppointment, {
    onSuccess: () => {
      queryClient.invalidateQueries('appointments');
      toast.success("Appointment Created Successfully");
    },
    onError: () => {
      toast.error(`Something went wrong`);
    },
  });
};
