import { memo } from "react";
interface Props {
  className?: string;
}
function DownpercentageIcon({ className }: Props) {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.77 0.5L4.23415 0.5C2.69057 0.5 1.72882 2.17443 2.50659 3.50774L4.27452 6.53847C5.04627 7.86148 6.95788 7.86148 7.72963 6.53847L9.49756 3.50774C10.2753 2.17443 9.31358 0.5 7.77 0.5Z"
        fill="#F45B69"
      />
    </svg>
  );
}
export default memo(DownpercentageIcon);
