import {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import CustomSelect, {
  OptionsType,
} from "components/atoms/custom-select/CustomSelect";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useAuthContext } from "lib/context/AuthContextContext/AuthContext";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Button from "components/atoms/button/Button";
import Pusher from "pusher-js";
import Sound from "../../../assets/audio/notification.mp3";
import ErrorState from "components/atoms/error-state/ErrorState";
import { useHasRealTimeNotifications } from "lib/hooks/queries/Notifications/useHasRealTimeNotifications";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import { useMarkAsReadNotification } from "lib/hooks/mutations/Notification/useMarkAsReadNotification";
import { useMarkAsReadAllNotification } from "lib/hooks/mutations/Notification/useMarkAsReadAllNotification";
import { useNotificationInfiniteScroll } from "lib/hooks/queries/Notifications/useNotifications";
import {
  format,
  parseISO,
  differenceInHours,
  formatDistanceToNow,
} from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import IconButton from "components/atoms/button/IconButton";
import { useTranslation } from "react-i18next";
import { useLanguage } from "lib/hooks/mutations/Language/useLanguage";


export interface IHeaderProps { }

const initialState = {
  isNotificationOpen: false,
  notifications: [],
  notificationCount: 0,
  notificationSound: null,
};

function notificationReducer(state: any, action: any) {
  switch (action.type) {
    case "SET_IS_NOTIFICATION_OPEN":
      return {
        ...state,
        isNotificationOpen: action.payload,
        realTimeNotify: action.payload,
      };
    case "SET_NOTIFICATIONS":
      return { ...state, notifications: action.payload };
    case "SET_NOTIFICATION_COUNT":
      return { ...state, notificationCount: action.payload };
    case "SET_NOTIFICATION_SOUND":
      return { ...state, notificationSound: action.payload };
    default:
      return state;
  }
}

const Header: FC<IHeaderProps> = () => {

  const { t, i18n } = useTranslation();
  const { user, SelectedLanguage, setSelectedLanguage } = useAuthContext();
  // const [SelectedLanguage, setSelectedLanguage] = useState(user?.user_information?.language || 'en')


  const { themeColors } = useThemeContext();
  const [state, dispatch] = useReducer(notificationReducer, initialState);



  const audioPlayer = useRef<HTMLAudioElement>(null);
  const {
    data: notificationsData,
    refetch: refetchNotifications,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading: isNotificationsLoading,
    error: hasError,
  } = useNotificationInfiniteScroll();
  const { hasRealTimeNotifications, setHasRealTimeNotifications } =
    useHasRealTimeNotifications();
  const { mutate: markAsReadNotification } = useMarkAsReadNotification();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { mutate: markAsReadAllNotification } = useMarkAsReadAllNotification();
  const toggleBodyScroll = (isNotificationOpen: boolean) => {
    if (isNotificationOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };
  const navigate = useNavigate();
  const formatDate = (dateString: string) => {
    const date = parseISO(dateString);
    const now = new Date();
    const diffHours = differenceInHours(now, date);

    if (diffHours < 24) {
      return formatDistanceToNow(date) + ` ${t('ago')}`;
    } else {
      return format(date, "MMM dd | hh:mm a");
    }
  };

  useEffect(() => {
    toggleBodyScroll(state.isNotificationOpen);
  }, [state.isNotificationOpen]);

  useEffect(() => {
    const pusher = new Pusher(`aa3e752b70450370a2d2`, {
      cluster: `mt1`,
    });

    const channel = pusher.subscribe("notifychannel");
    channel.bind("notifyevent", (data: any) => {
      if (data?.user_id === user.id) {
        dispatch({ type: "SET_NOTIFICATIONS", payload: [data] });
        dispatch({ type: "SET_NOTIFICATION_SOUND", payload: new Audio(Sound) });
        setHasRealTimeNotifications(data?.unread_count);

        if (audioPlayer?.current) {
          audioPlayer?.current?.play()
        }

      }
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, state.notificationCount]);

  const lastElementRef = useRef<HTMLDivElement | null>(null);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && hasNextPage && !isFetching) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetching]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    });

    if (lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }

    return () => {
      if (lastElementRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(lastElementRef.current);
      }
    };
  }, [handleIntersection]);

  const trailingActions = () => (
    <TrailingActions>
      <SwipeAction
        destructive={true}
        onClick={() => console.info("swipe action triggered")}
      >
        <IconButton
          secondary
          className="!rounded-none !border-l-0 !border-r-0  border-b-0 !border-[#E4E6E8] !text-red-600"
          icon={<Icon iconType={IconType.DELETE_ICON} color="#DC2626" />}
        >
          {t('Delete')}
        </IconButton>
      </SwipeAction>
    </TrailingActions>
  );
  const totalNotifications = notificationsData?.pages?.flatMap(
    (page) => page?.notifications?.data
  )?.length;



  const { mutate: changeLng } = useLanguage()

  const handleLanguageChange = (e: any) => {
    i18n.changeLanguage(e.target.value)
    setSelectedLanguage(e.target.value)

    const data = { lang: e.target.value }
    changeLng(data)
  }


  return (
    <div
      className={`flex gap-y-2 justify-between xl:items-center flex-col xl:flex-row relative ${state.isNotificationOpen ? "overflow-hidden" : ""
        }`}
    >
      <audio ref={audioPlayer} src={Sound} />
      <Input
        icon={IconType.SEARCH_ICON}
        className="w-full xl:!w-[412px] border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
        classes={{ icon: "left-4 !top-[30%]" }}
        placeholder={t("Search")}
      />
      <div className="gap-5 flex items-center justify-end flex-col md:flex-row">
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-[8px] mr-[11px]">
            <Icon iconType={SelectedLanguage === 'en' ? IconType.ENGLISH_FLAG_ICON : IconType.DUTCH_FLAG_ICON} />
            <div className="flex items-center gap-[2px]">
              <CustomSelect
                optionsType={OptionsType.SYSTEM_LANGUAGE_OPTIONS}
                placeholder=""
                value={SelectedLanguage}
                className="!bg-transparent !border-0 text-[#50B8E7] !indent-0 text-[14px] font-inter-bold !w-full !pl-0"
                onChange={handleLanguageChange}
              />
              <Icon
                iconType={IconType.DOWN_ARROW_ICON}
                color={themeColors.primary}
              />
            </div>
          </div>
          <div className="relative">
            <div
              onClick={() => {
                dispatch({
                  type: "SET_IS_NOTIFICATION_OPEN",
                  payload: !state.isNotificationOpen,
                });
                refetchNotifications();
                setHasRealTimeNotifications(0);
              }}
              className="w-[48px] relative h-[48px]  flex justify-center cursor-pointer items-center bg-white border-[1px] border-[#F2F4F7] rounded-xl"
            >
              <Icon iconType={IconType.NOTIFICATIONS_ICON} />
              <ConditionalRender condition={!!hasRealTimeNotifications}>
                {" "}
                <div className="w-[13px] h-[13px] bg-red-500 rounded-full absolute top-[10px] right-[13px] flex items-center">
                  <span className="text-white font-inter-semibold text-[10px] mx-auto">
                    {hasRealTimeNotifications ?? ""}
                  </span>
                </div>
              </ConditionalRender>
            </div>
          </div>
        </div>
        <div>
          <div className="w-auto h-[48px] max-w-full bg-white rounded-xl border-[1px] border-[#F2F4F7] flex  whitespace-nowrap items-center gap-[10px]">
            <img
              src={
                user?.user_information?.profil_picture
                  ? `https://mainhub.insusales.com/api/profile-picture/${user?.user_information?.profil_picture}`
                  : "https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
              }
              loading="lazy"
              alt="Profile"
              className="ml-[14px] w-[30px] h-[30px] rounded-full object-cover"
            />
            <h1 className="text-primaryTextColor font-inter-semibold flex items-center gap-1 shadow-customsecondary">
              {user?.name}
              <span>{user?.last_name?.slice(0, 2)}</span>
            </h1>
            <Icon
              iconType={IconType.DOWN_ARROW_ICON}
              className="ml-auto mr-[11px]"
            />
          </div>
        </div>
      </div>
      <ConditionalRender condition={state.isNotificationOpen}>
        <div
          className={`${state.isNotificationOpen ? "block overflow-hidden" : "hidden"
            } fixed z-[999999999] inset-0`}
        >
          <div
            className="fixed inset-0 transition-opacity"
            aria-hidden="true"
            onClick={() => {
              dispatch({
                type: "SET_IS_NOTIFICATION_OPEN",
                payload: false,
              });
              setShowTooltip(false);
            }}
          >
            <div className="absolute inset-0 bg-[--theme] opacity-[0.16]"></div>
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div></div>
          <div className="bg-white w-[437px] h-[700px]  absolute z-[9998] md:top-[80px] xl:top-[27px] right-[19rem] rounded-[30px] overflow-hidden">
            <div className="flex gap-3 justify-between items-end  px-[30px] py-[26px] sticky top-0 bg-white !rounded-[30px]">
              <div
                onClick={() => setShowTooltip(false)}
                className="flex flex-row items-center gap-3 w-full"
              >
                <h3
                  className={`text-[16px] sm:text-[18px] md:text-[20px] xl:text-[22px] text-[#0A1629] capitalize  font-inter-bold`}
                  id="modal-title"
                >
                  {t('Notifications')}
                </h3>
              </div>
              <Button
                className={`flex justify-center items-center bg-[#F4F9FD] size-[35px] md:size-[44px] rounded-[14px] flex-none`}
                onClick={() => setShowTooltip((prev) => !prev)}
              >
                <Icon iconType={IconType.DOTS_ICON} />
              </Button>
            </div>
            <ConditionalRender condition={showTooltip}>
              <div className="absolute cursor-pointer z-[9999] flex flex-col gap-3 top-[90px] right-[26px] bg-white  shadow-custom w-[200px]  rounded-[14px] p-[15px]">
                <div className="h-0 w-0 border-x-8 absolute border-x-transparent border-b-[16px] border-white top-[-15px] right-[15px]"></div>

                <div
                  typeof="button"
                  onClick={() => {
                    // eslint-disable-next-line no-lone-blocks
                    {
                      notificationsData &&
                        notificationsData?.pages?.flatMap((page) =>
                          page.notifications.data?.filter(
                            (item: Record<string, any>) =>
                              item.read_at === null
                                ? markAsReadAllNotification()
                                : null
                          )
                        );
                      setShowTooltip(false);
                    }
                  }}
                  className="flex cursor-pointer items-center gap-3 p-2 rounded-lg bg-[--theme-bg-opacity]"
                >
                  <Icon iconType={IconType.MARK_AS_READ_ICON} color="#000" />
                  <h1 className="text-[#0A1629] font-inter-semibold text-[14px]">
                    {t('Mark all as read')}
                  </h1>
                </div>
              </div>
            </ConditionalRender>


            <ErrorState error={hasError}>{t('Something went wrong!')}</ErrorState>
            {isNotificationsLoading ? (
              <LoadingScreen />
            ) : (
              <div
                onClick={() => setShowTooltip(false)}
                className="verticalOverflowScrollStyle min-h-auto max-h-full overflow-x-hidden overflow-y-auto"
                style={{ height: "calc(100% - 90px)" }}
              >
                {" "}
                <NoDataBoundary
                  condition={
                    notificationsData &&
                    notificationsData?.pages?.flatMap(
                      (page) => page.notifications.data
                    ).length > 0
                  }
                  fallback={
                    <>
                      <div className={`text-center my-20 text-xl !mt-[15rem]`}>
                        <p className="font-inter-regular text-primaryTextColor">
                          {t('Oops!')}
                        </p>
                        <p className="font-inter-regular text-primaryTextColor">
                          {t("We couldn't find any notification.")}
                        </p>
                        <p className="font-inter-regular text-primaryTextColor">
                          {t('Please check back later.')}
                        </p>
                      </div>
                    </>
                  }
                >
                  {notificationsData?.pages
                    ?.flatMap((page) => page?.notifications.data)
                    .map((item: Record<string, any>, index: number) => {
                      const isLastItem =
                        index === (totalNotifications as any) - 1;
                      return (
                        <SwipeableList>
                          <SwipeableListItem
                            trailingActions={trailingActions()}
                          >
                            <div
                              className={`border-t-[#E4E6E8] border-t-[1px] cursor-pointer  w-full  ${index === 0 ? "py-2" : "py-4"
                                } ${isLastItem
                                  ? "border-b-[1px] border-[#E4E6E8]"
                                  : ""
                                }`}
                              key={item?.id}
                              onClick={() => {
                                item?.read_at === null &&
                                  markAsReadNotification(item.id);
                                dispatch({
                                  type: "SET_IS_NOTIFICATION_OPEN",
                                  payload: false,
                                });
                                navigate(`/${item?.data?.navigate_to}`);
                              }}
                            >
                              <div className="px-[30px] flex items-start gap-[18px]">
                                <img
                                  alt="img"
                                  src={
                                    item?.data?.profil_picture === null
                                      ? "https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                                      : `https://mainhub.insusales.com/api/profile-picture/${item?.data?.profil_picture}`
                                  }
                                  className="w-[50px] h-[50px] rounded-full object-cover"
                                />
                                <div>
                                  {" "}
                                  <h1 className="text-[#0A1629] font-inter-semibold">
                                    <span className="mr-[2px]">
                                      {item?.data?.from_user}
                                    </span>
                                    {""}
                                    {""}
                                    <span
                                      onClick={() => {
                                        dispatch({
                                          type: "SET_IS_NOTIFICATION_OPEN",
                                          payload: false,
                                        });
                                      }}
                                      className="text-[#0A1629] ml-[2px] font-inter-regular min-w-[307px] w-auto"
                                    >
                                      {t(item?.data?.message)}
                                    </span>
                                  </h1>
                                  <div>
                                    <span className="text-[14px] text-[#7D8592] font-inter-regular">
                                      {item?.created_at &&
                                        formatDate(item.created_at)}
                                    </span>
                                  </div>
                                  <div ref={lastElementRef}></div>
                                </div>
                                {item?.read_at === null && (
                                  <div className="w-[10px] h-[10px] p-[5px] object-cover m-auto bg-[--theme] rounded-full"></div>
                                )}
                              </div>
                            </div>
                          </SwipeableListItem>
                        </SwipeableList>
                      );
                    })}
                </NoDataBoundary>
              </div>
            )}
          </div>
        </div>
      </ConditionalRender>
    </div>
  );
};

export default Header;
