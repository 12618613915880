import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function DonePendingsIcon({ className,color}: Props) {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.123 23.0625C10.123 23.5803 9.70337 24 9.18555 24H3.79492C1.72711 24 0.0449219 22.3178 0.0449219 20.25V3.75C0.0449219 1.68219 1.72711 0 3.79492 0H15.321C17.3887 0 19.071 1.68219 19.071 3.75V13.6875C19.071 14.2053 18.6512 14.625 18.1335 14.625C17.6157 14.625 17.196 14.2053 17.196 13.6875V3.75C17.196 2.71619 16.3549 1.875 15.321 1.875H3.79492C2.76111 1.875 1.91992 2.71619 1.91992 3.75V20.25C1.91992 21.2838 2.76111 22.125 3.79492 22.125H9.18555C9.70337 22.125 10.123 22.5447 10.123 23.0625ZM21.1687 15.269C20.7477 14.9676 20.1622 15.0646 19.8608 15.4854L15.2397 21.9402C15.1136 22.0865 14.9554 22.118 14.8708 22.1237C14.7833 22.1294 14.6141 22.1184 14.4689 21.9802L11.4773 19.1076C11.1039 18.7491 10.5105 18.761 10.1518 19.1345C9.79309 19.5081 9.80518 20.1015 10.1787 20.46L13.1732 23.3355C13.6218 23.7623 14.2204 24 14.8364 24C14.8893 24 14.9424 23.9982 14.9955 23.9947C15.6664 23.95 16.2928 23.6237 16.7141 23.0993C16.7249 23.0859 16.7355 23.072 16.7456 23.0579L21.3853 16.5769C21.6867 16.1559 21.5898 15.5704 21.1687 15.269ZM14.3835 5.625H4.72729C4.20947 5.625 3.78979 6.04468 3.78979 6.5625C3.78979 7.08032 4.20947 7.5 4.72729 7.5H14.3835C14.9012 7.5 15.321 7.08032 15.321 6.5625C15.321 6.04468 14.9012 5.625 14.3835 5.625ZM15.321 10.3125C15.321 9.79468 14.9012 9.375 14.3835 9.375H4.72729C4.20947 9.375 3.78979 9.79468 3.78979 10.3125C3.78979 10.8303 4.20947 11.25 4.72729 11.25H14.3835C14.9012 11.25 15.321 10.8303 15.321 10.3125ZM4.72729 13.125C4.20947 13.125 3.78979 13.5447 3.78979 14.0625C3.78979 14.5803 4.20947 15 4.72729 15H10.5918C11.1096 15 11.5293 14.5803 11.5293 14.0625C11.5293 13.5447 11.1096 13.125 10.5918 13.125H4.72729Z"
        fill={color || "#50B8E7"}
      />
    </svg>
  );
}
export default memo(DonePendingsIcon);
