import { memo } from "react";
interface Props {
  className?: string;
}
function LanguageIcon({ className }: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className={className}>
    <rect width="20" height="20" rx="10" fill="url(#pattern0)"/>
    <defs>
    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
    <use xlinkHref="#image0_385_4362" transform="translate(-0.333333) scale(0.00208333)"/>
    </pattern>
    <image id="image0_385_4362" width="800" height="480" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyAAAAHgAgMAAAAgaeFmAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAADFBMVEUAAADdAAD/zgD///9i/kqcAAAAAWJLR0QDEQxM8gAAAAd0SU1FB+EICgkZMPwJaxkAAAE2SURBVHja7c8BDQAACAOgl7SkKc3hBw1IAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJ6YEiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIv2RLSEiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIlIfOXgBikX7OtctAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA4LTEwVDA5OjI1OjQ4KzAwOjAwH9KPuQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wOC0xMFQwOToyNTo0OCswMDowMG6PNwUAAAAASUVORK5CYII="/>
    </defs>
    </svg>
    
  );
}
export default memo(LanguageIcon);
