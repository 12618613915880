import { FC, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import Form from "../../../atoms/form/Form";
import Input from "../../../atoms/input/Input";
import { FieldError, useFieldArray } from "react-hook-form";
import Button from "../../../atoms/button/Button";
import Icon from "../../../atoms/icon/Icon";
import { IconType } from "../../../../lib/helpers/constants/iconTypes";
import FormStepNavigationButtons from "../../../molecules/form-step-navigation-buttons/FormStepNavigationButtons";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";
import ErrorState from "components/atoms/error-state/ErrorState";
import { useVerifyWorkersEmail } from "lib/hooks/queries/VerifyWorkersEmail/useVerifyWorkersEmail";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { generateRandomPassword } from "lib/hooks/shared/generateRandomPassword";

type ErrorsType = {
  workers?: {
    [key: number]: {
      name?: FieldError;
      position?: FieldError;
    };
  };
};

export interface IAddUserStepProps {}

const AddUserStep: FC<IAddUserStepProps> = () => {
  const [duplicateEmailError, setDuplicateEmailError] = useState<string | null>(
    null
  );
  const store = JSON.parse(
    localStorage.getItem("multi_step_form_state") || "{}"
  );
  const {
    mutate: verifyEmail,
    isError,
    error,
    isLoading
  } = useVerifyWorkersEmail();

  const { formData } = useMultiStepFormContext<any>();

  const {
    control,
    register,
    handleSubmit,
    errors,
    currentStep,
    updateFormData,
    showPasswordByIndex,
    toggleShowPasswordByIndex,
    getValues,
    setValue
  } = useSignupCustomHooks({
    initalStep: 3,
    defaultValues: store.workers
      ? store
      : {
          workers: [
            {
              name: "",
              position: "",
              email: "",
              password: ""
            }
          ]
        }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "workers"
  });

  const handleGeneratePassword = (index: number) => {
    const newPassword = generateRandomPassword();
    setValue(`workers.${index}.password`, newPassword);
  };

  const onSubmit = (data: Record<string, any>) => {
    const emailFormData = data?.workers?.map(
      (item: Record<string, any>) => item.email
    );
    const emailSet = new Set(emailFormData);

    if (emailSet.size !== emailFormData.length) {
      setDuplicateEmailError(
        "Duplicate emails found. Please ensure each email is unique."
      );
      return;
    }

    const isDuplicateWithFormData = data.workers.some(
      (worker: Record<string, any>) => worker.email === formData.email
    );

    if (isDuplicateWithFormData) {
      setDuplicateEmailError(
        "Duplicate emails found. Please ensure each email is unique."
      );
      return;
    }

    setDuplicateEmailError(null);
    updateFormData(getValues());
    verifyEmail({ emails: emailFormData });
  };

  return (
    <Fragment>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-between h-full"
      >
        <div className="mb-[50px]">
          <h1 className="text-bluebutton uppercase font-bold text-[14px] text-center mt-[40px] mb-[8px]">
            STEP {currentStep}/6
          </h1>
          <span className="text-[#282D46] font-inter-bold text-[25px] flex justify-center">
            Add your users!
          </span>
        </div>
        <div className="m-auto !mt-[initial] p-5 3xl:p-5 flex flex-col gap-[13px] 2xl:gap-[20px]">
         <>
         <div
            className="flex flex-row w-full gap-[13px] items-end"
              >
                <Input
                  label={"First Name"}
                  placeholder="First Name"
                  value={formData.name ?? ''}
                  type="text"
                  readOnly
                  className="3xl:w-[274.82px]"
                />
                <Input
                  label={"Position"}
                  placeholder="Position"
                  value={"Owner" ?? ''}
                  type="text"
                  className="3xl:w-[274.82px]"
                  readOnly
                />
                <div className="relative">
                  <Input
                    label={"Email"}
                    placeholder="email@company.net"
                    readOnly
                    value={formData.email ?? ''}
                    type="text"
                    className="3xl:w-[360px]"
                  />
                </div>
                <Input
                label="Password"
                  placeholder="••••••••"
                  icon={
                      IconType.SHOW_PASSWORD_ICON
                  }
                  className="3xl:w-[360px]"
                 readOnly
                //  value={formData.password ?? ''}
                />
              </div>
           {fields.map((field, index) => {
            return (
              <div
                key={`field-key-${field.id}`}
                className="flex flex-row w-full gap-[13px] items-end"
              >
                <Input
                  {...register(`workers.${index}.name`)}
                  placeholder="First Name"
                  type="text"
                  className="3xl:w-[274.82px]"
                  error={(errors as ErrorsType)?.workers?.[index]?.name}
                />
                <Input
                  {...register(`workers.${index}.position`)}
                  placeholder="Position"
                  type="text"
                  className="3xl:w-[274.82px]"
                  error={(errors as ErrorsType)?.workers?.[index]?.position}
                />
                <div className="relative">
                  <Input
                    {...register(`workers.${index}.email`)}
                    placeholder="email@company.net"
                    type="text"
                    className="3xl:w-[360px]"
                  />
                </div>
                <Input
                  {...register(`workers.${index}.password`)}
                  placeholder="••••••••"
                  type={showPasswordByIndex[index] ? "text" : "password"}
                  icon={
                    IconType.AUTO_GENERATE_PASSWORD_ICON &&
                    showPasswordByIndex[index]
                      ? IconType.HIDDEN_PASSWORD_ICON
                      : IconType.SHOW_PASSWORD_ICON
                  }
                  toggleShowPassowrd={() => toggleShowPasswordByIndex(index)}
                  className="3xl:w-[360px]"
                  classes={{
                    icon: index === 0 ? "-translate-y-1/2" : "-translate-y-1/2"
                  }}
                  childrenIcon={IconType.AUTO_GENERATE_PASSWORD_ICON}
                  toggleGeneratePassword={() => handleGeneratePassword(index)}
                />

                <Button
                  className="bg-[#FFF0F0] rounded-[14px] p-[12px] 2xl:p-[15px]"
                  onClick={() => remove(index)}
                >
                  <Icon iconType={IconType.DELETE_ICON} />
                </Button>
              </div>
            );
          })}
         </>
          <Button
            type="button"
            className="flex items-center gap-3 text-primary font-inter-semibold"
            onClick={() =>
              append({
                name: "",
                position: "",
                email: "",
                password: ""
              })
            }
          >
            <Icon iconType={IconType.INCRASE_ICON} color="#50B8E7" />
            Add new user
          </Button>
          {duplicateEmailError && (
            <ErrorState
              error={true}
              className="lg:max-w-[400px] 2xl:max-w-[700px] mx-auto"
            >
              {duplicateEmailError}
            </ErrorState>
          )}
          <ErrorState
            error={isError}
            className="lg:max-w-[400px] 2xl:max-w-[700px] mx-auto"
          >
            Sorry, this email {(error as any)?.response?.data?.message} is
            already in use. Please try another email!
          </ErrorState>
        </div>
        <FormStepNavigationButtons isLoading={isLoading} />
      </Form>
    </Fragment>
  );
};

export default AddUserStep;
