import { useAuthContext } from "lib/context/AuthContextContext/AuthContext";

export function usePermissions(requiredPermissions: string[]) {
  const { user } = useAuthContext();
  

  const userPermissions = user?.permissions?.map((item: any) =>  item?.name);


  const hasPermission = requiredPermissions?.some((perm) =>
    userPermissions?.includes(perm)
  );

  return hasPermission;
}