import { FC, LabelHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

export interface ILabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  text?: string;
  htmlFor?: string;
}

const Label: FC<ILabelProps> = ({ text, htmlFor, ...rest }) => {
  const { t } = useTranslation();
  return (
    <label
      htmlFor={htmlFor}
      {...rest}
      className={`block text-[#282D46] text-[14px] mb-[8px] font-inter-medium ${rest.className}`}
    >
      {text && t(text)} {rest.children}
    </label>
  );
};

export default Label;