import { memo } from "react";

interface Props {
    className?: string;
    color?: string;
}

function CopyIcon({ className,color }: Props) {
    return (
        <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.6206 4.624C20.5876 2.58456 18.9183 0.935547 16.8711 0.935547H4.03516C1.96741 0.935547 0.285156 2.6178 0.285156 4.68555V17.498C0.285156 19.5373 1.92119 21.2015 3.9497 21.247C3.9827 23.2865 5.65202 24.9355 7.69923 24.9355H20.5352C22.6029 24.9355 24.2852 23.2533 24.2852 21.1855V8.37306C24.2852 6.33381 22.6491 4.66952 20.6206 4.624ZM4.88673 19.373H4.03516C3.00128 19.373 2.16016 18.5319 2.16016 17.498V4.68555C2.16016 3.65167 3.00128 2.81055 4.03516 2.81055H16.8711C17.905 2.81055 18.7461 3.65167 18.7461 4.68555V17.498C18.7461 18.5319 17.905 19.373 16.8711 19.373H4.88673ZM22.4102 21.1855C22.4102 22.2194 21.569 23.0605 20.5352 23.0605H7.69923C6.68627 23.0605 5.85831 22.2531 5.82527 21.248H16.8711C18.9389 21.248 20.6211 19.5658 20.6211 17.498V6.49998C21.6153 6.54498 22.4102 7.36792 22.4102 8.37302V21.1855H22.4102Z" fill={color || '#50B8E7'} />
        </svg>

    );
}
export default memo(CopyIcon);
