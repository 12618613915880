import React, { FC } from "react";
import LoginTitle from "../../../atoms/title/LoginTitle";
import Paragraph from "../../../atoms/paragraph/Paragraph";
import Form from "../../../atoms/form/Form";
import Input from "../../../atoms/input/Input";
import Button from "../../../atoms/button/Button";
import { useMultiStepFormContext } from "../../../../lib/context/MultiStepFormContext/MultiStepFormContext";
import BlueLink from "../../../atoms/link/BlueLink";
import { useTwoFactorAuth } from "../../../../lib/hooks/mutations/2FA/useTwoFactorAuth";
import { useTwoFactorForm } from "../../../../lib/hooks/forms/useTwoFactorForm";

export interface ITwoFactorAuthProps {}

const TwoFactorAuth: FC<ITwoFactorAuthProps> = () => {
  const {
    mutate: login,
    errorMessage,
    isLoading,
  } = useTwoFactorAuth({ showError: true });
  const { prevFormStep, formData }: any = useMultiStepFormContext();
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useTwoFactorForm();

  const onSubmit = () => {
    const codeData = getValues();

    const data = {
      email: formData.email,
      password: formData.password,
      google2fa_secret: formData.twoFA ? formData.twoFA.secret : "",
      twofa_code: codeData.twofa_code,
    };

    login(data);
    localStorage.removeItem("multi_step_form_state");
  };


  return (
    <div className="flex flex-col gap-8">
      <div>
        <div className="pb-2">
          <LoginTitle title="Two-factor verification" />
        </div>
        <Paragraph paragraph="Enter the confirmation code via Google Authentificator." />
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-10">
          {formData.twoFA && (
            <div>
              <img
                src={formData.twoFA.QR_Image}
                alt=""
                className="w-[200] h-[200px] m-auto"
              />
              <div className="text-center">
                <span>{formData.twoFA.secret}</span>
              </div>
            </div>
          )}
          <div className="relative">
            <Input
              label="Code"
              type="number"
              {...register("twofa_code")}
              error={errors.twofa_code}
              autoFocus
            />
            {errorMessage && (
              <pre className=" font-inter-regular text-xs text-red-600 w-max absolute bottom-[-35px]  left-[2px]">
                Wrong verification code
              </pre>
            )}
          </div>
          <Button
            type="submit"
            className="bg-bluebutton text-white p-[15px] w-full rounded-[8px] disabled:opacity-50"
            disabled={isLoading}
          >
            Confirm
          </Button>
          <div className="text-center" onClick={prevFormStep}>
            <span className="text-[14px]">Didn't you receive the code? </span>
            <BlueLink to="/" title="Back to login." />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TwoFactorAuth;
