import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import  {FC } from "react";

export interface IActivityLogItemProps<T>{
data: T;
isLoading: boolean,
}

const ActivityHistoryList: FC<IActivityLogItemProps<any>> = ({
  data,
  isLoading
}) => {
  
  if (isLoading) return <LoadingScreen />;

  if (!data || !data?.pages[0]?.data?.data?.length) return <EmptyEntity name="Logs" />;

  

  return (
    <div className="border-l-2 border-dashed border-l-border ms-5 lg:ms-0">
      <div className="flex flex-col gap-4 md:gap-8">
        {data?.pages[0]?.data?.data?.map((log: any, index: any) => (
          
              <div key={index} className="pb-1">
                {/* show the date for the latest change */}
                {index === 0 && (
                  <div
                    style={{ width: "fit-content" }}
                    className="ml-[-20px] mb-5 pb-1 pt-2 px-2 bg-[#F2F4F7] rounded-[16px] font-inter-medium text-xs text-primaryTextColor"
                  >
                    {log?.created_at?.substring(0, 10)}
                  </div>
                )}

                {/* show the next date that is different from the first date */}
                {index > 0 && log?.created_at.substring(0, 10) !== data?.pages[0]?.data?.data[index - 1].created_at.substring(0, 10) && (
                    <div
                      style={{ width: "fit-content" }}
                      className="ml-[-20px] mb-5 pb-1 pt-2 px-2 bg-[#F2F4F7] rounded-[16px] font-inter-medium text-xs text-primaryTextColor"
                    >
                      {log?.created_at.substring(0, 10)}
                    </div>
                  )}
    
                <div className="flex ml-[-8px] items-center">
                  <div className={`size-[14px] border border-[--theme] rounded-full bg-white flex-none`} />
                  <div className="w-[5px] md:w-[40px] h-[1px] border-t-2 border-dashed border-t-border flex-none" />
                  <div className="bg-white rounded-[8px] border border-border p-4" style={{ boxShadow: "0px 1px 4.6px 0px #0000001F" }}>

                      <div className="flex flex-row gap-2 md:gap-4 items-center">
                        <div className="bg-border size-[30px] md:size-[50px] rounded-full flex-none " />
                        <ConditionalRender condition={log.type === "Asked Permission" || log.type === "Export Leads"}>
                          <div className="text-primaryTextColor text-sm font-inter-regular ">
                            <span className="font-inter-semibold ">
                              {log.from_user}{" "}
                            </span>
                            {log.description}.
                          </div>
                        </ConditionalRender>
                      
                        <ConditionalRender condition={log.type === "Feedback Permission"}>
                          <div className="text-primaryTextColor text-sm font-inter-regular ">
                            <span className="font-inter-semibold ">
                              {log.changed_from_user}{" "}
                            </span>

                            {log.description} 

                            <span className="font-inter-semibold ">
                              {log.from_user}
                            </span>
                          </div>
                        </ConditionalRender>
                        <div>
                          <span className="text-secondaryTextColor font-inter-medium text-sm">
                            {log.created_at.substring(11, 16)}{" "}
                          </span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default ActivityHistoryList;
