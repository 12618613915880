import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import Icon from 'components/atoms/icon/Icon';
import Input from 'components/atoms/input/Input';
import { IconType } from 'lib/helpers/constants/iconTypes';
import withModal from 'lib/helpers/hoc/withModal'
import { useBaseOverview } from 'lib/hooks/mutations/Documents/useBaseOverview';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface INewCompanyProps {
    onConfirm: () => void;
    id: string
}

const NewBaseOverview: React.FC<INewCompanyProps> = ({ onConfirm, id }) => {

    const { t } = useTranslation()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: { name: '' } });

    const { mutateAsync, isLoading } = useBaseOverview()

    const onSubmit = (data: any) => {

        const finalData = {
            name: data.name,
            insurance_management_id: id
        }

        mutateAsync(finalData).then(() => {
            onConfirm()
        })

    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-0 flex flex-col gap-7">
                <Controller
                    control={control}
                    name='name'
                    rules={{ required: t('Overview Name is required') }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            value={field.value}
                            label={t('Übersichtsname')}
                            className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
                            placeholder={t('Enter Overview Name')}
                            error={(errors as any)?.name}
                        />
                    )}


                />


                <div className='flex-none flex justify-end'>
                    <IconButton
                        type='submit'
                        className='w-auto'
                        disabled={isLoading}
                        icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
                    >
                        {t('Add New Overview')}
                    </IconButton>
                </div>
            </div>
        </Form>
    );
}

export default withModal(NewBaseOverview)