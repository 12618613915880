import { memo } from "react";

interface Props {
  className?: string;
}

function MinusIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="12"
      height="2"
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.00355C0 0.652097 0.298477 0.367188 0.666667 0.367188H11.3333C11.7015 0.367188 12 0.652097 12 1.00355C12 1.35501 11.7015 1.63991 11.3333 1.63991H0.666667C0.298477 1.63991 0 1.35501 0 1.00355Z"
        fill="#7D8592"
      />
    </svg>
  );
}
export default memo(MinusIcon);
