import { createNewProducts } from "api/Customers";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useCreateNewProduct = () => {
  const queryClient = useQueryClient();

  return useMutation(createNewProducts, {
    onSuccess: () => {
      toast.success("Created Successfully");
      queryClient.invalidateQueries(["customerProducts", "coustomers"]);
      queryClient.invalidateQueries(["coustomers"]);
    },
    onError: () => {
      toast.error("Failed to create");
    },
  });
};
