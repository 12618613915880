import { memo } from "react";

interface Props {
    className?: string;
    color?: string;
}

function RadioButtonIcon({ className, color }: Props) {
    return (
        <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z" fill={color || "#50B8E7"} />
        </svg>

    );
}
export default memo(RadioButtonIcon);
