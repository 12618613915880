import { memo } from "react";

interface Props {
  className?: string;
}

function ConverteIcon({ className }: Props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.8588 24L18.5332 22.6745L20.9099 20.2977H8.93927C5.3213 20.2977 2.37785 17.3543 2.37785 13.7363V12H4.25254V13.7363C4.25254 16.3206 6.35501 18.423 8.93927 18.423H20.9099L18.5332 16.0464L19.8588 14.7207L24.4985 19.3604L19.8588 24ZM22.6245 12H20.7498V10.2637C20.7498 7.6794 18.6474 5.57698 16.0631 5.57698H4.09244L6.46913 7.95362L5.14354 9.27926L0.503906 4.63963L5.14354 0L6.46913 1.32555L4.09244 3.70228H16.0631C19.6811 3.70228 22.6245 6.64574 22.6245 10.2637V12Z"
        fill="white"
      />
    </svg>
  );
}
export default memo(ConverteIcon);
