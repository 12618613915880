import { memo } from "react";
interface Props {
  className?: string;
}
function UploadImageIcon({ className }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15 13.5C15 15.1569 13.6569 16.5 12 16.5C10.3431 16.5 9 15.1569 9 13.5C9 11.8431 10.3431 10.5 12 10.5C13.6569 10.5 15 11.8431 15 13.5Z"
        stroke="#282D46"
        strokeWidth="1.5"
      />
      <circle cx="12" cy="7" r="1" fill="#282D46" />
      <path
        d="M21 15.5V11.5C21 8.73858 18.7614 6.5 16 6.5H15.874C15.4299 4.77477 13.8638 3.5 12 3.5C10.1362 3.5 8.57006 4.77477 8.12602 6.5H8C5.23858 6.5 3 8.73858 3 11.5V15.5C3 18.2614 5.23858 20.5 8 20.5H16C18.7614 20.5 21 18.2614 21 15.5Z"
        stroke="#282D46"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default memo(UploadImageIcon);
