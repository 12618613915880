import { createContext, useContext } from "react";

export interface ISidebarContext {
    isToggled: boolean;
    toggleSidebar: () => void; 
}

export const DefaultValues: ISidebarContext = {
    isToggled: true,
    toggleSidebar: () => {}
}

export const SidebarContext = createContext<ISidebarContext>(DefaultValues);
export const useSidebarContext = () => useContext(SidebarContext);