import {FC} from 'react'
import SecurityView from 'components/organisms/security/SecurityView'


export interface ISecurityProps{}


export const Security:FC<ISecurityProps> = () => {
  return (
    <SecurityView/>
  )
}
