import { memo } from "react";
interface Props {
  className?: string;
}
function VerticalArrowColorfulIcon({ className }: Props) {
  return (
    <svg
      width="2"
      height="26"
      viewBox="0 0 2 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="2" height="26" rx="1" fill="white" />
    </svg>
  );
}
export default memo(VerticalArrowColorfulIcon);
