import { memo } from "react";
interface Props {
  className?: string;
}
function LogoIcon({ className }: Props) {
  return (
    <svg
      width="93"
      height="39"
      viewBox="0 0 93 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M50.3672 11.0555C50.3672 10.5143 50.8083 10.0732 51.3495 10.0732H54.7907C55.3319 10.0732 55.773 10.5143 55.773 11.0555V18.6512C55.773 21.6607 54.1807 24.4012 51.6561 25.9059C51.0648 26.2594 50.3703 25.7902 50.3703 25.0988V11.0555H50.3672Z"
        fill="white"
      />
      <path
        d="M25.0614 19.1644C23.7068 20.4314 21.8955 22.4273 20.8412 23.7412C20.6942 23.9258 20.3845 23.8257 20.3782 23.5879C20.2937 20.3782 19.7338 15.9766 14.4969 14.4969C9.407 13.0578 5.6498 18.3041 5.4058 22.7276V37.5905C5.4058 38.1318 4.9647 38.5729 4.4235 38.5729H0.9823C0.4411 38.5729 0 38.1318 0 37.5905V0.9823C0 0.4411 0.4411 0 0.9823 0H4.4235C4.9647 0 5.4058 0.4411 5.4058 0.9823V12.4885C9.2944 8.212 22.055 5.2244 25.0614 19.1644Z"
        fill="white"
      />
      <path
        d="M50.3672 11.0555C50.3672 10.5143 50.8083 10.0732 51.3495 10.0732H54.7907C55.3319 10.0732 55.773 10.5143 55.773 11.0555V18.6512C55.773 21.6607 54.1807 24.4012 51.6561 25.9059C51.0648 26.2594 50.3703 25.7902 50.3703 25.0988V11.0555H50.3672Z"
        fill="white"
      />
      <path
        d="M91.95 26.4222C91.609 29.2972 90.43 31.9782 88.559 34.1306C86.685 36.2829 84.204 37.8064 81.429 38.5103C78.6544 39.2142 75.7106 39.064 72.967 38.0817C71.0838 37.406 69.3538 36.358 67.8772 35.0065L68.0023 37.5405C68.0305 38.1036 67.5831 38.5729 67.02 38.5729H63.632C63.0876 38.5729 62.6497 38.1317 62.6497 37.5905V29.9636C62.6497 28.7967 63.1377 27.6799 63.9949 26.8915L67.3172 23.8319L68.9658 27.2888C69.7698 28.9719 70.6677 30.6737 72.1724 31.7749C72.9139 32.3161 73.7304 32.7541 74.6 33.0638C76.3488 33.6894 78.2258 33.7864 79.993 33.3391C81.764 32.8886 83.344 31.9188 84.539 30.5454C85.731 29.1752 86.482 27.464 86.697 25.6308C86.916 23.8007 86.585 21.9268 85.75 20.25C84.917 18.5732 83.616 17.1716 82.011 16.2144C80.409 15.2602 78.5731 14.8003 76.743 14.8879C74.9098 14.9755 73.161 15.6106 71.7188 16.7149L58.2262 29.8197L53.6806 34.5185L51.3437 36.3517C49.889 37.4904 48.1997 38.2913 46.3978 38.698C44.9587 39.0202 43.4727 39.0859 42.0118 38.8888L40.8293 38.7324C39.7375 38.5854 38.6707 38.2944 37.6571 37.8658L37.1566 37.6531C35.9021 37.1275 34.7727 36.3423 33.8342 35.3569C33.0678 34.5466 32.4421 33.6112 31.9885 32.5914L31.9603 32.5319C31.3785 31.2243 31.0781 29.8071 31.0781 28.3743V26.904L35.7457 22.6025L36.2869 27.1386C36.3995 28.0865 36.7061 29 37.1847 29.8228C38.1326 31.4589 39.7281 32.6477 41.5613 33.1044C42.4185 33.3203 43.3226 33.3766 44.1985 33.2609L44.2486 33.2546C45.5969 33.0826 46.8701 32.5382 47.9275 31.6842L48.5219 31.2024L56.3835 23.4628L58.2043 21.8079C58.7924 21.2761 59.3399 20.6973 59.8435 20.0842L60.0375 19.8495C61.1918 18.4449 62.074 16.8463 62.6497 15.1257V0.9823C62.6497 0.4411 63.0876 0 63.632 0H67.0701C67.6144 0 68.0555 0.4411 68.0555 0.9823V12.5855L68.4465 12.429C70.7115 10.699 73.4519 9.7011 76.3269 9.5634C79.202 9.4227 82.08 10.1485 84.595 11.6469C87.11 13.1423 89.153 15.3478 90.461 17.9756C91.769 20.6066 92.288 23.5441 91.95 26.4222Z"
        fill="white"
      />
      <path
        d="M36.1188 11.0555V17.6752C36.1188 17.7283 36.1063 17.866 36.0938 17.9254C35.7903 19.3708 35.0489 20.6878 33.9727 21.7014L27.8098 27.5014C27.4532 27.8393 27.131 28.2115 26.8494 28.612C26.0079 29.8195 25.5543 31.2554 25.5543 32.7258V38.2223C25.5543 38.4163 25.3979 38.5727 25.2039 38.5727H20.8617C20.4675 38.5727 20.1484 38.2536 20.1484 37.8594V32.1376C20.1484 31.0302 20.3111 29.9259 20.6302 28.8622L20.7397 28.4962C21.0776 27.3637 21.6313 26.3032 22.3665 25.371C22.8514 24.7578 23.4113 24.2041 24.0339 23.7286L25.5073 22.6024C26.6836 21.7045 27.7723 20.7003 28.764 19.6023L30.4658 17.3217L30.713 11.0555C30.713 10.5143 31.1541 10.0732 31.6953 10.0732H35.1365C35.6777 10.0732 36.1188 10.5143 36.1188 11.0555Z"
        fill="white"
      />
    </svg>
  );
}
export default memo(LogoIcon);
