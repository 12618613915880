import { useForm, UseFormProps } from "react-hook-form";
import * as YUP from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const TwoFactorSchema = YUP.object().shape({
    twofa_code: YUP.string()
        .required("Please enter your code")
        .length(6, 'Enter a six digit code')
        
});

export function useTwoFactorForm(options?: UseFormProps<any>) {
    return useForm<any | any>({
        mode: "onSubmit",
        ...options,
        resolver: yupResolver(TwoFactorSchema),
    });
}

export type LoginForm = ReturnType<typeof useTwoFactorForm>;