import { useMutation } from "react-query";
import { getUsersByPermission } from "../../../../api/User";


export const useUsersByPermission = () => {
    
    return {
        ...useMutation(getUsersByPermission, {
            onSuccess: (res) => {
                
                    
            },
            onError: (err) => {
                return
            },
        }),
    };
};