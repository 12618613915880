import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/text-area/TextArea";
import ImageDropZone from "components/molecules/image-dropzone/ImageDropZone";
import ToggleSwitch from "components/molecules/toggle-switch/ToggleSwitch";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useSourceForm } from "lib/hooks/forms/useSourceForm";
import { useCreateSources } from "lib/hooks/mutations/Sources/useCreateSources";
import { ISourceCreate } from "lib/types/sources.types";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

export interface ICreateSourceProps {
  onConfirm: () => void;
  themeColors: {
    primary: string;
  };
  setStep: (args: number) => void;
}

const NewSourceModal: FC<ICreateSourceProps> = ({ onConfirm, themeColors , setStep}) => {
  const [resetKey, setResetKey] = useState<number>(0);

  const onSubmit = (data: Record<string, any>) => {
    data.status = data.status ? 1 : 0;
    createNewSource(data as ISourceCreate).then(() => {
        onConfirm();
        reset();
        setResetKey((prevKey) => prevKey + 1);
        setStep(1);
    }).catch(() => {
    })
  };

  const { mutateAsync: createNewSource, isLoading } = useCreateSources();

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
  } = useSourceForm({
    defaultValues: {
      persons: 0,
      name: "",
      description: "",
      status: false,
      logo: null,
    },
  });

  const resetForm = () => {
    reset();
    setResetKey((prevKey) => prevKey + 1);
  };

  const {t} = useTranslation();

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="mb-0 flex flex-col gap-6"
    >
      <ImageDropZone
        label={t("Source logo")}
        {...register("logo")}
        control={control}
        classes={{
          container: "max-w-[150px] mr-auto",
          subContainer: "ml-0",
          wrapperLabelClassName: "!w-[100px] !h-[100px]",
        }}
        acceptParameters="image/png, image/jpg, image/jpeg"
        key={resetKey}
      />
      <Input
        label={t("Source name*")}
        placeholder={t("Enter source name")}
        {...register("name")}
        error={errors?.name}
      />
      <TextArea
        placeholder={t("Write description...")}
        label={t("Description")}
        {...register("description")}
        error={errors?.description}
      />
      <ToggleSwitch
        label={t("Actived*")}
        classes={{ label: "!flex flex-row-reverse gap-[15px]" }}
        {...register("status")}
        error={errors?.status}
      />
      <div className="flex gap-5 justify-end">
        <IconButton
          secondary={true}
          className={`min-w-[120px] !text-[${themeColors.primary}]`}
          type="button"
          onClick={resetForm}
        >
          {t('Reset')}
        </IconButton>
        <IconButton
          disabled={isLoading ? true : false}
          className={`min-w-[120px] bg-[${themeColors.primary}] border-[${themeColors.primary}]`}
          typeof="button"
          icon={<Icon iconType={IconType.PLUS_ICON} />}
        >
          {isLoading ? t("Creating...") : t("Create")}
        </IconButton>
      </div>
    </Form>
  );
};

export default NewSourceModal;
