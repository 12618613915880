import { useMultiStepFormContext } from 'lib/context/MultiStepFormContext/MultiStepFormContext';
import React from 'react'
import ResetPassword from './reset-password-steps/ResetPassword';
import ResetPasswordComplete from './reset-password-steps/ResetPasswordComplete';


const ResetPasswordProcces = () => {

    const { currentStep } = useMultiStepFormContext();
    

    return (
        <>
            {
                {
                    1: <ResetPassword />,
                    2: <ResetPasswordComplete />
                }[currentStep]
            }
        </>
    )
}

export default ResetPasswordProcces