import { memo } from "react";
interface Props {
    className?: string;
    color?: string;
}
function CHFICON({ className, color }: Props) {
    return (

        

    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.91586 6.09355C1.57993 6.3373 0.506489 7.3123 0.112739 8.64824C0.037739 8.90137 0.028364 9.22012 0.0143015 11.8357C-0.00444854 15.0186 0.000238961 15.117 0.272114 15.7826C0.886176 17.2826 2.5643 18.2014 4.12524 17.8873C4.8893 17.7326 5.40961 17.4561 5.95805 16.9217C6.5393 16.3451 6.90493 15.5998 6.97055 14.8357L6.99868 14.4842H5.98149H4.96899V14.6014C4.96899 14.7701 4.80961 15.1639 4.6643 15.3514C4.38305 15.7217 3.77836 15.9795 3.33305 15.9139C2.8268 15.8436 2.38618 15.5154 2.16118 15.0467L2.0393 14.7889V11.9998V9.21074L2.16118 8.94824C2.4143 8.40449 2.90649 8.09043 3.49711 8.09043C4.24711 8.08574 4.88461 8.6623 4.97836 9.42168L4.99243 9.53887L5.99086 9.55293L6.98461 9.5623V9.3748C6.98461 8.77949 6.63774 7.87012 6.2018 7.33574C5.76586 6.79668 5.12836 6.37949 4.45336 6.1873C4.05024 6.0748 3.29555 6.02793 2.91586 6.09355Z" fill="#979797"/>
    <path d="M7.92188 12V17.9531H8.92969H9.9375V15.4922V13.0312H11.6719H13.4063V15.4922V17.9531H14.4141H15.4219V12V6.04687L14.4281 6.05625L13.4297 6.07031L13.4156 8.54062L13.4063 11.0156H11.6719H9.9375V8.53125V6.04687H8.92969H7.92188V12Z" fill="#979797"/>
    <path d="M16.4062 12V17.9531H17.4141H18.4219V15.6094V13.2656H20.25H22.0781V12.2578V11.25H20.25H18.4219V9.67969V8.10937H21.2109H24V7.07812V6.04687H20.2031H16.4062V12Z" fill="#979797"/>
    </svg>


    );
}
export default memo(CHFICON);
