import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function DownloadIcon({ className, color }: Props) {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.4792 4.95452C15.2025 4.89952 14.9767 4.72702 14.8608 4.48035C13.5675 1.75452 10.575 0.230354 7.58167 0.782021C4.855 1.28202 2.7175 3.45869 2.26167 6.19952C2.12667 7.00869 2.13667 7.81869 2.28917 8.60785C2.33917 8.86535 2.22833 9.15202 2.00083 9.35869C1.04667 10.2262 0.5 11.462 0.5 12.7504C0.5 15.277 2.55583 17.3337 5.08333 17.3337H14.25C17.6967 17.3337 20.5 14.5304 20.5 11.0837C20.5 8.11285 18.3883 5.53535 15.4783 4.95535L15.4792 4.95452ZM14.2508 15.6662H5.08417C3.47583 15.6662 2.1675 14.3579 2.1675 12.7495C2.1675 11.9304 2.51583 11.1429 3.1225 10.5912C3.7775 9.99535 4.085 9.11369 3.925 8.28869C3.81 7.69535 3.80333 7.08452 3.905 6.47119C4.24083 4.45285 5.87583 2.78702 7.88083 2.41952C8.20167 2.36119 8.52083 2.33285 8.835 2.33285C10.7617 2.33285 12.5067 3.40702 13.3533 5.19452C13.6983 5.92119 14.3533 6.42869 15.1517 6.58869C17.2842 7.01452 18.8325 8.90369 18.8325 11.0837C18.8325 13.6104 16.7767 15.667 14.2492 15.667L14.2508 15.6662ZM13.1017 10.077C13.4275 10.4029 13.4275 10.9295 13.1017 11.2554L10.8458 13.5112C10.5233 13.8337 10.0992 13.9962 9.675 13.9979L9.6675 13.9995L9.66 13.9979C9.23583 13.9962 8.81167 13.8337 8.48917 13.5112L6.23333 11.2554C5.9075 10.9295 5.9075 10.4029 6.23333 10.077C6.55917 9.75119 7.08583 9.75119 7.41167 10.077L8.83417 11.4995V7.33285C8.83417 6.87202 9.2075 6.49952 9.6675 6.49952C10.1275 6.49952 10.5008 6.87202 10.5008 7.33285V11.4995L11.9233 10.077C12.2492 9.75119 12.7758 9.75119 13.1017 10.077Z"
        fill={color || "white"}
      />
    </svg>
  );
}
export default memo(DownloadIcon);
