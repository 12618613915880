export interface INumbersCode {
  value: string;
  label: string;
  icon: string;
}
export const numbersCode: Array<INumbersCode> = [
  { value: "+41", label: "+41", icon: "CH" }, // Zurich, Switzerland
  { value: "+31", label: "+31", icon: "NL" }, // Netherlands
  { value: "+49", label: "+49", icon: "DE" }, // Germany
  { value: "+383", label: "+383", icon: "XK" }, // Kosovo
  { value: "+1", label: "+1", icon: "US" }, // United States
  { value: "+44", label: "+44", icon: "GB" }, // United Kingdom
  { value: "+33", label: "+33", icon: "FR" }, // France
  { value: "+39", label: "+39", icon: "IT" }, // Italy
  { value: "+34", label: "+34", icon: "ES" }, // Spain
  { value: "+46", label: "+46", icon: "SE" }, // Sweden
  { value: "+45", label: "+45", icon: "DK" }, // Denmark
  { value: "+47", label: "+47", icon: "NO" }, // Norway
  { value: "+48", label: "+48", icon: "PL" }, // Poland
  { value: "+43", label: "+43", icon: "AT" }, // Austria
  { value: "+32", label: "+32", icon: "BE" }, // Belgium
  { value: "+30", label: "+30", icon: "GR" }, // Greece
  { value: "+36", label: "+36", icon: "HU" }, // Hungary
  { value: "+353", label: "+353", icon: "IE" }, // Ireland
  { value: "+351", label: "+351", icon: "PT" }, // Portugal
  { value: "+40", label: "+40", icon: "RO" }, // Romania
  { value: "+7", label: "+7", icon: "RU" }, // Russia
  { value: "+386", label: "+386", icon: "SI" }, // Slovenia
  { value: "+421", label: "+421", icon: "SK" }, // Slovakia
  { value: "+358", label: "+358", icon: "FI" }, // Finland
  { value: "+380", label: "+380", icon: "UA" }, // Ukraine
  { value: "+90", label: "+90", icon: "TR" }, // Turkey
  { value: "+61", label: "+61", icon: "AU" }, // Australia
  { value: "+64", label: "+64", icon: "NZ" }, // New Zealand
  { value: "+91", label: "+91", icon: "IN" }, // India
  { value: "+81", label: "+81", icon: "JP" }, // Japan
  { value: "+82", label: "+82", icon: "KR" }, // South Korea
  { value: "+86", label: "+86", icon: "CN" }, // China
  { value: "+66", label: "+66", icon: "TH" }, // Thailand
  { value: "+63", label: "+63", icon: "PH" }, // Philippines
  { value: "+62", label: "+62", icon: "ID" }, // Indonesia
  { value: "+60", label: "+60", icon: "MY" }, // Malaysia
  { value: "+65", label: "+65", icon: "SG" }, // Singapore
  { value: "+55", label: "+55", icon: "BR" }, // Brazil
  { value: "+52", label: "+52", icon: "MX" }, // Mexico
  { value: "+54", label: "+54", icon: "AR" }, // Argentina
  { value: "+56", label: "+56", icon: "CL" }, // Chile
  { value: "+57", label: "+57", icon: "CO" }, // Colombia
  { value: "+58", label: "+58", icon: "VE" }, // Venezuela
  { value: "+27", label: "+27", icon: "ZA" }, // South Africa
  { value: "+20", label: "+20", icon: "EG" }, // Egypt
  { value: "+234", label: "+234", icon: "NG" }, // Nigeria
  { value: "+212", label: "+212", icon: "MA" } // Morocco
];