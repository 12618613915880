import { useForm, UseFormProps } from "react-hook-form";
import * as YUP from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const ForgotPasswordSchema = YUP.object().shape({
    email: YUP.string()
        .required("Please enter your email")
        .email("Enter a valid email")

});

export function useForgotPasswordForm(options?: UseFormProps<any>) {
    return useForm<any | any>({
        mode: "onSubmit",
        ...options,
        resolver: yupResolver(ForgotPasswordSchema),
    });
}

export type LoginForm = ReturnType<typeof useForgotPasswordForm>;