import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function AlertIcon({ className, color }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10 9C10.5128 9 10.9355 9.38604 10.9933 9.88338L11 10V14C11 14.5523 10.5523 15 10 15C9.48716 15 9.06449 14.614 9.00673 14.1166L9 14V10C9 9.44771 9.44771 9 10 9ZM11.01 6C11.01 5.44772 10.5623 5 10.01 5L9.88338 5.00673C9.38604 5.06449 9 5.48716 9 6C9 6.55228 9.44771 7 10 7L10.1266 6.99327C10.624 6.93551 11.01 6.51284 11.01 6Z"
        fill={color}
      />
    </svg>
  );
}
export default memo(AlertIcon);
