import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function CalendarIcon({ className, color }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1284_38671)">
        <path
          d="M15.8333 1.66667H15V0.833333C15 0.373333 14.6275 0 14.1667 0C13.7058 0 13.3333 0.373333 13.3333 0.833333V1.66667H6.66667V0.833333C6.66667 0.373333 6.29417 0 5.83333 0C5.3725 0 5 0.373333 5 0.833333V1.66667H4.16667C1.86917 1.66667 0 3.53583 0 5.83333V15.8333C0 18.1308 1.86917 20 4.16667 20H15.8333C18.1308 20 20 18.1308 20 15.8333V5.83333C20 3.53583 18.1308 1.66667 15.8333 1.66667ZM4.16667 3.33333H15.8333C17.2117 3.33333 18.3333 4.455 18.3333 5.83333V6.66667H1.66667V5.83333C1.66667 4.455 2.78833 3.33333 4.16667 3.33333ZM15.8333 18.3333H4.16667C2.78833 18.3333 1.66667 17.2117 1.66667 15.8333V8.33333H18.3333V15.8333C18.3333 17.2117 17.2117 18.3333 15.8333 18.3333ZM15.8333 11.6667C15.8333 12.1267 15.4608 12.5 15 12.5H5C4.53917 12.5 4.16667 12.1267 4.16667 11.6667C4.16667 11.2067 4.53917 10.8333 5 10.8333H15C15.4608 10.8333 15.8333 11.2067 15.8333 11.6667ZM10 15C10 15.46 9.6275 15.8333 9.16667 15.8333H5C4.53917 15.8333 4.16667 15.46 4.16667 15C4.16667 14.54 4.53917 14.1667 5 14.1667H9.16667C9.6275 14.1667 10 14.54 10 15Z"
          fill={color ? color : '#7D8592'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1284_38671">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default memo(CalendarIcon);
