import { memo } from "react";

interface Props {
  className?: string;
}

function LogoutIcon({ className }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 2C21.5128 2 21.9355 2.38604 21.9933 2.88338L22 3V21C22 21.5128 21.614 21.9355 21.1166 21.9933L21 22H15C14.4477 22 14 21.5523 14 21C14 20.4872 14.386 20.0645 14.8834 20.0067L15 20H20V4H15C14.4872 4 14.0645 3.61396 14.0067 3.11662L14 3C14 2.48716 14.386 2.06449 14.8834 2.00673L15 2H21ZM10.6129 6.2097L10.7071 6.29289L15.7071 11.2929C16.0676 11.6534 16.0953 12.2206 15.7903 12.6129L15.7071 12.7071L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071C8.93241 17.3466 8.90468 16.7794 9.2097 16.3871L9.29289 16.2929L12.585 13H3C2.44772 13 2 12.5523 2 12C2 11.4872 2.38604 11.0645 2.88338 11.0067L3 11H12.585L9.29289 7.70711C8.93241 7.34662 8.90468 6.77939 9.2097 6.3871L9.29289 6.29289C9.62334 5.96245 10.1275 5.91161 10.5114 6.14038L10.6129 6.2097Z"
        fill="#7D8592"
      />
    </svg>
  );
}
export default memo(LogoutIcon);
