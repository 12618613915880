import { useForm, UseFormProps } from "react-hook-form";
import * as YUP from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const CustomerFormSchema = YUP.object().shape({
  first_name: YUP.string().required("Please enter your first name"),
  last_name: YUP.string().required("Please enter your last name"),
  birthdate: YUP.string().required("Please enter your date of birth"),
  phone: YUP.string().required('Please enter Phone number'),
  address: YUP.string().required('Please enter address'),
  city: YUP.string().required('Please enter city'),
  nationality: YUP.string().optional(),
  date: YUP.string(),
  language: YUP.string().required("Please enter language"),
  email: YUP.string().email("Enter a valid email"),
  nr: YUP.number()
    .transform((value, originalValue) => {
      const numericValue = Number(originalValue);
      return isNaN(numericValue) ? undefined : numericValue;
    })
    .min(1, "At least 1 character")
    .required("Please enter your house number"),
  postal_code: YUP.number()
    .transform((value: any, originalValue: string) => {
      return originalValue === "" || isNaN(Number(originalValue))
        ? undefined
        : value;
    })
    .min(1)
    .required("Please enter your postal code"),
  note: YUP.string().nullable().optional()
});

export function useCustomerForm(options?: UseFormProps<any>) {
  return useForm<any | any>({
    mode: "onSubmit",
    ...options,
    resolver: yupResolver(CustomerFormSchema)
  });
}

export type CustomerForm = ReturnType<typeof useCustomerForm>;
