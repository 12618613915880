import React, { FC } from 'react'
import LoginProcces from '../../components/organisms/login/LoginProcces'


export interface ILoginProps { }


const Login: FC<ILoginProps> = () => {
  return (
    <LoginProcces />
  )
}

export default Login