import { createContext, useContext } from "react";
import { ConfirmParams } from "./types";
export type ModalContextType = {
  confirm: (params: ConfirmParams) => Promise<unknown>;
  setHasChanges: any;
  hasChanges: boolean;
};
export const ModalContextInitialValues: ModalContextType = {
  confirm: () => new Promise((resolve) => resolve),
  setHasChanges: () => {},
  hasChanges: false,
};
export const ModalContext = createContext<ModalContextType>(
  ModalContextInitialValues
);
export const useModalContext = () => useContext(ModalContext);