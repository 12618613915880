import { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}

function VerticalArrowIcon({ className, color }: Props) {
  return (
    <svg
      width="2"
      height="26"
      viewBox="0 0 2 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect opacity="0.501651" width="2" height="26" rx="1" fill="white" />
    </svg>
  );
}
export default memo(VerticalArrowIcon);
