import { memo } from "react";
interface Props {
  className?: string;
}
function DownloadPDFIcon({ className }: Props) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.72656 11.25C4.72656 10.8358 4.39078 10.5 3.97656 10.5C3.56235 10.5 3.22656 10.8358 3.22656 11.25H4.72656ZM3.97656 12H3.22656H3.97656ZM15.2266 11.25C15.2266 10.8358 14.8908 10.5 14.4766 10.5C14.0623 10.5 13.7266 10.8358 13.7266 11.25H15.2266ZM8.64091 11.5315C8.38215 11.8549 8.43459 12.3269 8.75804 12.5857C9.08149 12.8444 9.55346 12.792 9.81221 12.4685L8.64091 11.5315ZM12.8122 8.71852C13.071 8.39507 13.0185 7.92311 12.6951 7.66435C12.3716 7.40559 11.8997 7.45803 11.6409 7.78148L12.8122 8.71852ZM8.64091 12.4685C8.89967 12.792 9.37164 12.8444 9.69508 12.5857C10.0185 12.3269 10.071 11.8549 9.81221 11.5315L8.64091 12.4685ZM6.81221 7.78148C6.55346 7.45803 6.08149 7.40559 5.75804 7.66435C5.43459 7.92311 5.38215 8.39507 5.64091 8.71852L6.81221 7.78148ZM8.47656 12C8.47656 12.4142 8.81235 12.75 9.22656 12.75C9.64078 12.75 9.97656 12.4142 9.97656 12H8.47656ZM9.97656 3.75C9.97656 3.33579 9.64078 3 9.22656 3C8.81235 3 8.47656 3.33579 8.47656 3.75H9.97656ZM3.22656 11.25V12H4.72656V11.25H3.22656ZM3.22656 12C3.22656 13.6569 4.56971 15 6.22656 15V13.5C5.39814 13.5 4.72656 12.8284 4.72656 12H3.22656ZM6.22656 15H12.2266V13.5H6.22656V15ZM12.2266 15C13.8834 15 15.2266 13.6569 15.2266 12H13.7266C13.7266 12.8284 13.055 13.5 12.2266 13.5V15ZM15.2266 12V11.25H13.7266V12H15.2266ZM9.81221 12.4685L12.8122 8.71852L11.6409 7.78148L8.64091 11.5315L9.81221 12.4685ZM9.81221 11.5315L6.81221 7.78148L5.64091 8.71852L8.64091 12.4685L9.81221 11.5315ZM9.97656 12V3.75H8.47656V12H9.97656Z"
        fill="#7D8592"
      />
    </svg>
  );
}
export default memo(DownloadPDFIcon);
