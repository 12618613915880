export enum CardType {
  CURRENT_PLAN_SUMMARY_CARD = "CURRENT_PLAN_SUMMARY_CARD",
  INFO_CARD = "INFO_CARD",
  APPOINTMENT_PDF_CARD_VIEWER = "APPOINTMENT_PDF_CARD_VIEWER",
  LEAD_CARD = "LEAD_CARD",
  SOURCE_CARD = "SOURCE_CARD",
  CAMPAIGN_CARD = "CAMPAIGN_CARD",
  SECURITY_CARD = "SECURITY_CARD",
  CUSTOMERS_CARD = 'CUSTOMERS_CARD',
  INSURANCE_CARD = 'INSURANCE_CARD',
  CUSTOMER_GRID_CARD = 'CUSTOMER_GRID_CARD',
  CUSTOMER_SECURITY_CARD = 'CUSTOMER_SECURITY_CARD',
  PARTNERS_GRID_CARDS = 'PARTNERS_GRID_CARDS',
  PENDINGS_LIST_CARD = 'PENDINGS_LIST_CARD',
  PENDINGS_CARD = 'PENDINGS_CARD',
}
