import { memo } from "react";

interface Props {
  className?: string;
}

function PaypalIcon({ className }: Props) {
  return (
    <svg
      width="37"
      height="26"
      viewBox="0 0 37 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="1.13911"
        y="1.04298"
        width="35.0197"
        height="24.1247"
        rx="2.72375"
        fill="white"
      />
      <rect
        x="1.13911"
        y="1.04298"
        width="35.0197"
        height="24.1247"
        rx="2.72375"
        stroke="#F2F4F7"
        strokeWidth="0.778215"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9127 19.7962L16.1418 18.2925L15.6316 18.2803H13.1953L14.8884 7.18433C14.8937 7.15073 14.9108 7.11954 14.9357 7.09735C14.9607 7.07516 14.9926 7.06299 15.0259 7.06299H19.1339C20.4977 7.06299 21.4389 7.35626 21.9302 7.93519C22.1606 8.20678 22.3073 8.49066 22.3783 8.80295C22.4528 9.13071 22.454 9.52224 22.3814 9.99986L22.3761 10.0346V10.3407L22.6065 10.4756C22.8004 10.582 22.9546 10.7037 23.0729 10.843C23.2699 11.0753 23.3973 11.3705 23.4512 11.7203C23.5069 12.0801 23.4885 12.5084 23.3973 12.9933C23.2922 13.5509 23.1223 14.0367 22.8928 14.434C22.6818 14.8002 22.4129 15.104 22.0936 15.3393C21.7887 15.563 21.4266 15.7328 21.0171 15.8414C20.6203 15.9482 20.1679 16.0021 19.6717 16.0021H19.352C19.1234 16.0021 18.9014 16.0871 18.7271 16.2397C18.5523 16.3954 18.4367 16.6081 18.4013 16.8408L18.3771 16.9762L17.9724 19.6265L17.9541 19.7238C17.9492 19.7546 17.9409 19.7699 17.9286 19.7803C17.9177 19.7898 17.902 19.7962 17.8867 19.7962H15.9127"
        fill="#28356A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.825 10.0699C22.8129 10.151 22.7988 10.2338 22.7831 10.3188C22.2414 13.1937 20.3879 14.1869 18.0208 14.1869H16.8156C16.5261 14.1869 16.2821 14.4041 16.237 14.6992L15.4452 19.8907C15.4159 20.0846 15.5604 20.2592 15.7495 20.2592H17.8872C18.1403 20.2592 18.3553 20.0691 18.3952 19.8111L18.4162 19.6989L18.8187 17.059L18.8446 16.9142C18.8839 16.6553 19.0995 16.4651 19.3525 16.4651H19.6722C21.7433 16.4651 23.3647 15.596 23.8385 13.081C24.0364 12.0304 23.934 11.1531 23.4102 10.5362C23.2517 10.3502 23.055 10.1957 22.825 10.0699"
        fill="#298FC2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2577 9.83643C22.1749 9.81145 22.0895 9.78889 22.0019 9.76847C21.9138 9.74857 21.8237 9.73094 21.7308 9.71547C21.4058 9.66121 21.0497 9.63547 20.6683 9.63547H17.4486C17.3692 9.63547 17.2939 9.65398 17.2265 9.68745C17.078 9.76124 16.9677 9.90655 16.941 10.0844L16.256 14.5685L16.2363 14.6993C16.2813 14.4041 16.5253 14.1869 16.8148 14.1869H18.0201C20.3872 14.1869 22.2406 13.1932 22.7824 10.3189C22.7985 10.2338 22.8122 10.151 22.8243 10.07C22.6873 9.99479 22.5388 9.93051 22.379 9.87574C22.3395 9.86217 22.2988 9.84911 22.2577 9.83643"
        fill="#282D46"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9409 10.0845C16.9676 9.90656 17.0779 9.76126 17.2265 9.68797C17.2943 9.65437 17.3692 9.63586 17.4485 9.63586H20.6683C21.0497 9.63586 21.4058 9.66173 21.7308 9.716C21.8236 9.73134 21.9138 9.74909 22.0019 9.76899C22.0894 9.78928 22.1748 9.81198 22.2576 9.83683C22.2987 9.84951 22.3395 9.86269 22.3793 9.87575C22.5392 9.93053 22.6877 9.99532 22.8247 10.07C22.9859 9.00761 22.8234 8.28426 22.2677 7.62926C21.6549 6.90807 20.5492 6.59946 19.1342 6.59946H15.0261C14.7371 6.59946 14.4905 6.81665 14.4459 7.11233L12.7348 18.3224C12.7011 18.5441 12.8666 18.7442 13.083 18.7442H15.6191L16.9409 10.0845"
        fill="#28356A"
      />
    </svg>
  );
}
export default memo(PaypalIcon);
