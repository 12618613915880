import { useState } from "react";

export const useToolTip = () => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const [openTooltipIndex, setOpenTooltipIndex] = useState<number | null>(null);

  const handleOpenTooltip = () => {
    setOpenTooltip((prev) => !prev);
  };

  const preventClickPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };
  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltipbyIndex = (index: number) => {
    setOpenTooltipIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCloseTooltipbyIndex = () => {
    setOpenTooltipIndex(null);
  };

  return {
    openTooltip,
    handleOpenTooltip,
    handleCloseTooltip,
    handleOpenTooltipbyIndex,
    handleCloseTooltipbyIndex,
    openTooltipIndex,
    preventClickPropagation,
  };
};
