import { createContext, useContext } from "react";

export interface IThemeContext {
    themeColors: {
        primary: any;
    };
}

export const DefaultValues: IThemeContext = {
    themeColors: {
        primary: '',
    }
};

export const ThemeContext = createContext<IThemeContext>(DefaultValues);
export const useThemeContext = () => useContext(ThemeContext);
