



import { memo } from "react";
interface Props {
    className?: string;
    color?: string;
}
function CirclePlusIcon({ className, color }: Props) {
    return (

        <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill={color ? color : '#282D46'} fillOpacity="0.08" />
            <g opacity="0.4">
                <path d="M12 9H6" stroke={color ? color : '#282D46'} strokeLinecap="round" />
                <path d="M9 12L9 6" stroke={color ? color : '#282D46'} strokeLinecap="round" />
            </g>
        </svg>


    );
}
export default memo(CirclePlusIcon);
