import { FunctionComponent } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import RoutesApp from "../Routes";
import Signup from "../../pages/Signup/Signup";
import Login from "../../pages/Login/Login";

import LoginLayout from "../../components/templates/credential-management-layout/LoginLayout";
import ForgotPassword from "../../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import EmailVerificationResponse from "components/organisms/signup/email-verification-response/EmailVerificationResponse";

export interface IUnAuthenticatedRoutes {}

const UnAuthenticatedRoutes: FunctionComponent<IUnAuthenticatedRoutes> = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LoginLayout children={<Login />} />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/forgot-password",
      element: <LoginLayout children={<ForgotPassword />} />,
    },
    {
      path: "/reset-password/:id",
      element: <LoginLayout children={<ResetPassword />} />,
    },
    {
      path: "/email-verification/:id",
      element: <EmailVerificationResponse />,
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    },
  ]);
  return (
    <div>
      <RoutesApp appRoutes={router} />
    </div>
  );
};
export default UnAuthenticatedRoutes;
