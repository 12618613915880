import Card from "components/molecules/card/Card";
import { ICustomerGridCard } from "components/molecules/card/customer-grid-card/CustomerGridCard";
import ToggleSwitch from "components/molecules/toggle-switch/ToggleSwitch";
import { CardType } from "lib/helpers/constants/cardTypes";
import { useUpdatePartnerStatus } from "lib/hooks/mutations/Partners/useUpdatePartnerStatus";
import { FC, useEffect, useState } from "react";

export interface ICustomersGridViewProps {
  data: any;
}

const PartnersGridView: FC<ICustomersGridViewProps> = ({ data }) => {
  const { mutateAsync: updateSourceStatus } = useUpdatePartnerStatus();
  const [checkedStates, setCheckedStates] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    // Initialize checkedStates based on data
    const initialCheckedStates = data.reduce(
      (acc: { [key: string]: boolean }, item: any) => {
        acc[item.id] = item.status === 1;
        return acc;
      },
      {}
    );
    setCheckedStates(initialCheckedStates);
  }, [data]);

  const toggleStatus = async (id: string) => {
    // Assuming updateSourceStatus toggles the status on the backend and returns the new status
    updateSourceStatus({ id })
      .then((res: any) => {
        if (res.status !== undefined) {
          setCheckedStates((prevStates) => ({
            ...prevStates,
            [id]: res.status === 1,
          }));
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4">
        {data.map((item: any) => (
          <div key={item.id}>
            <Card<ICustomerGridCard<string[]>>
              {...item}
              cardType={CardType.PARTNERS_GRID_CARDS}
              props={{
                logo: item.logo,
                name: item.name,
                email: item.email,
                phone: item.phone,
                toggleRender: () => (
                  <div className="absolute right-[10px] top-[10px]">
                    <ToggleSwitch
                      checked={checkedStates[item.id]}
                      onChange={() => toggleStatus(item.id)}
                      classes={{ label: "!mb-0" }}
                    />
                  </div>
                ),
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default PartnersGridView;
