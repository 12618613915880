import { FC, HTMLAttributes } from "react";

export interface IDefaultContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const DefaultContainer: FC<IDefaultContainerProps> = ({
  children,
  ...rest
}) => {
  return (
    <div
      className={`max-w-[450px] 2xl:max-w-[500px] w-full mx-auto ${rest.className}`}
    >
      {children}
    </div>
  );
};

export default DefaultContainer;
