import { sendFeedback } from "api/Pendings"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"


export const useSendFeedback = () => {
    const queryClient = useQueryClient();
    return useMutation(sendFeedback,{
        onSuccess: () => {
            toast.success("Feedback sent successfully")
            queryClient.invalidateQueries("customerPending");

        },
        onError: (error) => {
            console.error(error)
        }
    })
}