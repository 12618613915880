import { getLeadNotes } from "api/Leads";
import { UseQueryOptions, useQuery } from "react-query";

interface DocumentParams {
    lead_id?: any;
}

export const useLeadNotes = (
    params: DocumentParams,
    options?: UseQueryOptions<any, any, unknown, string[]>
) => {
    const queryInfo = useQuery(
        ['leadsNotes'],
        () => getLeadNotes(params),
        {
            onError: (err) => {
                console.error(err);
            },
            ...options,
            enabled: false,
        }
    );

    return queryInfo;
};