import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC } from "react";

export interface IInsuranceCardProps {
  label: string;
  icon: string;
}

const InsuranceCard: FC<IInsuranceCardProps> = ({ label, icon }) => {
  return (
    <div className="border-[1px] border-[#F2F4F] rounded-[20px] bg-[#50B8E7] bg-opacity-[5%] min-w-[160px] flex items-center justify-center  flex-col  py-[30px]">
      <Icon iconType={icon as IconType} />
      <h1 className="text-[#282D46] mt-[41px] font-inter-medium text-[20px]">{label}</h1>
    </div>
  );
};

export default InsuranceCard;
