import { memo } from "react";
interface Props {
  className?: string;
}
function SearchIcon({ className }: Props) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17C10.4845 17 12.3102 16.3199 13.7567 15.18L14.1601 15.5742L17.2929 18.7071L17.3871 18.7903C17.7794 19.0953 18.3466 19.0676 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L15.5661 14.1518L15.1721 13.7668C16.3167 12.3187 17 10.4892 17 8.5C17 3.80558 13.1944 0 8.5 0ZM8.5 2C12.0899 2 15 4.91015 15 8.5C15 12.0899 12.0899 15 8.5 15C4.91015 15 2 12.0899 2 8.5C2 4.91015 4.91015 2 8.5 2Z"
        fill="#282D46"
      />
    </svg>
  );
}
export default memo(SearchIcon);
