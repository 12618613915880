import { useMutation } from "react-query";
import { forgotPassowrd } from "../../../../api/User";
import { useMultiStepFormContext } from "../../../context/MultiStepFormContext/MultiStepFormContext";




export const useForgotPassowrd = () => {
    const { nextFormStep } = useMultiStepFormContext();

    return {
        ...useMutation(forgotPassowrd, {
            onSuccess: () => {
                nextFormStep();
            },
            onError: () => {
               
            },
        }),
    };
};