import { FC, ReactNode } from "react";
import AuthContextProvider from "./AuthContextContext/AuthContextProvider";
import MultiStepFormProvider from "./MultiStepFormContext/MultiStepFormProvider";
import ModalContextProvider from "./ModalContext/ModalContextProvider";
import ThemeContextProvider from "./ThemeContext/ThemeContextProvider";

interface IComponent {
  children: ReactNode;
}
const combineProviders = (...components: FC<IComponent>[]): FC<IComponent> => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};
/**
 *  The order of the providers is significant
 *  NOTE: If you need to change the order, DO IT CAREFULLY!
 */
const providers = [
  AuthContextProvider,
  MultiStepFormProvider,
  ModalContextProvider,
  ThemeContextProvider
];
const AppContextProviders = combineProviders(...(providers as FC[]));
export default AppContextProviders;
