import {  updateLeadStatus } from "api/Leads";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useChangeLeadStatus = (id?: string,
    options?: UseMutationOptions<any, any, any, unknown>
) => {
    const queryClient = useQueryClient();


    return useMutation(updateLeadStatus, {
        onSuccess: (res) => {
            toast.success("Lead status updated successfully!");
            queryClient.invalidateQueries('leads')
        },
        onError: () => {
            toast.error("Lead status update failed! Please try again later.");
        },
        ...options
    })
}