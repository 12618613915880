import { memo } from "react";

interface Props {
  className?: string;
}

function MasterCardIcon({ className }: Props) {
  return (
    <svg
      width="37"
      height="25"
      viewBox="0 0 37 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.13911"
        y="0.465279"
        width="35.0197"
        height="24.1247"
        rx="4.28018"
        fill="white"
      />
      <rect
        x="1.13911"
        y="0.465279"
        width="35.0197"
        height="24.1247"
        rx="4.28018"
        stroke="#F2F4F7"
        strokeWidth="0.778215"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1398 19.2C27.0255 19.2 30.1756 16.0875 30.1756 12.248C30.1756 8.40844 27.0255 5.2959 23.1398 5.2959C21.3983 5.2959 19.8045 5.9211 18.5759 6.95674C17.3474 5.92122 15.7537 5.29611 14.0123 5.29611C10.1266 5.29611 6.97656 8.40865 6.97656 12.2482C6.97656 16.0877 10.1266 19.2002 14.0123 19.2002C15.7539 19.2002 17.3476 18.575 18.5762 17.5394C19.8048 18.5749 21.3984 19.2 23.1398 19.2Z"
        fill="#D61616"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
        d="M18.5762 17.5393C20.089 16.2642 21.0483 14.3668 21.0483 12.248C21.0483 10.1291 20.089 8.23171 18.5762 6.95657C19.8048 5.92103 21.3984 5.2959 23.1398 5.2959C27.0256 5.2959 30.1756 8.40844 30.1756 12.248C30.1756 16.0875 27.0256 19.2 23.1398 19.2C21.3984 19.2 19.8048 18.5749 18.5762 17.5393Z"
        fill="#F9A000"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
        d="M18.5756 17.5388C20.0885 16.2637 21.0478 14.3663 21.0478 12.2474C21.0478 10.1286 20.0885 8.23119 18.5756 6.95605C17.0628 8.23119 16.1035 10.1286 16.1035 12.2474C16.1035 14.3663 17.0628 16.2637 18.5756 17.5388Z"
        fill="#FF5E00"
      />
    </svg>
  );
}
export default memo(MasterCardIcon);
