import Button from 'components/atoms/button/Button'
import Icon from 'components/atoms/icon/Icon'
import Paragraph from 'components/atoms/paragraph/Paragraph'
import LoginTitle from 'components/atoms/title/LoginTitle'
import { IconType } from 'lib/helpers/constants/iconTypes'
import React, { FC } from 'react'
export interface IResetPasswordCompleteProps { }




const ResetPasswordComplete: FC<IResetPasswordCompleteProps> = () => {
    return (
        <div className='flex flex-col gap-8'>
            <div>
                <div className='pb-2'>
                    <LoginTitle title='Password changed ' />
                </div>
                <Paragraph paragraph='You can now log in to your account.' />
            </div>

            <Icon iconType={IconType.RESET_PASSWORD_COMPLETE_ICON} className='max-w-[250px] w-full h-auto m-auto' />

            <div className='flex flex-col gap-6'>

                <div className='pt-5'>
                    <a href={'/'} className='no-underline'>
                        <Button type='button' className='bg-bluebutton text-white p-[15px] w-full rounded-[8px]'>
                            Go to login
                        </Button>
                    </a>

                </div>

            </div>


        </div>
    )
}

export default ResetPasswordComplete