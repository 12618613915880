import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
const EditSourceIcon = ({ className, color }: Props) => {
  return (
    <div>
      <svg
        className={className}
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2829_49334)">
          <path
            d="M5.81634 13.7925L16.2883 3.72553C16.8626 3.17603 16.8627 2.28646 16.2883 1.7369L14.903 0.41109C14.3302 -0.13716 13.3979 -0.13716 12.8242 0.411871C9.68296 3.43162 5.48546 7.46681 2.34403 10.4867C2.30739 10.5219 2.26804 10.5747 2.24401 10.6261C2.22115 10.675 0.0534134 15.3148 0.0416578 15.3406C-0.101108 15.6527 0.141385 15.9969 0.490461 15.9969C0.490559 15.9969 0.490657 15.9969 0.490788 15.9969H12.3107C12.5812 15.9969 12.8005 15.7871 12.8005 15.5282C12.8005 15.2693 12.5811 15.0595 12.3107 15.0595H2.85494L5.65637 13.8991C5.71626 13.8742 5.77288 13.8355 5.81634 13.7925ZM13.5177 1.074C13.7087 0.891215 14.0194 0.891246 14.2104 1.074L15.5957 2.39978C15.7842 2.58012 15.7904 2.87637 15.5949 3.0634L14.5591 4.05918L12.481 2.07056L13.5177 1.074ZM11.7899 2.7349L13.868 4.72353L12.4857 6.05231L10.4077 4.06368L11.7899 2.7349ZM1.45142 14.6193L2.84789 11.6303L4.60591 13.3127L1.45142 14.6193ZM3.38303 10.8167L9.71663 4.72806L11.7947 6.71668L5.46106 12.8052L3.38303 10.8167Z"
            fill={color || "#282D46"}
          />
        </g>
        <defs>
          <clipPath id="clip0_2829_49334">
            <rect width="16.7191" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default memo(EditSourceIcon);
