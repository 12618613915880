import { useInfiniteQuery } from "react-query";
import { toast } from "react-toastify";
import { usePagination } from "lib/hooks/shared/usePagination";
import { getCampaigns } from "api/Campaign";
import { ICampaignResponse, PaginationParams } from "lib/types/campaign.types";

export const useCampaigns = (
  search?: string,
  status?: string,
  date_of_creation?: string
) => {
  const {
    currentPage,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage
  } = usePagination(1, 10);

  const { data, isLoading, hasNextPage, hasPreviousPage } = useInfiniteQuery<
    Array<ICampaignResponse> | any
  >(
    ["campaigns", currentPage, itemsPerPage, search, status, date_of_creation],
    ({ pageParam = currentPage }) => {
      return getCampaigns({
        page: pageParam,
        number_per_page: itemsPerPage,
        search: search,
        status: status,
        date_of_creation: date_of_creation
      } as PaginationParams);
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data?.next_page_url
          ? new URL(lastPage.data.next_page_url).searchParams.get("page")
          : false;
      },
      getPreviousPageParam: (firstPage, allPages) => {
        const currentPage = allPages[allPages.length - 1];
        const prevPageUrl = currentPage?.data?.prev_page_url;
        return prevPageUrl
          ? new URL(prevPageUrl).searchParams.get("page")
          : false;
      },
      onError: () => {
        toast.error(`Something went wrong`);
      }
    }
  );

  return {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    itemsPerPage,
    handleNextPage,
    handlePreviousPage,
    handleItemsPerPageChange
  };
};
