import { markAsReadAllNotifcation } from "api/Notifications"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"


export const useMarkAsReadAllNotification = () => {
    const queryClient = useQueryClient();
    return useMutation<any, any, void>(
        () => markAsReadAllNotifcation(),
        {
            onSuccess: () => {
                queryClient.refetchQueries('notifications');
            },
            onError: () => {
                toast.error('Something went wrong');
            }
        },
    );
};