import Card, { ICard } from "components/molecules/card/Card";
import { CardType, ISecurityCardProps } from "components/molecules/card/export";
import { FC } from "react";
import { JSX } from "react/jsx-runtime";

export interface ISecurityGridListProps<T> {
  data: T;
}

const SecurityGridList: FC<ISecurityGridListProps<any>> = ({ data }) => {
  return (
    <div className="flex flex-col gap-5 w-full">
      {data.map((item: JSX.IntrinsicAttributes & ICard<ISecurityCardProps>) => {
        return (
          <Card<ISecurityCardProps>
            key={`security-key-${item.id}`}
            {...item}
            cardType={CardType.SECURITY_CARD}
          />
        );
      })}
    </div>
  );
};

export default SecurityGridList;
