import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import Icon from 'components/atoms/icon/Icon';
import Input from 'components/atoms/input/Input';
import FileDropZone from 'components/molecules/file-dropzone/FileDropZone';
import { IconType } from 'lib/helpers/constants/iconTypes';
import withModal from 'lib/helpers/hoc/withModal'
import { useTemplates } from 'lib/hooks/mutations/Documents/useTemplates';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IAddTemplateProps {
    onConfirm: () => void;
}

const AddTemplate: React.FC<IAddTemplateProps> = ({ onConfirm }) => {

    const { t } = useTranslation()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { mutateAsync, isLoading } = useTemplates()

    const onSubmit = (data: any) => {

        const finalData = new FormData();

        finalData.append('name', data.name);
        finalData.append('type', 'Templates');
        finalData.append('file', data.file[0]);

        mutateAsync(finalData).then(() => {
            onConfirm()
        })

    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-0 flex flex-col gap-7">
                <Controller
                    control={control}
                    name='name'
                    rules={{ required: t('Template Name is required') }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            value={field.value}
                            label={t('Template Name')}
                            className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
                            placeholder={t('Enter Template Name')}
                            error={(errors as any)?.name}
                        />
                    )}


                />
                <div>
                    <Controller
                        control={control}
                        name="file"
                        rules={{ required: t("File is required") }}
                        render={({ field: { onChange, value } }) => (
                            <FileDropZone
                                formats={" .pdf, .docx, .doc,"}
                                file={value}
                                setFile={onChange}
                                label="Upload Template File"
                                classes={{ label: "bg-white" }}
                                name="file"
                                error={errors.file}
                                control={control}
                            />
                        )}
                    />
                </div>

                <div className='flex-none flex justify-end'>
                    <IconButton
                        type='submit'
                        disabled={isLoading}
                        className='w-auto'
                        icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
                    >
                        {t('Add Template')}
                    </IconButton>
                </div>
            </div>
        </Form>
    );
}

export default withModal(AddTemplate)