import { FC, useEffect, useState } from "react";
import Form from "../../../atoms/form/Form";
import Input from "../../../atoms/input/Input";
import { IconType } from "../../../../lib/helpers/constants/iconTypes";
import DefaultContainer from "../../../templates/default-container/DefaultContainer";
import FormStepNavigationButtons from "../../../molecules/form-step-navigation-buttons/FormStepNavigationButtons";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";
import AutocompleteInput from "components/atoms/input/autocomplet-input/AutoCompletInput";
import Label from "components/atoms/input/input-label/InputLabel";
import axios from "axios";

export interface ICompanyInformationStepProps {}

const CompanyInformationStep: FC<ICompanyInformationStepProps> = () => {
  const {
    currentStep,
    updateFormData,
    nextFormStep,
    register,
    handleSubmit,
    getValues,
    errors,
    setValue,
    store,
    clearErrors,
  } = useSignupCustomHooks({ initalStep: 1 });
  const [data, setData] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const onSubmit = () => {
    updateFormData(getValues());
    nextFormStep();
  };


  useEffect(() => {
    const getAddresses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_ADDRESS_API}krankenkasse/regions`);
        const flatData = response.data.map((item: { plz: any; ort: any; commune: any; }) => `${item.plz}, ${item.ort}, ${item.commune ?? ''}`);
        setData(flatData);
      } catch (e) {
        console.log(e);
      }
    };
    getAddresses();
  }, []);

  useEffect(() => {
    if (inputValue) {
      const filtered = data.filter((item) =>
        item.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredData(filtered.slice(0, 100) as any);
    } else {
      setFilteredData([]);
    }
  }, [inputValue, data]);
  


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };
  
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-between h-full"
    >
      <div>
        <h1 className="text-bluebutton uppercase font-bold text-[14px] text-center mt-[40px] mb-[8px]">
         STEP {currentStep}/6
        </h1>
        <span className="text-[#282D46] font-inter-bold text-[25px] flex justify-center">
          Company informations
        </span>
      </div>
      <DefaultContainer className="flex flex-col gap-[15px] 2xl:gap-[24px] mb-[35px] 2xl:mb-0">
        <Input
          label="Company Name"
          placeholder="Company Name"
          {...register("company_name")}
          error={errors.company_name}
        />
        <Input
          label="Company-ID / VET-Number"
          placeholder="Company-ID / VET-Number"
          {...register("ui_number")}
          error={errors.ui_number}
        />
        <Input
          label="Website"
          placeholder="Website"
          {...register("company_website")}
          error={errors.company_website}
        />
        <Input
          label="Company E-Mail"
          placeholder="My.email@hub.net"
          {...register("company_email")}
          error={errors.company_email}
        />
        <div>
          <Label text="Address" />
            <AutocompleteInput
            suggestions={filteredData}
            value={store.company_address}
            setValue={(name, value) => {
              setValue(name, value);
              setInputValue(value);
            }}
            className="block"
            clearErrors={clearErrors}
            placeholder="Bern, Switzerland 7100"
            onChange={handleInputChange}
            icon={IconType.LOCATION_ICON}
            error={errors.company_address}
            name="company_address"
          />
        </div>
      </DefaultContainer>
      <FormStepNavigationButtons />
    </Form>
  );
};
export default CompanyInformationStep;
