import { usePermissions } from 'lib/hooks/shared/usePermissions';
import { FC, ComponentType, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

interface WithPermissionProps {
  permissions?: string[];
}

const withPermission = (WrappedComponent: ComponentType, requiredPermissions: string[]) => {
  const WithPermission: FC<WithPermissionProps> = (props) => {
    const hasPermission = usePermissions(requiredPermissions);

    if (!hasPermission) {
      return <Navigate to="/" replace />;
    }

    return <WrappedComponent {...props as PropsWithChildren<any>} permissions={requiredPermissions} />;
  };

  return WithPermission;
};

export default withPermission;