import { memo } from "react";
interface Props {
    className?: string;
}
const EmployeeEmailIcon = ({ className }: Props) => {
    return (
        <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.5" y="3" width="20" height="18" rx="4" stroke="#282D46" strokeWidth="1.5" />
            <path d="M2.5 7L10.0012 13.001C11.4621 14.1697 13.5379 14.1697 14.9988 13.001L22.5 7" stroke="#282D46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default memo(EmployeeEmailIcon);
