import { useQuery } from "react-query";
import { getCustomersFamilyMembers } from "api/Customers";

export const useCustomerFamilyMembers = (costumer_id?: any) => {
  return useQuery<Array<any> | any>(
    ["customersFamilyMembers", costumer_id],
    () => getCustomersFamilyMembers(costumer_id),
    {
      onError(error: any) {
        console.error(error);
      }
    }
  );
};
