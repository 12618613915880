import { useMutation } from "react-query";
import { logout } from "../../../../api/User";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "lib/context/AuthContextContext/AuthContext";



export const useLogout = () => {
    const navigate = useNavigate()
    const {setUser, setIsAuthenticated, setIsLoading } = useAuthContext()
    return {
        ...useMutation(logout, {
            onSuccess: () => {
                localStorage.removeItem('token');
                setIsAuthenticated(false)
                setUser({})
                navigate("/");
                setIsLoading(false)
            },

            onError: (err) => {
                console.error(err);
            },
        }),
    };
};