import { useInfiniteQuery } from "react-query";
import { toast } from "react-toastify";
import { ISource } from "lib/types/sources.types";
import { usePagination } from "lib/hooks/shared/usePagination";
import { getPartners } from "api/Partners";
import { IFilterProps } from "components/organisms/partners/PartnersView";

export const usePartners = (filterData: IFilterProps) => {
  const {
    currentPage,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage
  } = usePagination(1, 10);

  const { data, isLoading, hasNextPage, hasPreviousPage } = useInfiniteQuery<Array<ISource> | any>(
    ["partners", currentPage, itemsPerPage, filterData],
    ({ pageParam = currentPage }) => {
      return getPartners({ page: pageParam, number_per_page: itemsPerPage, search: filterData.search, status: filterData.status,creation_date: filterData.creation_date  });
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.partners?.next_page_url
          ? new URL(lastPage.partners.next_page_url).searchParams.get("page")
          : false;
      },
      getPreviousPageParam: (firstPage, allPages) => {
        const currentPage = allPages[allPages.length - 1];
        const prevPageUrl = currentPage?.partners?.prev_page_url;
        return prevPageUrl
          ? new URL(prevPageUrl).searchParams.get("page")
          : false;
    },
      onError: () => {
        toast.error(`Something went wrong`);
      }
    }
  );

  return {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    itemsPerPage,
    handleNextPage,
    handlePreviousPage,
    handleItemsPerPageChange
  };
};
