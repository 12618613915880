import {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  forwardRef,
} from "react";
import Icon from "../icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";

type TChecboxClasses = {
  container: string;
};

export interface ICheckboxProps extends InputHTMLAttributes<any> {
  id?: string;
  isChecked?: boolean;
  setIsChecked?: (arg: any) => void;
  classes?: Partial<TChecboxClasses>;
}

const Checkbox: ForwardRefRenderFunction<HTMLInputElement, ICheckboxProps> = (
  { id, classes = { container: "" }, isChecked, setIsChecked, ...rest },
  ref
) => {
  return (
    <label
      className="relative flex items-center rounded-full cursor-pointer w-5"
      htmlFor={id}
    >
      <input
        type={rest.type || "checkbox"}
        className={`${classes.container} peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border transition-all checked:border-[--theme] checked:bg-[--theme] hover:before:opacity-10 ${rest.className}`}
        id={id}
        ref={ref}
        defaultChecked={isChecked}
        // checked={isChecked} 
        {...rest}
        onChange={(e) => {
          if (setIsChecked) setIsChecked(e);
          if (rest.onChange) (rest.onChange as any)(e);
        }}
      />
      <span className="absolute flex justify-center items-center text-white h-5 w-5 rounded-md transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
        <Icon iconType={IconType.CHECKBOX_TICK_ICON} className="h-3.5 w-3.5" />
      </span>
    </label>
  );
};

export default forwardRef(Checkbox);
