import axios, { AxiosResponse, Method } from "axios";
export interface Params {
  [key: string]: string | number;
}
export async function apiRequest<D = {}, R = unknown>(
  method: Method,
  path: string,
  data?: D,
  headers?: Record<string, string> | undefined,
  params?: Params,
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream'
) {
  const url = `${process.env.REACT_APP_API_URL}${path}`;
  try {
    const response = await axios.request<D, AxiosResponse<R>>({
      url,
      method,
      data,
      headers,
      params,
      responseType,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}



