import { FC, HTMLAttributes } from "react";

export interface IDashboardBoxLayoutProps extends HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
}

const DashboardBoxLayout: FC<IDashboardBoxLayoutProps> = ({
    children,
    ...rest
}) => {
    return (
        <div className={`bg-white p-5 rounded-[20px] border-[#F2F4F7] border-solid border ${rest.className}`}>
            {children}
        </div>
    );
};

export default DashboardBoxLayout;
