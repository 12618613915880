import IconButton from "components/atoms/button/IconButton";
import Checkbox from "components/atoms/checkbox/Checkbox";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import React, { Dispatch, useEffect, useState } from "react";
import Creatable from "react-select/creatable";
import { Controller, useForm } from "react-hook-form";
import { customStyles } from "lib/helpers/configs/customStyles";
import Label from "components/atoms/input/input-label/InputLabel";
import { useTranslation } from "react-i18next";

export interface IFilterAppointmentsDropdownProps {
  primary_status?: string;
  from_date?: string;
  to_date?: string;
  typeof?: string;
  number_of_persons?: number;
  status?: string;
}

export interface IFilterAppointmentsProps {
  setFilterData?: (args: any) => void;
  FilterData?: IFilterAppointmentsDropdownProps;
  setIsOpen?: Dispatch<React.SetStateAction<boolean>>;
  themeColors?: {
    primary: string;
  };
}

const FilterAppointments: React.FC<IFilterAppointmentsProps> = ({
  setFilterData,
  FilterData,
  setIsOpen,
  themeColors,
}) => {
  const [DateFrom, setDateFrom] = useState<any>(FilterData?.from_date);
  const [DateTo, setDateTo] = useState<any>(FilterData?.to_date);
  const [loading, setLoading] = useState(false);
  const { control, reset } = useForm();
  const [key, setKey] = useState(0); // Key state to force re-render
  const [InsideData, setInsideData] =
    useState<IFilterAppointmentsDropdownProps>(
      FilterData || {
        primary_status: undefined,
        from_date: undefined,
        to_date: undefined,
        typeof: undefined,
        number_of_persons: undefined,
        status: "",
      }
    );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setInsideData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setInsideData((prevFormData: any) => ({
      ...prevFormData,
      from_date: DateFrom,
    }));
  }, [DateFrom]);

  useEffect(() => {
    setInsideData((prevFormData: any) => ({
      ...prevFormData,
      to_date: DateTo,
    }));
  }, [DateTo]);

  const handleButtonClick = () => {
    setLoading(true);
    if (setFilterData) {
      setFilterData(InsideData);
    }
    setIsOpen && setIsOpen(false);
    setLoading(false);
  };

  const ResetData = () => {
    setInsideData({
      primary_status: undefined,
      from_date: undefined,
      to_date: undefined,
      typeof: undefined,
      number_of_persons: undefined,
      status: "",
    });
    setKey((prevKey) => prevKey + 1); // Change key to force re-render
    setDateFrom(null);
    setDateTo(null);
    reset();
  };

  const { t } = useTranslation();
  return (
    <div key={key} className="flex flex-col gap-3 w-[300px]">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div
          className={`flex flex-row rounded-[8px] justify-between items-center py-2 px-4 border 
                 ${InsideData.primary_status === "Open"
              ? "border-[--theme]"
              : "border-[#D0D5DD]"
            }`}
        >
          <span
            className={`text-sm font-inter-regular ${InsideData.primary_status === "Open"
              ? "text-[--theme]"
              : "text-primaryTextColor"
              }`}
          >
            {t('Open')}
          </span>
          <Checkbox
            onChange={handleInputChange}
            type="radio"
            name="primary_status"
            checked={InsideData.primary_status === "Open"}
            value={"Open"}
          />
        </div>
        <div
          className={`flex flex-row rounded-[8px] justify-between items-center py-2 px-4 border 
                 ${InsideData.primary_status === "Close"
              ? "border-[--theme]"
              : "border-[#D0D5DD]"
            }`}
        >
          <span
            className={`text-sm font-inter-regular ${InsideData.primary_status === "Close"
              ? "text-[--theme]"
              : "text-primaryTextColor"
              }`}
          >
            {t('Close')}
          </span>
          <Checkbox
            onChange={handleInputChange}
            type="radio"
            name="primary_status"
            checked={InsideData.primary_status === "Close"}
            value={"Close"}
          />
        </div>
      </div>

      <PrimaryDatePicker
        label={t("From")}
        setStartDate={setDateFrom}
        startDate={DateFrom}
        wrapperClassName="!left-[2px]"
        className="placeholder:!text-sm indent-3"
      />

      <PrimaryDatePicker
        label={t("To")}
        setStartDate={setDateTo}
        startDate={DateTo}
        wrapperClassName="!left-[2px]"
        className="placeholder:!text-sm indent-3"
      />

      <div>
        <Label text={t("Appointment Type")} />
        <Controller
          name="typeof"
          control={control}
          defaultValue={InsideData.typeof}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder={t("Appointment Type")}
              options={[
                { label: t("Physical"), value: "Physical" },
                { label: t("Digital"), value: "Digital" },
              ]}
              value={
                field.value
                  ? {
                    value: field.value,
                    label: field.value,
                  }
                  : null
              }
              className="text-sm"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                field.onChange(selectedValue);
                setInsideData((prevState) => ({
                  ...prevState,
                  typeof: selectedValue,
                }));
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>

      <div>
        <Label text={t("Number of Persons")} />
        <Controller
          name="number_of_persons"
          control={control}
          defaultValue={InsideData.number_of_persons}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder={t("Persons")}
              options={[
                { label: "1", value: "1" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                { label: "4", value: "4" },
                { label: "5", value: "5" },
              ]}
              value={
                field.value
                  ? {
                    value: field.value,
                    label: field.value,
                  }
                  : null
              }
              className="text-sm"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                field.onChange(selectedValue);
                setInsideData((prevState) => ({
                  ...prevState,
                  number_of_persons: selectedValue,
                }));
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>

      <div>
        <Label text={t("Status")} />
        <Controller
          name="status"
          control={control}
          defaultValue={InsideData.status}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder={t("Status")}
              options={[
                { label: t("Pending"), value: "Pending" },
                { label: t("Won"), value: "Won" },
                { label: t("Lost"), value: "Lost" },
                { label: t("Follow Up"), value: "Follow Up" },
              ]}
              value={
                field.value
                  ? {
                    value: field.value,
                    label: field.value,
                  }
                  : null
              }
              className="text-sm"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                field.onChange(selectedValue);
                setInsideData((prevState) => ({
                  ...prevState,
                  status: selectedValue,
                }));
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>

      <div className="pt-5">
        <div className="flex flex-row justify-end gap-3">
          <IconButton
            secondary={true}
            className="w-[110px]"
            onClick={ResetData}
            disabled={loading}
          >
            {t('Reset')}
          </IconButton>
          <IconButton
            className="w-[110px]"
            onClick={handleButtonClick}
            disabled={loading}
          >
            {loading ? t("Loading...") : t("Confirm")}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default FilterAppointments;
