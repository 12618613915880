import { memo } from "react";
interface Props {
  className?: string;
}
function ColorfulLogoIcon({ className }: Props) {
  return (
    <svg width="199" height="81" viewBox="0 0 199 81" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M108.499 23.3555C108.499 22.2568 109.449 21.3613 110.615 21.3613H118.028C119.194 21.3613 120.144 22.2568 120.144 23.3555V38.7758C120.144 44.8855 116.714 50.4491 111.275 53.5039C110.002 54.2215 108.505 53.269 108.505 51.8653V23.3555H108.499Z" fill="url(#paint0_linear_386_4644)"/>
    <path d="M53.9861 39.8185C51.0681 42.3906 47.1663 46.4426 44.8952 49.11C44.5785 49.4848 43.9114 49.2815 43.8978 48.7988C43.7158 42.2826 42.5097 33.3468 31.2286 30.3428C20.2641 27.4212 12.1705 38.0719 11.6449 47.0522V77.226C11.6449 78.3249 10.6947 79.2204 9.52891 79.2204H2.11603C0.950198 79.2204 0 78.3249 0 77.226V2.90631C0 1.8076 0.950198 0.912109 2.11603 0.912109H9.52891C10.6947 0.912109 11.6449 1.8076 11.6449 2.90631V26.2655C20.0216 17.5836 47.5099 11.5184 53.9861 39.8185Z" fill="url(#paint1_linear_386_4644)"/>
    <path d="M108.499 23.3555C108.499 22.2568 109.449 21.3613 110.615 21.3613H118.028C119.194 21.3613 120.144 22.2568 120.144 23.3555V38.7758C120.144 44.8855 116.714 50.4491 111.275 53.5039C110.002 54.2215 108.505 53.269 108.505 51.8653V23.3555H108.499Z" fill="url(#paint2_linear_386_4644)"/>
    <path d="M198.075 54.5528C197.34 60.3894 194.8 65.8322 190.77 70.2019C186.733 74.5714 181.388 77.6643 175.411 79.0933C169.434 80.5223 163.092 80.2174 157.182 78.2232C153.126 76.8514 149.399 74.7238 146.218 71.9801L146.487 77.1244C146.548 78.2676 145.584 79.2204 144.371 79.2204H137.073C135.9 79.2204 134.957 78.3247 134.957 77.226V61.7423C134.957 59.3733 136.008 57.1061 137.855 55.5055L145.012 49.2941L148.563 56.3121C150.295 59.729 152.229 63.1839 155.471 65.4195C157.068 66.5182 158.827 67.4074 160.7 68.0361C164.467 69.3062 168.51 69.5031 172.317 68.595C176.132 67.6805 179.536 65.7116 182.11 62.9234C184.678 60.1417 186.296 56.6678 186.759 52.9461C187.231 49.2308 186.518 45.4265 184.719 42.0224C182.924 38.6182 180.122 35.7728 176.664 33.8296C173.213 31.8924 169.259 30.9587 165.316 31.1366C161.367 31.3144 157.6 32.6038 154.493 34.8456L125.428 61.4502L115.636 70.9894L110.602 74.711C107.469 77.0227 103.83 78.6487 99.948 79.4743C96.848 80.1284 93.6469 80.2618 90.4999 79.8617L87.9526 79.5442C85.6007 79.2457 83.3027 78.655 81.1192 77.7849L80.0411 77.353C77.3387 76.286 74.9058 74.6919 72.8841 72.6915C71.2332 71.0464 69.8853 69.1474 68.9082 67.0771L68.8474 66.9563C67.5941 64.3017 66.947 61.4246 66.947 58.5158V55.5309L77.0018 46.7983L78.1676 56.0072C78.4101 57.9315 79.0706 59.7861 80.1016 61.4565C82.1435 64.778 85.5805 67.1914 89.5295 68.1186C91.376 68.5569 93.3236 68.6712 95.2104 68.4363L95.3183 68.4235C98.2228 68.0743 100.965 66.9691 103.243 65.2354L104.524 64.2572L121.459 48.5448L125.381 45.1851C126.648 44.1055 127.827 42.9305 128.912 41.6858L129.33 41.2093C131.817 38.3578 133.717 35.1124 134.957 31.6194V2.90631C134.957 1.8076 135.9 0.912109 137.073 0.912109H144.479C145.652 0.912109 146.602 1.8076 146.602 2.90631V26.4624L147.444 26.1447C152.324 22.6325 158.227 20.6067 164.42 20.3271C170.613 20.0415 176.813 21.515 182.231 24.5569C187.648 27.5928 192.049 32.0702 194.867 37.405C197.685 42.7463 198.803 48.7098 198.075 54.5528Z" fill="url(#paint3_linear_386_4644)"/>
    <path d="M77.8056 23.3555V36.7944C77.8056 36.9022 77.7786 37.1818 77.7517 37.3024C77.0979 40.2367 75.5008 42.9104 73.1825 44.9682L59.9067 56.743C59.1385 57.4289 58.4444 58.1846 57.8378 58.9976C56.0251 61.449 55.048 64.3641 55.048 67.3492V78.5078C55.048 78.9017 54.7111 79.2192 54.2932 79.2192H44.9394C44.0902 79.2192 43.4028 78.5714 43.4028 77.7711V66.1551C43.4028 63.9069 43.7533 61.665 44.4407 59.5056L44.6766 58.7625C45.4045 56.4634 46.5972 54.3104 48.181 52.4179C49.2255 51.1731 50.4316 50.049 51.7728 49.0837L54.9467 46.7973C57.4807 44.9745 59.8259 42.9358 61.9622 40.7067L65.6281 36.0768L66.1606 23.3555C66.1606 22.2568 67.1108 21.3613 68.2767 21.3613H75.6895C76.8554 21.3613 77.8056 22.2568 77.8056 23.3555Z" fill="url(#paint4_linear_386_4644)"/>
    <defs>
    <linearGradient id="paint0_linear_386_4644" x1="-11.3091" y1="-40.6542" x2="224.225" y2="124.534" gradientUnits="userSpaceOnUse">
    <stop stopColor="#28BDCA"/>
    <stop offset="1" stopColor="#3687C8"/>
    </linearGradient>
    <linearGradient id="paint1_linear_386_4644" x1="-16.6613" y1="26.0618" x2="37.1591" y2="63.8079" gradientUnits="userSpaceOnUse">
    <stop stopColor="#28BDCA"/>
    <stop offset="1" stopColor="#3687C8"/>
    </linearGradient>
    <linearGradient id="paint2_linear_386_4644" x1="-11.3091" y1="-40.6542" x2="224.225" y2="124.534" gradientUnits="userSpaceOnUse">
    <stop stopColor="#28BDCA"/>
    <stop offset="1" stopColor="#3687C8"/>
    </linearGradient>
    <linearGradient id="paint3_linear_386_4644" x1="88.7225" y1="24.4792" x2="178.827" y2="87.6729" gradientUnits="userSpaceOnUse">
    <stop stopColor="#28BDCA"/>
    <stop offset="1" stopColor="#3687C8"/>
    </linearGradient>
    <linearGradient id="paint4_linear_386_4644" x1="50.9684" y1="44.4162" x2="69.829" y2="57.6437" gradientUnits="userSpaceOnUse">
    <stop stopColor="#28BDCA"/>
    <stop offset="1" stopColor="#3687C8"/>
    </linearGradient>
    </defs>
    </svg>
  );
}
export default memo(ColorfulLogoIcon);
