import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function SupportIcon({ className, color }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1169 3.03039e-05H9.61693C8.14208 -0.0037243 6.68472 0.341464 5.36607 1.00752C2.15179 2.61395 0.118318 5.90212 0.116926 9.49973L0.123219 9.86808C0.163813 10.9716 0.399414 12.0594 0.81816 13.0806L0.923926 13.3251L0.228596 15.0032C-0.0843566 15.7583 -0.0756534 16.6084 0.252693 17.3569L0.328166 17.5163C1.04434 18.9218 2.74201 19.5409 4.20512 18.8991L5.80293 18.1971L6.03643 18.2989C7.17113 18.7642 8.38839 19.0033 9.61953 19.0001C13.2149 18.9987 16.5031 16.9652 18.1114 13.7472C18.7756 12.4323 19.1208 10.9749 19.117 9.49755L19.117 9.00011C18.8542 4.21065 15.1382 0.410857 10.4391 0.0200158L10.1169 3.03039e-05ZM9.61438 2.00003L10.0749 1.99911L10.0618 1.99851C13.8687 2.20861 16.9084 5.24834 17.1185 9.05521L17.117 9.50011C17.12 10.6651 16.8484 11.8117 16.3243 12.8493C15.0527 15.3936 12.4568 16.999 9.61654 17.0001C8.45195 17.0031 7.30531 16.7316 6.26778 16.2075C6.0013 16.0729 5.68862 16.0644 5.41522 16.1843L3.40171 17.0676C2.89594 17.2894 2.30609 17.0593 2.08423 16.5535C1.97478 16.304 1.97188 16.0206 2.0762 15.7689L2.94073 13.683C3.05229 13.4138 3.0409 13.1094 2.90952 12.8493C2.38547 11.8117 2.11389 10.6651 2.11692 9.50272C2.11802 6.66029 3.7234 4.06436 6.26398 2.79462C7.30528 2.26867 8.45194 1.99707 9.61438 2.00003Z"
        fill={color ? color : '#50B8E7'}
      />
    </svg>
  );
}
export default memo(SupportIcon);
