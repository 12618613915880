import { verifyEmail } from "api/User";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useMutation } from "react-query";

export const useVerifyEmailExist = () => {
  const { nextFormStep } = useMultiStepFormContext();
  return useMutation(verifyEmail, {
    onSuccess: (res) => {
      return nextFormStep();
    },
    onError: (error) => {
      console.error(error);
    },
  });
};
