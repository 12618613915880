import { useInfiniteQuery } from "react-query";
import { toast } from "react-toastify";
import { ILead, PaginationParams } from "lib/types/leads.types";
import { usePagination } from "lib/hooks/shared/usePagination";
import { getEmploye } from "api/Employee";


export const useEmployees = () => {
  
  const {
    currentPage,
    handleItemsPerPageChange,
    handleNextPage, 
    handlePreviousPage,
    itemsPerPage
  } = usePagination(1, 10);


  const { data, isLoading, hasNextPage, hasPreviousPage } = useInfiniteQuery<Array<ILead> | any>(
    ["customers", currentPage, itemsPerPage,],
    ({ pageParam = currentPage }) => {
      return getEmploye({ page: pageParam, number_per_page: itemsPerPage,
      } as PaginationParams);
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data?.next_page_url
          ? new URL(lastPage.data.next_page_url).searchParams.get("page")
          : false;
      },
      getPreviousPageParam: (firstPage, allPages) => {
        const currentPage = allPages[allPages.length - 1];
        const prevPageUrl = currentPage?.data?.prev_page_url;
        return prevPageUrl
          ? new URL(prevPageUrl).searchParams.get("page")
          : false;
      },
      onError: () => {
        toast.error(`Something went wrong`);
      }
    }
  );

  return {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    itemsPerPage,
    handleNextPage,
    handlePreviousPage,
    handleItemsPerPageChange
  };
};
