import { useForm, UseFormProps } from "react-hook-form";
import * as YUP from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const LeadsSchema = YUP.object().shape({
  first_name: YUP.string().required("Please enter first name"),
  last_name: YUP.string().required("Please enter last name"),
  nationality: YUP.string().optional(),
  birthdate: YUP.string().required("Please enter date of birth"),
  language: YUP.string().required("Please enter language"),
  health_insurance: YUP.string().optional(),
  code: YUP.string().optional(),
phone: YUP.number()
  .typeError("Phone number is required")
  .required("Please enter phone number")
  .min(6, "Phone number is too short"),
  email: YUP.string()
    .optional()
    .email("Enter a valid email"),
  address: YUP.string().required("Please enter street"),
  nr: YUP.number()
  .transform((value, originalValue) => {
    const numericValue = Number(originalValue);
    return isNaN(numericValue) ? undefined : numericValue;
  })
  .min(1, "At least 1 character")
  .required("Please enter house number"),
  postal_code: YUP.number()
    .transform((value, originalValue) => {
      return originalValue === "" || isNaN(Number(originalValue)) ? undefined : value;
    })
  .min(1)
  .required("Please enter postal code"),  city: YUP.string().required("Please enter city"),
  notes: YUP.string().optional(),
});

export function useLeadsForm(options?: UseFormProps<any>) {
  return useForm<any | any>({
    mode: "onSubmit",
    ...options,
    resolver: yupResolver(LeadsSchema),
  });
}

export type LeadsSchemaType = ReturnType<typeof useLeadsForm>;
