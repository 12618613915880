import React, { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}
function CommentIcon({ className }: Props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.905 9.07513C1.93227 8.97476 1.872 8.83519 1.81448 8.73459C1.79657 8.70459 1.77714 8.67553 1.75627 8.64751C1.26294 7.89934 0.999991 7.02285 1.00002 6.12667C0.992002 3.55428 3.12509 1.4668 5.76279 1.4668C8.06317 1.4668 9.98336 3.06042 10.4321 5.17586C10.4993 5.4895 10.5332 5.80935 10.5334 6.13011C10.5334 8.70617 8.48254 10.8264 5.84484 10.8264C5.42546 10.8264 4.85942 10.721 4.55073 10.6346C4.24204 10.5482 3.93381 10.4336 3.85429 10.4029C3.77296 10.3717 3.68659 10.3556 3.59946 10.3555C3.50429 10.3551 3.41004 10.3741 3.3224 10.4112L1.76796 10.9722C1.73391 10.9868 1.69782 10.9963 1.66094 11.0001C1.63183 11 1.60304 10.9942 1.57621 10.9829C1.54939 10.9716 1.52507 10.9551 1.50468 10.9343C1.48428 10.9136 1.4682 10.889 1.45738 10.8619C1.44656 10.8349 1.4412 10.806 1.44163 10.7769C1.44354 10.7514 1.44815 10.7261 1.45538 10.7015L1.905 9.07513Z"
        stroke="#282D46"
        strokeOpacity="0.5"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default memo(CommentIcon);
