import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function ProductsIcon({ className, color }: Props) {
  return (
    <svg
      width="27"
      height="22"
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.9998 4.57812L11.0404 4.38056L10.0244 6.74258H3.94727H3.44727V7.24258V16.5868V17.0868H3.94727H11.4998H11.9998V16.5868V4.57812ZM6.63926 14.2847H6.23311V13.8785H6.63926V14.2847Z"
        stroke={color || "#282D46"}
      />
      <path
        d="M20.8611 16.0613L23.1565 13.7659L23.4437 14.0531L20.5075 16.9892L18.9687 15.4504L19.2558 15.1632L20.154 16.0613L20.5075 16.4149L20.8611 16.0613ZM21.2602 10.1504C18.37 10.1504 16.0195 12.5009 16.0195 15.3911C16.0195 18.2813 18.37 20.6318 21.2602 20.6318C24.1505 20.6318 26.501 18.2813 26.501 15.3911C26.501 12.5009 24.1505 10.1504 21.2602 10.1504Z"
        stroke={color || "#282D46"}
      />
      <path
        d="M11.5945 2.06476L11.8946 1.36719H11.1352H3.70734H3.37812L3.24803 1.66961L1.54069 5.63859L1.24062 6.33617H2H9.42795H9.75717L9.88726 6.03374L11.5945 2.06476Z"
        stroke={color || "#282D46"}
      />
      <path
        d="M20.5236 9.79232L20.9588 9.73545V9.29653V7.24263V6.74263H20.4588H14.3816L13.3656 4.38055L12.4062 4.57812V16.5869V17.0869H12.9062H15.2298H15.8384L15.7202 16.4898C15.6499 16.1344 15.6126 15.7672 15.6126 15.3913C15.6127 12.5278 17.7566 10.1538 20.5236 9.79232Z"
        stroke={color || "#282D46"}
      />
      <path
        d="M13.2715 1.36719H12.5121L12.8122 2.06477L14.5195 6.03375L14.6496 6.33617H14.9788H22.4067H23.166L22.866 5.6386L21.1587 1.66962L21.0287 1.36719H20.6994H13.2715Z"
        stroke={color || "#282D46"}
      />
    </svg>
  );
}
export default memo(ProductsIcon);
