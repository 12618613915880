import { memo } from "react";
interface Props {
  className?: string;
}
const EmojiIcon = ({ className }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.1875 10C7.70527 10 8.125 9.58027 8.125 9.0625C8.125 8.54473 7.70527 8.125 7.1875 8.125C6.66973 8.125 6.25 8.54473 6.25 9.0625C6.25 9.58027 6.66973 10 7.1875 10Z"
        fill="#282D46"
        fillOpacity="0.5"
      />
      <path
        d="M10.0019 15C8.22764 15 6.73546 13.8465 6.26319 12.2723C6.24998 12.2255 6.2479 12.1762 6.25711 12.1284C6.26632 12.0806 6.28656 12.0356 6.31623 11.997C6.3459 11.9584 6.38418 11.9273 6.428 11.9061C6.47183 11.885 6.51999 11.8743 6.56866 11.875H13.4316C13.4802 11.8743 13.5284 11.885 13.5722 11.9061C13.616 11.9273 13.6543 11.9584 13.684 11.997C13.7136 12.0356 13.7339 12.0806 13.7431 12.1284C13.7523 12.1762 13.7502 12.2255 13.737 12.2723C13.2687 13.8465 11.7761 15 10.0019 15Z"
        fill="#282D46"
        fillOpacity="0.5"
      />
      <path
        d="M12.8125 10C13.3303 10 13.75 9.58027 13.75 9.0625C13.75 8.54473 13.3303 8.125 12.8125 8.125C12.2947 8.125 11.875 8.54473 11.875 9.0625C11.875 9.58027 12.2947 10 12.8125 10Z"
        fill="#282D46"
        fillOpacity="0.5"
      />
      <path
        d="M10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125Z"
        stroke="#282D46"
        strokeOpacity="0.5"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default memo(EmojiIcon);
