import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function InsuranceIcon({ className, color }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9948 7.03634C25.2255 18.7713 17.6893 23.7636 13.4164 24.9413C13.1334 25.0196 12.8319 25.0196 12.5489 24.9413C8.34611 23.7841 1.02584 18.9393 1 7.62094C1.02531 6.64287 1.61756 5.75381 2.54859 5.29624C9.05303 1.88462 12.1816 1 12.9771 1C13.7726 1 17.1485 1.9412 24.0849 5.62883C24.634 5.91627 24.9797 6.45101 24.9948 7.03634Z"
        stroke={color || '#7D8592'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.46094 13.0265L11.1524 16.4552L18.5355 9.58398"
        stroke={color || '#7D8592'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default memo(InsuranceIcon);
