import { dataOfCsv } from "api/Appointment";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useMutation } from "react-query";

export const useUploadAppointmentDataOfCsv = () => {
    const { updateFormData } = useMultiStepFormContext();
    return useMutation(dataOfCsv, {
        onSuccess: (res) => {
            updateFormData(res)
        },
        onError: (error) => {
            console.error(error);
        }
    });
};
