import { FC, useEffect } from "react";
import Icon from "../../../atoms/icon/Icon";
import { IconType } from "../../../../lib/helpers/constants/iconTypes";
import Paragraph from "../../../atoms/paragraph/Paragraph";
import DefaultContainer from "../../../templates/default-container/DefaultContainer";
import { useEmailVerification } from "lib/hooks/mutations/EmailVerification/useEmailVerification";
import { useParams } from "react-router-dom";

export interface ICongratulationsStepProps {}

const EmailVerificationResponse: FC<ICongratulationsStepProps> = () => {
  const { id: token } = useParams<{ id: string }>();
  const { mutate: emailVerification, isSuccess } = useEmailVerification();

  useEffect(() => {
    emailVerification({ token: token });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="flex justify-between h-full flex-col">
      <DefaultContainer className="m-auto">
        <div className="text-center flex flex-col gap-[60px]">
          <h1 className="text-[#282D46] font-inter-bold text-[18px] mt-[40px]">
            <span className="text-[#282D46] font-inter-bold text-[25px] block">
              {isSuccess ?? "Congratulations"}
            </span>
            Email Verified
          </h1>
          <Icon iconType={IconType.CONGRATS_ICON} className="mx-auto" />
          <Paragraph
            className="text-[#282D46] font-inter-regular text-[14px]"
            paragraph="Now you can complete entering the company information."
          />
        </div>
      </DefaultContainer>
    </div>
  );
};

export default EmailVerificationResponse;
