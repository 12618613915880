import { useQuery } from "react-query"
import { getStatisticInfoCards } from "api/Customers";


export const useStatisticInfoCard = () => {
    return useQuery<Array<any> | any>(
      'coustomers',
        () => getStatisticInfoCards(),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}