import {
  InputHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
import { DeepMap, FieldError } from "react-hook-form";
import Label from "./input-label/InputLabel";
import ErrorMessage from "../error-message/ErrorMessage";
import { IconType } from "../../../lib/helpers/constants/iconTypes";
import Icon from "../icon/Icon";
import Button from "../button/Button";


type TypeInputClass = {
  container?: string;
  icon?: string;
  label?: string;
  fillcolor?: string;
  errorClassName?: string;
};

export interface IInputFieldProps
  extends InputHTMLAttributes<HTMLInputElement> {
  error?: DeepMap<any, FieldError>;
  label?: string;
  icon?: IconType;
  childrenIcon?: IconType;
  toggleGeneratePassword?: () => void;
  toggleShowPassowrd?: () => void;
  classes?: Partial<TypeInputClass>;
  as?: "input" | "textarea";
}

const InputField: ForwardRefRenderFunction<
  HTMLInputElement,
  IInputFieldProps
> = (
  {
    toggleShowPassowrd,
    toggleGeneratePassword,
    classes = {
      errorClassName: "",
      container: "",
      icon: "",
      label: "",
      fillcolor: "",
    },
    error,
    childrenIcon,
    label,
    as = "input",
    ...rest
  },
  ref
) => {


    return (
      <div className={`relative ${classes?.container}`}>
        {label && <Label text={label} className={classes.label} />}
        <input
          ref={ref}
          {...rest}
          className={`border-[1px] border-inputborder rounded-[8px] h-[40px] 2xl:h-[46px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular ${rest.className}`}
        />
        <ErrorMessage error={error} className={classes.errorClassName} />
        {rest.icon ? (
          <Button type="button" className="button" onClick={toggleShowPassowrd}>
            <Icon
              iconType={rest.icon}
              className={`absolute right-3 bg-white top-[55%] cursor-pointer transform -translate-y-1/1 ${classes?.icon}`}
              color={classes?.fillcolor}
            />
          </Button>
        ) : null}
        {childrenIcon ? (
          <Button
            type="button"
            className="button"
            onClick={toggleGeneratePassword}
          >
            <Icon
              iconType={childrenIcon}
              className={`absolute bg-white cursor-pointer right-[40px] top-[55%] transform -translate-y-1/1 ${classes?.icon}`}
              color={classes?.fillcolor}
            />
          </Button>
        ) : null}
      </div>
    );
  };

export default forwardRef(InputField);
