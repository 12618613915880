import { exportCustomer } from "api/Customers"
import { useMutation } from "react-query"
import { toast } from "react-toastify"



export const useExportCustomer = () => {
    return useMutation(exportCustomer,{
        onSuccess: () => {
         toast.success('Exported  Successfully')
        },
        onError: () => {
            toast.error('Export Failed')
        }
    })
}