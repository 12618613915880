import { useQuery } from "react-query"
import { getStatisticCardsData } from "api/Appointment";


export const useAppointmentsStatisticCard = () => {
    return useQuery<Array<any> | any>(
      ['appointments'],
        () => getStatisticCardsData(),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}