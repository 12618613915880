import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function RateStarIcon({ className, color }: Props) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill={color || "#6C737F"}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.40154 1.87781C8.59354 1.48864 8.68963 1.29414 8.81996 1.23197C8.87597 1.20527 8.93724 1.19141 8.99929 1.19141C9.06135 1.19141 9.12261 1.20527 9.17863 1.23197C9.30904 1.29414 9.40504 1.48864 9.59713 1.87781L11.4194 5.56947C11.476 5.68431 11.5044 5.74181 11.5458 5.78639C11.5825 5.82584 11.6265 5.85782 11.6754 5.88056C11.7306 5.90622 11.794 5.91556 11.9207 5.93406L15.9968 6.52989C16.426 6.59256 16.6406 6.62389 16.74 6.72872C16.7826 6.77377 16.8147 6.82778 16.8338 6.8868C16.853 6.94583 16.8587 7.00838 16.8505 7.06989C16.8316 7.21306 16.6763 7.36439 16.3655 7.66706L13.4171 10.5387C13.3253 10.6282 13.2793 10.6731 13.2496 10.7263C13.2234 10.7734 13.2065 10.8252 13.2 10.8788C13.1927 10.9393 13.2035 11.0026 13.2252 11.129L13.921 15.1851C13.9943 15.613 14.031 15.8269 13.962 15.9538C13.9324 16.0083 13.891 16.0556 13.8408 16.092C13.7906 16.1285 13.7329 16.1533 13.6719 16.1646C13.5298 16.191 13.3377 16.0899 12.9535 15.8879L9.30963 13.9716C9.19604 13.9119 9.13929 13.8821 9.07946 13.8703C9.02652 13.8599 8.97206 13.8599 8.91913 13.8703C8.85929 13.8821 8.80254 13.9119 8.68904 13.9716L5.04504 15.8879C4.66088 16.0899 4.46879 16.191 4.32671 16.1646C4.2657 16.1533 4.20799 16.1285 4.15778 16.092C4.10758 16.0556 4.06616 16.0083 4.03654 15.9538C3.96763 15.8269 4.00429 15.613 4.07771 15.1851L4.77338 11.129C4.79504 11.0026 4.80588 10.9393 4.79854 10.8788C4.79206 10.8252 4.77522 10.7734 4.74896 10.7263C4.71929 10.6731 4.67338 10.6282 4.58146 10.5387L1.63313 7.66706C1.32229 7.36439 1.16696 7.21306 1.14813 7.06989C1.13999 7.00838 1.1457 6.94583 1.16483 6.8868C1.18396 6.82778 1.21604 6.77377 1.25871 6.72872C1.35796 6.62389 1.57263 6.59256 2.00179 6.52981L6.07788 5.93397C6.20463 5.91547 6.26804 5.90622 6.32321 5.88064C6.37209 5.8579 6.4161 5.82589 6.45279 5.78639C6.49421 5.74181 6.52254 5.68431 6.57929 5.56947L8.40154 1.87781Z"
        fill={color || "#6C737F"}
        stroke={color || "#6C737F"}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default memo(RateStarIcon);
