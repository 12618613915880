import { memo } from "react";
interface Props {
  className?: string;
  color?:string;
}
function PhoneIcon({ className, color }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.28538 12.0783C8.27932 14.1103 9.92648 15.75 11.963 16.7347C12.112 16.8053 12.2768 16.8359 12.4413 16.8234C12.6057 16.811 12.764 16.756 12.9007 16.6637L15.8994 14.6642C16.032 14.5757 16.1846 14.5218 16.3433 14.5072C16.502 14.4926 16.6619 14.5179 16.8084 14.5806L22.4184 16.9849C22.6089 17.0659 22.768 17.2065 22.8717 17.3858C22.9754 17.565 23.018 17.7731 22.9931 17.9786C22.8158 19.3661 22.1388 20.6414 21.0889 21.5657C20.039 22.49 18.6883 22.9999 17.2895 23C12.9692 23 8.82595 21.2838 5.77108 18.2289C2.71621 15.174 1 11.0308 1 6.71051C1.00007 5.31174 1.51 3.96096 2.43429 2.91108C3.35858 1.86121 4.63387 1.18423 6.02135 1.00692C6.22691 0.982043 6.43497 1.02464 6.61421 1.1283C6.79345 1.23196 6.93414 1.39106 7.01509 1.58163L9.42145 7.19646C9.48369 7.34169 9.50905 7.50006 9.49527 7.65746C9.4815 7.81487 9.42901 7.96643 9.34248 8.09864L7.34984 11.1433C7.25915 11.2803 7.20554 11.4384 7.19424 11.6023C7.18294 11.7662 7.21434 11.9302 7.28538 12.0783V12.0783Z"
        stroke={color || '#282D46'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default memo(PhoneIcon);
