import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function EventsIcon({ className, color }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.0469 10.6641H13.2188C13.6071 10.6641 13.9219 10.3493 13.9219 9.96094C13.9219 9.57262 13.6071 9.25781 13.2188 9.25781H12.0469C11.6586 9.25781 11.3438 9.57262 11.3438 9.96094C11.3438 10.3493 11.6586 10.6641 12.0469 10.6641Z"
        fill={color || '#7D8592'}
      />
      <path
        d="M9.04688 9.25781H7.875C7.48669 9.25781 7.17188 9.57262 7.17188 9.96094C7.17188 10.3493 7.48669 10.6641 7.875 10.6641H9.04688C9.43519 10.6641 9.75 10.3493 9.75 9.96094C9.75 9.57262 9.43519 9.25781 9.04688 9.25781Z"
        fill={color || '#7D8592'}
      />
      <path
        d="M16.2188 10.6641H17.3906C17.7789 10.6641 18.0938 10.3493 18.0938 9.96094C18.0938 9.57262 17.7789 9.25781 17.3906 9.25781H16.2188C15.8304 9.25781 15.5156 9.57262 15.5156 9.96094C15.5156 10.3493 15.8304 10.6641 16.2188 10.6641Z"
        fill={color || '#7D8592'}
      />
      <path
        d="M4.875 12.4219H3.70312C3.31481 12.4219 3 12.7367 3 13.125C3 13.5133 3.31481 13.8281 3.70312 13.8281H4.875C5.26331 13.8281 5.57812 13.5133 5.57812 13.125C5.57812 12.7367 5.26331 12.4219 4.875 12.4219Z"
        fill={color || '#7D8592'}
      />
      <path
        d="M9.04688 12.4219H7.875C7.48669 12.4219 7.17188 12.7367 7.17188 13.125C7.17188 13.5133 7.48669 13.8281 7.875 13.8281H9.04688C9.43519 13.8281 9.75 13.5133 9.75 13.125C9.75 12.7367 9.43519 12.4219 9.04688 12.4219Z"
        fill={color || '#7D8592'}
      />
      <path
        d="M13.9219 13.125C13.9219 12.7367 13.6071 12.4219 13.2188 12.4219H12.0469C11.6586 12.4219 11.3438 12.7367 11.3438 13.125C11.3438 13.5133 11.6586 13.8281 12.0469 13.8281H13.2188C13.6071 13.8281 13.9219 13.5133 13.9219 13.125Z"
        fill={color || '#7D8592'}
      />
      <path
        d="M4.875 15.5859H3.70312C3.31481 15.5859 3 15.9007 3 16.2891C3 16.6774 3.31481 16.9922 3.70312 16.9922H4.875C5.26331 16.9922 5.57812 16.6774 5.57812 16.2891C5.57812 15.9007 5.26331 15.5859 4.875 15.5859Z"
        fill={color || '#7D8592'}
      />
      <path
        d="M9.04688 15.5859H7.875C7.48669 15.5859 7.17188 15.9007 7.17188 16.2891C7.17188 16.6774 7.48669 16.9922 7.875 16.9922H9.04688C9.43519 16.9922 9.75 16.6774 9.75 16.2891C9.75 15.9007 9.43519 15.5859 9.04688 15.5859Z"
        fill={color || '#7D8592'}
      />
      <path
        d="M21.0938 13.0817V3.98438C21.0938 2.5628 19.9372 1.40625 18.5156 1.40625H17.1562V0.703125C17.1562 0.314812 16.8414 0 16.4531 0C16.0648 0 15.75 0.314812 15.75 0.703125V1.40625H13.2188V0.703125C13.2188 0.314812 12.9039 0 12.5156 0C12.1273 0 11.8125 0.314812 11.8125 0.703125V1.40625H9.28125V0.703125C9.28125 0.314812 8.96644 0 8.57812 0C8.18981 0 7.875 0.314812 7.875 0.703125V1.40625H5.34375V0.703125C5.34375 0.314812 5.02894 0 4.64062 0C4.25231 0 3.9375 0.314812 3.9375 0.703125V1.40625H2.57812C1.15655 1.40625 0 2.5628 0 3.98438V18.0469C0 19.21 0.946266 20.1562 2.10938 20.1562H12.6385C13.462 22.3971 15.6177 24 18.1406 24C21.3715 24 24 21.3715 24 18.1406C24 15.986 22.8308 14.0996 21.0938 13.0817ZM1.40625 3.98438C1.40625 3.3382 1.93195 2.8125 2.57812 2.8125H3.9375V3.51562C3.9375 3.90394 4.25231 4.21875 4.64062 4.21875C5.02894 4.21875 5.34375 3.90394 5.34375 3.51562V2.8125H7.875V3.51562C7.875 3.90394 8.18981 4.21875 8.57812 4.21875C8.96644 4.21875 9.28125 3.90394 9.28125 3.51562V2.8125H11.8125V3.51562C11.8125 3.90394 12.1273 4.21875 12.5156 4.21875C12.9039 4.21875 13.2188 3.90394 13.2188 3.51562V2.8125H15.75V3.51562C15.75 3.90394 16.0648 4.21875 16.4531 4.21875C16.8414 4.21875 17.1562 3.90394 17.1562 3.51562V2.8125H18.5156C19.1618 2.8125 19.6875 3.3382 19.6875 3.98438V6.09375H1.40625V3.98438ZM2.10938 18.75C1.72167 18.75 1.40625 18.4346 1.40625 18.0469V7.5H19.6875V12.4889C19.1945 12.3538 18.6759 12.2812 18.1406 12.2812C14.9098 12.2812 12.2812 14.9098 12.2812 18.1406C12.2812 18.3464 12.292 18.5497 12.3128 18.75H2.10938ZM18.1406 22.5938C15.6852 22.5938 13.6875 20.5961 13.6875 18.1406C13.6875 15.6852 15.6852 13.6875 18.1406 13.6875C20.5961 13.6875 22.5938 15.6852 22.5938 18.1406C22.5938 20.5961 20.5961 22.5938 18.1406 22.5938Z"
        fill={color || '#7D8592'}
      />
      <path
        d="M20.4844 17.4375H18.8438V15.7969C18.8438 15.4086 18.5289 15.0938 18.1406 15.0938C17.7523 15.0938 17.4375 15.4086 17.4375 15.7969V18.1406C17.4375 18.5289 17.7523 18.8438 18.1406 18.8438H20.4844C20.8727 18.8438 21.1875 18.5289 21.1875 18.1406C21.1875 17.7523 20.8727 17.4375 20.4844 17.4375Z"
        fill={color || '#7D8592'}
      />
    </svg>
  );
}
export default memo(EventsIcon);
