import Form from "components/atoms/form/Form";
import SuccesfulLoginIcon from "components/atoms/icon/icon-type/SuccesfulLoginIcon";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface IStep6Props {
  onConfirm: () => void;
  themeColors: {
    primary: string;
  };
}

const Step6: FC<IStep6Props> = ({ onConfirm, themeColors }) => {

  const {t} = useTranslation();

  return (
    <Form className="mb-0 flex flex-col justify-center items-center gap-7 pb-7">
      <div>
        <SuccesfulLoginIcon color={themeColors.primary} />
      </div>
      <div className="text-[20px] font-inter-bold leading-[17px]">
        {t('Congratulations!')}
      </div>
      <div className="text-[17px] font-inter-medium leading-[17px]">
        {t('Your leads have been uploaded successfully.')}
      </div>
      <div className="flex w-full px-20">
        <Link
          onClick={onConfirm}
          to={"/leads"}
          className={`bg-[--theme] block text-center text-[#FFFFFF] py-3  w-full rounded-[8px] text-[13px] font-inter-semibold leading-[22.75px]`}
        >
          {t('Go to Leads')}
        </Link>
      </div>
    </Form>
  );
};

export default Step6;
