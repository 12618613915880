import { updateResource } from "api/Documents";
import { queryClient } from "lib/helpers/configs/queryClient";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useUpdateResource = () => {

    const { t } = useTranslation()

    return useMutation(updateResource, {
        onSuccess: () => {
            queryClient.invalidateQueries("resources");

            toast.success(t("Updated successfully"));
        },
        onError: () => {
            toast.error(t("Failed to update"));
        },
    });
};
