import { sendAppointmentNote } from "api/Appointment";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useAppointmentNote = () => {

  

    return useMutation(sendAppointmentNote, {
        onSuccess: () => {
    
        },
        onError: () => {
            toast.error(`Something went wrong`);
        },
    });
};
