import React, { memo } from 'react'


interface Props {
    className?: string;
    color?: string;
}
function Check3Icon({ className , color}: Props) {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill={color || "#50B8E7"}  />
            <path d="M11.5241 17.1399C10.8414 17.1399 10.2315 17.0223 9.6946 16.7869C9.16098 16.5516 8.7384 16.2251 8.42685 15.8075C8.11861 15.3866 7.95289 14.8994 7.92969 14.3459H9.49077C9.51065 14.6475 9.61174 14.9093 9.79403 15.1314C9.97964 15.3501 10.2216 15.5192 10.5199 15.6385C10.8182 15.7578 11.1496 15.8175 11.5142 15.8175C11.9152 15.8175 12.2699 15.7479 12.5781 15.6087C12.8897 15.4695 13.1333 15.2756 13.3089 15.027C13.4846 14.7751 13.5724 14.4851 13.5724 14.157C13.5724 13.8156 13.4846 13.5156 13.3089 13.2571C13.1366 12.9953 12.883 12.7898 12.5483 12.6406C12.2169 12.4915 11.8158 12.4169 11.3452 12.4169H10.4851V11.1641H11.3452C11.723 11.1641 12.0545 11.0961 12.3395 10.9602C12.6278 10.8243 12.8532 10.6354 13.0156 10.3935C13.178 10.1482 13.2592 9.86151 13.2592 9.53338C13.2592 9.21851 13.188 8.94508 13.0455 8.71307C12.9062 8.47775 12.7074 8.2938 12.4489 8.16122C12.1937 8.02865 11.892 7.96236 11.544 7.96236C11.2126 7.96236 10.9027 8.02367 10.6143 8.14631C10.3293 8.26562 10.0973 8.43797 9.91832 8.66335C9.73935 8.88542 9.64323 9.15223 9.62997 9.46378H8.14347C8.16004 8.91359 8.32244 8.42969 8.63068 8.01207C8.94223 7.59446 9.35322 7.26799 9.86364 7.03267C10.3741 6.79735 10.9408 6.67969 11.5639 6.67969C12.2169 6.67969 12.7803 6.80729 13.2543 7.0625C13.7315 7.31439 14.0994 7.6508 14.358 8.07173C14.6198 8.49266 14.7491 8.95336 14.7457 9.45384C14.7491 10.0239 14.59 10.5078 14.2685 10.9055C13.9503 11.3033 13.526 11.5701 12.9957 11.706V11.7855C13.6719 11.8883 14.1955 12.1567 14.5668 12.5909C14.9413 13.0251 15.1269 13.5637 15.1236 14.2067C15.1269 14.7668 14.9711 15.2689 14.6562 15.7131C14.3447 16.1572 13.9188 16.5069 13.3786 16.7621C12.8383 17.014 12.2202 17.1399 11.5241 17.1399Z" fill="white" />
        </svg>
    )
}

export default memo(Check3Icon)