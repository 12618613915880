import { updateSourceStatus } from "api/Sources";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useUpdateSourceStatus = () => {
    const queryClient = useQueryClient();
    return useMutation(updateSourceStatus, {
        onSuccess: () => {
            toast.success("Source status updated successfully!");
            queryClient.invalidateQueries('sources')
        },
        onError: (err: any) => {
            if(err?.response?.data?.error === 'Unauthenticated'){
                toast.error('Unauthenticated')
            }else if(err?.response?.data?.message){
                toast.warning(err?.response?.data?.message)
            } else {
                toast.error("An unknown error occurred");
            }
        }
    })
}