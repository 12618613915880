import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Check2Icon from "components/atoms/icon/icon-type/Check2Icon";
import Check3Icon from "components/atoms/icon/icon-type/Check3Icon";
import CheckIcon from "components/atoms/icon/icon-type/CheckIcon";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import FileDropZone from "components/molecules/file-dropzone/FileDropZone";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useStoreAdditionalCampaign } from "lib/hooks/mutations/Campaign/useStoreAdditionalCampaign";
import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface IProps<T> {
  setStep: (args: number) => void;
  handleFormValuesChange: (step: number, values: any) => void;
  formValues: any;
  themeColors: {
    primary: string;
  };
  id: T;
}

const UploadCSV: FC<IProps<any>> = ({
  setStep,
  handleFormValuesChange,
  formValues,
  themeColors,
  id
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const { mutateAsync: createCampaign, isLoading } = useStoreAdditionalCampaign();
  const [resetCount, setResetCount] = useState<number>(0);


  const onSubmit = async (data: Record<string, any>): Promise<void> => {
    handleFormValuesChange(3, data);
    const formData = new FormData();
    if (data.file) {
      formData.append("file", data.file[0] as FileList[0]);
    }
    for (const key in formValues) {
      formData.append(key, formValues[key]);
    }

    createCampaign({
      campaing_id: id?.id,
      file: data.file[0],
    })
      .then(() => {
        setStep(2);
      })
      .catch(() => { });
  };

  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mb-0 flex flex-col pl-4">
      <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5">
          {t('Upload CSV')}
        </div>

        <Controller
          control={control}
          name="file"
          rules={{ required: t("File is required") }}
          render={({ field: { onChange, value } }) => (
            <FileDropZone
              key={`file-dropzone-${resetCount}`}
              formats={".csv"}
              file={value}
              themesColor={themeColors}
              setFile={onChange}
              label=""
              classes={{ label: "bg-white" }}
              name="file"
              error={errors.file}
              control={control}
            />
          )}
        />
        <div className="absolute top-[0] left-[-12px] z-9999">
          <CheckIcon color={themeColors.primary} />
        </div>
        {isLoading && <LoadingScreen className="!h-[250px] !top-[40px]" />}
      </div>
      <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5 opacity-40">
          {t('Only adjust the fields you want to import')}
        </div>

        <div className="absolute top-[0] left-[-12px] z-9999 opacity-40">
          <Check2Icon color={themeColors.primary} />
        </div>
      </div>
      <div className="pl-7 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5 opacity-40">
          {t("Remove the leads here that you don't want to import")}
        </div>

        <div className="absolute top-[0] left-[-12px] z-9999 opacity-40">
          <Check3Icon color={themeColors.primary} />
        </div>
      </div>
      <div className="flex gap-5 justify-end">
        <IconButton
          type="button"
          onClick={() => {
            reset();
            handleFormValuesChange(3, {});
            setResetCount((prevCount) => prevCount + 1);
          }}
          secondary={true}
          className={`w-full max-w-[150px] text-[${themeColors.primary}]`}
        >
          {t('Reset')}
        </IconButton>

        <IconButton
          disabled={isLoading ? true : false}
          icon={<Icon iconType={IconType.UPLOAD_FILE_ICON} />}
          className={`w-full max-w-[150px] bg-[${themeColors.primary}] border-[${themeColors.primary}]`}
        >
          {isLoading ? t("Creating...") : t("Continue")}
        </IconButton>
      </div>
    </Form>
  );
};

export default UploadCSV;
