import { FC } from "react";
import FormStepNavigationButtons from "../../../molecules/form-step-navigation-buttons/FormStepNavigationButtons";
import Form from "../../../atoms/form/Form";
import Accordion from "components/molecules/accordion/Accordion";
import AccordionTitle from "components/molecules/accordion/AccordionTitle/AccordionTitle";
import AccordionBody from "components/molecules/accordion/AccordionBody/AccordionBody";
import { frequentlyQuestions } from "lib/helpers/constants/frequentlyQuestions";
import { Fragment } from "react/jsx-runtime";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";

export interface IPlanCostOverviewStepProps {}

const PlanCostOverviewStep: FC<IPlanCostOverviewStepProps> = () => {
  const { nextFormStep, currentStep } = useSignupCustomHooks({
    initalStep: undefined,
  });

  return (
    <Fragment>
      <Form className="flex justify-between flex-col h-full">
        <div>
          <h1 className="text-bluebutton uppercase font-bold text-[14px] text-center mt-[40px] mb-[8px]">
            STEP {currentStep}/6
          </h1>
          <span className="text-[#282D46] font-inter-bold text-[25px] flex justify-center">
            Plan cost overview
          </span>
        </div>
        <div className="p-[40px] w-full">
          <h1 className="text-[#131523] font-inter-semibold px-4 py-2 mb-[32px]">
            Choosen modules
          </h1>
          <div className="w-full">
            <div className="border-b-2 border-[#E6E9F4] grid grid-cols-[400px,auto,auto,auto] 2xl:grid-cols-[600px,auto,auto,auto]">
              <div className="px-4 py-2">Name</div>
              <div className="px-4 py-2 pr-0">Price per user</div>
              <div className="px-4 py-2 pl-0">Users</div>
              <div className="px-4 py-2">Total price</div>
            </div>
            <div className="text-left text-[14px] font-inter-medium text-[#131523]">
              <div className="border-b-[1px] border-[#E6E9F4] grid grid-cols-[400px,auto,auto,auto] 2xl:grid-cols-[600px,auto,auto,auto]">
                <div className="px-4 py-4">Client Management</div>
                <div className="px-4 py-4">CHF9.6</div>
                <div className="px-4 py-4">13</div>
                <div className="px-4 py-4">CHF124</div>
              </div>
              <div className="border-b-[1px] border-[#E6E9F4] grid grid-cols-[400px,auto,auto,auto] 2xl:grid-cols-[600px,auto,auto,auto]">
                <div className="px-4 py-4">Client Management</div>
                <div className="px-4 py-4">CHF9.6</div>
                <div className="px-4 py-4">13</div>
                <div className="px-4 py-4">CHF124</div>
              </div>
              <div className="grid grid-cols-[400px,auto,auto,auto] 2xl:grid-cols-[600px,auto,auto,auto]">
                <div className="px-4 py-4">Client Management</div>
                <div className="px-4 py-4">CHF9.6</div>
                <div className="px-4 py-4">13</div>
                <div className="px-4 py-4">CHF124</div>
              </div>
            </div>
            <div className="text-left mt-[22px] text-[#282D46] font-inter-regular text-[14px] bg-[#F5F6F7] rounded-[8px]">
              <div className="grid  grid-cols-[400px,auto,auto,auto] 2xl:grid-cols-[600px,auto,auto,auto]">
                <div className="px-4 py-4">Total</div>
                <div className="px-4 py-4">CHF120</div>
                <div className="px-4 py-4">92</div>
                <div className="px-4 py-4">CHF1260</div>
              </div>
            </div>
          </div>
          <div className="mt-[42px]">
            <h1 className="text-[#131523] font-inter-semibold px-4 py-2 mb-[32px]">
              Chosen functionnalities{" "}
            </h1>
            <div className="w-full">
              <div className="border-b-2 border-[#E6E9F4] grid grid-cols-[400px,auto,auto,auto] 2xl:grid-cols-[600px,auto,auto,auto]">
                <div className="px-4 py-2">Name</div>
                <div className="px-4 py-2 pr-0">Price per user</div>
                <div className="px-4 py-2 pl-0">Users</div>
                <div className="px-4 py-2">Total price</div>
              </div>
              <div className="text-left text-[14px] font-inter-medium text-[#131523]">
                <div className="border-b-[1px] border-[#E6E9F4] grid grid-cols-[400px,auto,auto,auto] 2xl:grid-cols-[600px,auto,auto,auto]">
                  <div className="px-4 py-4">Client Management</div>
                  <div className="px-4 py-4">CHF9.6</div>
                  <div className="px-4 py-4">13</div>
                  <div className="px-4 py-4">CHF124</div>
                </div>
                <div className="border-b-[1px] border-[#E6E9F4] grid grid-cols-[400px,auto,auto,auto] 2xl:grid-cols-[600px,auto,auto,auto]">
                  <div className="px-4 py-4">Client Management</div>
                  <div className="px-4 py-4">CHF9.6</div>
                  <div className="px-4 py-4">13</div>
                  <div className="px-4 py-4">CHF124</div>
                </div>
                <div className="grid grid-cols-[400px,auto,auto,auto] 2xl:grid-cols-[600px,auto,auto,auto]">
                  <div className="px-4 py-4">Client Management</div>
                  <div className="px-4 py-4">CHF9.6</div>
                  <div className="px-4 py-4">13</div>
                  <div className="px-4 py-4">CHF124</div>
                </div>
              </div>
              <div className="text-left mt-[22px] text-[#282D46] font-inter-regular text-[14px] bg-[#F5F6F7] rounded-[8px]">
                <div className="grid  grid-cols-[400px,auto,auto,auto] 2xl:grid-cols-[600px,auto,auto,auto]">
                  <div className="px-4 py-4">Total</div>
                  <div className="px-4 py-4">CHF120</div>
                  <div className="px-4 py-4">92</div>
                  <div className="px-4 py-4">CHF1260</div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[42px]">
            <h1 className="text-[#131523] font-inter-semibold px-4 py-2 mb-[32px]">
              Total Cost{" "}
            </h1>
            <div className="w-full">
              <div className="text-left mt-[22px] text-[#282D46] font-inter-regular text-[14px] bg-[#F5F6F7] rounded-[8px]">
                <div className="grid grid-cols-[80%,auto]">
                  <div className="px-4 py-4">Total</div>
                  <div className="px-4 py-4">CHF1206</div>
                </div>
              </div>
              <div className="text-left text-[14px] font-inter-medium text-[#131523]">
                <div className="border-b-[1px] border-[#E6E9F4] grid grid-cols-[80%,auto]">
                  <div className="px-4 py-4 flex items-center gap-[70px]  font-inter-medium ">
                    <span className="text-[#50B8E7] bg-[#F2F4F7] rounded-full flex items-center justify-center w-[24px] h-[24px] text-[12px]">
                      5
                    </span>
                    <span className="text-[14px] text-[#282D46]">Modules</span>
                  </div>
                  <div className="px-4 py-4">CHF162</div>
                </div>
                {/* <div className="grid grid-cols-[100px,100px]">
                  <h1>TOTAL</h1>
                  <h1>TVA</h1>
                  <h1>TOTAL DUE</h1>
                  <h1>CHF28300</h1>
                  <h1>9%</h1>
                  <h1>CHF31800</h1>
                </div> */}
              </div>
            </div>
          </div>
          <div>
            <h1 className="text-[20px] text-[#131523] mb-[35px] mt-[150px]">
              Frequently Asked Questions
            </h1>
            {frequentlyQuestions.map((item) => {
              return (
                <Accordion
                  key={`frequently-question-key-${item.id}`}
                  className="border-none p-0"
                  classes={{
                    body: "border-none p-0 !m-0 !pl-5 !pb-2",
                    wrapper: "border-none p-0",
                    title:
                      "border-t-[1px]  border-[#E6E9F4] p-5 w-full flex items-center justify-between",
                  }}
                >
                  <AccordionTitle>{item.title}</AccordionTitle>
                  <AccordionBody>{item.label}</AccordionBody>
                </Accordion>
              );
            })}
          </div>
        </div>
        <FormStepNavigationButtons
          onDraftButton={true}
          onNextStep={nextFormStep}
        />
      </Form>
    </Fragment>
  );
};

export default PlanCostOverviewStep;
