import {  updateCustomerProduct } from "api/Customers";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useUpdateCustomerProduct = (
options?: UseMutationOptions<any, any, any, unknown>
) => {
    const queryClient = useQueryClient();
    return useMutation(updateCustomerProduct, {
        onSuccess: () => {
            toast.success("Customer Product updated successfully!");
            queryClient.invalidateQueries('customerProducts')
            queryClient.invalidateQueries('coustomers')
        },
        onError: () => {
            toast.error("Customer update failed! Please try again later.");
        },
        ...options
    })
}