import { checkEmailVerification } from "api/User"
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useMutation } from "react-query"



export const useCheckEmailVerification = () => {
    const {nextFormStep} = useMultiStepFormContext();
    return useMutation(checkEmailVerification,{
        onSuccess:() => {
        nextFormStep();
        },
        onError: (err) => {
            console.error(err)
        }
    })
}