import { HTMLAttributes, ComponentType } from "react";
import {
  ICurrentPlanSummaryCardProps,
  IInfoCardProps,
  IAppointmentPDFCardViewerProps,
  ISecurityCardProps,
  CardType,
  CurrentPlanSummaryCard,
  InfoCard,
  AppointmentPDFCardViewer,
  LeadCard,
  SourceCard,
  CampaignCard,
  SecurityCard,
  ICustomersCardProps,
  CustomersCard,
  IInsuranceCardProps,
  InsuranceCard
} from "components/molecules/card/export";
import CustomerGridCard from "./customer-grid-card/CustomerGridCard";
import CustomersSecurityCard, { ICustomersSecurityCardProps } from "./customers-security-card/CustomersSecurityCard";
import PartnersGridCard from "./partners-grid-card/PartnersGridCard";
import PendingsListCard, { IPendingsListCardProps } from "./pendings-list-card/PendingsListCard";
import PendingsCard from "./pendings-card/PendingsCard";


type PossibleTypes =
  | ICurrentPlanSummaryCardProps
  | IInfoCardProps
  | IAppointmentPDFCardViewerProps
  | ISecurityCardProps | 
  ICustomersCardProps | 
  IInsuranceCardProps  | ICustomersSecurityCardProps
  | IPendingsListCardProps |  Record<string, unknown>;

export interface ICard<T> extends HTMLAttributes<HTMLDivElement> {
  cardType?: CardType;
  props?: T;
}
const componentMap: Record<CardType, ComponentType<PossibleTypes>> = {
  [CardType.CURRENT_PLAN_SUMMARY_CARD]:
    CurrentPlanSummaryCard as ComponentType<PossibleTypes>,
  [CardType.INFO_CARD]: InfoCard as ComponentType<PossibleTypes>,
  [CardType.APPOINTMENT_PDF_CARD_VIEWER]:
    AppointmentPDFCardViewer as ComponentType<PossibleTypes>,
  [CardType.LEAD_CARD]: LeadCard as ComponentType<PossibleTypes>,
  [CardType.SOURCE_CARD]: SourceCard as ComponentType<PossibleTypes>,
  [CardType.CAMPAIGN_CARD]: CampaignCard as ComponentType<PossibleTypes>,
  [CardType.SECURITY_CARD]: SecurityCard as ComponentType<PossibleTypes>,
  [CardType.CUSTOMERS_CARD]: CustomersCard as ComponentType<PossibleTypes>,
  [CardType.INSURANCE_CARD]: InsuranceCard as ComponentType<PossibleTypes>,
  [CardType.CUSTOMER_GRID_CARD]: CustomerGridCard as unknown as ComponentType<PossibleTypes>,
  [CardType.CUSTOMER_SECURITY_CARD]: CustomersSecurityCard as unknown as ComponentType<PossibleTypes>,
  [CardType.PARTNERS_GRID_CARDS]: PartnersGridCard as unknown as ComponentType<PossibleTypes>,
  [CardType.PENDINGS_LIST_CARD]: PendingsListCard as unknown as ComponentType<PossibleTypes>,
  [CardType.PENDINGS_CARD]: PendingsCard as unknown as ComponentType<PossibleTypes>,

};
function Card<T extends {}>({
  cardType = CardType.CURRENT_PLAN_SUMMARY_CARD,
  props,
  ...rest
}: ICard<T>) {
  const Component = componentMap[cardType];
  if (!Component) {
    return null;
  }
  return <Component {...props} {...(rest as T)} />;
}
export default Card;
