import { sendComment } from "api/Pendings";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useSendComment = () => {
  const queryClient = useQueryClient();
  return useMutation(sendComment, {
    onSuccess: () => {
      queryClient.invalidateQueries('customerPending');
    },
    onError: () => {
      toast.error(`Something went wrong`);
    },
  });
};
