import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function CameraIcon({ className, color }: Props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="40" height="40" rx="10" fill="#CC7272" />
      <path
        d="M20 15.3164C17.3076 15.3164 15.1172 17.5011 15.1172 20.1865C15.1172 22.8719 17.3076 25.0567 20 25.0567C22.6924 25.0567 24.8828 22.8719 24.8828 20.1865C24.8828 17.5011 22.6924 15.3164 20 15.3164ZM20 23.4982C18.1692 23.4982 16.6797 22.0126 16.6797 20.1865C16.6797 18.3605 18.1692 16.8748 20 16.8748C21.8308 16.8748 23.3203 18.3605 23.3203 20.1865C23.3203 22.0126 21.8308 23.4982 20 23.4982Z"
        fill={color || 'white'}
      />
      <path
        d="M27.6562 12.9771H24.7822C24.6912 12.9771 24.6093 12.9276 24.5671 12.8474L23.772 11.181C23.7698 11.1764 23.7676 11.1719 23.7653 11.1674C23.4575 10.5533 22.8387 10.1719 22.1504 10.1719H17.9053C17.217 10.1719 16.5982 10.5533 16.2904 11.1674C16.2881 11.1719 16.2859 11.1764 16.2837 11.181L15.4886 12.8476C15.4464 12.9276 15.3644 12.9771 15.2734 12.9771H12.3438C11.0514 12.9771 10 14.0257 10 15.3147V25.8342C10 27.1232 11.0514 28.1719 12.3438 28.1719H27.6562C28.9486 28.1719 30 27.1232 30 25.8342V15.3147C30 14.0257 28.9486 12.9771 27.6562 12.9771ZM28.4375 25.8342C28.4375 26.2638 28.087 26.6134 27.6562 26.6134H12.3438C11.913 26.6134 11.5625 26.2638 11.5625 25.8342V15.3147C11.5625 14.8851 11.913 14.5355 12.3438 14.5355H15.2734C15.9617 14.5355 16.5805 14.1541 16.8884 13.5401C16.8907 13.5355 16.8929 13.531 16.8951 13.5264L17.6902 11.8599C17.7324 11.7798 17.8143 11.7303 17.9053 11.7303H22.1504C22.2413 11.7303 22.3233 11.7798 22.3654 11.8598L23.1606 13.5264C23.1628 13.5309 23.165 13.5355 23.1673 13.54C23.4751 14.154 24.0939 14.5355 24.7822 14.5355H27.6562C28.087 14.5355 28.4375 14.885 28.4375 15.3147V25.8342Z"
        fill={color || 'white'}
      />
      <path d="M26.875 16.0938H25.3125V17.6522H26.875V16.0938Z"         fill={color || 'white'}
 />
    </svg>
  );
}
export default memo(CameraIcon);
