import { getAppointmentNotes } from "api/Appointment";
import { useQuery } from "react-query";

interface DocumentParams {
    appointment_id?: any;
}

export const useAppointmentNotes = (
    params: DocumentParams,
) => {
    const queryInfo = useQuery(
        ['appointmentNotes'],
        () => getAppointmentNotes(params),
        {
            onError: (err) => {
                console.error(err);
            },
            enabled: false,
        }
    );

    return queryInfo;
};