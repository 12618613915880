import IconButton from "components/atoms/button/IconButton";
import SuccesfulLoginIcon from "components/atoms/icon/icon-type/SuccesfulLoginIcon";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface IProps {
  onConfirm: () => void;
  themeColors: {
    primary: string;
  };
  setCurrentStep: (args: number) => void;
}


const Congrats: FC<IProps> = ({ onConfirm, themeColors,setCurrentStep }) => {
  const {t} = useTranslation();
  
  return (
    <div className="mb-0 flex flex-col justify-center items-center gap-7 pb-7">
      <div>
        <SuccesfulLoginIcon color={"--theme"} />
      </div>
      <div className="text-[20px] font-inter-bold leading-[17px]">
        {t('Congratulations!')}
      </div>
      <div className="text-[17px] font-inter-medium leading-[17px]">
        {t('Your appointment have been uploaded successfully')}.
      </div>
      <div className="flex w-full px-20">
        <IconButton
          onClick={() => {
            onConfirm();
            setCurrentStep(1);
          }}
          className=" py-3  w-full rounded-[8px] text-[13px] font-inter-semibold leading-[22.75px]"
        >
          {t('Go to Appointments')}
        </IconButton>
      </div>
    </div>
  );
};

export default Congrats;
