import { updateStatus } from "api/Campaign";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useUpdateCampaignStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(updateStatus, {
    onSuccess: () => {
        queryClient.invalidateQueries('campaigns')
        toast.success("Campaign status changed successfully");
    },
    onError: () => {
      toast.error('Something went wrong')
    }
  });
};
