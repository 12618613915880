import { FC, useState } from "react";
import { createCampaignTableHeaders } from "lib/helpers/constants/columns/createCampaignTableHeaders";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import withModal from "lib/helpers/hoc/withModal";
import UploadCSV from "./additional-info-steps/UploadCSV";
import StoreFields from "./additional-info-steps/StoreFields";
import Step6 from "../create-campaign-steps/Step6";
import ChooseFields from "./additional-info-steps/ChooseFields";

export interface ICreateCampaignProps<T> {
  onConfirm: () => void;
  themeColors: {
    primary: string;
  };
  data: T;
  setCurrentStep: (args: number) => void;
  currentStep: number;
}

const AdditionalInfoProcess: FC<ICreateCampaignProps<any>> = ({
  onConfirm,
  themeColors,
  currentStep,
  setCurrentStep,
  data
}) => {
  const handleFormValuesChange = (step: number, values: any) => {
    setFormValues((prev) => ({ ...prev, ...values }));
  };

  const [FormValues, setFormValues] = useState<Array<string>>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cardsOrder, _] = useState(createCampaignTableHeaders);

  const handleUpdateData = (updatedData: any[]) => {
    setFormValues(updatedData);
  };

  return (
    <div className="mb-0 flex flex-col gap-7">
      <ConditionalRender condition={currentStep >= 2 && currentStep !== 6}>
        <button
          type="button"
          className="absolute top-[26px] sm:top-[31px] md:top-[45px]"
          onClick={() => {
            setCurrentStep(currentStep > 1 ? currentStep - 1 : 1);
          }}
        >
          <Icon
            className={currentStep >= 2 && currentStep < 4 ? "block" : "hidden"}
            iconType={IconType.BACK_BUTTON_MODAL}
            color={themeColors.primary}
          />
        </button>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 1}>
        <UploadCSV
        themeColors={themeColors}
        setStep={setCurrentStep}
        handleFormValuesChange={handleFormValuesChange}
        formValues={FormValues}
        id={data}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 2}>
        <ChooseFields setStep={setCurrentStep} themeColors={themeColors} />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 3}>
         <StoreFields
          setStep={setCurrentStep}
          dataCols={cardsOrder}
          onUpdateData={handleUpdateData}
          themeColors={themeColors}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 4}>
        <Step6 onConfirm={onConfirm} themeColors={themeColors} />
      </ConditionalRender>
    </div>
  );
};

export default withModal(AdditionalInfoProcess);
