import { createContext, useContext } from "react";

export interface IMultiStepFormContext<T> {
  currentStep: number;
  nextFormStep: () => void;
  prevFormStep: () => void;
  updateFormData: (data: Partial<T>) => void;
  formData: T;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  clearFormData: () => void;
  removeKeyFromFormData: (key: string) => void
}

export const defaultValues: IMultiStepFormContext<unknown> = {
  currentStep: 1,
  nextFormStep: () => {},
  prevFormStep: () => {},
  updateFormData: () => {},
  formData: {},
  setCurrentStep: () => {},
  clearFormData: () => {},
  removeKeyFromFormData: () => {}
};

export const MultiStepFormContext = createContext(
  defaultValues as IMultiStepFormContext<unknown>
);

export const useMultiStepFormContext = <T>(): IMultiStepFormContext<T> =>
  useContext(MultiStepFormContext as React.Context<IMultiStepFormContext<T>>);
