import EmployeesManagementView from 'components/organisms/employees-management/EmployeesManagementView'
import { usePermissions } from 'lib/hooks/shared/usePermissions'
import { FC } from 'react'
import { PermissionsTypes as P } from 'lib/helpers/constants/permissions'
import EmployeeProfileView from 'components/organisms/employee-profile/EmployeeProfileView'

export interface IEmployeesManagementProps {}

const EmployeesManagement:FC<IEmployeesManagementProps> = () => {
  const hasPermissions = usePermissions([P['Hr Admin']]);
  return (
        <>
        {hasPermissions ? <EmployeesManagementView/> : <EmployeeProfileView/>}
        </>
  )
}

export default EmployeesManagement