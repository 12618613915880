
export const createCampaignTableHeaders = [
  {
    id: 1,
    Header: "First Name",
    accessor: "first_name",
    isChecked: true
  },
  {
    id: 2,
    Header: "Last Name",
    accessor: "last_name",
    isChecked: true
  },
  {
    id: 3,
    Header: "Nationality",
    accessor: "nationality",
    isChecked: true
  },
  {
    id: 4,
    Header: "Date Of Birth",
    accessor: "birthdate",
    isChecked: true
  },
  {
    id: 5,
    Header: "Health Insurance",
    accessor: "health_insurance",
    isChecked: true
  },

  {
    id: 6,
    Header: "Persons in Household",
    accessor: "persons",
    isChecked: true
  },
  {
    id: 7,
    Header: "Language",
    accessor: "language",
    isChecked: true
  },
  {
    id: 8,
    Header: "Phone",
    accessor: "phone",
    isChecked: true
  },
  {
    id: 9,
    Header: "Email",
    accessor: "email",
    isChecked: true
  },
  {
    id: 10,
    Header: "Street",
    accessor: "address",
    isChecked: true
  },
  {
    id: 11,
    Header: "Nr",
    accessor: "nr",
    isChecked: true
  },
  {
    id: 12,
    Header: "PLZ",
    accessor: "postal_code",
    isChecked: true
  },
  {
    id: 13,
    Header: "City",
    accessor: "city",
    isChecked: true
  },
 
];
