import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Check3Icon from "components/atoms/icon/icon-type/Check3Icon";
import CheckIcon from "components/atoms/icon/icon-type/CheckIcon";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import Table from "components/molecules/table/Table";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useUploadLeadStoreFile } from "lib/hooks/mutations/Leads/useUploadLeadStoreFile";
import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import { createCampaignTableHeaders } from "lib/helpers/constants/columns/createCampaignTableHeaders";
import { useTranslation } from "react-i18next";
export interface IStep3Props {
  setStep: any;
  dataCols: any;
  isAssignLeadsActive: string;
  onUpdateData: (updatedData: any[]) => void;
  themeColors: {
    primary: string;
  };
  setDataCols: any;
}

interface FormData {
  data: any[];
  file: File | null;
  extra_fields: any[];
}

const Step3: FC<IStep3Props> = ({
  setStep,
  dataCols,
  isAssignLeadsActive,
  onUpdateData,
  themeColors,
  setDataCols,
}) => {
  const { handleSubmit } = useForm();
  const { formData }: { formData: FormData } = useMultiStepFormContext<any>();
  const hasPermission = usePermissions([P["Leads Admin"], P["Leads Menager"]]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setStoredLeads] = useState<string[]>();
  const { mutateAsync: storeUploadedLeads, isLoading } = useUploadLeadStoreFile();
  const [tableData, setTableData] = useState(formData.data || []);

  useEffect(() => {
    setTableData(formData.data);
    if (formData.extra_fields) {
      const extraFieldHeaders = formData.extra_fields.map((field) => ({
        Header: field.field_name,
        accessor: field.field_name.toLowerCase().replace(/\s+/g, '_'),
        isChecked: true,
        Cell: ({ row }:any) => row.original[field.field_name.toLowerCase().replace(/\s+/g, '_')] || field.field_value,
      }));
      setDataCols((prevCols: any) => [...prevCols, ...extraFieldHeaders]);

      // Ensure tableData includes extra fields
      setTableData((prevData) =>
        prevData.map((row) => ({
          ...row,
          ...formData.extra_fields.reduce((acc, field) => {
            acc[field.field_name.toLowerCase().replace(/\s+/g, '_')] = field.field_value;
            return acc;
          }, {}),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleRemove = (index: number) => {
    const newData = tableData.filter((_, i) => i !== index);
    setTableData(newData);
    setStoredLeads(newData);
    onUpdateData(newData);
  };
  // eslint-disable-next-line
  const actionsColumn = {
    Header: "Delete",
    accessor: "delete",
    Cell: ({ row }: any) => (
      <button
        type="button"
        onClick={() => handleRemove(row.index)}
        className="text-red-600 hover:text-red-800"
      >
        <Icon iconType={IconType.DELETE_ICON} color="#7D8592" />
      </button>
    ),
  };

  const dynamicHeaders = useMemo(() => {
    const defaultHeaders = createCampaignTableHeaders;
    const extraFieldHeaders = formData.extra_fields.map((field) => ({
      Header: field.field_name,
      accessor: field.field_name.toLowerCase().replace(/\s+/g, '_'),
      isChecked: true,
      Cell: ({ row }: any) => row.original[field.field_name.toLowerCase().replace(/\s+/g, '_')] || field.field_value,
    }));
    return [actionsColumn, ...defaultHeaders, ...extraFieldHeaders];
  }, [formData, actionsColumn]); 

const {t} = useTranslation();
  const onSubmit = () => {
    let updatedData = null;
    if (hasPermission && isAssignLeadsActive === "Own Leads") {
      updatedData = {
        own_appointments: 1,
        leads: tableData,
        file: formData?.file,
        extra_fields: formData?.extra_fields as any,
      };
    } else {
      updatedData = { leads: tableData, file: formData?.file, extra_fields: formData?.extra_fields as any };
    }
    storeUploadedLeads(updatedData)
      .then(() => {
        setStep(4);
        setStoredLeads([]);
      })
      .catch(() => {});
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="mb-0 flex flex-col pl-4 pb-2"
    >
      <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5">
          {t('Upload CSV')}
        </div>
        <div className="absolute top-[0] left-[-12px] z-9999">
          <CheckIcon color={themeColors.primary} />
        </div>
      </div>
      <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5 ">
          {t('Only adjust the fields you want to import')}
        </div>

        <div className="absolute top-[0] left-[-12px] z-9999 ">
          <CheckIcon color={themeColors.primary} />
        </div>
      </div>
      <div className="pl-7 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5 ">
          {t("Remove the leads here that you don't want to import")}
        </div>

        <div
          className="rounded-[20px] p-4 w-full "
          style={{
            backgroundColor: `${themeColors.primary}0D`,
          }}
        >
          <div className="overflow-auto horizontalOverflowScrollStyle w-full h-full px-5 ">
            <Fragment>
            <Table
              style={{ backgroundColor: `${themeColors.primary}0D` }}
              classes={{ subContainer: "!p-0 !rounded-none" }}
              columns={dynamicHeaders}
              data={tableData || []}
              search={""}
              isColumnSorted={true}
            />
            </Fragment>
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <Icon iconType={IconType.ALERT_ICON} color={themeColors.primary} />
          <span className="font-inter-medium text-[13px] text-primaryTextColor pt-1">
            {isAssignLeadsActive !== "Own Leads"
              ? t("These leads will not be assigned to any account.")
              : t("These leads will be assigned to your account.")}
          </span>
        </div>
        <div className="absolute top-[0] left-[-12px] z-9999 ">
          <Check3Icon color={themeColors.primary} />
        </div>
      </div>
      <div className="flex gap-5 justify-end pt-5">
        <IconButton
          icon={<Icon iconType={IconType.UPLOAD_FILE_ICON} />}
          className="w-full max-w-[150px]"
         disabled={isLoading}
        >
          {isLoading ? t("Creating...") : t("Continue")}
          </IconButton>
      </div>
      {isLoading && <LoadingScreen />}
    </Form>
  );
};

export default Step3;
