import { useForm, UseFormProps } from "react-hook-form";
import * as YUP from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const PartnerFormSchema = YUP.object().shape({
    logo:YUP.mixed().optional().nullable(),
    name: YUP.string().required("Partner name is required"),
    email: YUP.string().optional().email("Invalid email address"),
    description: YUP.string().optional(),
    phone: YUP.string().optional().min(6, "Phone number is too short").typeError("Phone number is required"),
    status: YUP.boolean().optional()

});

export function usePartnerForm(options?: UseFormProps<any>) {
    return useForm<any | any>({
        mode: "onSubmit",
        ...options,
        resolver: yupResolver(PartnerFormSchema),
    });
}

export type PartnerForm = ReturnType<typeof usePartnerForm>;