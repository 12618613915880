import Headline from "components/atoms/headline/Headline";
import { FC, useState } from "react";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Input from "components/atoms/input/Input";
import Button from "components/atoms/button/Button";
import TabsSwitch from "components/molecules/tabs/TabsSwitch";
import ImageDropZone from "components/molecules/image-dropzone/ImageDropZone";
import { useForm } from "react-hook-form";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import CustomSelect from "components/atoms/custom-select/CustomSelect";
import FileDropZone from "components/molecules/file-dropzone/FileDropZone";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface ICreateEmployeesManagementProps { }

const CreateEmployeesManagement: FC<ICreateEmployeesManagementProps> = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { control } = useForm();
  const {t} = useTranslation();
  const navigate = useNavigate();


  const renderTabs = () => {
    switch (selectedTab) {
      case 0:
        return (
          <div>
            <ImageDropZone
              classes={{
                container: "!justify-start",
                label: "!w-[120px]",
                subContainer: "!ml-0"
              }}
              name="img"
              control={control}
            />
            <div className="grid grid-cols-2 gap-5">
              <Input placeholder={t("First Name")} />
              <Input placeholder={t("Last Name")} />
              <Input placeholder={t("Mobile Number")} />
              <Input placeholder={t("Email Address")} />
              <PrimaryDatePicker
                startDate={undefined}
                setStartDate={undefined}
              />
              <Input placeholder={t("First Name")} />
              <Input placeholder={t("First Name")} />
              <CustomSelect
                customOptions={[
                  { label: `${t("Marital Status")}`, value: "Marital Status" }
                ]}
                placeholder={t("Marital Status")}

              />
              <CustomSelect
                customOptions={[
                  { label: `${t("Gender")}`, value: "Gender" },
                  { label: `${t("Male")}`, value: "Male" },
                  { label: `${t("Female")}`, value: "Female" }
                ]}
                placeholder={t("Gender")}
              />
              <CustomSelect
                customOptions={[{ label: `${t("Nationality")}`, value: "Nationality" }]}
                placeholder={t("Nationality")}
              />
            </div>
            <Input placeholder={t("Address")} className="my-5" />
            <div className="grid grid-cols-3 gap-5">
              <CustomSelect
                customOptions={[{ label: `${t("City")}`, value: "City" }]}
                placeholder={t("City")}
              />
              <CustomSelect
                customOptions={[{ label: `${t("State")}`, value: "State" }]}
                placeholder={t("State")}
              />
              <CustomSelect
                customOptions={[{ label: `${t("ZIP Code")}`, value: "ZIP Code" }]}
                placeholder={t("ZIP Code")}
              />
            </div>
            <div className="flex items-center justify-end gap-5 mb-[89px]">
              <Button className="text-[#91929E] bg-[#F4F9FD]  flex items-center min-w-[130px]  mt-[47px] justify-center  p-3 rounded-[8px] font-inter-bold">
                {t("Cancel")}
              </Button>
              <Button onClick={()=>setSelectedTab(1)} className="flex items-center min-w-[270px]  mt-[47px] justify-center font-inter-bold text-white p-3 rounded-[8px] gap-2 bg-bluebutton">
                {t("Next Step")}
                <Icon iconType={IconType.RIGHT_ARROW_ICON} />
              </Button>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <div className="grid grid-cols-2 gap-5 mb-5">
              <Input placeholder={t("Employee ID")} />
              <Input placeholder={t("User Name")} />
              <CustomSelect
                customOptions={[
                  {
                    label: `${t("Select Employee Type")}`,
                    value: "Select Employee Type"
                  }
                ]}
                placeholder={t("Select Employee Type")}

              />
              <Input placeholder={t("Email Address")} />
              <CustomSelect
                customOptions={[
                  { label: `${t("Select Department")}`, value: "Select Department" }
                ]}
                placeholder={t("Select Department")}
              />
              <Input placeholder={t("Enter Role")} />
              <CustomSelect
                customOptions={[
                  { label: `${t("Select Working Days")}`, value: "Select Working Days" }
                ]}
                placeholder={t("Select Working Days")}
              />
              <PrimaryDatePicker
                startDate={undefined}
                setStartDate={undefined}
              />
            </div>
            <CustomSelect
              customOptions={[
                { label: `${t("Select Working Days")}`, value: "Select Working Days" }
              ]}
              placeholder={t("Select Office Location")}
            />
            <div className="flex items-center justify-end gap-5 mb-[89px]">
              <Button className="text-[#91929E] bg-[#F4F9FD] flex items-center min-w-[130px]  mt-[47px] justify-center  p-3 rounded-[8px] font-inter-bold">
                {t("Cancel")}
              </Button>
              <Button onClick={()=>setSelectedTab(2)} className="flex items-center min-w-[270px]  mt-[47px] justify-center font-inter-bold text-white p-3 rounded-[8px] gap-2 bg-bluebutton">
                {t("Next Step")}
                <Icon iconType={IconType.RIGHT_ARROW_ICON} />
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div className="grid grid-cols-2 gap-5">
              <FileDropZone
                name=""
                control={control}
                classes={{ label: "bg-transparent" }}
              />
              <FileDropZone
                name=""
                control={control}
                classes={{ label: "bg-transparent" }}
              />
              <FileDropZone
                name=""
                control={control}
                classes={{ label: "bg-transparent" }}
              />
              <FileDropZone
                name=""
                control={control}
                classes={{ label: "bg-transparent" }}
              />
            </div>
            <div className="flex items-center justify-end gap-5 mb-[89px]">
              <Button className="text-[#91929E] bg-[#F4F9FD] flex items-center min-w-[130px]  mt-[47px] justify-center  p-3 rounded-[8px] font-inter-bold">
                {t("Cancel")}
              </Button>
              <Button onClick={()=>setSelectedTab(3)} className="flex items-center min-w-[270px]  mt-[47px] justify-center font-inter-bold text-white p-3 rounded-[8px] gap-2 bg-bluebutton">
                {t("Next Step")}
                <Icon iconType={IconType.RIGHT_ARROW_ICON} />
              </Button>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div className="grid grid-cols-2 gap-5">
              <Input placeholder={t("Enter Email Address")} />
              <Input placeholder={t("Enter Strato ID")} />
              <Input placeholder={t("Enter Skype ID")} />
              <Input placeholder={t("Enter Github ID")} />
            </div>
            <div className="flex items-center justify-end gap-5 mb-[89px]">
              <Button className="text-[#91929E] bg-[#F4F9FD] flex items-center min-w-[130px]  mt-[47px] justify-center  p-3 rounded-[8px] font-inter-bold">
                {t("Cancel")}
              </Button>
              <Button className="flex items-center min-w-[270px]  mt-[47px] justify-center font-inter-bold text-white p-3 rounded-[8px] gap-2 bg-bluebutton">
                {t("Confirm")}
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="flex items-center gap-[8px]">
        <Button className="gap-[8px]" onClick={() => navigate('/hr/employees-management')}>
          <Icon iconType={IconType.LEFT_ARROW_ICON} className="mt-3 gap-5" />
        </Button>
        <Headline title={t("New employee")} />
      </div>
      <DashboardBoxLayout className="mt-[26px]">
        <TabsSwitch
          ActiveTab={selectedTab}
          setSelectedTab={(id) => setSelectedTab(id)}
          tabs={[
            { id: 0, name: t("Personal Information") },
            { id: 1, name: t("Professional Information") },
            { id: 2, name: t("Documents") },
            { id: 3, name: t("Account Access") }
          ]}
        ></TabsSwitch>
        {renderTabs()}
      </DashboardBoxLayout>
    </div>
  );
};

export default CreateEmployeesManagement;
