import { memo } from "react";
interface Props {
  className?: string;
}
function DecraseIcon({ className }: Props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M2 8.00016C2 7.63197 2.29848 7.3335 2.66667 7.3335H13.3333C13.7015 7.3335 14 7.63197 14 8.00016C14 8.36835 13.7015 8.66683 13.3333 8.66683H2.66667C2.29848 8.66683 2 8.36835 2 8.00016Z" fill="#7D8592"/>
    </svg>
    
  );
}
export default memo(DecraseIcon);
