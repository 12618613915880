import { dataOfCsv } from "api/Customers";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useUploadCustomersDataOfCSV = () => {
  const { updateFormData } = useMultiStepFormContext();
  return useMutation(dataOfCsv, {
    onSuccess: (res) => {
      updateFormData(res);
    },
    onError: (error) => {
      toast.error("Failed to upload customers data");
    },
  });
};
