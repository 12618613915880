import React, { memo } from 'react';
import { Handle, Position, getOutgoers, useEdges, useNodeId, useNodes, } from 'reactflow';
import CustomHandle from './CustomHandle';
import Icon from '../../../atoms/icon/Icon';
import { IconType } from '../../../../lib/helpers/constants/iconTypes';

function CustomNode({ data }: any) {

    const edges = useEdges();
    const nodes = useNodes();

    const nodeId = useNodeId();


    const incomers = getOutgoers(
        { id: nodeId ? nodeId : '1', position: nodes[nodeId ? parseInt(nodeId) - 1 : 1].position, data: { data } },
        nodes,
        edges,
    );

    return (
        <>
            <div className={`relative border-[#D0D5DD] rounded-[8px] w-[215px] bg-white border-[0.5px]`} style={{ boxShadow: nodeId === '1' ? '0px 0px 15.7px 0px #00000026' : '' }}>
                <div className='p-4 text-center'>
                    {/* <img src="" alt="" /> */}
                    <div className='w-[65px] h-[65px] bg-gray-400 rounded-full mt-[-50px] mx-auto'>

                    </div>

                    {nodeId !== '1' && (
                        <div className='absolute top-[10px] right-[12px]'>
                            <Icon className='w-[15px] h-[15px]' iconType={IconType.EDIT_ICON} />
                        </div>
                    )}

                    <div className='pt-1'>
                        <span className='color-primaryTextColor font-inter-bold'>{data.name}</span>
                    </div>
                    <div>
                        <span className='color-primaryTextColor font-inter-regular text-[14px]'>{data.job}</span>
                    </div>
                    <div>
                        <span className='text-[#91929E] font-inter-regular text-[14px]'>{data.team}</span>

                    </div>

                </div>

            </div>

            {nodeId !== '1' && (
                <CustomHandle isConnectable={1} />
            )}

            <Handle type="source" position={Position.Bottom} className={`w-[30px] h-[30px] text-white rounded-full !bottom-[-15px] flex items-center justify-center ${nodeId === '1' ? '!bg-[#50B8E7]' : '!bg-[#7D8592]'}  `} style={{ left: 'calc(50%-15px)' }}>
                {incomers.length !== 0 && incomers.length}
            </Handle>
        </>

    );
}

export default memo(CustomNode);
