import { showPendingDocument } from "api/Pendings";
import { UseQueryOptions, useQuery } from "react-query";

export const useShowPendingDocument = (
  link: string,
  options?: UseQueryOptions<any, any, unknown, string[]>
) => {
  const queryInfo = useQuery(
    ["showPendingDocument", link],
    () => showPendingDocument(link),
    {
      enabled: !!link, // Only run the query if the link is not null or undefined
      onError: (err) => {
        console.error(err);
      },
      ...options,
    }
  );

  return queryInfo;
};
