import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC, useState } from "react";
import CSVUpload from "components/organisms/customers/upload-customers-process/upload-customers-steps/CSVUpload";
import ChooseFields from "./upload-customers-steps/ChooseFields";
import StoreFields from "./upload-customers-steps/StoreFields";
import Congrats from "./upload-customers-steps/Congrats";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import withModal from "lib/helpers/hoc/withModal";
import { useTranslation } from "react-i18next"

export interface IUploadCustomerProcessProps {
  onConfirm: () => void;
  themeColors: {
    primary: string;
  };
  setCurrentStep: (args: number) => void;
  currentStep: number;
}

const UploadCustomerProcess: FC<IUploadCustomerProcessProps> = ({
  onConfirm,
  themeColors,
  setCurrentStep,
  currentStep,
}) => {
  const {t} = useTranslation();
  const cols = [
    {
      id: 1,
      Header: `${t("First Name")}`,
      accessor: "first_name",
      isChecked: true,
    },
    {
      id: 2,
      Header: `${t("Last Name")}`,
      accessor: "last_name",
      isChecked: true,
    },
    {
      id: 3,
      Header: `${t("Phone")}`,
      accessor: "phone",
      isChecked: true,
    },
    {
      id: 4,
      Header: `${t("Date Of Birth")}`,
      accessor: "birthdate",
      isChecked: true,
    },
    {
      id: 5,
      Header: `${t("Address")}`,
      accessor: "address",
      isChecked: true,
    },
    {
      id: 6,
      Header: `${t("Email")}`,
      accessor: "email",
      isChecked: true,
    },
    {
      id: 7,
      Header: `${t("Postal Code")}`,
      accessor: "postal_code",
      isChecked: true,
    },
    {
      id: 8,
      Header: `${t("City")}`,
      accessor: "city",
      isChecked: true,
    },
    {
      id: 9,
      Header: `${t("Canton")}`,
      accessor: "canton",
      isChecked: true,
    },
    {
      id: 10,
      Header: `${t("Nationality")}`,
      accessor: "nationality",
      isChecked: true,
    },
    {
      id: 11,
      Header: `${t("Nr")}`,
      accessor: "nr",
      isChecked: true,
    },
    {
      id: 12,
      Header: `${t("Note")}`,
      accessor: "note",
      isChecked: true,
    },
  ];

  const [formValues, setFormValues] = useState<Array<string>>([]);

  const handleFormValuesChange = (step: number, values: any) => {
    setFormValues((prev) => ({ ...prev, ...values }));
  };

  const handleUpdateData = (updatedData: any[]) => {
    setFormValues(updatedData);
  };

  return (
    <div className="mb-0 flex flex-col gap-7">
      <ConditionalRender condition={currentStep >= 2 && currentStep !== 4}>
        <button
          type="button"
          className="absolute top-[26px] sm:top-[31px] md:top-[45px]"
          onClick={() => {
            setCurrentStep(currentStep > 1 ? currentStep - 1 : 1);
          }}
        >
          <Icon
            className={currentStep >= 2 && currentStep < 6 ? "block" : "hidden"}
            iconType={IconType.BACK_BUTTON_MODAL}
            color={themeColors.primary}
          />
        </button>
      </ConditionalRender>
      {/* {
        {
          1: (
            <CSVUpload
              themeColors={themeColors}
              formValues={formValues}
              handleFormValuesChange={handleFormValuesChange}
              setStep={setStep}
            />
          ),
          2: <ChooseFields setStep={setStep} themeColors={themeColors} />,
          3: (
            <StoreFields
              setStep={setStep}
              dataCols={cols}
              onUpdateData={handleUpdateData}
              themeColors={themeColors}
            />
          ),
          4: <Congrats onConfirm={onConfirm} themeColors={themeColors} />,
        }[step]
      } */}
      <ConditionalRender condition={currentStep === 1}>
        <CSVUpload
          themeColors={themeColors}
          formValues={formValues}
          handleFormValuesChange={handleFormValuesChange}
          setStep={setCurrentStep}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 2}>
        <ChooseFields setStep={setCurrentStep} themeColors={themeColors} />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 3}>
        <StoreFields
          setStep={setCurrentStep}
          dataCols={cols}
          onUpdateData={handleUpdateData}
          themeColors={themeColors}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 4}>
        <Congrats
          onConfirm={onConfirm}
          themeColors={themeColors}
          setCurrentStep={setCurrentStep}
        />
      </ConditionalRender>
    </div>
  );
};

export default withModal(UploadCustomerProcess);
