import { FC } from "react";
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IErrorMessageProps {
  error?: FieldError;
  className?: string;
}

const ErrorMessage: FC<IErrorMessageProps> = ({ error, className }) => {
  const {t} = useTranslation();
  if (!error) {
    return null;
  }

  return (
    <pre
      className={`${className} font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]`}
    >
      {t(error.message as string)}*
    </pre>
  );
};

export default ErrorMessage;
