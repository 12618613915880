import { useMutation } from "react-query";
import { verifyPasswordToken } from "../../../../api/User";
import { useNavigate } from "react-router-dom";

export interface IUseVerifyPasswordTokenProps {

}

export const useVerifyPasswordToken = () => {
    const navigate = useNavigate();
    return {
        ...useMutation(verifyPasswordToken, {
            onSuccess: () => {
                // nextFormStep();
            },
            onError: () => {
                navigate('/')
             
            },
        })

    };
};