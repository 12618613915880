import React from 'react'
import { useMultiStepFormContext } from '../../../lib/context/MultiStepFormContext/MultiStepFormContext';
import InitialForgotPassword from './forgotPasswordSteps/InitialForgotPassword';
import CheckEmail from './forgotPasswordSteps/CheckEmail';

const ForgotPasswordProcces = () => {
  const { currentStep } = useMultiStepFormContext();
  return (
    <>
      {
        {
          1: <InitialForgotPassword />,
          // 2: <PhoneCodeVerification />,
          // 3: <ConfirmEmail />,
          2: <CheckEmail />,
        }[currentStep]
      }
    </>
  )
}

export default ForgotPasswordProcces