import { FC } from "react";
import { useMultiStepFormContext } from "../../../lib/context/MultiStepFormContext/MultiStepFormContext";
import CongratulationsStep from "./congratulations-step/CongratulationsStep";
import CompanyInformationStep from "./company-information-step/CompanyInformationStep";
import QuestionsStep from "./questions-step/QuestionsStep";
import SignupStep from "./signup-step/SignupStep";
import AddUserStep from "./add-user-step/AddUserStep";
import CredentialManagementLayout from "../../templates/credential-management-layout/CredentialManagementLayout";
import CustomizedPlanStep from "./customized-plan-step/CustomizedPlanStep";
import CreateUsersGroupStep from "./create-users-group-step/CreateUsersGroupStep";
import OrganizationChart from "./organization-chart/OrganizationChart";
import PaymentSuccessful from "./payment-successful-step/PaymentSuccessful";
import CardInfoStep from "./card-info-step/CardInfoStep";
import PlanCostOverviewStep from "./plan-cost-overview-step/PlanCostOverviewStep";
import CheckEmailVerificationStep from "./check-email-verification-step/CheckEmailVerificationStep";
import useMediaQuery from "lib/hooks/shared/useMediaQuery";

export interface ISignupProcessProps {}

const SignupProcess: FC<ISignupProcessProps> = () => {
  const { currentStep } = useMultiStepFormContext();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const renderMobileLayout = () => (
    <div className="w-full">
      <div className="w-full flex flex-col items-center justify-center h-screen bg-gray-100 p-5">
        <h1 className="text-2xl font-bold mb-4 font-inter-bold">
          Sorry, this application requires a larger screen
        </h1>
        <p className="text-lg mb-8 font-inter-regular">
          Please access this page on a device with a larger screen for the best
          experience.
        </p>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Linearicons_screen.svg/768px-Linearicons_screen.svg.png"
          alt="Illustration of a large screen device"
        />
      </div>{" "}
    </div>
  );

  const renderDesktopLayout = () => (
    <div className="w-full">
      {
        {
          1: (
            <CredentialManagementLayout>
              <SignupStep />
            </CredentialManagementLayout>
          ),
          2: (
            <CredentialManagementLayout>
              <CompanyInformationStep />
            </CredentialManagementLayout>
          ),
          3: (
            <CredentialManagementLayout>
              <QuestionsStep />
            </CredentialManagementLayout>
          ),
          4: <CheckEmailVerificationStep />,
          5: (
            <CredentialManagementLayout>
              <CongratulationsStep />
            </CredentialManagementLayout>
          ),
          6: (
            <CredentialManagementLayout>
              <AddUserStep />
            </CredentialManagementLayout>
          ),
          7: (
            <CredentialManagementLayout>
              <OrganizationChart />
            </CredentialManagementLayout>
          ),
          8: (
            <CredentialManagementLayout>
              <CustomizedPlanStep />
            </CredentialManagementLayout>
          ),
          9: (
            <CredentialManagementLayout>
              <CreateUsersGroupStep />
            </CredentialManagementLayout>
          ),
          10: (
            <CredentialManagementLayout>
              <PlanCostOverviewStep />
            </CredentialManagementLayout>
          ),
          11: (
            <CredentialManagementLayout>
              <CardInfoStep />
            </CredentialManagementLayout>
          ),
          12: (
            <CredentialManagementLayout>
              <PaymentSuccessful />
            </CredentialManagementLayout>
          ),
        }[currentStep]
      }
    </div>
  );
  return isMobile ? renderMobileLayout() : renderDesktopLayout();
};

export default SignupProcess;
