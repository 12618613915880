
const options = [
  {
    label: "Personal information",
    value: "Personal information"
  },
  {
    label: "Contact information",
    value: "Contact information"
  },
  { label: "Address details", value: "Address details" },
  { label: "Related details", value: "Related details" }
];

const fieldTypesOptions = [
  {
    label: "Text",
    value: "text"
  },
  {
    label: "Number",
    value: "number"
  }
];
const uploadfieldTypesOptions = [
  {
    label: "Select",
    value: "select"
  },  {
    label: "Text",
    value: "text"
  },
  {
    label: "Number",
    value: "number"
  }
];

export { options, fieldTypesOptions,uploadfieldTypesOptions };
