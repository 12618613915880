
export const useGenerateTimeOptions = () => {
    
    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 8; hour <= 21; hour++) {
          for (let minute = 0; minute < 60; minute += 15) {
            if (hour === 21 && minute > 0) break; 
            const hourString = hour.toString().padStart(2, '0');
            const minuteString = minute.toString().padStart(2, '0');
            times.push({ value: `${hourString}:${minuteString}`, label: `${hourString}:${minuteString}` });
          }
        }
        return times;
      };
      return {
        timeOptions: generateTimeOptions()
      }
}