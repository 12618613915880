import { FC, memo, useEffect, useState } from "react";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { Controller, useForm } from "react-hook-form";
import TextArea from "components/atoms/text-area/TextArea";
import Button from "components/atoms/button/Button";
import IconButton from "components/atoms/button/IconButton";
import { useRate } from "lib/hooks/shared/useRate";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import Form from "components/atoms/form/Form";
import { useLostFeedback } from "lib/hooks/mutations/Appointments/useLostFeedback";
import { useHandleDropZoneFileChange } from "lib/hooks/shared/useHandleDropZoneFileChange";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import useMediaQuery from "lib/hooks/shared/useMediaQuery";
import { customStyles } from "lib/helpers/configs/customStyles";
import Creatable from "react-select/creatable";
import { useTranslation } from "react-i18next";

export interface ILostAppointmentProps<T> {
  onConfirm: () => void;
  data: T;
  setCurrentStep: (step: number) => void;
  themeColors: {
    primary: string;
  };
  setPreviewURL: (url: string) => void;
}

const LostAppointment: FC<ILostAppointmentProps<any>> = ({
  onConfirm,
  data,
  setCurrentStep,
  themeColors,
  setPreviewURL,
}) => {
  const isMobile = useMediaQuery("(max-width: 764px)");
  const { hoverRate, rate, setHoverRate, setRate } = useRate();
  const { mutateAsync: chooseLostFeedback, isLoading } =
    useLostFeedback(onConfirm);
  const { preview, handleFileChange, setPreview } =
    useHandleDropZoneFileChange();
  const [showAppointmentDone, setShowAppointmentDone] =
    useState<boolean>(false);
  const [showAppointmentNotDone, setShowAppointmentNotDone] =
    useState<boolean>(false);
  const [lastSelected, setLastSelected] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [fileSize, setFileSize] = useState("");

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
    getValues,
  } = useForm();

  const handleOptionSelect = (option: string) => {
    setLastSelected(option);
  };


  const onSubmit = (formData: Record<string, any>): void => {
    const { appointment_done, appointment_not_done, note, file } = formData;

    const updatedFormData: Record<string, any> = {
      appointment_id: data?.id,
      rating_stars: rate,
      feedback_status: "Lost",
      ...(note && { note }),
      ...(file && { file }),
    };

    if (lastSelected === "appointment_done") {
      updatedFormData.appointment_done = appointment_done;
    } else if (lastSelected === "appointment_not_done") {
      updatedFormData.not_done_appointment = appointment_not_done;
    }

    chooseLostFeedback(updatedFormData)
      .then(() => {
        localStorage.removeItem('lst-apt-form-state');
        setCurrentStep(1);
      })
      .catch(() => { });
  };

  const handleReset = () => {
    reset({
      appointment_done: "",
      appointment_not_done: "",
      note: "",
      file: "",
    });
    setFileName("");
    setPreview(null);
    localStorage.removeItem('lst-apt-form-state');
  };


  const handleDeleteImage = () => {
    setFileName("");
    setPreview(null);
    reset({ file: "" });
    localStorage.removeItem('lst-apt-form-state');
  };

  const handleChangeStep = (step: number) => {
    const formData = getValues();
    localStorage.setItem('lst-apt-form-state', JSON.stringify({
      ...formData,
      filename: fileName,
      file: preview,
      appointmetDone: showAppointmentDone,
      appointmentNotDone: showAppointmentNotDone,
    }));
    setCurrentStep(step);
  };

  useEffect(() => {
    const savedFormState = localStorage.getItem('lst-apt-form-state');
    if (savedFormState) {
      const formData = JSON.parse(savedFormState);
      reset(formData);
      setPreview(formData.file);
      setFileName(formData.filename);
      setShowAppointmentDone(formData.appointmetDone);
      setShowAppointmentNotDone(formData.appointmentNotDone);
    }
    // eslint-disable-next-line
  }, [reset]);

  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.removeItem('lst-apt-form-state');
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('lst-apt-form-state');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const { t } = useTranslation()

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="mb-0 flex flex-col gap-7 w-full"
    >
      <Button
        onClick={() => {
          setCurrentStep(1);
          localStorage.removeItem('lst-apt-form-state');
        }}
        type="button"
        className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#CC7272] text-[22px]"
      >
        <Icon
          iconType={IconType.BACK_BUTTON_MODAL}
          className="!w-[30px] !h-[30px]"
          color={themeColors.primary}
        />
        {t('Status')} : {t('Lost')}
      </Button>
      <div className="flex flex-col gap-8">
        <div
          className=" border-[1px] border-[#D0D5DD] p-[22px] pl-[38px] rounded-[8px] cursor-pointer"
          onClick={() => {
            setShowAppointmentDone(true);
            setShowAppointmentNotDone(false);
            handleOptionSelect("appointment_done");
          }}
        >
          <div className="flex items-center justify-between w-full">
            <Label className="flex items-center gap-3 cursor-pointer !mb-0 w-full">
              <Input
                type="radio"
                className={`!w-5 !h-5 ${showAppointmentDone ? 'accent-[#CC7272]' : ''}`}
                name="bordered-radio"
                onChange={() => {
                  setShowAppointmentDone(true);
                  setShowAppointmentNotDone(false);
                  handleOptionSelect("appointment_done");
                }}
              />
              {t('Appointment Done')}
            </Label>
            <Icon
              iconType={
                showAppointmentDone
                  ? IconType.UP_ARROW_ICON
                  : IconType.DOWN_ARROW_ICON
              }
            />
          </div>
          <ConditionalRender condition={showAppointmentDone}>
            <div className="relative max-w-[402px] !w-full mt-[36px]">
              <Label text={t("Reason")} />
              <Controller
                name="appointment_done"
                control={control}
                render={({ field }) => (
                  <Creatable<any, false>
                    {...field}
                    placeholder={t("Choose a reason")}
                    options={[
                      {
                        label: `${t("Customer not at home")}`,
                        value: "Customer not at home",
                      },
                      { label: `${t("I was sent away")}`, value: "I was sent away" },
                      {
                        label: `${t("Not heard on the phone")}`,
                        value: "Not heard on the phone",
                      },
                    ]}
                    value={
                      field.value
                        ? { value: field.value, label: field.value }
                        : null
                    }
                    className="cursor-pointer font-inter-regular react-select-container"
                    classNamePrefix="react-select"
                    onChange={(newValue: any, actionMeta: any) => {
                      if (
                        actionMeta.action === "select-option" &&
                        newValue?.value
                      ) {
                        field.onChange(newValue.value);
                      }
                    }}
                    styles={customStyles}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                  />
                )}
              />
            </div>
          </ConditionalRender>
        </div>
        <div
          className=" border-[1px] border-[#D0D5DD] p-[22px] pl-[38px] rounded-[8px] cursor-pointer"
          onClick={() => {
            setShowAppointmentNotDone(true);
            setShowAppointmentDone(false);
            handleOptionSelect("appointment_not_done");
          }}
        >
          <div className="flex items-center justify-between">
            <Label className="flex items-center gap-3 cursor-pointer !mb-0 w-full">
              <Input
                type="radio"
                className={`!w-5 !h-5 ${showAppointmentNotDone ? 'accent-[#CC7272]' : ''}`}
                name="bordered-radio"
                checked={showAppointmentNotDone}
                onChange={() => {
                  setShowAppointmentDone(false);
                  setShowAppointmentNotDone(true);
                  handleOptionSelect("appointment_not_done");
                }}
              />
              {t('Appointment Not Done')}
            </Label>
            <Icon
              iconType={
                showAppointmentDone
                  ? IconType.UP_ARROW_ICON
                  : IconType.DOWN_ARROW_ICON
              }
            />
          </div>
          <ConditionalRender condition={showAppointmentNotDone}>
            <div
              className={`grid 2xl:gap-5 w-full mt-[36px] ${preview
                ? "grid-cols-[auto,auto]"
                : "md:grid-cols-max-content lg:grid-cols-[200px,auto,auto] 2xl:grid-cols-[auto,250px,250px]"
                }`}
            >
              <div className="relative max-w-[402px] !w-full mt-[36px]">
                <Label text={t("Reason")} />
                <Controller
                  name="appointment_not_done"
                  control={control}
                  render={({ field }) => (
                    <Creatable<any, false>
                      {...field}
                      placeholder={t("Choose a reason")}
                      options={[
                        {
                          label: `${t("Customer not at home")}`,
                          value: "Customer not at home",
                        },
                        { label: `${t("I was sent away")}`, value: "I was sent away" },
                        {
                          label: `${t("Not heard on the phone")}`,
                          value: "Not heard on the phone",
                        },
                      ]}
                      value={
                        field.value
                          ? { value: field.value, label: field.value }
                          : null
                      }
                      className="cursor-pointer font-inter-regular react-select-container"
                      classNamePrefix="react-select"
                      onChange={(newValue: any, actionMeta: any) => {
                        if (
                          actionMeta.action === "select-option" &&
                          newValue?.value
                        ) {
                          field.onChange(newValue.value);
                        }
                      }}
                      styles={customStyles}
                      isValidNewOption={() => false}
                      menuPosition={"fixed"}
                      menuPortalTarget={document.body}
                    />
                  )}
                />
              </div>
              <ConditionalRender condition={!preview}>
                <Controller
                  control={control}
                  name="file"
                  render={({ field: { onChange } }) => (
                    <div className={`flex justify-center relative `}>
                      <div className={`m-2  2xl:m-4 `}>
                        <div className="flex items-start justify-center w-full flex-col gap-[5px]">
                          <label className="text-[#282D46] font-inter-medium text-[14px]">
                            {t('Upload Pictures')}
                          </label>
                          <label
                            className={`flex flex-col cursor-pointer w-full h-32 border-[1px] rounded-[10px] border-border border-dashed bg-transparent relative `}
                          >
                            <div className="flex flex-col items-center justify-center pt-[20px]">
                              <Icon
                                iconType={IconType.UPLOAD_IMAGE_COLORFUL_ICON}
                                className="absolute top-[20%]"
                              />
                              <div
                                className={`mb-3 text-center max-w-[152px] relative top-[50px]`}
                              >
                                <p className="!text-primaryTextColor text-[14px] font-inter-regular mb-1">
                                  {t('Drag & Drop or')}{" "}
                                  <span className="!text-[#CC7272] underline">
                                    {t('choose picture')}
                                  </span>{" "}
                                  {t('to upload')}
                                </p>
                              </div>
                            </div>
                            <Input
                              type="file"
                              onChange={(e) => {
                                handleFileChange(e);
                                const file = e.target.files?.[0];
                                setFileName(file?.name ?? "");
                                if (file) {
                                  const size = file.size / 1024;
                                  if (size < 1024) {
                                    setFileSize(`${size.toFixed(2)} KB`);
                                  } else {
                                    setFileSize(
                                      `${(size / 1024).toFixed(2)} MB`
                                    );
                                  }
                                  const reader = new FileReader();
                                  reader.onloadend = () =>
                                    onChange(reader.result);
                                  reader.readAsDataURL(file);
                                }
                              }}
                              accept={"image/*"}
                              className="!opacity-0	"
                              capture={true}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="file"
                  render={({ field: { onChange } }) => (
                    <div className={`flex justify-center relative `}>
                      <div className={`m-2 2xl:m-4 `}>
                        <div className="flex items-start justify-center w-full flex-col gap-[5px]">
                          <label className="text-[#282D46] font-inter-medium text-[14px]">
                            {t("Take A Pictue")}
                          </label>
                          <label
                            className={`flex flex-col cursor-pointer w-full h-32 border-[1px] rounded-[10px] border-border border-dashed bg-transparent relative `}
                          >
                            <div className="flex flex-col items-center justify-center pt-[20px]">
                              <Icon
                                iconType={IconType.CAMERA_ICON}
                                className="absolute top-[20%]"
                              />
                              <div
                                className={`mb-3 text-center max-w-[152px] relative top-[50px]`}
                              >
                                <p className="!text-primaryTextColor text-[14px] font-inter-regular mb-1">
                                  <span className="!text-[#CC7272] underline">
                                    {t('Take')}
                                  </span>{" "}
                                  {t('a picture from you camera')}
                                </p>
                              </div>
                            </div>
                            <Input
                              disabled={isMobile ? false : true}
                              type="file"
                              onChange={(e) => {
                                handleFileChange(e);
                                const file = e.target.files?.[0];
                                setFileName(file?.name ?? "");
                                if (file) {
                                  const size = file.size / 1024;
                                  if (size < 1024) {
                                    setFileSize(`${size.toFixed(2)} KB`);
                                  } else {
                                    setFileSize(
                                      `${(size / 1024).toFixed(2)} MB`
                                    );
                                  }
                                  const reader = new FileReader();
                                  reader.onloadend = () =>
                                    onChange(reader.result);
                                  reader.readAsDataURL(file);
                                }
                              }}
                              accept={"image/*"}
                              capture={true}
                              className="!opacity-0	"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </ConditionalRender>
              {preview ? (
                <div className="flex items-start justify-center w-full flex-col gap-[5px] ml-5 relative">
                  <label className="text-[#282D46] font-inter-medium text-[14px] mb-3">
                    {t("Upload Justification")}
                  </label>
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-start gap-5">
                      <Icon iconType={IconType.FILE_JPG_ICON} />
                      <div>
                        <h1 className="font-inter-medium text-[#282D46] text-[14px] capitalize text-ellipsis overflow-hidden w-[100px] truncate">
                          {fileName}
                        </h1>
                        <p className="uppercase text-[#6C737F] text-[14px] font-inter-regular">
                          {fileSize}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 absolute bottom-[17px] right-[25px]">
                      <Button type="button" onClick={() => {
                        setPreviewURL(preview);
                        handleChangeStep(13);
                      }}>
                        <Icon iconType={IconType.SHOW_PASSWORD_ICON} />
                      </Button>
                      <Button type="button" onClick={handleDeleteImage}>
                        <Icon
                          iconType={IconType.DELETE_ICON}
                          color="#6C737F"
                          className=""
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </ConditionalRender>
        </div>
        <TextArea
          label={t("Notes")}
          placeholder={t("Write note...")}
          {...register("note")}
          error={errors?.note}
        />
        <div className="flex flex-row justify-between  border-[1px] border-[#D0D5DD] p-4 rounded-[8px] items-center">
          <div className="text-[14px] font-inter-medium leading-[20px]">
            {t('Appointment Quality')}
          </div>
          <div className="flex flex-row gap-2">
            {Array.from({ length: 5 }).map((_, index) => (
              <Button
                key={`rate-key-${index}`}
                type="button"
                onMouseEnter={() => setHoverRate(index)}
                onMouseLeave={() => setHoverRate(-1)}
                onClick={() => setRate(index + 1)}
              >
                <Icon
                  iconType={IconType.RATE_STAR_ICON}
                  color={
                    index <= Math.max(hoverRate, rate - 1)
                      ? "#EFBE12    "
                      : "#6C737F"
                  }
                />
              </Button>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4">
        <IconButton
          type="button"
          secondary={true}
          className="w-full max-w-[150px] !border-[#CC7272] !text-[#CC7272]"
          onClick={handleReset}
        >
          {t('Reset')}
        </IconButton>

        <IconButton
          secondary={true}
          type="submit"
          className="w-full max-w-[150px] !border-[#CC7272] bg-[#CC7272] text-white"
          disabled={isLoading ? true : false}
        >
          {isLoading ? t("Updating...") : t("Lost")}
        </IconButton>
      </div>
    </Form>
  );
};

export default memo(LostAppointment);
