import { memo } from "react";

interface Props {
  className?: string;
}

function NotificationsIcon({ className }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C13.9511 0 17.169 3.13941 17.2961 7.06012L17.3 7.30112V11.8019C17.3 12.6917 17.9831 13.4218 18.8533 13.4962L19.1332 13.5094C20.2445 13.6286 20.2872 15.2401 19.2614 15.4741L19.1332 15.4954L19 15.5024H1L0.866825 15.4954C-0.288942 15.3714 -0.288942 13.6334 0.866825 13.5094L1.14668 13.4962C1.96851 13.4259 2.62352 12.7708 2.69376 11.9486L2.7 11.8019V7.30112C2.7 3.26886 5.96828 0 10 0ZM11.557 17.103C12.3277 17.103 12.8087 17.9381 12.422 18.6047C11.9211 19.4684 10.9983 20 10 20C9.00166 20 8.07886 19.4684 7.57796 18.6047C7.21064 17.9714 7.62639 17.1861 8.32964 17.1092L8.443 17.103H11.557ZM4.70442 7.0826C4.81899 4.25617 7.14611 2 10 2C12.9271 2 15.3 4.37335 15.3 7.30112V11.8019L15.3051 11.9984L15.3276 12.2563C15.3797 12.6817 15.504 13.0848 15.6878 13.453L15.714 13.502H4.285L4.3122 13.453L4.41182 13.2362C4.59742 12.7951 4.7 12.3105 4.7 11.8019V7.30112L4.70442 7.0826Z"
        fill="#282D46"
      />
    </svg>
  );
}
export default memo(NotificationsIcon);
