import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function AccountAccesIcon({ className, color }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="4"
        y="7"
        width="16"
        height="14"
        rx="4"
        stroke={color || "#282D46"}
        strokeWidth="1.5"
      />
      <circle
        cx="12"
        cy="14"
        r="2"
        stroke={color || "#282D46"}
        strokeWidth="1.5"
      />
      <path
        d="M16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7"
        stroke={color || "#282D46"}
        strokeWidth="1.5"
      />
    </svg>
  );
}
export default memo(AccountAccesIcon);
