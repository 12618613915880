import { useInfiniteQuery } from "react-query";
import { toast } from "react-toastify";
import { getNotifications } from "api/Notifications";

export const useNotificationInfiniteScroll = () => {
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    refetch,
    error
  } = useInfiniteQuery(
    "notifications",
    ({ pageParam = 1 }) =>
      getNotifications({
        page: pageParam,
        number_per_page: 10
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.notifications?.next_page_url
          ? new URL(lastPage?.notifications?.next_page_url).searchParams.get("page")
          : false;
      },
      onError: () => {
        toast.error(`Something went wrong`);
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  return {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
    refetch,
    error
  };
};
