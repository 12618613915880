import Card, { ICard } from "components/molecules/card/Card";
import { ICustomersSecurityCardProps } from "components/molecules/card/customers-security-card/CustomersSecurityCard";
import { CardType, ISecurityCardProps } from "components/molecules/card/export";
import { FC } from "react";
import { JSX } from "react/jsx-runtime";

export interface ISecurityGridListProps<T> {
  data: T;
}

const CustomersSecurityGridList: FC<ISecurityGridListProps<Record<string,any>>> = ({ data }) => {
  return (
    <div className="flex flex-col gap-5 md:w-[calc(98vw-_330px)] ">
      {data.map((item: JSX.IntrinsicAttributes & ICard<ISecurityCardProps>) => {
        return (
          <Card<ICustomersSecurityCardProps>
            key={`security-key-${item.id}`}
            {...item}
            cardType={CardType.CUSTOMER_SECURITY_CARD}
          />
        );
      })}
    </div>
  );
};

export default CustomersSecurityGridList;
