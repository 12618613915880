import { useState } from "react";



export const useRate = () => {
  const [rate, setRate] = useState<number>(0);
  const [hoverRate, setHoverRate] = useState<number>(-1);
  const handleRate = (rate: number) => {
    setRate(rate);
    if (hoverRate !== 0) {
      setHoverRate(0);
    }
  };
  return {
    rate,
    hoverRate,
    setRate,
    setHoverRate,
    handleRate
  };
};
