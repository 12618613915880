import { verifyWorkersEmail } from "api/User";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useMutation } from "react-query";

export const useVerifyWorkersEmail = () => {
  const { nextFormStep } = useMultiStepFormContext();
  const mutation = useMutation(verifyWorkersEmail, {
    onSuccess: (res) => {
      return nextFormStep();
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return {
    ...mutation,
    error: mutation.error
  }
};