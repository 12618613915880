import CustomersView from 'components/organisms/customers/CustomersView'
import { FC } from 'react'

export interface ICustomersProps {}

const Customers:FC<ICustomersProps> = () => {
  return (
   <CustomersView/>
  )
}

export default Customers