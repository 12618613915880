import { updateAppointment } from "api/Appointment";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useUpdateAppointment = (
  options?: UseMutationOptions<any, any, any, unknown>
) => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateAppointment(id, data), {
    onSuccess: () => {
      toast.success("Appointments updated successfully");
      queryClient.invalidateQueries('appointments');
    },
    onError: () => {
      toast.error("Appointments couldn't be updated");
    },
    ...options,
  });
};