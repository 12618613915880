import { downloadFeedBack } from "api/Customers";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useDownloadFeedback = () => {
  return useMutation(downloadFeedBack, {
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Customers.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    onError: (err) => {
      toast.error("Something went wrong!");
    }
  });
};
