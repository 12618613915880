import { useState } from "react";

export const usePagination = (
  initialPage: number = 1,
  initialItemsPerPage: number = 10
) => {
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [itemsPerPage, setItemsPerPage] = useState<number>(initialItemsPerPage);

  const handleNextPage = (hasNextPage: boolean): void => {
    if (hasNextPage) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleItemsPerPageChange = (selectedItemsPerPage: number) => {
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };
  const handlePreviousPage = (): void => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
    }
  };

  return {
    currentPage,
    itemsPerPage,
    handleNextPage,
    handlePreviousPage,
    handleItemsPerPageChange
  };
};
