import { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}

const NewPartnersIcon = ({ className, color }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M24.3105 20.0625H20.373V24H18.498V20.0625H14.5605V18.1875H18.498V14.25H20.373V18.1875H24.3105V20.0625ZM14.3405 10.4736C14.4229 10.8444 14.4668 11.2297 14.4668 11.625C14.4668 13.2446 13.7358 14.6966 12.5869 15.6689C12.6196 15.6819 12.6528 15.6945 12.6855 15.7079V17.7797C11.6818 17.2328 10.5319 16.9219 9.31055 16.9219V16.92C9.29498 16.9204 9.27924 16.9204 9.26367 16.9208V16.9219C5.36078 16.9219 2.18555 20.0971 2.18555 24H0.310547C0.310547 20.2795 2.59204 17.0821 5.82916 15.7322C4.63623 14.7603 3.87305 13.2801 3.87305 11.625C3.87305 8.70428 6.24921 6.32812 9.16992 6.32812C9.51709 6.32812 9.85638 6.362 10.1851 6.42609C10.1038 6.05841 10.0605 5.67664 10.0605 5.28497C10.0605 2.37085 12.4314 0 15.3455 0C18.2596 0 20.6305 2.37085 20.6305 5.28497C20.6305 6.92065 19.8834 8.38513 18.7128 9.35522C20.2603 9.96973 21.594 11.0013 22.5709 12.3281H20.0468C18.882 11.2958 17.376 10.6406 15.7214 10.5564C15.5971 10.5652 15.4719 10.5699 15.3455 10.5699C15.0018 10.5699 14.6658 10.5366 14.3405 10.4736ZM11.9355 5.28497C11.9355 7.16528 13.4652 8.69495 15.3455 8.69495C17.2256 8.69495 18.7555 7.16528 18.7555 5.28497C18.7555 3.40466 17.2256 1.875 15.3455 1.875C13.4652 1.875 11.9355 3.40466 11.9355 5.28497ZM12.5918 11.625C12.5918 9.7381 11.0568 8.20312 9.16992 8.20312C7.28302 8.20312 5.74805 9.7381 5.74805 11.625C5.74805 13.5119 7.28302 15.0469 9.16992 15.0469C11.0568 15.0469 12.5918 13.5119 12.5918 11.625Z"
        fill={color || '#50B8E7'}
      />
    </svg>
  );
};

export default memo(NewPartnersIcon);
