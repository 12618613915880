import Card from "components/molecules/card/Card";
import { IInfoCardProps } from "components/molecules/card/info-card/InfoCard";
import { CardType } from "lib/helpers/constants/cardTypes";
import { FC } from "react";
import TypeBoard from "components/molecules/type-board/TypeBoard";

export interface IAppointmentGridViewProps<T> {
  data?: T;
}

const PendingsGridView: FC<IAppointmentGridViewProps<any>> = ({ data }) => {
  const renderLeadCards = (
    status: string,
    borderColor: string,
    bgColor: string,
    title: string
  ) => (
    <div className="flex-1">
      <TypeBoard
        openCreateModal={() => {}}
        borderColor={borderColor}
        containerClassName="!w-full"
        bgColor={bgColor}
        data={data
          .filter((item: any) => item.status === status)
          .map((item: any) => {            
            console.log("item", item);
            
            return (
              <div className="cursor-pointer" key={`card-key-${item.id}`}>
                <Card<IInfoCardProps>
                  cardType={CardType.PENDINGS_CARD}
                  {...(item?.info ?? [])}
                  {...(item ?? [])}
                  props={{
                    item: item,
                    costumer: {
                      first_name: item?.first_name,
                      last_name: item?.last_name
                    },
                    title: item?.title,
                    category: item?.category,
                    deadline: item?.deadline,
                    image: item?.image,
                  }}
                />
              </div>
            )
          })}
        title={title}
      />
    </div>
  );

  return (
    <>
      <div className="overflow-x-auto md:w-[calc(98vw-_360px)] horizontalOverflowScrollStyle">
        <div className="flex gap-5 flex-row flex-nowrap">
          {renderLeadCards("Open", "#EFBE12", "#EFBE120D", "Open")}
          {renderLeadCards("Processing", "#50B8E7", "#50B8E70D", "Processing")}
          {renderLeadCards("Done", "#2DE28B", "#2DE28B0D", "Done")}
        </div>
      </div>
    </>
  );
};

export default PendingsGridView;
