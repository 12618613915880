import { emailVerificationRequest } from "api/User";
import { useMutation } from "react-query";

export interface IEmailVerificationRequest {}

export const useEmailVerification = () => {
  return useMutation(emailVerificationRequest, {
    onSuccess: () => {
      
    },
    onError: (error) => {
      console.error(error);
    },
  });
};
