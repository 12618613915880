import { useMutation } from "react-query";
import { resetPassword } from "../../../../api/User";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useState } from "react";

export interface IUseResetPasswordProps {

}

export const useResetPassword = () => {
    const [Error, setError] = useState('')
    const { nextFormStep } = useMultiStepFormContext()

    return {
        ...useMutation(resetPassword, {
            onSuccess: () => {
                nextFormStep();
            },
            onError: (error: any) => {
                setError(error.message)
            },
        }),
        Error

    };
};