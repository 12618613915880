import React, { FC } from "react";
import LoginTitle from "../../../atoms/title/LoginTitle";
import Paragraph from "../../../atoms/paragraph/Paragraph";
import Input from "../../../atoms/input/Input";
import Button from "../../../atoms/button/Button";
import Form from "../../../atoms/form/Form";
import BlueLink from "../../../atoms/link/BlueLink";
import { useShowPassowrd } from "../../../../lib/hooks/shared/useShowPasswod";
import { IconType } from "../../../../lib/helpers/constants/iconTypes";
import { useLoginForm } from "../../../../lib/hooks/forms/useLoginForm";
import { useLogin } from "../../../../lib/hooks/mutations/Login/useLogin";
import { useMultiStepFormContext } from "../../../../lib/context/MultiStepFormContext/MultiStepFormContext";
import ErrorState from "components/atoms/error-state/ErrorState";

export interface IInitialLoginProps { }

const InitialLogin: FC<IInitialLoginProps> = () => {
  const { updateFormData } = useMultiStepFormContext();
  const { mutate: login, isError, isLoading } = useLogin();
  const { showPassword, toggleShowPassword } = useShowPassowrd();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues
  } = useLoginForm();

  const onSubmit = () => {
    const data = getValues();
    login(data)
    updateFormData(data)
  };

  return (
    <div className="flex flex-col gap-8">
      <div>
        <div className="pb-2">
          <LoginTitle title="Welcome" />
        </div>
        <Paragraph paragraph="Please login, to continue." />
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-6">
          <Input
            label="Email"
            placeholder="My.email@finanu.ch"
            type="email"
            autoComplete="email"
            {...register("email")}
            icon={isError ? IconType.CRICLE_CLOSE_ICON : undefined}
            classes={{ fillcolor: isError ? "#D61616" : "" }}
            error={errors.email}
            className={`${isError ? "border-red-500" : "border-inputborder"}`}
          />
          <div className="relative">
            <Input
              label="Password"
              placeholder="••••••••"
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              {...register("password")}
              error={errors.password}
              classes={{ fillcolor: isError ? "#D61616" : "" }}
              className={`${isError ? "border-red-500" : "border-inputborder"}`}
              icon={
                showPassword
                  ? IconType.HIDDEN_PASSWORD_ICON
                  : IconType.SHOW_PASSWORD_ICON
              }
              toggleShowPassowrd={toggleShowPassword}
            />

            <ErrorState error={isError}>Invalid credentials</ErrorState>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <input type="checkbox" />
              <span className="text-primaryTextColor font-inter-medium text-[11px] md:text-[16px]">
              Remember me
              </span>
            </div>
            <BlueLink
              className="text-[11px] sm:text-[14px]"
              to="/forgot-password"
              title="Forget password"
            />
          </div>
          <Button
            type="submit"
            className="bg-bluebutton text-white p-[15px] w-full rounded-[8px] disabled:opacity-50"
            disabled={isLoading}
          >
            Login
          </Button>
          <div className="text-center">
            <span className="text-[14px] font-inter-regular">
              You don’t have an account yet?
            </span>
            <BlueLink to="/signup" title="Register now." />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default InitialLogin;
