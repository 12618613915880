import Source from "components/organisms/source/Source";
import { FC } from "react";

export interface ISourceViewProps {}

const SourceView: FC<ISourceViewProps> = () => {
  return <Source />;
};

export default SourceView;
