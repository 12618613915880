import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line
export interface ICustomerGridCard<T> {
  first_name?: number;
  last_name?: string;
  created_at: string;
  products: Array<any>;
  setUpdateData: Dispatch<SetStateAction<any>>; // Corrected prop name to match the parent component
  data: any;
  setIsModalOpen: (value: boolean) => void;
  renderButton?: () => JSX.Element;
}

const CustomerGridCard: FC<ICustomerGridCard<Array<[]>>> = ({
  first_name,
  last_name,
  created_at,
  products,
  setUpdateData,
  data,
  setIsModalOpen,
  renderButton,
}) => {

  const {t} = useTranslation();

  const date = new Date(created_at);
  const formattedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  return (
    <div className="w-full relative flex flex-col max-w-screen-sm border gap-3 border-solid border-gray-200 rounded-2xl  p-3 overflow-x-auto verticalOverflowScrollStyle">
      <div className="flex flex-row items-center justify-between px-2 ">
        <h1 className="text-[#282D46] font-inter-medium">
          {first_name} {last_name}
        </h1>
        {renderButton && renderButton()}
      </div>
      <p className="text-[#282D46] opacity-50 text-[11px] font-inter-medium px-2">
        {formattedDate}
      </p>
      <div className="flex flex-row text-[#282D46] justify-between text-sm  font-inter-regular px-2">
        <span className="font-inter-regular">{t('Total Products')}</span>
        <span className="font-inter-regular">{products.length}</span>
      </div>
      <div className="text-sm  font-inter-regular px-2">
        <table className="table-auto capitalize">
          <thead>
            <tr>
              <th className="font-inter-regular font-normal px-4 py-1 whitespace-nowrap pl-0">
                {t('Company Name')}
              </th>
              <th className="font-inter-regular font-normal py-1 px-4 whitespace-nowrap	">
                {t('Product Name')}
              </th>
              <th className="font-inter-regular font-normal py-1 px-4 whitespace-nowrap	">
                {t('Provision')}
              </th>
              <th className="font-inter-regular font-normal px-4 py-1 whitespace-nowrap	">
                {t("Status")}
              </th>
            </tr>
          </thead>
          {products?.map((item, index: number) => {
            return (
              <tbody key={`key-${index}`}>
                <tr>
                  <td className="font-inter-regular px-4 py-1 pl-0 text-center">
                    {item.company_name ?? "N/A"}
                  </td>
                  <td className="font-inter-regular px-4 py-1 text-center">
                    {item.name ?? "N/A"}
                  </td>
                  <td className="font-inter-regular px-4 py-1 text-center">
                    {item.commission || "N/A"}
                  </td>
                  <td
                    className={`font-inter-regular px-4 py-1 text-center capitalize   ${
                      item?.status === "Provisioniert"
                        ? "border-[#2DE28B] text-[#2DE28B]"
                        : item?.status === "Quality"
                        ? "border-[#50B8E7] text-[#50B8E7]"
                        : item?.status === "Pending"
                        ? "border-[#EFBE12] text-[#EFBE12]"
                        : item?.status === "Canceled"
                        ? "border-[#CC7272] text-[#CC7272]"
                        : item?.status === "open"
                        ? "border-[#282D46] text-[#282D46]"
                        : ""
                    }`}
                  >
                    {item.status ?? "N/A"}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default CustomerGridCard;
