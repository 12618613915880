export function getStatusColor(status: string) {
    switch (status) {
      case "Pending":
        return "text-[#EFBE12] bg-[#EFBE12] bg-opacity-10 ";
      case "Won":
        return "text-[#2DE28B] bg-[#2DE28B] bg-opacity-10";
      case "Follow Up":
        return "text-[#70DDE4] bg-[#70DDE4] bg-opacity-10";
      case "Lost":
        return "text-[#CC7272] bg-[#CC7272] bg-opacity-10";
      case "Open":
        return "text-[#282D46] bg-[#282D46] bg-opacity-10";
      default:
        return "";
    }
  }
