
import { FC, HTMLAttributes } from "react";

export interface IFilterBorderedWrapperProps extends HTMLAttributes<HTMLFormElement> {
    children: React.ReactNode;
    onClick?: () => void
}

const FilterBorderedWrapper: FC<IFilterBorderedWrapperProps> = ({ children,onClick, ...rest }) => {
    return (
        <div onClick={onClick} className={`border-[#F2F4F7] 2xl:p-[10px] p-[7px] border rounded-[8px]  flex justify-center items-center ${rest.className}`}>
            {children}
        </div>
    )

};

export default FilterBorderedWrapper;