import { useQuery } from "react-query"
import { getStatisticCardsData } from "api/Leads";


export const useLeadsStatisticCard = (hasOwn: any) => {
    return useQuery<Array<any> | any>(
      ['leads', hasOwn],
        () => getStatisticCardsData({owne_leads: hasOwn}),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}