import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function ProfileDownloadIcon({ className, color }: Props) {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.67279 11.5303C4.3799 11.2374 4.3799 10.7626 4.6728 10.4697C4.96569 10.1768 5.44057 10.1768 5.73346 10.4697L8.45309 13.1893L8.45309 1C8.45309 0.585786 8.78888 0.25 9.20309 0.25C9.6173 0.25 9.95309 0.585786 9.95309 1V13.1894L12.6728 10.4697C12.9657 10.1768 13.4406 10.1768 13.7335 10.4697C14.0263 10.7626 14.0263 11.2374 13.7335 11.5303L9.73342 15.5303C9.59276 15.671 9.402 15.75 9.20308 15.75C9.00417 15.75 8.81341 15.671 8.67276 15.5303L4.67279 11.5303ZM3.54294 8.46913C3.80201 8.14594 3.75004 7.67392 3.42685 7.41484C3.10366 7.15576 2.63164 7.20774 2.37256 7.53093C1.17184 9.02878 0.453125 10.9315 0.453125 13C0.453125 17.8325 4.37063 21.75 9.20312 21.75C14.0356 21.75 17.9531 17.8325 17.9531 13C17.9531 10.9315 17.2344 9.02878 16.0337 7.53093C15.7746 7.20774 15.3026 7.15576 14.9794 7.41484C14.6562 7.67392 14.6042 8.14594 14.8633 8.46913C15.8584 9.71047 16.4531 11.2848 16.4531 13C16.4531 17.0041 13.2072 20.25 9.20312 20.25C5.19906 20.25 1.95312 17.0041 1.95312 13C1.95312 11.2848 2.54784 9.71047 3.54294 8.46913Z"
        fill="#282D46"
      />
    </svg>
  );
}
export default memo(ProfileDownloadIcon);
