import React from "react";
import { useDrag } from "react-dnd";
import OverviewCard from "./OverviewCard";

const ItemType = {
  CARD: "card",
};

interface DraggableOverviewCardProps {
  id: number;
  index: number;
  source: string;
  themeColors: {
    primary: string;
  };
  moveCard: (
    dragIndex: number,
    hoverIndex: number,
    source: string,
    destination: string
  ) => void;
  removeCard: (id: number, source: string) => void;
}

const DraggableOverviewCard: React.FC<DraggableOverviewCardProps> = ({
  id,
  index,
  source,
  themeColors,
  removeCard,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.CARD,
    item: { id, index, source },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const handleRemove = () => {
    removeCard(id, source);
  };

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <OverviewCard
        id={id}
        themeColors={themeColors}
        removeCard={handleRemove}
        source={source}
      />
    </div>
  );
};

export default DraggableOverviewCard;
