import { DeleteLeadDocument } from "api/Leads";
import { queryClient } from "lib/helpers/configs/queryClient";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useDeleteLeadDocument = () => {
  return useMutation(DeleteLeadDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries("leadsDocument");
      queryClient.refetchQueries("leadsDocument", { exact: true });
      toast.success("Document deleted Successfully");
    },
    onError: () => {
      toast.error(`Failed to delete document! Please try again later.`);
    },
  });
};
