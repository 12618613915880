import { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}

const ArchivedPartnersIcon = ({ className, color }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.90625 14.7705V23.2968C0.90625 23.6851 1.22106 24 1.60937 24H24.2031C24.5914 24 24.9062 23.6851 24.9062 23.2968V14.7705C24.9075 14.6299 24.8641 14.4879 24.7855 14.3718L21.9756 10.1569C21.8452 9.96125 21.6257 9.84373 21.3906 9.84373H20.6875V3.51562C20.6875 3.12731 20.3726 2.81249 19.9843 2.81249H19.2812V0.703124C19.2812 0.314812 18.9664 0 18.5781 0H7.28124C6.89293 0 6.57811 0.314812 6.57811 0.703124V2.81249H5.87499C5.48668 2.81249 5.17187 3.12731 5.17187 3.51562V9.84373H4.42187C4.18679 9.84373 3.96723 9.96125 3.83682 10.1568C3.83682 10.1568 1.02611 14.3729 1.02569 14.3735C1.02344 14.3783 0.90625 14.5396 0.90625 14.7656L0.90625 14.7705ZM23.5 22.5937H2.3125V15.4687H9.39061V17.5781C9.39061 17.9664 9.70542 18.2812 10.0937 18.2812H15.7656C16.1539 18.2812 16.4687 17.9664 16.4687 17.5781V15.4687H23.5V22.5937ZM21.0143 11.25L22.8893 14.0625H20.6875V11.25H21.0143ZM7.98436 1.40625H17.875V2.81249H7.98436V1.40625ZM6.57811 4.21874H19.2812V14.0625H15.7656C15.3773 14.0625 15.0625 14.3773 15.0625 14.7656V16.875H10.7969V14.7656C10.7969 14.3773 10.482 14.0625 10.0937 14.0625H6.57811V4.21874ZM4.79818 11.25H5.17187V14.0625H2.92318L4.79818 11.25Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M8.6875 7.03125H17.1719C17.5602 7.03125 17.875 6.71644 17.875 6.32812C17.875 5.93981 17.5602 5.625 17.1719 5.625H8.6875C8.29919 5.625 7.98438 5.93981 7.98438 6.32812C7.98438 6.71644 8.29919 7.03125 8.6875 7.03125Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M8.6875 9.84375H17.1719C17.5602 9.84375 17.875 9.52894 17.875 9.14062C17.875 8.75231 17.5602 8.4375 17.1719 8.4375H8.6875C8.29919 8.4375 7.98438 8.75231 7.98438 9.14062C7.98438 9.52894 8.29919 9.84375 8.6875 9.84375Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M8.6875 12.6562H14.3594C14.7477 12.6562 15.0625 12.3414 15.0625 11.9531C15.0625 11.5648 14.7477 11.25 14.3594 11.25H8.6875C8.29919 11.25 7.98438 11.5648 7.98438 11.9531C7.98438 12.3415 8.29919 12.6562 8.6875 12.6562Z"
        fill={color || '#50B8E7'}
      />
    </svg>
  );
};

export default memo(ArchivedPartnersIcon);
