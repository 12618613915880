import { createNewPartner } from "api/Partners";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useCreatePartners = () => {
  const queryClient = useQueryClient();
  return useMutation(createNewPartner, {
    onSuccess: () => {
      queryClient.invalidateQueries('partners');
      toast.success("Partner Created Successfully");
    },
    onError: () => {
      toast.error(`Something went wrong`);
    },
  });
};
