import { getActivityLog } from "api/Customers";
import { UseQueryOptions, useQuery } from "react-query";

interface DocumentParams {
    costumer_id?: any;
}

export const useCustomerLogs = (
params: DocumentParams,
  options?: UseQueryOptions<any, any, unknown, string[]>
) => {
  const queryInfo = useQuery(
    ['activityLog'],
    () => getActivityLog(params),
    {
      onError: (err) => {
        console.error(err);
      },
      ...options,
      enabled: false,
    }
  );

  return queryInfo;
};