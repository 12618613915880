import { memo } from "react";
interface Props {
  className?: string;
}
function FlagIcon({ className }: Props) {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_2560_34926)">
        <path
          d="M23.2258 3.05176e-05H12.3871L0.421875 8.55706V15.6693H23.2258L24 15.2V13.7143L23.4961 13.2192V11.8145L24 11.4286V9.14287L23.5469 8.69429V7.25729L24 6.8571V4.57142L23.5469 4.15733V2.89795L24 2.28575V0.800026C24 0.358178 23.6534 3.05176e-05 23.2258 3.05176e-05Z"
          fill="#E5646E"
        />
        <path d="M12.0156 2.28574H24V4.57146H12.0156V2.28574Z" fill="#F2F2F6" />
        <path d="M12 6.8571L12.3871 9.14282H24V6.8571H12Z" fill="#F2F2F6" />
        <path
          d="M0.774188 12.8001V8.72662L0 9.14289V11.4286L0.328125 11.7176V13.2192L0 13.7143V15.2001C0 15.6419 0.346641 16.0001 0.774188 16.0001H23.2258C23.6534 16.0001 24 15.6419 24 15.2001H3.0968C1.81406 15.2001 0.774188 14.1256 0.774188 12.8001Z"
          fill="#DB4655"
        />
        <path
          d="M12.3871 3.05176e-05H0.774188L0.28125 0.800026L0.774188 9.14287H12.3871V3.05176e-05Z"
          fill="#5055A0"
        />
        <path
          d="M0.774188 3.05176e-05C0.346641 3.05176e-05 0 0.358178 0 0.800026V9.14287H0.774188V3.05176e-05Z"
          fill="#3F3F8F"
        />
        <path
          d="M1.55303 1.78341C1.33905 1.78341 1.16406 1.60429 1.16406 1.38341C1.16406 1.16254 1.3353 0.983414 1.54928 0.983414H1.55308C1.76706 0.983414 1.94017 1.16254 1.94017 1.38341C1.94017 1.60429 1.76697 1.78341 1.55303 1.78341Z"
          fill="white"
        />
        <path
          d="M1.55303 4.98344C1.33905 4.98344 1.16406 4.80432 1.16406 4.58345C1.16406 4.36257 1.3353 4.18345 1.54928 4.18345H1.55308C1.76706 4.18345 1.94017 4.36257 1.94017 4.58345C1.94017 4.80432 1.76697 4.98344 1.55303 4.98344Z"
          fill="white"
        />
        <path
          d="M1.55303 8.18343C1.33905 8.18343 1.16406 8.00431 1.16406 7.78343C1.16406 7.56255 1.3353 7.38342 1.54928 7.38342H1.55308C1.76706 7.38342 1.94017 7.56255 1.94017 7.78343C1.94017 8.00431 1.76697 8.18343 1.55303 8.18343Z"
          fill="white"
        />
        <path
          d="M1.55303 3.38343C1.33905 3.38343 1.16406 3.2043 1.16406 2.98343C1.16406 2.76255 1.3353 2.58343 1.54928 2.58343H1.55308C1.76706 2.58343 1.94017 2.76255 1.94017 2.98343C1.94013 3.2043 1.76697 3.38343 1.55303 3.38343Z"
          fill="#F2F2F6"
        />
        <path
          d="M1.55303 6.5834C1.33905 6.5834 1.16406 6.40427 1.16406 6.1834C1.16406 5.96252 1.3353 5.7834 1.54928 5.7834H1.55308C1.76706 5.7834 1.94017 5.96252 1.94017 6.1834C1.94017 6.40427 1.76697 6.5834 1.55303 6.5834Z"
          fill="#F2F2F6"
        />
        <path
          d="M10.8421 1.78341C10.6281 1.78341 10.4531 1.60429 10.4531 1.38341C10.4531 1.16254 10.6244 0.983414 10.8383 0.983414H10.8421C11.0561 0.983414 11.2292 1.16254 11.2292 1.38341C11.2292 1.60429 11.0561 1.78341 10.8421 1.78341Z"
          fill="white"
        />
        <path
          d="M10.8421 4.98344C10.6281 4.98344 10.4531 4.80432 10.4531 4.58345C10.4531 4.36257 10.6244 4.18345 10.8383 4.18345H10.8421C11.0561 4.18345 11.2292 4.36257 11.2292 4.58345C11.2292 4.80432 11.0561 4.98344 10.8421 4.98344Z"
          fill="white"
        />
        <path
          d="M10.8421 8.18343C10.6281 8.18343 10.4531 8.00431 10.4531 7.78343C10.4531 7.56255 10.6244 7.38342 10.8383 7.38342H10.8421C11.0561 7.38342 11.2292 7.56255 11.2292 7.78343C11.2292 8.00431 11.0561 8.18343 10.8421 8.18343Z"
          fill="white"
        />
        <path
          d="M10.8421 3.38341C10.6281 3.38341 10.4531 3.20429 10.4531 2.98341C10.4531 2.76254 10.6244 2.58342 10.8383 2.58342H10.8421C11.0561 2.58342 11.2292 2.76254 11.2292 2.98341C11.2292 3.20429 11.0561 3.38341 10.8421 3.38341Z"
          fill="#F2F2F6"
        />
        <path
          d="M10.8421 6.58338C10.6281 6.58338 10.4531 6.40426 10.4531 6.18338C10.4531 5.96251 10.6244 5.78339 10.8383 5.78339H10.8421C11.0561 5.78339 11.2292 5.96251 11.2292 6.18338C11.2292 6.40426 11.0561 6.58338 10.8421 6.58338Z"
          fill="#F2F2F6"
        />
        <path
          d="M3.87334 1.78341C3.65936 1.78341 3.48438 1.60429 3.48438 1.38341C3.48438 1.16254 3.65561 0.983414 3.86959 0.983414H3.87339C4.08738 0.983414 4.26048 1.16254 4.26048 1.38341C4.26048 1.60429 4.08733 1.78341 3.87334 1.78341Z"
          fill="#F2F2F6"
        />
        <path
          d="M3.87334 4.98343C3.65936 4.98343 3.48438 4.80431 3.48438 4.58343C3.48438 4.36256 3.65561 4.18343 3.86959 4.18343H3.87339C4.08738 4.18343 4.26048 4.36256 4.26048 4.58343C4.26048 4.80431 4.08733 4.98343 3.87334 4.98343Z"
          fill="#F2F2F6"
        />
        <path
          d="M3.87334 8.18342C3.65936 8.18342 3.48438 8.00429 3.48438 7.78341C3.48438 7.56253 3.65561 7.38341 3.86959 7.38341H3.87339C4.08738 7.38341 4.26048 7.56253 4.26048 7.78341C4.26048 8.00429 4.08733 8.18342 3.87334 8.18342Z"
          fill="#F2F2F6"
        />
        <path
          d="M3.87334 3.38343C3.65936 3.38343 3.48438 3.2043 3.48438 2.98343C3.48438 2.76255 3.65561 2.58343 3.86959 2.58343H3.87339C4.08738 2.58343 4.26048 2.76255 4.26048 2.98343C4.26044 3.2043 4.08733 3.38343 3.87334 3.38343Z"
          fill="white"
        />
        <path
          d="M3.87334 6.5834C3.65936 6.5834 3.48438 6.40427 3.48438 6.1834C3.48438 5.96252 3.65561 5.7834 3.86959 5.7834H3.87339C4.08738 5.7834 4.26048 5.96252 4.26048 6.1834C4.26048 6.40427 4.08733 6.5834 3.87334 6.5834Z"
          fill="white"
        />
        <path
          d="M2.71319 2.58339C2.4992 2.58339 2.32422 2.40426 2.32422 2.18339C2.32422 1.96251 2.49545 1.78339 2.70944 1.78339H2.71323C2.92722 1.78339 3.10032 1.96251 3.10032 2.18339C3.10028 2.40431 2.92717 2.58339 2.71319 2.58339Z"
          fill="white"
        />
        <path
          d="M2.71319 5.78342C2.4992 5.78342 2.32422 5.6043 2.32422 5.38342C2.32422 5.16255 2.49545 4.98343 2.70944 4.98343H2.71323C2.92722 4.98343 3.10032 5.16255 3.10032 5.38342C3.10032 5.6043 2.92717 5.78342 2.71319 5.78342Z"
          fill="white"
        />
        <path
          d="M2.71319 4.1834C2.4992 4.1834 2.32422 4.00428 2.32422 3.7834C2.32422 3.56253 2.49545 3.38341 2.70944 3.38341H2.71323C2.92722 3.38341 3.10032 3.56253 3.10032 3.7834C3.10032 4.00428 2.92717 4.1834 2.71319 4.1834Z"
          fill="#F2F2F6"
        />
        <path
          d="M2.71319 7.38344C2.4992 7.38344 2.32422 7.20432 2.32422 6.98344C2.32422 6.76256 2.49545 6.58344 2.70944 6.58344H2.71323C2.92722 6.58344 3.10032 6.76256 3.10032 6.98344C3.10032 7.20432 2.92717 7.38344 2.71319 7.38344Z"
          fill="#F2F2F6"
        />
        <path
          d="M6.19756 1.78341C5.98358 1.78341 5.80859 1.60429 5.80859 1.38341C5.80859 1.16254 5.97983 0.983414 6.19381 0.983414H6.19761C6.41159 0.983414 6.5847 1.16254 6.5847 1.38341C6.5847 1.60429 6.4115 1.78341 6.19756 1.78341Z"
          fill="white"
        />
        <path
          d="M6.19756 4.98344C5.98358 4.98344 5.80859 4.80432 5.80859 4.58345C5.80859 4.36257 5.97983 4.18345 6.19381 4.18345H6.19761C6.41159 4.18345 6.5847 4.36257 6.5847 4.58345C6.5847 4.80432 6.4115 4.98344 6.19756 4.98344Z"
          fill="white"
        />
        <path
          d="M6.19756 8.18343C5.98358 8.18343 5.80859 8.00431 5.80859 7.78343C5.80859 7.56255 5.97983 7.38342 6.19381 7.38342H6.19761C6.41159 7.38342 6.5847 7.56255 6.5847 7.78343C6.5847 8.00431 6.4115 8.18343 6.19756 8.18343Z"
          fill="white"
        />
        <path
          d="M6.19756 3.38343C5.98358 3.38343 5.80859 3.2043 5.80859 2.98343C5.80859 2.76255 5.97983 2.58343 6.19381 2.58343H6.19761C6.41159 2.58343 6.5847 2.76255 6.5847 2.98343C6.58466 3.2043 6.4115 3.38343 6.19756 3.38343Z"
          fill="#F2F2F6"
        />
        <path
          d="M6.19756 6.5834C5.98358 6.5834 5.80859 6.40427 5.80859 6.1834C5.80859 5.96252 5.97983 5.7834 6.19381 5.7834H6.19761C6.41159 5.7834 6.5847 5.96252 6.5847 6.1834C6.5847 6.40427 6.4115 6.5834 6.19756 6.5834Z"
          fill="#F2F2F6"
        />
        <path
          d="M5.03741 2.58339C4.82342 2.58339 4.64844 2.40426 4.64844 2.18339C4.64844 1.96251 4.81967 1.78339 5.03366 1.78339H5.03745C5.25144 1.78339 5.42455 1.96251 5.42455 2.18339C5.42455 2.40431 5.25139 2.58339 5.03741 2.58339Z"
          fill="#F2F2F6"
        />
        <path
          d="M5.03741 5.78342C4.82342 5.78342 4.64844 5.6043 4.64844 5.38342C4.64844 5.16255 4.81967 4.98343 5.03366 4.98343H5.03745C5.25144 4.98343 5.42455 5.16255 5.42455 5.38342C5.42455 5.6043 5.25139 5.78342 5.03741 5.78342Z"
          fill="#F2F2F6"
        />
        <path
          d="M5.03741 4.1834C4.82342 4.1834 4.64844 4.00428 4.64844 3.7834C4.64844 3.56253 4.81967 3.38341 5.03366 3.38341H5.03745C5.25144 3.38341 5.42455 3.56253 5.42455 3.7834C5.42455 4.00428 5.25139 4.1834 5.03741 4.1834Z"
          fill="white"
        />
        <path
          d="M5.03741 7.38344C4.82342 7.38344 4.64844 7.20432 4.64844 6.98344C4.64844 6.76256 4.81967 6.58344 5.03366 6.58344H5.03745C5.25144 6.58344 5.42455 6.76256 5.42455 6.98344C5.42455 7.20432 5.25139 7.38344 5.03741 7.38344Z"
          fill="white"
        />
        <path
          d="M8.51788 1.78341C8.30389 1.78341 8.12891 1.60429 8.12891 1.38341C8.12891 1.16254 8.30014 0.983414 8.51413 0.983414H8.51792C8.73191 0.983414 8.90502 1.16254 8.90502 1.38341C8.90502 1.60429 8.73186 1.78341 8.51788 1.78341Z"
          fill="#F2F2F6"
        />
        <path
          d="M8.51788 4.98344C8.30389 4.98344 8.12891 4.80432 8.12891 4.58345C8.12891 4.36257 8.30014 4.18345 8.51413 4.18345H8.51792C8.73191 4.18345 8.90502 4.36257 8.90502 4.58345C8.90502 4.80432 8.73186 4.98344 8.51788 4.98344Z"
          fill="#F2F2F6"
        />
        <path
          d="M8.51788 8.18343C8.30389 8.18343 8.12891 8.00431 8.12891 7.78343C8.12891 7.56255 8.30014 7.38342 8.51413 7.38342H8.51792C8.73191 7.38342 8.90502 7.56255 8.90502 7.78343C8.90502 8.00431 8.73186 8.18343 8.51788 8.18343Z"
          fill="#F2F2F6"
        />
        <path
          d="M8.51788 3.38343C8.30389 3.38343 8.12891 3.2043 8.12891 2.98343C8.12891 2.76255 8.30014 2.58343 8.51413 2.58343H8.51792C8.73191 2.58343 8.90502 2.76255 8.90502 2.98343C8.90497 3.2043 8.73186 3.38343 8.51788 3.38343Z"
          fill="white"
        />
        <path
          d="M8.51788 6.5834C8.30389 6.5834 8.12891 6.40427 8.12891 6.1834C8.12891 5.96252 8.30014 5.7834 8.51413 5.7834H8.51792C8.73191 5.7834 8.90502 5.96252 8.90502 6.1834C8.90502 6.40427 8.73186 6.5834 8.51788 6.5834Z"
          fill="white"
        />
        <path
          d="M7.35381 2.58339C7.13983 2.58339 6.96484 2.40426 6.96484 2.18339C6.96484 1.96251 7.13608 1.78339 7.35006 1.78339H7.35386C7.56784 1.78339 7.74095 1.96251 7.74095 2.18339C7.74091 2.40431 7.5678 2.58339 7.35381 2.58339Z"
          fill="white"
        />
        <path
          d="M7.35381 5.78342C7.13983 5.78342 6.96484 5.6043 6.96484 5.38342C6.96484 5.16255 7.13608 4.98343 7.35006 4.98343H7.35386C7.56784 4.98343 7.74095 5.16255 7.74095 5.38342C7.74095 5.6043 7.5678 5.78342 7.35381 5.78342Z"
          fill="white"
        />
        <path
          d="M7.35381 4.1834C7.13983 4.1834 6.96484 4.00428 6.96484 3.7834C6.96484 3.56253 7.13608 3.38341 7.35006 3.38341H7.35386C7.56784 3.38341 7.74095 3.56253 7.74095 3.7834C7.74095 4.00428 7.5678 4.1834 7.35381 4.1834Z"
          fill="#F2F2F6"
        />
        <path
          d="M7.35381 7.38344C7.13983 7.38344 6.96484 7.20432 6.96484 6.98344C6.96484 6.76256 7.13608 6.58344 7.35006 6.58344H7.35386C7.56784 6.58344 7.74095 6.76256 7.74095 6.98344C7.74095 7.20432 7.5678 7.38344 7.35381 7.38344Z"
          fill="#F2F2F6"
        />
        <path
          d="M9.68194 2.58339C9.46795 2.58339 9.29297 2.40426 9.29297 2.18339C9.29297 1.96251 9.4642 1.78339 9.67819 1.78339H9.68198C9.89597 1.78339 10.0691 1.96251 10.0691 2.18339C10.069 2.40431 9.89592 2.58339 9.68194 2.58339Z"
          fill="#F2F2F6"
        />
        <path
          d="M9.68194 5.78342C9.46795 5.78342 9.29297 5.6043 9.29297 5.38342C9.29297 5.16255 9.4642 4.98343 9.67819 4.98343H9.68198C9.89597 4.98343 10.0691 5.16255 10.0691 5.38342C10.0691 5.6043 9.89592 5.78342 9.68194 5.78342Z"
          fill="#F2F2F6"
        />
        <path
          d="M9.68194 4.1834C9.46795 4.1834 9.29297 4.00428 9.29297 3.7834C9.29297 3.56253 9.4642 3.38341 9.67819 3.38341H9.68198C9.89597 3.38341 10.0691 3.56253 10.0691 3.7834C10.0691 4.00428 9.89592 4.1834 9.68194 4.1834Z"
          fill="white"
        />
        <path
          d="M9.68194 7.38344C9.46795 7.38344 9.29297 7.20432 9.29297 6.98344C9.29297 6.76256 9.4642 6.58344 9.67819 6.58344H9.68198C9.89597 6.58344 10.0691 6.76256 10.0691 6.98344C10.0691 7.20432 9.89592 7.38344 9.68194 7.38344Z"
          fill="white"
        />
        <path
          d="M0.140625 13.0537L0.949687 13.7143H24V11.4286H0.774188L0.140625 13.0537Z"
          fill="#F2F2F6"
        />
        <path
          d="M0 11.4286V13.7144H0.949687C0.837141 13.4325 0.774234 13.124 0.774234 12.8001V11.4286H0L0 11.4286Z"
          fill="#E1E1E6"
        />
      </g>
      <defs>
        <clipPath id="clip0_2560_34926">
          <rect width="24" height="16" rx="1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default memo(FlagIcon);
