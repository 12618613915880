import IconButton from "components/atoms/button/IconButton";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import FileDropZone from "components/molecules/file-dropzone/FileDropZone";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import UploadedFile from "components/molecules/uploaded-file/UploadedFile";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useLeadsForm } from "lib/hooks/forms/useLeadsForm";
import { useDeleteLeadDocument } from "lib/hooks/mutations/Leads/useDeleteLeadDocument";
import { useUploadLeadFiles } from "lib/hooks/mutations/Leads/useUploadLeadFiles";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface IUploadLeadFileProps {
  onConfirm: () => void;
  lead: any;
  isLoadingGettingDocuments: boolean;
  data: any;
}

const UploadLeadFilesTab = ({
  onConfirm,
  lead,
  isLoadingGettingDocuments,
  data,
}: IUploadLeadFileProps) => {
  const { control, reset } = useLeadsForm();

  const { mutateAsync: uploadLeadFiles, isLoading } = useUploadLeadFiles(
    lead.id ?? ""
  );

  const [resetCount, setresetCount] = useState<number>(0);

  const [File, setFile] = useState<File | any>();

  const HandleFileUpload = (e: any) => {
    e.preventDefault();
    const formData = new FormData();

    if (!File) {
      return onConfirm();
    }

    formData.append("lead_id", lead.id);
    formData.append("document", File[0]);

    uploadLeadFiles(formData).then(() => {
      setFile(undefined);
      // if (res) {
      //   onConfirm();
      // }
    });
  };

  const { mutateAsync: DeleteLeadDocument } = useDeleteLeadDocument();

  const [IsDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const {t} = useTranslation();
  const DeleteDocument = (document_id: string) => {
    const data = { document_id, lead_id: lead?.id };

    setIsDeleteLoading(true);
    DeleteLeadDocument(data)
      .then(() => {
        setIsDeleteLoading(false);
        // onConfirm();
      })
      .catch(() => {});
  };

  return (
    <>
      {isLoadingGettingDocuments ? (
        <LoadingScreen />
      ) : (
        <Form className="flex flex-col gap-5 mb-0 " onSubmit={HandleFileUpload}>
          <InputsFolder title={t('Upload new file')}>
            <FileDropZone
              key={`file-dropzone-${resetCount}`}
              file={File}
              setFile={setFile}
              name="files"
              label=""
              control={control}
              classes={{ label: "bg-white" }}
            />
          </InputsFolder>

          <ConditionalRender condition={data?.data?.length > 0}>
            {IsDeleteLoading ? (
              <LoadingScreen />
            ) : (
              <InputsFolder title={`${t('Files')} (${data?.data?.length})`}>
                <div className="flex flex-col gap-4">
                  {data?.data?.map((item: any) => (
                    <UploadedFile
                      key={item.id}
                      icon={
                        item.name.split(".")[1] === "pdf"
                          ? IconType.FILE_PDF_ICON
                          : item.name.split(".")[1] === "csv" ||
                            item.name.split(".")[1] === "xlsx" ||
                            item.name.split(".")[1] === "xls"
                          ? IconType.UPLOADED_CSV_ICON
                          : IconType.FILE_JPG_ICON
                      }
                      title={item.name}
                      size=""
                      updatedate={item.created_at.substring(0, 10)}
                      link={item.file}
                      linkName="leads"
                      deleteIcon={
                        <IconButton
                          onClick={() => {
                            reset();
                            DeleteDocument(item.id);
                            setresetCount((prevCount) => prevCount + 1);
                          }}
                          type="button"
                          className="w-full bg-transparent border-none p-0"
                        >
                          <Icon
                            iconType={IconType.DELETE_ICON}
                            color="#979797"
                          />
                        </IconButton>
                      }
                    />
                  ))}
                </div>
              </InputsFolder>
            )}
          </ConditionalRender>

          <div className="flex justify-end pt-5">
            <IconButton disabled={isLoading}>
              {isLoading ? t("Saving Changes...") : t("Save changes")}
            </IconButton>
          </div>
        </Form>
      )}
    </>
  );
};

export default UploadLeadFilesTab;
