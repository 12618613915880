import { StylesConfig } from "react-select";

export const customStyles: StylesConfig<any, false> = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
    borderColor: "#ced4da",
    backgroundColor: "white",
    borderRadius: "8px",
    fontFamily: "InterRegular",
    "::placeholder": {
      fontFamily: "InterRegular",
      color: "#667085",
    },
    cursor: "pointer",
    "&:hover": {
      borderColor: "#ced4da",
    },
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "black",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e2e8f0",
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "black",
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "white",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const customStylesLightBorder: StylesConfig<any, false> = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
    borderColor: "#F2F4F7",
    backgroundColor: "white",
    borderRadius: "8px",
    fontFamily: "InterRegular",
    "::placeholder": {
      fontFamily: "InterRegular",
      color: "#7D8592",
    },
    cursor: "pointer",
    "&:hover": {
      borderColor: "#F2F4F7",
    },
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "black",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e2e8f0",
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "black",
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "white",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};
