import { useMutation } from "react-query";
import { createAccount } from "../../../../api/User";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useAuthContext } from "lib/context/AuthContextContext/AuthContext";

export const useCreateAccount = () => {
  const { nextFormStep, updateFormData } = useMultiStepFormContext();
  const {setUser} = useAuthContext();
  return useMutation(createAccount, {
    onSuccess: (res) => {
      updateFormData(res);
      nextFormStep();
      setUser(res)
    },
    onError: (error) => {
      console.error(error);
    },
  });
};
