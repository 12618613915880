import { requestFeedBack } from "api/Customers"
import { queryClient } from "lib/helpers/configs/queryClient"
import { useMutation } from "react-query"
import { toast } from "react-toastify"



export const useRequestSecurityFeedbackExport = () => {
    return useMutation(requestFeedBack,{
        onSuccess: () => {
            queryClient.invalidateQueries('customersSecurity');
            toast.success('Successfully')
        },
        onError: () => {
            toast.error('Something went wrong!')
        }
    })
}