import { apiRequest } from "./Api";

export const getCustomersPendings = (params?: any) =>
  apiRequest<any, any>("GET", "costumers/pendings", null, undefined, params);

export const getDataById = (id: number) =>
  apiRequest<any, any>("GET", `costumers/pendings/${id}`);

export const sendComment = (data?: any) =>
  apiRequest<any, unknown>("POST", "costumers/pendings/store-comment", data);

export const storeNewDocument = (data?: any) =>
  apiRequest<any, unknown>("POST", "costumers/pendings/store-document", data);

export const showPendingDocument = (link?: string) =>
  apiRequest<any, any>("GET", `costumers/pendings/show-file/${link}`);

export const sendFeedback = (data?: any) =>
  apiRequest<any, unknown>("POST", "costumers/pendings/feedback-file", data);


export const makeDonePending = (data?: any) => 
  apiRequest<FormData,any>('POST', 'costumers/pendings/complete',data)
