import Button from "components/atoms/button/Button";
import IconButton from "components/atoms/button/IconButton";
import Checkbox from "components/atoms/checkbox/Checkbox";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC, useState } from "react";
import Step1TransferTable from "./step1-transfer-table/Step1TransferTable";
import Step2ChoosePartner from "./step2-choose-partner/Step2ChoosePartner";
import { Check2Icon } from "components/atoms/icon/export";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line
export interface ITransferAppointmentModalProps<T> {
  onConfirm: () => void;
  themeColors: {
    primary: string;
  };
}

const TransferAppointmentModal: FC<ITransferAppointmentModalProps<any>> = ({
  onConfirm,
  themeColors,
}) => {
 
  const { t } = useTranslation();

  const columns = [
    {
      id: 1,
      Header: <Checkbox />,
      accessor: "id",
      Cell: ({ row: { original } }: any) => (
        <div className="flex items-center gap-5">
          <Checkbox />
          {original.id}
        </div>
      ),
      isChecked: true,
    },
    {
      id: 2,
      Header: `${t("Appointment Date")}`,
      accessor: "Appointment Date",
      Cell: ({ row: { original } }: any) => <>{`${original.date}`}</>,
      isChecked: true,
    },
    {
      id: 3,
      Header: `${t("Appointment Title")}`,
      accessor: (row: any) =>
        row?.info.first_name + row?.info.last_name || t("Not available"),
      isChecked: true,
    },
    {
      id: 4,
      Header: `${t("Persons")}`,
      accessor: (row: any) => row?.info.persons || t("Not available"),
      isChecked: true,
    },
    {
      id: 5,
      Header: `${t("Health Insurance")}`,
      accessor: "HealthInsurance",
      Cell: ({ row: { original } }: any) => (
        <>{original.is_digital === 0 ? "Physical" : "Digital"}</>
      ),
      isChecked: true,
    },
    {
      id: 6,
      Header: `${t("Status")}`,
      accessor: "status",
      Cell: ({ row: { original } }: any) => <>{original.feedback_status}</>,
      isChecked: true,
    },
    {
      id: 7,
      Header: `${t("Reasons")}`,
      accessor: "reasons",
      isChecked: true,
    },
    {
      id: 8,
      Header: `${t("Notes")}`,
      accessor: (row: any) => row?.info.note || t("Not available"),
      isChecked: true,
    },
    {
      id: 9,
      Header: "",
      accessor: "actions",
      Cell: ({ row: { original } }: any) => {
        return (
          <Button
            className="cursor-pointer"
            // onClick={() => updateAppointment(original)}
          >
            <Icon iconType={IconType.OPTIONS_ICON} />
          </Button>
        );
      },
      isChecked: true,
    },
  ];

  const [Step, setStep] = useState(1);

  return (
    <div className="mb-0 flex flex-col gap-7">
      <button
        type="button"
        className="absolute top-[26px] sm:top-[31px] md:top-[45px]"
        onClick={() => {
          setStep(Step > 1 ? Step - 1 : 1);
        }}
      >
        <Icon
          iconType={IconType.BACK_BUTTON_MODAL}
          color={themeColors.primary}
        />
      </button>

      <div className="mb-0 flex flex-col pl-4">
        <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
          <div
            className={`text-[14px] text-primaryTextColor font-inter-medium pb-5`}
          >
            {t('Choose the appointments you want to transfer')}
          </div>
          {Step === 1 && <Step1TransferTable data={[]} columns={columns} />}

          <div className="absolute top-[0] left-[-12px] z-9999">
            <Icon
              iconType={IconType.CHECK_ICON}
              color={themeColors.primary}
              className={`overflow-visible`}
            />
          </div>
        </div>
        <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 relative bg-transparent">
          <div
            className={`text-[14px] text-primaryTextColor font-inter-medium ${
              Step !== 2 ? "opacity-40" : ""
            }`}
          >
            {t('Choose partner')}
          </div>
          <div className="absolute top-[0px] left-[-12px] z-9999 ">
            <Check2Icon className="opacity-40" color={themeColors.primary} />
          </div>
        </div>
        {Step === 2 && (
          <div className="pl-7 pt-5">
            <Step2ChoosePartner />
          </div>
        )}
        <div className="flex justify-end gap-5 pt-5">
          <IconButton
            onClick={() => {
              setStep(1);
            }}
            secondary
            className="w-full max-w-[150px] !text-sm"
          >
            {t('Reset')}
          </IconButton>
          <IconButton
            onClick={() => {
              setStep(2);
            }}
            icon={<Icon iconType={IconType.TRANSFER_ICON} />}
            className="w-full max-w-[150px] !text-sm"
          >
            {t('Transfer')}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default TransferAppointmentModal;
