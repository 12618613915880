import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function HomeIcon({ className , color }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill='none'
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1878_20969)">
        <path
          d="M19.2675 7.55727L12.9467 1.2356C12.1644 0.455591 11.1047 0.0175781 10 0.0175781C8.89529 0.0175781 7.83564 0.455591 7.05334 1.2356L0.73251 7.55727C0.499531 7.78875 0.314818 8.06418 0.189079 8.36759C0.0633394 8.67099 -0.000925334 8.99634 1.00662e-05 9.32477V17.5056C1.00662e-05 18.1686 0.263402 18.8045 0.732243 19.2734C1.20108 19.7422 1.83697 20.0056 2.50001 20.0056H17.5C18.1631 20.0056 18.7989 19.7422 19.2678 19.2734C19.7366 18.8045 20 18.1686 20 17.5056V9.32477C20.0009 8.99634 19.9367 8.67099 19.8109 8.36759C19.6852 8.06418 19.5005 7.78875 19.2675 7.55727ZM12.5 18.3389H7.50001V15.0606C7.50001 14.3976 7.7634 13.7617 8.23224 13.2928C8.70108 12.824 9.33697 12.5606 10 12.5606C10.6631 12.5606 11.2989 12.824 11.7678 13.2928C12.2366 13.7617 12.5 14.3976 12.5 15.0606V18.3389ZM18.3333 17.5056C18.3333 17.7266 18.2455 17.9386 18.0893 18.0949C17.933 18.2511 17.721 18.3389 17.5 18.3389H14.1667V15.0606C14.1667 13.9555 13.7277 12.8957 12.9463 12.1143C12.1649 11.3329 11.1051 10.8939 10 10.8939C8.89494 10.8939 7.83513 11.3329 7.05373 12.1143C6.27233 12.8957 5.83334 13.9555 5.83334 15.0606V18.3389H2.50001C2.279 18.3389 2.06703 18.2511 1.91075 18.0949C1.75447 17.9386 1.66668 17.7266 1.66668 17.5056V9.32477C1.66745 9.10392 1.75517 8.89225 1.91084 8.7356L8.23168 2.41643C8.70143 1.94887 9.33723 1.68639 10 1.68639C10.6628 1.68639 11.2986 1.94887 11.7683 2.41643L18.0892 8.7381C18.2442 8.89414 18.3319 9.10478 18.3333 9.32477V17.5056Z"
        fill={color ? color : "#7D8592"}
          />
      </g>
      <defs>
        <clipPath id="clip0_1878_20969">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default memo(HomeIcon);
