import { getPermissionsOfModule } from "api/User"
import { permissionsKeys } from "lib/hooks/forms/keys/permissionsKeys"
import { IPermissionsModuleResponse } from "lib/types/permission.module.types"
import { useQuery } from "react-query"


export const usePermissionsModule = (search?: string) => {
    return useQuery<Array<IPermissionsModuleResponse> | any>(
        permissionsKeys.permissions(search),
        () => getPermissionsOfModule(search ?? ""),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}