import { memo } from "react";
interface Props {
  className?: string;
  color?:string;
}
function LocationIcon({ className , color}: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C7.5741 2 4 5.69567 4 10.2381C4 12.609 5.79814 15.9405 9.36249 20.3921L9.67162 20.7755C10.7181 22.0644 12.6074 22.2606 13.8937 21.2162C14.0533 21.0867 14.1991 20.9411 14.3289 20.7817C18.099 16.1527 20 12.693 20 10.2381C20 5.69567 16.4259 2 12 2ZM12 4C15.3061 4 18 6.78554 18 10.2381C18 12.0829 16.27 15.2313 12.7781 19.5187C12.7349 19.5718 12.6863 19.6203 12.6331 19.6635C12.2043 20.0116 11.5745 19.9463 11.2264 19.5175L10.9216 19.1394C7.63059 15.0292 6 12.0081 6 10.2381C6 6.78554 8.69391 4 12 4ZM12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6ZM12 8C13.1046 8 14 8.89543 14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10C10 8.89543 10.8954 8 12 8Z"
        fill={color || '#7D8592'}
      />
    </svg>
  );
}
export default memo(LocationIcon);
