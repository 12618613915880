import { apiRequest } from "./Api";

export const createEmployee = (data: any) =>
  apiRequest<FormData, any>("POST", "employee/store", data);

export const getSelfProfile = (id?: string) =>
  apiRequest<any, any>("GET", `hr/user-information?id=${id}`);

export const getEmploye = (params?: any) =>
  apiRequest<any, any>("GET", "hr/users", null, undefined, params);

export const updateEmployee = (data: any) => {
  return apiRequest<FormData, any>("POST", `hr/update-user-information`, data, {
    "content-type": "multipart/form-data",
  });
};

export const showEmployeSelfDocument = (data?: any, link?: string) =>
  apiRequest<any, any>("GET", `hr/show-user-document/${link}`, data);

export const showEmployeDocument = (link?: string) =>
  apiRequest<any, any>("GET", `hr/show-user-document/${link}`);
