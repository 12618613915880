export const frequentlyQuestions = [
    {
      id: 1,
      title: "Is there any a possibility of yearly subscription?",
      label:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae numquam officiis rerum exercitationem aspernatur libero qui expedita dolore sapiente doloremque? Beatae rem architecto distinctio molestiae nostrum suscipit cumque veniam, voluptates corporis, fugit molestias voluptatibus natus in cum nisi hic impedit tenetur. Excepturi illo provident culpa quia quas totam repellendus repellat!      ",
    },
    {
      id: 2,
      title: "Is there any a possibility of yearly subscription?",
      label:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae numquam officiis rerum exercitationem aspernatur libero qui expedita dolore sapiente doloremque? Beatae rem architecto distinctio molestiae nostrum suscipit cumque veniam, voluptates corporis, fugit molestias voluptatibus natus in cum nisi hic impedit tenetur. Excepturi illo provident culpa quia quas totam repellendus repellat!",
    },
    {
      id: 3,
      title: "Is there any a possibility of yearly subscription?",
      label:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae numquam officiis rerum exercitationem aspernatur libero qui expedita dolore sapiente doloremque? Beatae rem architecto distinctio molestiae nostrum suscipit cumque veniam, voluptates corporis, fugit molestias voluptatibus natus in cum nisi hic impedit tenetur. Excepturi illo provident culpa quia quas totam repellendus repellat!",
    },
  ];