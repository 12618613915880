import { useForm, UseFormProps } from "react-hook-form";
import * as YUP from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const ResetPasswordFormSchema = YUP.object().shape({
    password: YUP.string()
        .required("Password is required")
        .min(8, "Password should be more than 8 letters"),
    'password_confirmation': YUP.string()
        .required("Password is required")
        .min(8, "Password should be more than 8 letters")
        .oneOf([YUP.ref('password')], 'Passwords must match')
});

export function useResetPasswordForm(options?: UseFormProps<any>) {
    return useForm<any | any>({
        mode: "onSubmit",
        ...options,
        resolver: yupResolver(ResetPasswordFormSchema),
    });
}

export type LoginForm = ReturnType<typeof useResetPasswordForm>;