import { memo } from "react";
interface Props {
  className?: string;
}
function UppercentageIcon({ className }: Props) {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.66323 9.75L7.19908 9.75C8.74266 9.75 9.7044 8.07557 8.92664 6.74226L7.15871 3.71153C6.38695 2.38852 4.47535 2.38852 3.7036 3.71153L1.93567 6.74226C1.1579 8.07557 2.11964 9.75 3.66323 9.75Z"
        fill="#2CC95B"
      />
    </svg>
  );
}
export default memo(UppercentageIcon);
