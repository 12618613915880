import { requestForFollowUp } from "api/Appointment";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useFollowUpFeedback = (onConfirm: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(requestForFollowUp, {
    onSuccess: () => {
      queryClient.invalidateQueries("appointments");
      toast.success("Follow up feedback sent successfully");
      onConfirm();
    },
    onError: () => {
      toast.error(`Something went wrong`);
    },
  });
};
