import { storeUploadedCustomers } from "api/Customers";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useMutation } from "react-query";

export const useUploadCustomersManually = () => {
    const { updateFormData } = useMultiStepFormContext();
    return useMutation(storeUploadedCustomers, {
        onSuccess: (res) => {
            updateFormData(res);
        },
        onError: (error) => {
            console.error(error);
        }
    });
};
