import NewCard from "components/molecules/add-new-card/NewCard";
import Card from "components/molecules/card/Card";
import { ICampaignCardProps } from "components/molecules/card/campaign-card/CampaignCard";
import { CardType } from "lib/helpers/constants/cardTypes";
import { useUpdateCampaignStatus } from "lib/hooks/mutations/Campaign/useUpdateCampaignStatus";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface ICampaignGridProps<T> {
  data: T;
  openNewCampaignModal: () => void;
}


const CampaignGrid: FC<ICampaignGridProps<any>> = ({
  data,
  openNewCampaignModal,
}) => {
  const { mutate: changeStatus } = useUpdateCampaignStatus();

  const {t} = useTranslation()

  return (
    <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-4">
      {data.map((item: any) => {
        return (
          <Card<ICampaignCardProps>
            key={`campaign-card-${item.id}`}
            {...item}
            cardType={CardType.CAMPAIGN_CARD}
            changeStatus={changeStatus}
          />
        );
      })}

      <NewCard
        title={t("New Campaign")}
        desc={t(`Simply create a new source. Just click on the button`)}
        onClick={openNewCampaignModal}
      />
    </div>
  );
};

export default CampaignGrid;
