import IconButton from "components/atoms/button/IconButton";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import { Controller, useForm } from "react-hook-form";
import Creatable from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import React, { Dispatch, useEffect, useState } from "react";
import Label from "components/atoms/input/input-label/InputLabel";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useTranslation } from "react-i18next";

export interface IFilterProps {
  status?: string;
  date_of_creation?: any;
}

const initialState: IFilterProps = {
  status: "",
  date_of_creation: undefined,
};

export interface IFilterTableDropdownProps {
  setFilterData?: (args: any) => void;
  FilterData?: IFilterProps;
  setIsOpen?: Dispatch<React.SetStateAction<boolean>>;
}

const FilterDropDown: React.FC<IFilterTableDropdownProps> = ({
  setFilterData,
  FilterData,
  setIsOpen,
}) => {
  const [DateOfCreation, setDateOfCreation] = useState<Date | null>(
    FilterData?.date_of_creation || null
  );
  const { control, reset } = useForm({
    defaultValues: FilterData || initialState,
  });

  const [InsideData, setInsideData] = useState<IFilterProps>(
    FilterData || initialState
  );

  useEffect(() => {
    setInsideData((prevFormData: any) => ({
      ...prevFormData,
      date_of_creation: DateOfCreation,
    }));
  }, [DateOfCreation]);

  const handleButtonClick = () => {
    if (setFilterData) {
      setFilterData(InsideData);
    }
    setIsOpen && setIsOpen(false);
  };

  const ResetData = () => {
    setInsideData(initialState);
    setDateOfCreation(null);
    reset(initialState);
  };

  // Simulate loading state
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 1000); // Simulate loading delay
    return () => clearTimeout(timer);
  }, []);

  const {t} = useTranslation();

  if (isLoading) {
    return (
      <div className="p-4">
        <LoadingScreen />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3">
      <div>
        <Label text={t("Status")} />
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder={t("Choose Status")}
              options={[
                { label: `${t("Active")}`, value: "active" },
                { label: `${t("Deactive")}`, value: "deactive" },
              ]}
              value={
                field.value
                  ? {
                      value: field.value,
                      label: field.value === "active" ? `${t("Active")}` : `${t("Deactive")}`,
                    }
                  : null
              }
              className="cursor-pointer font-inter-regular react-select-container"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                field.onChange(selectedValue);
                setInsideData((prevState) => ({
                  ...prevState,
                  status: selectedValue,
                }));
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>
      <PrimaryDatePicker
        label={t("Date of creation")}
        setStartDate={setDateOfCreation}
        startDate={DateOfCreation}
        wrapperClassName="!left-[2px]"
      />

      <div className="pt-5">
        <div className="flex flex-row justify-end gap-3">
          <IconButton
            secondary={true}
            className="w-[110px]"
            onClick={ResetData}
          >
            {t("Reset")}
          </IconButton>
          <IconButton className="w-[110px]" onClick={handleButtonClick}>
            {t("Confirm")}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default FilterDropDown;