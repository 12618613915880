import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function TeamIcon({ className, color }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.42969 4.42857V21.5714C4.42969 23.465 5.96475 25 7.85834 25H21.573C23.4666 25 25.0016 23.465 25.0016 21.5714V4.42857C25.0016 2.53502 23.4666 1 21.573 1H7.85834C5.96475 1 4.42969 2.53502 4.42969 4.42857Z"
        stroke={color || "#7D8592"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1425 9.57158C18.1425 11.4651 16.6074 13.0001 14.7138 13.0001C12.8202 13.0001 11.2852 11.4651 11.2852 9.57158C11.2852 7.67803 12.8202 6.14301 14.7138 6.14301C16.6074 6.14301 18.1425 7.67803 18.1425 9.57158Z"
        stroke={color || "#7D8592"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.97931 20.9703C3.64749 21.2182 3.57948 21.6882 3.82741 22.02C4.07534 22.3518 4.54532 22.4198 4.87714 22.1719L3.97931 20.9703ZM24.5512 22.1719C24.8831 22.4198 25.353 22.3518 25.601 22.02C25.8489 21.6882 25.7809 21.2182 25.4491 20.9703L24.5512 22.1719ZM1 7.10672C0.585786 7.10672 0.25 7.44251 0.25 7.85672C0.25 8.27093 0.585786 8.60672 1 8.60672V7.10672ZM4.42865 8.60672C4.84287 8.60672 5.17865 8.27093 5.17865 7.85672C5.17865 7.44251 4.84287 7.10672 4.42865 7.10672V8.60672ZM1 12.2497C0.585786 12.2497 0.25 12.5855 0.25 12.9997C0.25 13.4139 0.585786 13.7497 1 13.7497V12.2497ZM4.42865 13.7497C4.84287 13.7497 5.17865 13.4139 5.17865 12.9997C5.17865 12.5855 4.84287 12.2497 4.42865 12.2497V13.7497ZM1 17.3927C0.585786 17.3927 0.25 17.7285 0.25 18.1427C0.25 18.5569 0.585786 18.8927 1 18.8927V17.3927ZM4.42865 18.8927C4.84287 18.8927 5.17865 18.5569 5.17865 18.1427C5.17865 17.7285 4.84287 17.3927 4.42865 17.3927V18.8927ZM4.87714 22.1719C10.7108 17.8131 18.7175 17.8131 24.5512 22.1719L25.4491 20.9703C19.0829 16.2136 10.3455 16.2136 3.97931 20.9703L4.87714 22.1719ZM1 8.60672H4.42865V7.10672H1V8.60672ZM1 13.7497H4.42865V12.2497H1V13.7497ZM1 18.8927H4.42865V17.3927H1V18.8927Z"
        fill={color || "#7D8592"}
      />
    </svg>
  );
}
export default memo(TeamIcon);
