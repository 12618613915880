import { deleteCustomerDocument } from "api/Customers";
import { queryClient } from "lib/helpers/configs/queryClient";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useDeleteCustomerDocument = () => {
  return useMutation(deleteCustomerDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries("customerDocument");
      queryClient.refetchQueries("customerDocument", { exact: true });
      toast.success("Document deleted Successfully");
    },
    onError: () => {
      toast.error(`Failed to delete document`);
    },
  });
};
