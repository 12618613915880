import { memo } from "react";
interface Props {
    className?: string;
    color?: string
}
const DeleteSourceIcon = ({ className, color }: Props) => {
    return (
        <div>
            <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.8907 2.5H11.6252V1.875C11.6252 0.841125 10.7463 0 9.66595 0H7.05359C5.97325 0 5.09432 0.841125 5.09432 1.875V2.5H1.82887C1.46817 2.5 1.17578 2.77981 1.17578 3.125C1.17578 3.47019 1.46817 3.75 1.82887 3.75H2.48196V12.875C2.48196 14.5981 3.94684 16 5.74741 16H10.9721C12.7727 16 14.2376 14.5981 14.2376 12.875V5.625C14.2376 5.27981 13.9452 5 13.5845 5C13.2238 5 12.9314 5.27981 12.9314 5.625V12.875C12.9314 13.9089 12.0525 14.75 10.9721 14.75H5.74741C4.66707 14.75 3.78814 13.9089 3.78814 12.875V3.75H14.8907C15.2514 3.75 15.5438 3.47019 15.5438 3.125C15.5438 2.77981 15.2514 2.5 14.8907 2.5ZM6.4005 1.875C6.4005 1.53038 6.69348 1.25 7.05359 1.25H9.66595C10.0261 1.25 10.319 1.53038 10.319 1.875V2.5H6.4005V1.875Z" fill="#282D46" />
                <path d="M14.8907 2.5H11.6252V1.875C11.6252 0.841125 10.7463 0 9.66595 0H7.05359C5.97325 0 5.09432 0.841125 5.09432 1.875V2.5H1.82887C1.46817 2.5 1.17578 2.77981 1.17578 3.125C1.17578 3.47019 1.46817 3.75 1.82887 3.75H2.48196V12.875C2.48196 14.5981 3.94684 16 5.74741 16H10.9721C12.7727 16 14.2376 14.5981 14.2376 12.875V5.625C14.2376 5.27981 13.9452 5 13.5845 5C13.2238 5 12.9314 5.27981 12.9314 5.625V12.875C12.9314 13.9089 12.0525 14.75 10.9721 14.75H5.74741C4.66707 14.75 3.78814 13.9089 3.78814 12.875V3.75H14.8907C15.2514 3.75 15.5438 3.47019 15.5438 3.125C15.5438 2.77981 15.2514 2.5 14.8907 2.5ZM6.4005 1.875C6.4005 1.53038 6.69348 1.25 7.05359 1.25H9.66595C10.0261 1.25 10.319 1.53038 10.319 1.875V2.5H6.4005V1.875Z" fill="#282D46" />
                <path d="M5.74733 13.5C5.38663 13.5 5.09424 13.2202 5.09424 12.875V6.875C5.09424 6.52981 5.38663 6.25 5.74733 6.25C6.10803 6.25 6.40042 6.52981 6.40042 6.875V12.875C6.40042 13.2202 6.10803 13.5 5.74733 13.5Z" fill="#282D46" />
                <path d="M5.74733 13.5C5.38663 13.5 5.09424 13.2202 5.09424 12.875V6.875C5.09424 6.52981 5.38663 6.25 5.74733 6.25C6.10803 6.25 6.40042 6.52981 6.40042 6.875V12.875C6.40042 13.2202 6.10803 13.5 5.74733 13.5Z" fill="#282D46" />
                <path d="M8.35963 13.5C7.99893 13.5 7.70654 13.2202 7.70654 12.875V6.875C7.70654 6.52981 7.99893 6.25 8.35963 6.25C8.72033 6.25 9.01272 6.52981 9.01272 6.875V12.875C9.01272 13.2202 8.72033 13.5 8.35963 13.5Z" fill="#282D46" />
                <path d="M8.35963 13.5C7.99893 13.5 7.70654 13.2202 7.70654 12.875V6.875C7.70654 6.52981 7.99893 6.25 8.35963 6.25C8.72033 6.25 9.01272 6.52981 9.01272 6.875V12.875C9.01272 13.2202 8.72033 13.5 8.35963 13.5Z" fill="#282D46" />
                <path d="M10.9719 13.5C10.6112 13.5 10.3188 13.2202 10.3188 12.875V6.875C10.3188 6.52981 10.6112 6.25 10.9719 6.25C11.3326 6.25 11.625 6.52981 11.625 6.875V12.875C11.625 13.2202 11.3326 13.5 10.9719 13.5Z" fill="#282D46" />
                <path d="M10.9719 13.5C10.6112 13.5 10.3188 13.2202 10.3188 12.875V6.875C10.3188 6.52981 10.6112 6.25 10.9719 6.25C11.3326 6.25 11.625 6.52981 11.625 6.875V12.875C11.625 13.2202 11.3326 13.5 10.9719 13.5Z" fill="#282D46" />
            </svg>
        </div>
    )
}

export default memo(DeleteSourceIcon)