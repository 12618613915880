import { dataOfCsv } from "api/Campaign";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useDataOfCsv = () => {
  const { updateFormData } = useMultiStepFormContext();
  return useMutation(dataOfCsv, {
    onSuccess: (res) => {
      updateFormData(res);
    },
    onError: (error) => {
      toast.error("Something went wrong please try again");
    },
  });
};
