import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC } from "react";

export interface ILeadCardProps {
    first_name: string;
    last_name: string;
    health_insurance?: string;
    created_at?: string;
    from_user?: string;
    documents?: any;
    address: string;
    date: string;
}

const LeadCard: FC<ILeadCardProps> = ({
    first_name,
    last_name,
    health_insurance,
    created_at,
    from_user,
    documents,
    address,
    date
}) => {


   
    return (
        <div className="w-full bg-white h-auto rounded-[12px] border-[#282D460F] border-solid border-2 p-5 flex flex-col gap-2">

            <div className="flex gap-3 items-center leading-none">
                <div className="flex-1">
                    <span className="font-inter-medium text-primaryTextColor text-base">
                        {first_name + ' ' + last_name}
                    </span>
                </div>
                <div className="flex-none">
                    <span className="font-inter-regular text-secondaryTextColor text-xs">{date ? date : <>{created_at?.substring(0, 10)}</>}</span>
                </div>
                <div className="flex-none">
                    <Icon
                        iconType={IconType.TOOLTIP_THREE_DOTS}
                        className="w-[2px] h-[10px]"
                    />
                </div>
            </div>
            <div className="flex gap-1 items-center leading-none">
                <div className="flex-none">
                    <div className="rounded-full bg-gray-400 w-6 h-6"></div>
                </div>
                <div className="flex-1 text-xs">
                    <span className="font-inter-medium text-secondaryTextColor ">{from_user} &#8226; {health_insurance} {address}</span>
                </div>
                <div>
                    <span className="font-inter-regular text-secondaryTextColor text-sm"></span>
                </div>
                <div className="flex-none">
                    <Icon
                        iconType={IconType.ATTACHMENT_ICON}
                    />
                </div>
                <div className="flex-none">
                    {/* <span className="font-inter-medium text-secondaryTextColor text-sm">{documents.length}</span> */}
                </div>
            </div>
        </div>
    );
};

export default LeadCard;
