import { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}

function SettingsIcon({ className, color }: Props) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9989 16.4297C11.1053 16.4297 9.57031 14.8945 9.57031 13.0008C9.57031 11.1072 11.1053 9.57202 12.9989 9.57202C14.8924 9.57202 16.4275 11.1072 16.4275 13.0008C16.4275 13.9102 16.0662 14.7824 15.4233 15.4254C14.7803 16.0684 13.9082 16.4297 12.9989 16.4297Z"
        stroke={color || '#7D8592'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1149 19.9665V17.6658C22.1149 17.096 22.3412 16.5497 22.744 16.1468L24.3709 14.5198C25.2097 13.6809 25.2097 12.3208 24.3709 11.4819L22.744 9.85491C22.3412 9.45206 22.1149 8.90567 22.1149 8.33595V6.03349C22.1149 4.8471 21.1532 3.88534 19.9669 3.88534H17.6646C17.0949 3.88533 16.5485 3.65901 16.1457 3.25615L14.5189 1.62917C13.68 0.790276 12.32 0.790276 11.4811 1.62917L9.85428 3.25615C9.45146 3.65901 8.90511 3.88533 8.33542 3.88534H6.03313C5.46315 3.88534 4.91654 4.11189 4.51366 4.51512C4.11078 4.91835 3.88468 5.46518 3.88513 6.03521V8.33595C3.88513 8.90567 3.65882 9.45206 3.25599 9.85491L1.62913 11.4819C0.790291 12.3208 0.790291 13.6809 1.62913 14.5198L3.25599 16.1468C3.65882 16.5497 3.88513 17.096 3.88513 17.6658V19.9665C3.88513 21.1529 4.84682 22.1147 6.03313 22.1147H8.33542C8.90511 22.1147 9.45146 22.341 9.85428 22.7439L11.4811 24.3708C12.32 25.2097 13.68 25.2097 14.5189 24.3708L16.1457 22.7439C16.5485 22.341 17.0949 22.1147 17.6646 22.1147H19.9652C20.5351 22.1151 21.0819 21.889 21.4851 21.4861C21.8883 21.0832 22.1149 20.5365 22.1149 19.9665Z"
        stroke={color || '#7D8592'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default memo(SettingsIcon);
