import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getLeadFeedbacks } from "api/Statistics";


interface FiltersProps {
    worker?: string,
    from_date_of_creation?: string,
    to_date_of_creation?: string,
    filter_on?: string
}
export const useLeadFeedbacks = (
    filters?: FiltersProps
) => {

    const { data, isLoading } = useQuery<Array<any>>(
        ['leads', filters?.worker, filters?.from_date_of_creation, filters?.to_date_of_creation, filters?.filter_on],
        () => {
            return getLeadFeedbacks({
                worker: filters?.worker === 'All agents' ? null : filters?.worker,
                from_date_of_creation: filters?.filter_on === 'Individual' ? filters?.from_date_of_creation : null,
                to_date_of_creation: filters?.filter_on === 'Individual' ?  filters?.to_date_of_creation : null,
                filter_on: filters?.filter_on === 'Individual' ? null : filters?.filter_on
            });
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },

    });

    return {
        data,
        isLoading,
    };
};
