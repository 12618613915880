import  { FC, HTMLAttributes } from 'react'

export interface IHeadlineProps extends HTMLAttributes<HTMLHeadingElement> {
    paragraph: string;
    children?: React.ReactNode;
}

const Paragraph: FC<IHeadlineProps> = ({ children,paragraph, ...rest }) => {
    return (
        <span {...rest} className={`${rest.className} text-[#667085] text-[14px] sm:text-[16px] font-inter-regular`}>{paragraph}{children}</span>
    )
}

export default Paragraph;