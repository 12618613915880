import React from 'react'
import { memo } from "react";
interface Props {
    className?: string;
    color?: string;
}
const AddNewIcon = ({className}:Props )=> {
    return (
        <svg className={className} width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="44" height="44" rx="22" fill="#F4F9FD" />
            <g clipPath="url(#clip0_2829_49321)">
                <path d="M29.5 23H23.5V29H21.5V23H15.5V21H21.5V15H23.5V21H29.5V23Z" fill="#50B8E7" />
            </g>
            <defs>
                <clipPath id="clip0_2829_49321">
                    <rect width="24" height="24" fill="white" transform="translate(10.5 10)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default memo(AddNewIcon)