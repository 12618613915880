import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function ThinPlusIcon({ className, color }: Props) {
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0.277344C6.36819 0.277344 6.66667 0.562253 6.66667 0.913707V5.36825H11.3333C11.7015 5.36825 12 5.65316 12 6.00462C12 6.35607 11.7015 6.64098 11.3333 6.64098H6.66667V11.0955C6.66667 11.447 6.36819 11.7319 6 11.7319C5.63181 11.7319 5.33333 11.447 5.33333 11.0955V6.64098H0.666667C0.298477 6.64098 0 6.35607 0 6.00462C0 5.65316 0.298477 5.36825 0.666667 5.36825H5.33333V0.913707C5.33333 0.562253 5.63181 0.277344 6 0.277344Z"
        fill={color ? color : "#7D8592"}
      />
    </svg>
  );
}
export default memo(ThinPlusIcon);
