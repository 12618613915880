import Headline from "components/atoms/headline/Headline";
import { FC, useEffect, useState } from "react";
import LeadStatistics from "./Leads/LeadStatistics";
import AppointmentStatistics from "./Appointments/AppointmentStatistics";
import CustomerStatistic from "./Customers/CustomerStatistic";
import { getUsersByPermission } from "api/User";
import { PermissionsTypes } from "lib/helpers/constants/permissions";
import Leaderboard from "./Leaderboard/Leaderboard";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";


export interface IStatisticsProps { }

const Statistics: FC<IStatisticsProps> = () => {

    const [fetchedUsers, setFetchedUsers] = useState<Array<string>>([]);


    useEffect(() => {

        getUsersByPermission({
            permissions: [PermissionsTypes["Leads Salesman"]],

        })
            .then((res) => {
                setFetchedUsers(res.users);
            })
            .catch(() => { });
        // eslint-disable-next-line
    }, []);

    const hasPermission = usePermissions([
        PermissionsTypes["Costumer Admin"],
        PermissionsTypes["Leads Admin"],
        PermissionsTypes["Appointment Admin"],
    ]);


    return (
        <>

            <Headline title={'Statistics'} />
            <div className="mt-[26px] flex gap-4 flex-wrap xl:flex-nowrap">

                <div className={`flex flex-col gap-3  ${hasPermission ? 'basis-full xl:basis-3/4' : 'basis-full'}`}>
                    <LeadStatistics fetchedUsers={fetchedUsers} />
                    <AppointmentStatistics fetchedUsers={fetchedUsers} />
                    <CustomerStatistic fetchedUsers={fetchedUsers} />
                </div>
                <ConditionalRender condition={hasPermission}>
                    <div className="basis-full xl:basis-1/4">
                        <Leaderboard />
                    </div>
                </ConditionalRender>
            </div>
        </>
    );
};

export default Statistics;
