import { memo } from "react";
interface Props {
  className?: string;
}
function OptionsIcon({ className }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 6C8.897 6 8 5.103 8 4C8 2.897 8.897 2 10 2C11.103 2 12 2.897 12 4C12 5.103 11.103 6 10 6ZM10 8C11.103 8 12 8.897 12 10C12 11.103 11.103 12 10 12C8.897 12 8 11.103 8 10C8 8.897 8.897 8 10 8ZM10 14C11.103 14 12 14.897 12 16C12 17.103 11.103 18 10 18C8.897 18 8 17.103 8 16C8 14.897 8.897 14 10 14Z"
        fill="#44474A"
      />
    </svg>
  );
}
export default memo(OptionsIcon);
