import { useState } from "react";


export const useActiveLink = (href: string) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const handleActive = () => {
    setIsActive(!isActive);
  };
  const location = window.location.pathname;
  return {
    isActive: location === href,
    handleActive,
  };
};