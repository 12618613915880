import { getLeadDocument } from "api/Leads";
import { UseQueryOptions, useQuery } from "react-query";

interface DocumentParams {
    lead_id?: any;
}

export const useLeadDocument = (
  params: DocumentParams,
  options?: UseQueryOptions<any, any, unknown, string[]>
) => {
  const queryInfo = useQuery(
    ['leadsDocument'],
    () => getLeadDocument(params),
    {
      onError: (err) => {
        console.error(err);
      },
      ...options,
      enabled: false,
    }
  );

  return queryInfo;
};