import { FC } from "react";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import { Controller, useForm } from "react-hook-form";
import TextArea from "components/atoms/text-area/TextArea";
import Button from "components/atoms/button/Button";
import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import { useRate } from "lib/hooks/shared/useRate";
import { useFollowUpFeedback } from "lib/hooks/mutations/Appointments/useFollowUpFeedback";
import { useGenerateTimeOptions } from "lib/hooks/shared/useGenerateTimeOptions";
import { customStyles } from "lib/helpers/configs/customStyles";
import Creatable from "react-select/creatable";
import Label from "components/atoms/input/input-label/InputLabel";
import { useTranslation } from "react-i18next";

export interface IFollowUpAppointmentProps<T> {
  onConfirm: () => void;
  data: T;
  setCurrentStep: (step: number) => void;
  themeColors: {
    primary: string;
  };
}

const FollowUpAppointment: FC<IFollowUpAppointmentProps<any>> = ({
  onConfirm,
  data,
  setCurrentStep,
  themeColors,
}) => {
  const { hoverRate, rate, setHoverRate, setRate } = useRate();
  const { mutateAsync: chooseFollowUpFeedback, isLoading } =
    useFollowUpFeedback(onConfirm);
  const { timeOptions } = useGenerateTimeOptions();
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
  } = useForm();

  const onSubmit = (formData: Record<string, any>): void => {
    formData = {
      appointment_id: data?.id,
      ...formData,
      rating_stars: rate,
      feedback_status: "Follow Up",
    };
    chooseFollowUpFeedback(formData)
      .then(() => {
        setCurrentStep(1);
      })
      .catch(() => { });
  };

  const { t } = useTranslation();

  return (
    <Form
      className="mb-0 flex flex-col gap-7"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Button
        onClick={() => setCurrentStep(1)}
        type="button"
        className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#70DDE4] text-[22px]"
      >
        <Icon
          iconType={IconType.BACK_BUTTON_MODAL}
          className="!w-[30px] !h-[30px]"
          color={themeColors.primary}
        />
        {('Status')} : {t('Follow Up')}
      </Button>
      <div className="flex flex-col gap-8">
        <div className="grid grid-cols-2 gap-7">
          <Controller
            control={control}
            name="new_date"
            rules={{ required: `${t("Reminder of appointment is required")}` }}
            render={({ field: { onChange, value } }) => (
              <PrimaryDatePicker
                label={t("Reminder Date")}
                startDate={value}
                wrapperClassName="!left-[5px]"
                setStartDate={(date: string) => onChange(date)}
              />
            )}
          />
          <div className="relative">
            <Label text={t("Reminder Time")} />
            <Controller
              name="new_time"
              control={control}
              rules={{ required: `${t("Reminder Time is required")}` }}
              render={({ field }) => (
                <Creatable<any, false>
                  {...field}
                  placeholder={t("Choose appointment time")}
                  options={timeOptions}
                  value={
                    field.value
                      ? { value: field.value, label: field.value }
                      : null
                  }
                  className="cursor-pointer font-inter-regular react-select-container"
                  classNamePrefix="react-select"
                  onChange={(newValue: any, actionMeta: any) => {
                    if (
                      actionMeta.action === "select-option" &&
                      newValue?.value
                    ) {
                      field.onChange(newValue.value);
                    }
                  }}
                  styles={customStyles}
                  isValidNewOption={() => false}
                  menuPosition={"fixed"}
                  menuPortalTarget={document.body}
                />
              )}
            />
          </div>
        </div>
        <TextArea
          label={t("Notes")}
          placeholder={t('Write note ...')}
          {...register("note", { required: `${t("Note is required")}` })}
          error={errors?.note}
        />
        <div className="flex flex-row justify-between  border-[1px] border-[#D0D5DD] p-4 rounded-[8px] items-center">
          <div className="text-[14px] font-inter-medium leading-[20px]">
            {t('Appointment Quality')}
          </div>
          <div className="flex flex-row gap-2">
            {Array.from({ length: 5 }).map((_, index) => (
              <Button
                key={`rate-key-${index}`}
                type="button"
                onMouseEnter={() => setHoverRate(index)}
                onMouseLeave={() => setHoverRate(-1)}
                onClick={() => setRate(index + 1)}
              >
                <Icon
                  iconType={IconType.RATE_STAR_ICON}
                  color={
                    index <= Math.max(hoverRate, rate - 1)
                      ? "#EFBE12    "
                      : "#6C737F"
                  }
                />
              </Button>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4">
        <IconButton
          secondary={true}
          type="button"
          onClick={() =>
            reset({
              new_date: "",
              new_time: "",
              note: "",
            })
          }
          className="w-full max-w-[150px] !border-[#70DDE4] !text-[#70DDE4]"
        >
          {t('Reset')}
        </IconButton>
        <IconButton
          secondary={true}
          type="submit"
          className="w-full max-w-[150px] !border-[#70DDE4] bg-[#70DDE4] text-white"
        >
          {isLoading ? t("Loading...") : t("Follow Up")}
        </IconButton>
      </div>
    </Form>
  );
};

export default FollowUpAppointment;
