import { useQuery } from "react-query";
import { getAllCampaigns } from "api/Campaign";

export const useAllCampaigns = () => {

    const { data, isLoading } = useQuery<Array<any> | any>(["campaigns"],
        () => {
            return getAllCampaigns();
        },
    );

    return {
        data,
        isLoading,
    };
};
