import React, { FC } from 'react'
import LoginTitle from '../../../atoms/title/LoginTitle'
import Paragraph from '../../../atoms/paragraph/Paragraph'
import Icon from '../../../atoms/icon/Icon'
import { IconType } from '../../../../lib/helpers/constants/iconTypes'



export interface ISuccessfulLoginProps { }
const SuccessfulLogin: FC<ISuccessfulLoginProps> = () => {

    
    return (
        <div className='flex flex-col gap-8'>
            <div>
                <div className='pb-2'>
                    <LoginTitle title='Anmeldung erfolgreich' />
                </div>
                <Paragraph paragraph='Genießen Sie Ihre Erfahrung mit Finanu.' />
            </div>
            <Icon className='m-auto max-w-[340px] h-auto' iconType={IconType.SUCCESSFUL_LOGIN_ICON} />
        </div>
    )
}

export default SuccessfulLogin