import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getResources } from "api/Documents";


interface FiltersProps {
    search?: string
}
export const useResources = (
    filters?: FiltersProps
) => {

    const { data, isLoading } = useQuery<Array<any>>(
        ['resources', filters?.search],
        () => {
            return getResources({
                search: filters?.search
            });
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
