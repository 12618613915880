import { updateCustomer } from "api/Customers";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useUpdateCustomer = (
options?: UseMutationOptions<any, any, any, unknown>
) => {
    const queryClient = useQueryClient();
    return useMutation(updateCustomer, {
        onSuccess: () => {
            toast.success("Customer updated successfully!");
            queryClient.invalidateQueries('coustomers')
        },
        onError: () => {
            toast.error("Customer update failed! Please try again later.");
        },
        ...options
    })
}