import { FC, HTMLAttributes } from "react";

export interface ILoginTitleProps extends HTMLAttributes<HTMLHeadingElement> {
    title: string;
}

const LoginTitle: FC<ILoginTitleProps> = ({ title, ...rest }) => {
    return (
        <div className={`font-inter-bold color-primaryTextColor text-[20px] sm:text-3xl font-bold ${rest.className}`}>{title}</div>
    )
}

export default LoginTitle