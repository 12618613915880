import CustomersSecurityView from 'components/organisms/customers-security/CustomersSecurityView'
import {FC} from 'react'

interface ICustomerSecurityProps{}

const CustomerSecurity:FC<ICustomerSecurityProps> = () => {
  return (
   <CustomersSecurityView/>
  )
}

export default CustomerSecurity