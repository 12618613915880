import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function CustomersIcon({ className, color }: Props) {
  return (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6482 6.12587C19.2 4.32919 16.8796 3.58302 14.7141 4.22933C14.1849 4.38728 13.8839 4.94434 14.0419 5.47355C14.1998 6.00277 14.7569 6.30374 15.2861 6.14579C16.6658 5.73402 18.1476 6.21054 19.091 7.38098C20.0522 8.57349 20.2727 10.2626 19.6496 11.6943C19.035 13.1066 17.7178 13.9998 16.2806 14C16.2576 14 16.2349 14.0008 16.2123 14.0023C16.1413 14.0006 16.0703 13.9998 15.999 13.9999C15.4468 14.0003 14.9994 14.4484 14.9998 15.0007C15.0003 15.553 15.4484 16.0003 16.0006 15.9999C18.4383 15.9979 20.736 17.3109 22.1568 19.5378C22.4539 20.0033 23.0721 20.14 23.5377 19.8429C24.0033 19.5458 24.1399 18.9276 23.8429 18.462C22.7949 16.8195 21.3503 15.5644 19.7001 14.8098C20.4569 14.2178 21.0763 13.4278 21.4834 12.4924C22.4023 10.381 22.0786 7.90048 20.6482 6.12587ZM8.00012 4C4.68642 4 2.00012 6.68629 2.00012 10C2.00012 11.9503 2.93062 13.6832 4.37188 14.7791C2.69233 15.5299 1.2213 16.7967 0.157467 18.4615C-0.139911 18.9269 -0.00371301 19.5453 0.461674 19.8427C0.92706 20.14 1.5454 20.0038 1.84278 19.5385C3.26488 17.3129 5.56225 16.0001 8.00003 16C10.4378 15.9999 12.7353 17.3125 14.1575 19.5379C14.4549 20.0033 15.0733 20.1394 15.5386 19.842C16.004 19.5446 16.1402 18.9263 15.8427 18.4609C14.7789 16.7963 13.3079 15.5297 11.6285 14.779C13.0697 13.6831 14.0001 11.9502 14.0001 10C14.0001 6.68629 11.3138 4 8.00012 4ZM4.00012 10C4.00012 12.2091 5.79092 13.9999 8 14H8.00012C10.2093 14 12.0001 12.2091 12.0001 10C12.0001 7.79086 10.2093 6 8.00012 6C5.79099 6 4.00012 7.79086 4.00012 10Z"
        fill={color || "#7D8592"}
      />
    </svg>
  );
}
export default memo(CustomersIcon);
