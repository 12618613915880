import { apiRequest } from "./Api";


export const storeResourceOrTemplate = (data: any) =>
    apiRequest<FormData, any>("POST", "insurance-management/store", data);

export const updateResource = (data: any) =>
    apiRequest<FormData, any>("POST", "insurance-management/update-resources", data);

export const storeOverview = (data: any) =>
    apiRequest<FormData, any>("POST", "insurance-management/store-document-overview", data);

export const storeBaseOverview = (data: any) =>
    apiRequest<FormData, any>("POST", "insurance-management/store-overview", data);

export const getResources = (params?: { search?: string }) =>
    apiRequest<any, any>("GET", "insurance-management/ressources", null, undefined, params);

export const getTemplates = (params?: { search?: string }) =>
    apiRequest<any, any>("GET", "insurance-management/templates", null, undefined, params);



