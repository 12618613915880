import {  requestFeedBack } from "api/Leads"
import { queryClient } from "lib/helpers/configs/queryClient"
import { useMutation } from "react-query"
import { toast } from "react-toastify"



export const useRequestFeedBackForExport = () => {
    return useMutation(requestFeedBack,{
        onSuccess: () => {
            queryClient.invalidateQueries('security');
            toast.success('Successfully')
        },
        onError: () => {
            toast.error('Something went wrong!')
        }
    })
}