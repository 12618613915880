import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Import the variable pie module from Highcharts
require("highcharts/modules/variable-pie")(Highcharts);

interface PendingsChartProps {
    data?: any;
    setHoveredSlice: (value: string | null) => void;
    color?: string[];
}

const PendingsChart: React.FC<PendingsChartProps> = ({ data, setHoveredSlice, color }) => {

    const formattedData = React.useMemo(() => {       
        return data?.map((item: any) => ({
            ...item,
            y: parseFloat(item.y === 0 || isNaN(item.y) ? 0 : item.y.toFixed(2)) // Ensure 'y' is formatted to two decimal places
        }));
    }, [data]);


    const options: Highcharts.Options = {
        chart: {
            type: "variablepie",
            height: 250,
        },
        title: {
            text: "",
            align: "left",
        },
        tooltip: {
            headerFormat: "",
            pointFormat: '<div>{point.y}%</div>',
            style: {
                fontSize: '25px',  // Set the tooltip font size to 25px
                color: '#282D46',  // Set the tooltip font color
                fontFamily: 'InterBold',
            },
            padding: 10,
            borderColor: '#F2F4F7',
            borderWidth: 1,
            shadow: {
                color: '#0000000D',
                offsetX: 0,
                offsetY: 10,
                opacity: 1,
                width: 70,
            },
            borderRadius: 10,
        },
        series: [
            {
                type: "variablepie",
                minPointSize: 10,
                innerSize: "70%",
                zMin: 0,
                name: "countries",
                borderRadius: 8,
                data: formattedData,
                colors: color || ["#EFBE12"],
                dataLabels: {
                    enabled: false,
                },
                states: {
                    hover: {
                        borderWidth: 15,
                        borderColor: color ? color[0] : "#EFBE12",
                        enabled: true,
                        brightness: 0,
                        halo: null,
                        animation: {
                            duration: 200,
                        },
                    },
                    inactive: {
                        opacity: 0.5,
                    }
                },
                inactiveOtherPoints: true,

                point: {
                    events: {
                        mouseOver() {
                            setHoveredSlice(this.name);
                        },
                        mouseOut() {
                            setHoveredSlice(null);
                        },
                    },
                }
            },
        ],
        credits: {
            enabled: false,
        },
    };

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

export default PendingsChart;
