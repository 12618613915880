import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import Icon from 'components/atoms/icon/Icon';
import Label from 'components/atoms/input/input-label/InputLabel';
import FileDropZone from 'components/molecules/file-dropzone/FileDropZone';
import { customStyles } from 'lib/helpers/configs/customStyles';
import { IconType } from 'lib/helpers/constants/iconTypes';
import { languageData } from 'lib/helpers/constants/languageData';
import withModal from 'lib/helpers/hoc/withModal'
import { useOverview } from 'lib/hooks/mutations/Documents/useOverview';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Creatable from "react-select/creatable";

interface INewOverviewProps {
    onConfirm: () => void;
    id: string;
}

const NewOverview: React.FC<INewOverviewProps> = ({ onConfirm, id }) => {

    const { t } = useTranslation()

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const { mutateAsync, isLoading } = useOverview()

    const [Language, setLanguage] = useState('')

    const onSubmit = (data: any) => {

        const finalData = new FormData();

        finalData.append('country_flag', data.language);
        finalData.append('name', Language);
        finalData.append('insurance_document_id', id);
        finalData.append('file', data.file[0]);

        mutateAsync(finalData).then(() => {
            onConfirm()
        })

    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-0 flex flex-col gap-7">

                <div className='relative'>
                    <Label text={t('File language')} />
                    <Controller
                        name="language"
                        control={control}
                        rules={{ required: `${t('Language is required')}` }}
                        render={({ field }) => (
                            <Creatable<any, false>
                                {...field}
                                placeholder={t('Choose language')}
                                options={languageData.map((language) => ({
                                    value: language.value,
                                    label: t(language.label),
                                }))}
                                value={
                                    field.value
                                        ? { value: field.value, label: t(languageData.find((lang) => lang.value === field.value)?.label as string) }
                                        : null
                                }
                                className="cursor-pointer font-inter-regular react-select-container"
                                classNamePrefix="react-select"
                                onChange={(newValue: any, actionMeta: any) => {
                                    if (
                                        actionMeta.action === "select-option" &&
                                        newValue?.value
                                    ) {
                                        field.onChange(newValue.value);
                                        setLanguage(newValue.label);
                                    }
                                }}
                                styles={customStyles}
                                isValidNewOption={() => false}
                                menuPosition={"fixed"}
                                menuPortalTarget={document.body}
                            />
                        )}
                    />
                    {errors.language?.message && (
                        <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                            <>{t(errors.language?.message as string)}</>
                        </div>
                    )}
                </div>
                <div>
                    <Controller
                        control={control}
                        name="file"
                        rules={{ required: t("File is required") }}
                        render={({ field: { onChange, value } }) => (
                            <FileDropZone
                                formats={".pdf, .docx, .doc"}
                                file={value}
                                setFile={onChange}
                                label="Upload Overview File"
                                classes={{ label: "bg-white" }}
                                name="file"
                                error={errors.file}
                                control={control}
                            />
                        )}
                    />
                </div>

                <div className='flex-none flex justify-end'>
                    <IconButton
                        type='submit'
                        disabled={isLoading}
                        className='w-auto'
                        icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
                    >
                        {t('Add Overview')}
                    </IconButton>
                </div>
            </div>
        </Form>
    );
}

export default withModal(NewOverview)