import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Icon from "components/atoms/icon/Icon";
import UpdatePendingsProcess from "components/organisms/pendings/update-pendings-process/UpdatePendingsProcess";
import { IconType } from "lib/helpers/constants/iconTypes";
import { usePending } from "lib/hooks/queries/Pendings/usePending";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export interface IPendingsCardProps {
  costumer: {
    first_name: string;
    last_name: string;
  };
  title: string;
  category: string;
  deadline?: string;
  item?: any;
}

const PendingsCard: FC<IPendingsCardProps> = ({
  costumer,
  title,
  category,
  deadline,
  item
}) => {
  const [isUpdatePendingModalOpen, setIsUpdatePendingModalOpen] =
    useState<boolean>(false);
  const [currentUpdatePending, setCurrentUpdatePending] = useState<number>(1);
  const [id, setId] = useState<number>();
  const {
    data: userData,
    isError,
    isLoading: isLoadingFetchingUser
  } = usePending(id);

  const { t } = useTranslation();
  const handleUpdatePending = (id: number) => {
    if (!isError) {
      setId(id);
      setIsUpdatePendingModalOpen(true);
    } else {
      toast.error(`${t('Something went wrong')}`);
    }
  };
  return (
    <>
      <div className="w-full bg-white h-auto rounded-[12px] border-[#282D460F] border-solid border-2 p-5 flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <div className="bg-[#50B8E7] bg-opacity-[15%] rounded-[18px] py-[4px] px-[14px]">
            <p className="text-[#50B8E7] font-inter-medium  text-[10px]">
              {category}
            </p>
          </div>
          <div
            onClick={() => {
              setIsUpdatePendingModalOpen(true);
              handleUpdatePending(item?.id);
            }}
          >
            <Icon
              iconType={IconType.TOOLTIP_THREE_DOTS}
              className="w-[2px] h-[10px]"
            />
          </div>
        </div>
        <div className="flex gap-3 items-center leading-none">
          <div className="flex-1">
            <span className="font-inter-medium text-primaryTextColor text-base">
              {costumer?.first_name} {costumer?.last_name} {title}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-[18px]">
            <p className="text-[#282D46] text-opacity-[50%] font-inter-regular text-[11px]">
              {t('Deadline')} : {deadline ?? t("Not Available")}
            </p>
            <div className="flex items-center gap-[4px]">
              <div className="w-[6px] h-[6px] bg-[#EB5757] rounded-full"></div>
              <p className="font-inter-regular text-[#EB5757] text-[11px]">
                {t('Urgent')}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-[13px]">
            <div className="flex items-center gap-[10px]"  onClick={() => {
              setIsUpdatePendingModalOpen(true);
              handleUpdatePending(item?.id);
              setCurrentUpdatePending(5);
            }}>
              <Icon iconType={IconType.COMMENT_ICON} />
              <p className="text-[11px] font-inter-medium text-[#282D46] text-opacity-[50%]">
               {item?.comments_count ?? '0'} 
              </p>
            </div>
          </div>
        </div>
      </div>
      <ConditionalRender condition={isUpdatePendingModalOpen}>
        <UpdatePendingsProcess
          userData={userData}
          setCurrentUpdatePending={setCurrentUpdatePending}
          updateCurrentPending={currentUpdatePending}
          isLoadingFetchingUser={isLoadingFetchingUser}
          showToCommentsBackButton={false}
          showToCommentsConfirm={false}
          hocClasses={{
            modalClassName:
              currentUpdatePending === 1
                ? "sm:w-[600px] md:max-w-[600px] sm-md:max-w-[600px] lg:min-w-[973px] 2xl:w-[948px]"
                : currentUpdatePending === 2
                ? "sm:max-w-[467px]   md:min-w-[467px] lg:w-[467px]"
                : currentUpdatePending === 3
                ? "sm:w-[600px] md:max-w-[600px]  sm-md:max-w-[600px]   lg:min-w-[894px]"
                : currentUpdatePending === 4
                ? "sm:w-[600px] md:max-w-[600px]  sm-md:max-w-[600px] lg:min-w-[894px]"
                : currentUpdatePending === 5
                ? "sm:w-[600px] md:max-w-[600px]  sm-md:max-w-[600px] 2xl:min-w-[930px]"
                : ""
          }}
          hocCurrentStep={currentUpdatePending}
          hocToggleModal={() => setIsUpdatePendingModalOpen(false)}
          hocisOpen={isUpdatePendingModalOpen}
          setIsUpdatePendingModalOpen={setIsUpdatePendingModalOpen}
          setHocCurrentStep={setCurrentUpdatePending}
          clearData={true}
          hocTitle={
            currentUpdatePending === 1
              ? ""
              : currentUpdatePending === 2
              ? t("Upload File")
              : ""
          }
        />
      </ConditionalRender>
    </>
  );
};

export default PendingsCard;
