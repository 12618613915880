import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender';
import FilterBorderedWrapper from 'components/atoms/filter-bordered-wrapper/FilterBorderedWrapper';
import Icon from 'components/atoms/icon/Icon';
import Input from 'components/atoms/input/Input';
import DynamicDropdown from 'components/molecules/dynamic-dropdown/DynamicDropdown';
import ConfigureTableDropdown from 'components/organisms/leads/dropdowns/ConfigureTableDropdown';
import { IconType } from 'lib/helpers/constants/iconTypes';
import { FC, useState } from 'react'
import TransferTable from './TransferTable';
import FilterAppointments from '../../dropdowns/FilterAppointments';
import { useTranslation } from 'react-i18next';

export interface IStep1TransferTableProps<T> {
    data: T;
    columns: any[];
}

export interface IFilterTransferTableProps {
    search?: string;
    source?: number;
    campaign?: number;
    status?: string;
    date_of_creation?: string;
}

const Step1TransferTable: FC<IStep1TransferTableProps<any>> = ({ columns, data }) => {




    const [cardsOrder, setCardsOrder] = useState(columns);
    const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);
    const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<IFilterTransferTableProps>({
        search: ""
    });

    const {t} = useTranslation();
    return (
        <>
            <div className="flex flex-row gap-3 items-center mb-5">
                <div className="flex-1">
                    <Input
                        icon={IconType.SEARCH_ICON}
                        className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
                        classes={{ icon: "left-4 !top-[30%]" }}
                        placeholder={t("Search")}
                        value={filterData?.search ?? ''}
                        onChange={(e) =>
                            setFilterData((prevFormData: any) => ({
                                ...prevFormData,
                                search: e.target.value
                            }))
                        }
                    />
                </div>
                <div className="flex-none">
                    <div className={`relative`}>
                        <FilterBorderedWrapper
                            className="cursor-pointer h-[48px] w-[48px] "
                            onClick={() => {
                                setConfigureTableOpen(!configureTableOpen);
                            }}
                        >
                            <Icon iconType={IconType.FILTER_BARS_ICON} />
                        </FilterBorderedWrapper>
                        <ConditionalRender condition={configureTableOpen}>
                            <DynamicDropdown
                                setIsOpen={setConfigureTableOpen}
                                showCloseButton={true}
                                title={t("Configure Table")}
                            >
                                <ConfigureTableDropdown
                                    setCardsOrder={setCardsOrder}
                                    cardsOrder={cardsOrder}
                                />
                            </DynamicDropdown>
                        </ConditionalRender>
                    </div>
                </div>
                <div className="flex-none">
                    <div className={`${filterTableOpen && "relative"}`}>
                        <FilterBorderedWrapper
                            className="h-[48px] w-[48px] cursor-pointer"
                            onClick={() => {
                                setFilterTableOpen(!filterTableOpen);
                            }}
                        >
                            <Icon iconType={IconType.FILTER_ICON} />
                        </FilterBorderedWrapper>
                        <ConditionalRender condition={filterTableOpen}>
                            <DynamicDropdown
                                setIsOpen={setFilterTableOpen}
                                showCloseButton={true}
                                title={t("Filter")}
                                className="sm:!w-[350px]"
                            >
                                <FilterAppointments />
                            </DynamicDropdown>
                        </ConditionalRender>
                    </div>
                </div>
            </div>
            <TransferTable data={[]} dataCols={columns} />
        </>
    )
}
export default Step1TransferTable