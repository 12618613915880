import { FC } from "react";
import Form from "../../../atoms/form/Form";
import ModuleSelector from "../../../molecules/module-selector/ModuleSelector";
import FormStepNavigationButtons from "../../../molecules/form-step-navigation-buttons/FormStepNavigationButtons";
import Card from "../../../molecules/card/Card";
import { CardType } from "../../../../lib/helpers/constants/cardTypes";
import {
  ICurrentPlanSummaryCardProps,
  IModuleData,
} from "../../../molecules/card/current-plan-summary-card/CurrentPlanSummaryCard";
import { useModules } from "../../../../lib/hooks/queries/Module/useModules";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";

export interface ICustomizedPlanStepProps {}

const CustomizedPlanStep: FC<ICustomizedPlanStepProps> = () => {
  const { data } = useModules();

  const {
    watch,
    updateFormData,
    nextFormStep,
    control,
    handleSubmit,
    errors,
    currentStep,
    setValue,
    clearErrors,
    getValues,
  } = useSignupCustomHooks({ initalStep: 4 });

  const onSubmit = () => {
    const watchedValues = watch();
    const selectedModuleIds = Object.keys(watchedValues).filter(
      (key) => !isNaN(Number(key)) && watchedValues[key]
    );
    updateFormData({
      modules: selectedModuleIds,
    });
    nextFormStep();
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="flex justify-between flex-col h-full"
    >
      <div>
        <h1 className="text-bluebutton uppercase font-bold text-[14px] text-center mt-[40px] mb-[8px]">
         STEP {currentStep}/11
        </h1>
        <span className="text-[#282D46] font-inter-bold text-[25px] flex justify-center">
          Customize plan
        </span>
      </div>
      <div className="grid grid-cols-[auto,306px]  p-[25px]  2xl:p-[40px]  gap-[140px]">
        <ModuleSelector
          data={data}
          control={control}
          error={errors}
          setValue={setValue}
          clearErrors={clearErrors}
          getValues={getValues}
        />
        <Card<ICurrentPlanSummaryCardProps>
          cardType={CardType.CURRENT_PLAN_SUMMARY_CARD}
          props={{
            selectedModuleIds: data?.modules
              ?.filter((module: IModuleData) => watch()[module.id])
              ?.map((module: IModuleData) => module.id),
            data: data,
          }}
        />
      </div>
      <FormStepNavigationButtons onDraftButton={true} />
    </Form>
  );
};
export default CustomizedPlanStep;
