import Headline from "components/atoms/headline/Headline";
import React, { useState } from "react";
import LeadsCards from "./leads-cards/LeadsCards";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import DashboardBoxTitle from "components/atoms/title/DashboardBoxTitle";
import IconButton from "components/atoms/button/IconButton";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Input from "components/atoms/input/Input";
import FilterBorderedWrapper from "components/atoms/filter-bordered-wrapper/FilterBorderedWrapper";
import ViewChangeButtonGroup from "components/molecules/view-change/ViewChangeButtonGroup";
import LeadsGridView from "./gird-view/LeadsGridView";
import { useModalContext } from "lib/context/ModalContext/ModalContext";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import ConfigureTableDropdown from "./dropdowns/ConfigureTableDropdown";
import FilterTableDropdown from "./dropdowns/FilterTableDropdown";
import LeadsList from "./leads-list/LeadsList";
import { useLeads } from "lib/hooks/queries/Leads/useLeads";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import PortraitImage from "assets/images/portrait.png";
import {
  ActionButton,
  FeedbackStatus
} from "lib/helpers/constants/columns/leadsTableHeaders";
import Button from "components/atoms/button/Button";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import PaginationControls from "components/molecules/pagination-controls/PaginationControls";
import { ILead } from "lib/types/leads.types";
import AssignedTo from "./assigned-to/AssignedTo";
import UploadLead from "./upload-lead/UploadLead";
import CreateLeadProcces from "./create-lead/CreateLeadProcces/CreateLeadProcces";
import UpdateLead from "./update-lead/UpdateLead";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import ExportLeadProcess from "./export-leads/ExportLeadProcess";
import Checkbox from "components/atoms/checkbox/Checkbox";
import AssignLeads from "./assign-leads/AssignLeads";
import { useTranslation } from "react-i18next";

export interface ILeadsViewProps { }

export interface IFilterProps {
  search?: string;
  source?: number;
  campaign?: number;
  status?: string;
  worker?: string;
  from_date_of_creation?: string;
  to_date_of_creation?: string;
}

const LeadsView: React.FC<ILeadsViewProps> = () => {
  const { confirm } = useModalContext();
  const hasAdminPermission = usePermissions([P["Leads Admin"]]);
  const hasMenagerPermission = usePermissions([P["Leads Menager"]]);

  const [filterData, setFilterData] = useState<IFilterProps>({
    search: "",
    source: undefined,
    campaign: undefined,
    status: "",
    worker: undefined,
    from_date_of_creation: undefined,
    to_date_of_creation: undefined,
  });

  const [isAssignLeadsActive, setIsAssignLeadsActive] = useState<string>('Assign Leads');

  const [activeView, setActiveView] = useState<string>("listView");

  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);

  const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);

  const [updateModalCurrentStep, setUpdateModalCurrentStep] = useState<number>(1);

  const [newLeadModalCurrentStep, setNewLeadModalCurrentStep] = useState(1);

  const [isOpen, setIsOpen] = useState(false);

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  
  const {t} = useTranslation();

  const [updateData, setUpdateData] = useState<any>();
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const { themeColors } = useThemeContext();

  const [uploadCurrentStep, setUploadCurrentStep] = useState<number>(1);
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);

  const {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage
  } = useLeads(
    isAssignLeadsActive,
    filterData.search,
    filterData.source,
    filterData.campaign,
    filterData.status,
    filterData.worker,
    filterData.from_date_of_creation,
    filterData.to_date_of_creation,
  );

  const handleAssignedTo = async (id: string) => {
    try {
      await confirm({
        title: t('Assign Lead'),
        classes: {
          modal: "md:min-w-[551px] md-max-w-full  lg:max-w-[551px] w-full"
        },
        showCloseButton: true,
        customComponent: (props) => {
          return <AssignedTo onConfirm={props.onConfirm} id={id} />;
        }
      });
    } catch (err) {
      return null;
    }
  };

  const handleCellClick = (row: any) => {
    setUpdateData(row.original);
    setIsUpdateModalOpen(true);
  };

  const [SelectedLeads, setSelectedLeads] = useState([])
  const [CheckedAll, setCheckedAll] = useState(false)


  const handleSelectAll = (e: any) => {

    setCheckedAll(e.target.checked)

  }

  React.useEffect(() => {
    if (CheckedAll) {
      setSelectedLeads(data?.pages?.[0]?.data.data)

    }
    else {
      setSelectedLeads([])
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CheckedAll])

  const leadsTableHeaders = [
    {
      id: 'checkbox',
      Header: <Checkbox onChange={handleSelectAll} isChecked={data?.pages?.[0]?.data.data.length === itemsPerPage} />,
      accessor: `${t('Not available')}`,
      Cell: ({ row }: any) => (
        <>
          <Checkbox id={row.original.id}
            isChecked={(SelectedLeads as any)?.filter((lead: any) => lead.id === row.original.id).length > 0}
            onChange={(e: any) => {
              if (e.target.checked) {
                setSelectedLeads([...(SelectedLeads as any), row.original] as any)
              }
              else {
                setSelectedLeads((SelectedLeads as any)?.filter((lead: any) => lead.id !== row.original.id))
              }
            }}
          />
        </>
      ),
      isChecked: true
    },
    {
      id: 1,
      Header: `${t('Name')}`,
      accessor: (row: ILead) =>
        row.info?.first_name && row.info?.last_name
          ? `${row.info.first_name} ${row.info.last_name}`
          : `${t('Not available')}`,
      Cell: ({ row }: any) => (
        <div onClick={() => handleCellClick(row)} className="cursor-pointer">
          {row.original.info?.first_name && row.original.info?.last_name
            ? `${row.original.info.first_name} ${row.original.info.last_name}`
            : `${t('Not available')}`}
        </div>
      ),
      isChecked: true
    },
    {
      Header: `${t('Source')}`,
      accessor: (row: ILead) => row?.source?.name || `${t('Not available')}`,
      Cell: ({ row }: any) => (
        <div onClick={() => handleCellClick(row)} className="cursor-pointer">
          {row?.original?.source?.name || `${t('Not available')}`}
        </div>
      ),
      id: 2,
      isChecked: true
    },
    {
      Header: `${t('Campaign')}`,
      accessor: (row: any) => row?.campaign?.name || `${t('Not available')}`,
      Cell: ({ row }: any) => (
        <div onClick={() => handleCellClick(row)} className="cursor-pointer">
          {row?.original?.campaign?.name || `${t('Not available')}`}
        </div>
      ),
      id: 8,
      isChecked: true
    },
    {
      Header: `${t('Email')}`,
      accessor: (row: ILead) => row?.info?.email || `${t('Not available')}`,
      Cell: ({ row }: any) => (
        <div onClick={() => handleCellClick(row)} className="cursor-pointer">
          {row?.original?.info?.email || `${t('Not available')}`}
        </div>
      ),
      id: 3,
      isChecked: true
    },
    {
      Header:`${t('Phone')}`,
      accessor: (row: ILead) => row?.info?.phone || `${t('Not available')}`,
      Cell: ({ row }: any) => (
        <div onClick={() => handleCellClick(row)} className="cursor-pointer">
          {row?.original?.info?.phone || `${t('Not available')}`}
        </div>
      ),
      id: 4,
      isChecked: true
    },
    {
      Header: `${t('Status')}`,
      accessor: (row: ILead) => `${t(row?.feedback_status)}` || `${t('Not available')}`,
      Cell: ({ row, value }: any) => (
        <div onClick={() => handleCellClick(row)} className="cursor-pointer">
          <FeedbackStatus value={value} />
        </div>
      ),
      id: 5,
      isChecked: true
    },
    ...(isAssignLeadsActive
      ? [
        {
          Header: `${t('Assigned salesmen')}`,
          accessor: "assigned_to",
          id: 6,
          Cell: ({ row }: any) => (
            <div
              onClick={() => handleCellClick(row)}
              className="cursor-pointer"
            >
              {row?.original?.assigned_to ? (
                <div className="flex items-center gap-2 cursor-pointer">
                  <img src={PortraitImage} alt="Portraitimage" />
                  <p className="text-[#282D46] font-inter-medium">
                    {row?.original?.assigned_to}
                  </p>
                </div>
              ) : (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAssignedTo(row?.original?.id);
                  }}
                  className="flex items-center gap-2 cursor-pointer"
                >
                  <Icon iconType={IconType.ASSIGNED_ICON} />
                  <p className="text-[#7D8592] font-inter-medium">
                    {t('Not assigned')}
                  </p>
                </Button>
              )}
            </div>
          ),
          isChecked: true
        }
      ]
      : []),
    {
      Header: <span className="sr-only"> {t('Actions')} </span>,
      accessor: "actions",
      id: 7,
      Cell: ({ row: { original } }: any) => (
        <ActionButton
          original={original}
          setIsUpdateModalOpen={setIsUpdateModalOpen}
          setUpdateData={setUpdateData}
        />
      ),
      isChecked: true
    }
  ];

  const [cardsOrder, setCardsOrder] = useState(leadsTableHeaders);
  React.useEffect(() => {

    setCardsOrder(leadsTableHeaders)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelectedLeads, t])


  const handleTabChange = (value: string) => {

    setIsAssignLeadsActive(value);

  };

  const exportLeads = async () => {
    try {
      await confirm({
        title: "",
        classes: {
          modal: "md:min-w-[650px] md-max-w-full lg:max-w-[650px] w-full"
        },
        showCloseButton: true,
        showBackButton: true,
        customComponent: (props) => {
          return (
            <ExportLeadProcess
              onCancel={props.onCancel}
              onConfirm={props.onConfirm}
            />
          );
        }
      });
    } catch (err) {
      return null;
    }
  };

  const openCreateModal = () => {
    setIsOpen(true);
  };

  const [IsAssignLeadsModalOpan, setIsAssignLeadsModalOpan] = useState(false)

  return (
    <>
      <div className="flex flex-col items-center justify-between gap-3 pb-6 md:flex-row pb-none ">
        <Headline title="Leads" />
        <ConditionalRender
          condition={hasAdminPermission || hasMenagerPermission}
        >
          <div className="bg-white p-1 rounded-[8px] flex items-center w-fit border-[1px] border-[#F2F4F7]">
            <Button
              onClick={() => {
                handleTabChange('Assign Leads');

              }}
              className={`text-white text-[14px] lg:text-[16px] rounded-[8px] p-2 font-inter-medium lg:min-w-[138.34px] cursor-pointer ${isAssignLeadsActive === 'Assign Leads'
                ? `bg-[--theme]`
                : "bg-transparent !text-primaryTextColor"
                }`}
            >
              {t('Assign Leads')}
            </Button>
            <Button
              onClick={() => {
                handleTabChange('Own Leads');

              }}
              className={`text-white text-[14px] lg:text-[16px] rounded-[8px] p-2 font-inter-medium lg:min-w-[138.34px] cursor-pointer ${isAssignLeadsActive === 'Own Leads'
                ? `bg-[--theme]`
                : "bg-transparent !text-primaryTextColor"
                }`}
            >
              {t('Own Leads')}
            </Button>
            <Button
              onClick={() => {
                handleTabChange('Assigned Leads');

              }}
              className={`text-white text-[14px] lg:text-[16px] rounded-[8px] p-2 font-inter-medium lg:min-w-[138.34px] cursor-pointer ${isAssignLeadsActive === 'Assigned Leads'
                ? `bg-[--theme]`
                : "bg-transparent !text-primaryTextColor"
                }`}
            >
              {t('Assigned Leads')}
            </Button>
          </div>
          <IconButton
            onClick={exportLeads}
            type="button"
            icon={<Icon color="#fff" iconType={IconType.EXPORT_ICON} />}
          >
            {t('Export Leads')}
          </IconButton>
        </ConditionalRender>
      </div>
      <LeadsCards hasOwn={isAssignLeadsActive} />
      <DashboardBoxLayout className="mt-[26px]">
        <div className="flex flex-col gap-3 items-center justify-between mb-5 md:flex-row">
          <div className="flex-1">
            <DashboardBoxTitle
              title={`Leads (${data?.pages?.[0]?.data?.data?.length ?? ""})`}
            />
          </div>
          <ConditionalRender
            condition={hasAdminPermission || hasMenagerPermission}
          >
            <IconButton
              disabled={SelectedLeads.length === 0}
              onClick={() => { setIsAssignLeadsModalOpan(true) }}
              icon={<Icon color="#fff" iconType={IconType.ASSIGN_LEAD_ICON} />}
            >
              {t('Assign Worker')}
            </IconButton>
          </ConditionalRender>
          <ConditionalRender
            condition={hasAdminPermission || hasMenagerPermission}
          >
            <IconButton
              onClick={() => setOpenUploadModal(true)}
              icon={<Icon color="#fff" iconType={IconType.UPLOAD_FILE_ICON} />}
            >
              {t('Upload Leads')}
            </IconButton>
          </ConditionalRender>
          <IconButton
            icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
            onClick={openCreateModal}
          >
            {t('New Lead')}
          </IconButton>
        </div>
        <div className="flex flex-col gap-3 items-center mb-5 md:flex-row">
          <div className="flex-1">
            <Input
              icon={IconType.SEARCH_ICON}
              className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
              classes={{ icon: "left-4 !top-[30%]" }}
              placeholder={t("Search")}
              value={filterData?.search ?? ""}
              onChange={(e) =>
                setFilterData((prevFormData: any) => ({
                  ...prevFormData,
                  search: e.target.value
                }))
              }
              maxLength={20}
            />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="flex-none">
              <div className={`relative`}>
                <FilterBorderedWrapper
                  className="cursor-pointer h-[48px] w-[48px] "
                  onClick={() => {
                    setConfigureTableOpen(!configureTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_BARS_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={configureTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setConfigureTableOpen}
                    showCloseButton={true}
                    title={t('Configure Table')}
                  >
                    <ConfigureTableDropdown
                      setCardsOrder={setCardsOrder}
                      cardsOrder={cardsOrder}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex-none">
              <div className={`${filterTableOpen && "relative"}`}>
                <FilterBorderedWrapper
                  className="h-[48px] w-[48px] cursor-pointer"
                  onClick={() => {
                    setFilterTableOpen(!filterTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={filterTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setFilterTableOpen}
                    showCloseButton={true}
                    title={t('Filter')}
                    className="sm:!w-[350px]"
                  >
                    <FilterTableDropdown
                      setFilterData={setFilterData}
                      FilterData={filterData}
                      setIsOpen={setFilterTableOpen}
                      isAssignedLeads={isAssignLeadsActive === 'Assigned Leads'}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex-none">
              <ViewChangeButtonGroup
                setSelectedTab={setActiveView}
                ActiveTab={activeView}
              />
            </div>
          </div>
        </div>
        <ConditionalRender condition={activeView === "kundenView"}>
          <div className="relative">
            {isLoading && <LoadingScreen />}
            <NoDataBoundary
              condition={data?.pages?.[0]?.data?.data?.length > 0}
              fallback={<EmptyEntity name="Leads" />}
            >
              <LeadsGridView
                data={data?.pages?.[0]?.data?.data ?? []}
                isAssignLeadsActive={isAssignLeadsActive}
              />
            </NoDataBoundary>
          </div>
        </ConditionalRender>
        <ConditionalRender condition={activeView !== "kundenView"}>
          <div className="relative">
            {isLoading && <LoadingScreen />}
            <NoDataBoundary
              condition={data?.pages?.[0]?.data?.data?.length > 0}
              fallback={<EmptyEntity name="Leads" />}
            >
              <LeadsList
                key={currentPage}
                data={data?.pages?.[0]?.data?.data ?? []}
                dataCols={cardsOrder}
              />
            </NoDataBoundary>
          </div>
        </ConditionalRender>
      </DashboardBoxLayout>

      <ConditionalRender condition={!!data && activeView !== "kundenView"}>
        <PaginationControls
          itemsPerPage={itemsPerPage}
          handleItemsPerPageChange={(value: number) =>
            handleItemsPerPageChange(value)
          }
          from={data?.pages[0]?.data?.from}
          to={data?.pages[0]?.data?.to}
          total={data?.pages[0]?.data?.total}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </ConditionalRender>
      <ConditionalRender condition={isOpen}>
        <CreateLeadProcces
          setHocCurrentStep={setNewLeadModalCurrentStep}
          isAssignLeadsActive={isAssignLeadsActive}
          hocCurrentStep={newLeadModalCurrentStep}
          onConfirm={() => setIsOpen(false)}
          setFormCurrentStep={setNewLeadModalCurrentStep}
          formCurrentStep={newLeadModalCurrentStep}
          hocisOpen={isOpen}
          hocToggleModal={() => setIsOpen(false)}
          hocTitle={
            newLeadModalCurrentStep === 1
              ? `${t('New Lead')}`
              : newLeadModalCurrentStep === 2
                ? ""
                : ""
          }
          hocClasses={{
            modalClassName: `${`sm:max-w-[570px] sm:w-full w-full ${newLeadModalCurrentStep === 1
              ? "md:min-w-[750px] md-max-w-full lg:max-w-[900px] w-full"
              : newLeadModalCurrentStep === 2
                ? "!w-[462px]"
                : ""
              }`}`
          }}
        />
      </ConditionalRender>
      <ConditionalRender condition={isUpdateModalOpen}>
        <UpdateLead
          setHocCurrentStep={setUpdateModalCurrentStep}
          hocCurrentStep={updateModalCurrentStep}
          onConfirm={() => setIsUpdateModalOpen(false)}
          setFormCurrentStep={setUpdateModalCurrentStep}
          formCurrentStep={updateModalCurrentStep}
          hocisOpen={isUpdateModalOpen}
          hasChanges={hasChanges}
          hocToggleModal={() => setIsUpdateModalOpen(false)}
          setHasChanges={setHasChanges}
          hocHasChanges={hasChanges}
          hocTitle={
            updateModalCurrentStep === 1
              ? ""
              : updateModalCurrentStep === 2
                ? ""
                : ""
          }
          hocClasses={{
            modalClassName: `${`sm:max-w-[570px] sm:w-full w-full ${updateModalCurrentStep === 1 || updateModalCurrentStep === 4 || updateModalCurrentStep === 5
              ? "md:min-w-[750px] lg:min-w-[1000px] xl:min-w-[1200px] 2xl:min-w-[1200px]"
              : updateModalCurrentStep === 2
                ? "!w-[462px]"
                : updateModalCurrentStep === 3
                  ? "text-[100px]  md:min-w-[570px] lg:min-w-[563px] w-full"
                  : updateModalCurrentStep === 4
                    ? "md:min-w-[750px] lg:min-w-[1000px] xl:min-w-[1200px] 2xl:min-w-[1200px]"
                    : ""
              }`}`
          }}
          lead={updateData}
        />
      </ConditionalRender>
      <ConditionalRender condition={openUploadModal}>
        <UploadLead

          clearData={true}
          isAssignLeadsActive={isAssignLeadsActive}
          setHocCurrentStep={setUploadCurrentStep}
          hocCurrentStep={updateModalCurrentStep}
          themeColors={themeColors}
          onConfirm={() => setOpenUploadModal(false)}
          setFormCurrentStep={setUploadCurrentStep}
          currentStep={uploadCurrentStep}
          hocisOpen={openUploadModal}
          hocToggleModal={() => setOpenUploadModal(false)}

          hocTitle={uploadCurrentStep < 5 ? "Upload Lead" : ""}
          hocClasses={{
            modalClassName:
              uploadCurrentStep === 1
                ? "w-full md:max-w-[700px] lg:min-w-[973px] max-w-[973px]"
                : uploadCurrentStep === 2
                  ? "w-full md:max-w-[700px] lg:min-w-[973px] max-w-[973px]"
                  : uploadCurrentStep === 3
                    ? "w-full md:max-w-[700px] lg:min-w-[973px] max-w-[973px]"
                    : uploadCurrentStep === 4
                      ? "w-full md:max-w-[700px]  lg:min-w-[762px] max-w-[762px]"
                      : uploadCurrentStep === 5
                        ? "!max-w-[462px] w-full"
                        : "",
            titleClassName: uploadCurrentStep === 1 ? "" : "ml-[35px]"
          }}
        />
      </ConditionalRender>
      <ConditionalRender condition={IsAssignLeadsModalOpan}>
        <AssignLeads
          hocCurrentStep={1}
          themeColors={themeColors}
          selectedLeads={SelectedLeads}
          onConfirm={() => { setIsAssignLeadsModalOpan(false); setSelectedLeads([]) }}
          hocisOpen={IsAssignLeadsModalOpan}
          hocToggleModal={() => setIsAssignLeadsModalOpan(false)}
          hocTitle={"Assign Worker"}
          hocClasses={{
            modalClassName: "w-full md:max-w-[700px] lg:min-w-[973px] max-w-[1000px]",
            titleClassName: ""
          }}
        />
      </ConditionalRender>
    </>
  );
};
export default LeadsView;
