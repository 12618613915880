import NewCard from "components/molecules/add-new-card/NewCard";
import SourceCard from "components/molecules/card/source-card/SourceCard";
import { FC, useState } from "react";
import CreateSource from "../create-source/CreateSource";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";

export interface ISourceData {
  _id:number;
  company_id: string;
  created_at: string;
  description: string;
  id: number;
  logo: string | null;
  name: string;
  status: string;
  updated_at: string;
}

export interface ISourceGridProps<T> {
  data: T[];
}

const SourceGrid: FC<ISourceGridProps<any>> = ({ data }) => {
  const [isModalOpen,setIsModalOpen] = useState<boolean>(false);
  const { themeColors } = useThemeContext();

  const getDateWithoutTimeZone = (dateString: string): string => {
    const date = new Date(dateString);

    // Extract day, month, and year components
    const day = date.getDate().toString().padStart(2, '0'); // Ensure 2 digits with leading zero
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    const year = date.getFullYear().toString();

    // Format date as day/month/year
    return `${day}/${month}/${year}`;
  }; 
  


 


  return (
    <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-4">
      {data.map((item: ISourceData, index: number) => (
        <SourceCard
          key={index}
          id={item.id}
          Insurance={item.name}
          date={getDateWithoutTimeZone(item.created_at)}
          late={0}
          unedited={0}
          New={0}
          campaigns={0}
          totalleads={0}
          active={item.status}
          logo={item.logo}
          description={item.description}
        />
      ))}
      <NewCard
        title="New Source"
        desc="Simply create a new source. Just click on the button"
        onClick={() => setIsModalOpen(true)}
      />
         <ConditionalRender condition={isModalOpen as boolean}>
        <CreateSource
          onConfirm={() => setIsModalOpen(false)}
          themeColors={themeColors}
          hocClasses={{modalClassName: 'max-w-[551px]'}}
          hocCurrentStep={1}
          hocToggleModal={
            () => setIsModalOpen(false)
          }
          hocisOpen={isModalOpen}
          hocTitle="Create New Source"
          setHocCurrentStep={() => {}}
          clearData={true}
          />  
      </ConditionalRender>
    </div>
  );
};

export default SourceGrid;
