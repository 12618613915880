export interface IColorChooseOptions{ 
    id: number;
    color: string;
    name: string;
}



export const colorChooseOptions: Array<IColorChooseOptions> = [
    {
        id: 1,
        name: 'Blue',
        color: '#50B8E7'
    },
    {
        id: 2, 
        name: 'red',
        color: '#D61C1C'
    },
    {
        id: 3,
        name: 'orange',
        color: '#EFBE12',
    },
    {
        id: 4,
        name: 'yellow',
        color: '#EFD912'
    }
]