import { FC } from "react";
import Card, { ICard } from "components/molecules/card/Card";
import { CardType } from "lib/helpers/constants/cardTypes";
import { IInfoCardProps } from "components/molecules/card/info-card/InfoCard";
import { JSX } from "react/jsx-runtime";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useParnterStatisticCard } from "lib/hooks/mutations/Partners/useParnterStatisticCard";
import { useTranslation } from "react-i18next";

export interface IAppointmentCardsProps {
}

const PartnersCard: FC<IAppointmentCardsProps> = () => {
  const {data} = useParnterStatisticCard();
  const {themeColors} = useThemeContext();
  const {t} = useTranslation();
  
  

  const cardsData = [
    {
      title: `${t("Total Partners")}`,
      count: data?.total,
      icon: IconType.TOTAL_PARTNERS_ICON,
      color: themeColors.primary,
      // percentagedown:
      //   wonPercentage < lostPercentage ? IconType.DOWN_PERCENTAGE_ICON : null,
      // percentageup:
      //   wonPercentage > lostPercentage ? IconType.UP_PERCENTAGE_ICON : null,
      //   percentage: `${wonPercentage.toFixed(2)}%`,
      updatedate: new Date().toLocaleDateString(),
    },
    {
      title: `${t("New Partners")}`,
      count: '-',
      icon: IconType.NEW_PARTNER_ICON,
      color: themeColors.primary,
      // percentagedown:
      //   lostAppointments < lostPercentage
      //     ? IconType.DOWN_PERCENTAGE_ICON
      //     : null,
      // percentageup:
      //   lostAppointments > lostPercentage ? IconType.UP_PERCENTAGE_ICON : null,
      //   percentage: `${lostPercentage.toFixed(2)}%`,
      updatedate: new Date().toLocaleDateString(),
    },
    {
      title: `${t("Archived Partnerss")}`,
      count: data?.archive,
      icon: IconType.ARCHIVED_PARTNERS_ICON,
      color: themeColors.primary,
      // percentagedown:
      //   followUpAppointments < lostPercentage
      //     ? IconType.DOWN_PERCENTAGE_ICON
      //     : null,
      // percentageup:
      //   followUpAppointments > lostPercentage
      //     ? IconType.UP_PERCENTAGE_ICON
      //     : null,
      //     percentage: `${followUpPercentage.toFixed(2)}%`,
      updatedate: new Date().toLocaleDateString(),
    },
    {
      title: `${t("Deleted Partners")}`,
      count: data?.deleted,
      icon: IconType.DELETE_PARTNERS_ICON,
      color: themeColors.primary,
      updatedate: new Date().toLocaleDateString(),
      // percentagedown:
      //   pendingAppointments < lostPercentage
      //     ? IconType.DOWN_PERCENTAGE_ICON
      //     : null,
      // percentageup:
      //   pendingAppointments > lostPercentage
      //     ? IconType.UP_PERCENTAGE_ICON
      //     : null,
      // percentage: `${pendingPercentage.toFixed(2)}%`,
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 2xl:grid-cols-4 items-center justify-between gap-5 ">
      {cardsData?.map(
        (
          item: JSX.IntrinsicAttributes & ICard<IInfoCardProps>,
          index: number
        ) => (
          <Card<IInfoCardProps>
            cardType={CardType.INFO_CARD}
            {...item}
            key={`appointments-cards-key-${index}`}
          />
        )
      )}
    </div>
  );
};

export default PartnersCard;
