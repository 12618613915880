import { createNewLead } from "api/Leads";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useCreateLeads = () => {
  const queryClient = useQueryClient();
  return useMutation(createNewLead, {
    onSuccess: () => {
      queryClient.invalidateQueries('leads');
      toast.success("Leads Created Successfully");
    },
    onError: () => {
      toast.error(`Something went wrong`);
    },
  });
};
