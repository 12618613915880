import { FC } from "react";


interface IConditionalRenderProps {
    condition: boolean;
    children: React.ReactNode;
  }
  
  const ConditionalRender: FC<IConditionalRenderProps> = ({ condition, children }) => (
    <>
      {condition ? children : null}
    </>
  );
  
  export default ConditionalRender;