import { changeLanguage } from "api/User";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useLanguage = () => {
    return useMutation(changeLanguage, {
        onSuccess: () => {
        },
        onError: () => {
            toast.error(`Error saving the language to database, please try again later`);
        },
    });
};
