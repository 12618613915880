import { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}

function PendingsIcon({ className, color }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_4363_65629)">
        <path
          d="M21.4879 17.8436C21.0724 17.5345 20.4852 17.6208 20.1761 18.0362C20.0061 18.2646 19.8243 18.4887 19.6355 18.7023C19.2926 19.0902 19.329 19.6827 19.7169 20.0256C19.8953 20.1833 20.1168 20.2607 20.3375 20.2607C20.5968 20.2607 20.855 20.1537 21.0403 19.9441C21.2638 19.6913 21.4792 19.4259 21.6805 19.1553C21.9895 18.7399 21.9033 18.1526 21.4879 17.8436Z"
          fill={color || "#7D8592"}
        />
        <path
          d="M23.0557 13.4474C22.5501 13.3373 22.0503 13.6582 21.9402 14.164C21.8796 14.4423 21.8064 14.7212 21.7225 14.9928C21.5696 15.4876 21.8467 16.0125 22.3414 16.1653C22.4336 16.1938 22.5268 16.2073 22.6185 16.2073C23.0188 16.2073 23.3895 15.9489 23.5139 15.5463C23.6135 15.2239 23.7004 14.893 23.7723 14.5629C23.8825 14.057 23.5616 13.5576 23.0557 13.4474Z"
          fill={color || "#7D8592"}
        />
        <path
          d="M16.9753 20.8476C16.7264 20.9865 16.4687 21.1164 16.2093 21.2335C15.7375 21.4466 15.5277 22.0019 15.7408 22.4738C15.8973 22.8205 16.2386 23.0257 16.5957 23.0257C16.7247 23.0257 16.8558 22.9989 16.9811 22.9424C17.2887 22.8034 17.5942 22.6494 17.8893 22.4847C18.3415 22.2323 18.5033 21.6612 18.2508 21.2091C17.9985 20.7571 17.4274 20.5952 16.9753 20.8476Z"
          fill={color || "#7D8592"}
        />
        <path
          d="M11.0624 4.5V11.6117L7.62467 15.0494C7.25857 15.4155 7.25857 16.0091 7.62467 16.3752C7.80776 16.5583 8.04762 16.6498 8.28762 16.6498C8.52753 16.6498 8.76748 16.5583 8.95057 16.3752L12.6629 12.6629C12.8387 12.4871 12.9374 12.2486 12.9374 12V4.5C12.9374 3.98222 12.5177 3.5625 11.9999 3.5625C11.4822 3.5625 11.0624 3.98222 11.0624 4.5Z"
          fill={color || "#7D8592"}
        />
        <path
          d="M23.0625 2.01562C22.5447 2.01562 22.125 2.43534 22.125 2.95312V5.55638C19.9429 2.13412 16.1321 0 12 0C8.79469 0 5.78123 1.24823 3.51469 3.51469C1.24823 5.78123 0 8.79469 0 12C0 15.2053 1.24823 18.2188 3.51469 20.4853C5.78123 22.7518 8.79469 24 12 24C12.0079 24 12.0156 23.999 12.0234 23.9988C12.0313 23.999 12.039 24 12.0469 24C12.3848 24 12.726 23.9858 13.0611 23.9577C13.5771 23.9145 13.9603 23.4612 13.9171 22.9453C13.8739 22.4293 13.4212 22.0459 12.9046 22.0893C12.6213 22.113 12.3328 22.125 12.0469 22.125C12.039 22.125 12.0313 22.126 12.0234 22.1262C12.0156 22.126 12.0079 22.125 12 22.125C6.41705 22.125 1.875 17.583 1.875 12C1.875 6.41705 6.41705 1.875 12 1.875C15.598 1.875 18.9074 3.7928 20.7157 6.84375H18.138C17.6202 6.84375 17.2005 7.26347 17.2005 7.78125C17.2005 8.29903 17.6202 8.71875 18.138 8.71875H21C21.5709 8.71875 22.1051 8.5583 22.5599 8.28033C22.5891 8.26364 22.6172 8.24569 22.644 8.22633C23.46 7.68956 24 6.76627 24 5.71875V2.95312C24 2.43534 23.5803 2.01562 23.0625 2.01562Z"
          fill={color || "#7D8592"}
        />
      </g>
      <defs>
        <clipPath id="clip0_4363_65629">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default memo(PendingsIcon);
