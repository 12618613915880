import { FC, useState } from "react";
import Button from "components/atoms/button/Button";
import Accordion from "../accordion/Accordion";
import AccordionTitle from "../accordion/AccordionTitle/AccordionTitle";
import ToggleSwitch from "../toggle-switch/ToggleSwitch";
import AccordionBody from "../accordion/AccordionBody/AccordionBody";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";
import { useModules } from "lib/hooks/queries/Module/useModules";
import { usePermissionsModule } from "lib/hooks/queries/PermissionsModule/usePermissionsModule";
import { IPermissions } from "lib/types/permission.module.types";
import { Fragment } from "react/jsx-runtime";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Label from "components/atoms/input/input-label/InputLabel";
import CheckSignupIcon from "components/atoms/icon/icon-type/CheckSignupIcon";
import { useTranslation } from "react-i18next";

export interface IModuleListProps {
  selectedUser: { id: number } | null;
  selectedUserPermissions: any;
  setSelectedUserPermissions: any;
}

const ModuleList: FC<IModuleListProps> = ({
  selectedUser,
  selectedUserPermissions,
  setSelectedUserPermissions
}) => {
  const [currentModuleName, setCurrentModuleName] = useState<any>();
  const { confirm, setCurrentStep, formData } = useSignupCustomHooks({
    initalStep: undefined
  });
  const { data: modules } = useModules();
  const { data: permissions } = usePermissionsModule(currentModuleName);

  const { t } = useTranslation();

  const handleClickModal = async (currentModuleName: string) => {
    try {
      await confirm({
        title: `${t('Unlock')} ${currentModuleName}`,
        showCloseButton: true,
        classes: { button: "absolute right-[43px] top-[30px]" },
        description: `${t('You can upgrade your plan to benefit with the')} ${currentModuleName} ${t('and more.')}`,
        customComponent: (props) => {
          return (
            <div className="flex justify-end items-center gap-3 mt-[61px]">
              <Button
                type="submit"
                onClick={() => props.onCancel()}
                className="w-[168px] p-[13px] bg-[#F4F9FD] text-[#91929E] text-[13px] font-inter-bold rounded-[8px]"
              >
                {t('Cancel')}
              </Button>
              <Button
                type="button"
                onClick={() => {
                  props.onConfirm();
                  setCurrentStep(8);
                }}
                className="w-[168px] p-[13px]  bg-bluebutton text-white text-[13px] font-inter-bold rounded-[8px] flex items-center justify-center gap-[4px]"
              >
                {t('Upgrade Plan')}
                <Icon iconType={IconType.UPGRADE_ICON} />
              </Button>
            </div>
          );
        }
      });
    } catch (err) {
      return null;
    }
  };

  // const updateLocalStorage = (permissions: any[]) => {
  //   const storedData = JSON.parse(
  //     localStorage.getItem("multi_step_form_state") || "{}"
  //   );

  //   let storedPermissions = storedData.grouproles || [];

  //   permissions.forEach((permission) => {
  //     const isPermissionChecked = storedPermissions.includes(permission.id);
  //     if (isPermissionChecked) {
  //       storedPermissions = storedPermissions.filter(
  //         (id: any) => id !== permission.id
  //       );
  //     } else {
  //       storedPermissions.push(permission.id);
  //     }
  //   });

  //   const updatedData = {
  //     ...storedData,
  //     grouproles: storedPermissions,
  //   };
  // };
  const handlePermissionSelect = (permission: any) => {
    if (selectedUser?.id !== undefined) {
      setSelectedUserPermissions((prevPermissions: { [key: number]: any }) => {
        const updatedPermissions = prevPermissions[selectedUser.id]?.includes(
          permission.id
        )
          ? prevPermissions[selectedUser.id].filter(
            (id: any) => id !== permission.id
          )
          : [...(prevPermissions[selectedUser.id] || []), permission.id];

        // Update local storage after updating the state
        const storedData = JSON.parse(
          localStorage.getItem("multi_step_form_state") || "{}"
        );
        storedData?.grouproles?.forEach((group: any) => {
          if (group?.id === selectedUser.id) {
            group.permission = updatedPermissions;
          }
        });
        localStorage.setItem(
          "multi_step_form_state",
          JSON.stringify(storedData)
        );

        return {
          ...prevPermissions,
          [selectedUser.id]: updatedPermissions
        };
      });
    }
  };
  // Check if a permission is selected for a user
  const isPermissionChecked = (permission: any) => {
    if (Array.isArray(selectedUserPermissions[selectedUser?.id as any])) {
      return selectedUserPermissions[selectedUser?.id as any].includes(
        permission.id
      );
    } else {
      return false;
    }
  };

  return (
    <div className="border-[1px] border-[#D0D5DD] rounded-[20px]">
      <div className="grid grid-cols-2 p-[20px]  border-[#D0D5DD] pb-[22px] border-b-[1px] border-[#]">
        <h1 className="font-inter-regular text-primaryTextColor text-[14px]">
          {t('Permissions')}
        </h1>
        <Button
          type="button"
          onClick={() => setSelectedUserPermissions([])}
          className={`font-inter-regular text-[#D0D5DD] underline text-[14px] text-right ${selectedUser ? "cursor-pointer !text-[#50B8E7]" : ""
            }`}
        >
          {t('Reset All')}
        </Button>
      </div>
      <div className="p-[35px] flex  flex-col gap-[14px]">
        {selectedUser ? (
          <Fragment>
            {modules?.modules?.map(
              (item: { id: number; name: string }, i: number) => {
                const isSelected = formData?.modules?.includes(String(item.id));
                return (
                  <Accordion
                    key={`module-key-${item.id}`}
                    classes={{
                      wrapper: "relative",
                      body: "grid grid-cols-2  lg:grid-cols-1 xl:grid-cols-3 2xl:grid-cols-3 gap-3 items-center"
                    }}
                    open={
                      currentModuleName === item.name ||
                      currentModuleName === item.id
                    }
                    onClick={() => {
                      setCurrentModuleName(!isSelected ? "" : item?.name);
                      if (!isSelected) {
                        handleClickModal(item?.name);
                      }
                    }}
                  >
                    <AccordionTitle
                      customComponent={
                        <ToggleSwitch
                          className="!absolute right-[50px] top-[10px]"
                          disabled={true}
                          initialValue={isSelected}
                        />
                      }
                    >
                      {item.name}
                    </AccordionTitle>
                    <AccordionBody>
                      {permissions?.permissions?.map(
                        (permission: IPermissions) => {
                          const labelAfterSpace =
                            permission.name.split(" ").pop() || permission.name;
                          return (
                            <div className="flex items-center gap-[5px]">
                              <label className="relative flex items-center rounded-full cursor-pointer">
                                <input
                                  id="input"
                                  type="checkbox"
                                  className="peer w-[20px] cursor-pointer h-[20px] appearance-none border-2 border-gray-300 rounded-md"
                                  checked={isPermissionChecked(permission)}
                                  onChange={() => handlePermissionSelect(permission)}
                                />
                                <span className="absolute flex justify-center items-center text-white h-4 w-4 rounded-md transition-opacity opacity-0 pointer-events-none top-[10px] left-[10px] -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                  <CheckSignupIcon />
                                </span>
                              </label>
                              <Label className="!mb-0" text={labelAfterSpace} />
                            </div>
                          );
                        }
                      )}
                    </AccordionBody>
                  </Accordion>
                );
              }
            )}
          </Fragment>
        ) : (
          <EmptyEntity classes={{ childrenclass: "text-center" }}>
            {t('Select a user group to show the modules and functionalities')}
          </EmptyEntity>
        )}
      </div>
    </div>
  );
};

export default ModuleList;
