import { uploadAppointmentFile } from "api/Appointment";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useUploadAppointmentFile = (id?: string,
    options?: UseMutationOptions<any, any, any, unknown>
) => {
    const queryClient = useQueryClient();
    return useMutation(uploadAppointmentFile, {
        onSuccess: () => {
            toast.success("File uploaded successfully!")
            queryClient.refetchQueries('appointmentDocument');
        },
        
        onError: () => {
            toast.error("File upload failed! Please try again later.")
        },
        ...options
    })
}