import { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}

function ProjectIcon({ className , color }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1284_38692)">
        <path
          d="M19.5775 9.18065C19.1717 8.63565 18.5492 8.32232 17.8692 8.32232H17.5V6.66648C17.5 4.36898 15.6308 2.49982 13.3333 2.49982H9.12C8.99167 2.49982 8.86167 2.46898 8.7475 2.41232L6.11833 1.09732C5.77333 0.924818 5.38667 0.833985 5 0.833985H3.33333C1.495 0.833151 0 2.32815 0 4.16648V14.9998C0 17.2973 1.86917 19.1665 4.16667 19.1665H14.6317C16.4867 19.1665 18.09 17.9732 18.6333 16.1607L19.91 11.064C20.105 10.4123 19.9833 9.72648 19.5775 9.18065ZM1.66667 14.9998V4.16648C1.66667 3.24732 2.41417 2.49982 3.33333 2.49982H5C5.12833 2.49982 5.25833 2.53065 5.3725 2.58732L8.00167 3.90232C8.34667 4.07482 8.73333 4.16565 9.12 4.16565H13.3333C14.7117 4.16565 15.8333 5.28732 15.8333 6.66565V8.32148H7.17917C5.7625 8.32148 4.4975 9.22065 4.02167 10.5882L2.19667 16.5365C1.865 16.1123 1.66667 15.579 1.66667 14.9998ZM18.3033 10.6223L17.0267 15.7182C16.7067 16.784 15.7442 17.4998 14.6317 17.4998H4.16667C3.99333 17.4998 3.82417 17.4823 3.66083 17.4482L5.60583 11.1073C5.83833 10.4382 6.47167 9.98899 7.18 9.98899H17.87C18.0717 9.98899 18.1892 10.1057 18.2408 10.1757C18.2925 10.2457 18.3708 10.3915 18.3033 10.6223Z"
          fill={color ? color : "#7D8592"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1284_38692">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default memo(ProjectIcon);
