import { memo } from "react";

interface Props {
  className?: string;
}

function TransferIcon({ className }: Props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_3581_62654)">
        <path
          d="M24.4918 11.9936L13.5541 0V7.18433H12.2836C9.13617 7.18433 6.177 8.41003 3.95135 10.6357C1.72571 12.8613 0.5 15.8205 0.5 18.9681V24L2.59912 21.7C5.40704 18.6237 9.39288 16.8441 13.5541 16.8036V23.9874L24.4918 11.9936ZM1.90643 20.3844V18.9681C1.90643 16.1962 2.98584 13.5901 4.9458 11.6301C6.90576 9.67017 9.51172 8.59076 12.2836 8.59076H14.9604V3.62933L22.5886 11.9936L14.9604 20.358V15.3966H13.6986C9.2594 15.3966 4.99048 17.2084 1.90643 20.3844Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3581_62654">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default memo(TransferIcon);
