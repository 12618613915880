import Table from "components/molecules/table/Table";
import { FC, useMemo } from "react";
import { Column } from "react-table";
import { Fragment } from "react/jsx-runtime";

export interface IPartnersListProps<T> {
  data: T;
  dataCols: any;
}

const PartnersList: FC<IPartnersListProps<any>> = ({ data, dataCols }) => {

  const columns = useMemo<Column<any>[]>(
    () =>

      dataCols.filter((cols: any) => cols.isChecked)

    , [dataCols]

  ) as Column<object>[];

    console.log(columns);
    
  return (
    <Fragment>
      <Table columns={columns} data={data} search={""} isColumnSorted={true} />
    </Fragment>
  );
};

export default PartnersList;
