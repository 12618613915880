import {
  ForwardRefRenderFunction,
  SelectHTMLAttributes,
  forwardRef,
} from "react";
import { DeepMap, FieldError } from "react-hook-form";
import ErrorMessage from "../error-message/ErrorMessage";
import { numbersCode } from "../../../lib/helpers/constants/numbersCode";
import Label from "../input/input-label/InputLabel";
import { businessDirections } from "lib/helpers/constants/businessDirection";
import { languageData } from "lib/helpers/constants/languageData";
import { systemLanguage } from "lib/helpers/constants/languageSystem";

type TCustomSelectClasses = {
  container: string;
};

export enum OptionsType {
  NUMBERS_CODE = "numbers",
  BUSSNIESS_DIRECTION = "bussniess_direction",
  LANGUAGE_OPTIONS = "language_options",
  SYSTEM_LANGUAGE_OPTIONS = "system_language_options",
}

export interface ICustomSelectProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  error?: DeepMap<any, FieldError>;
  optionsType?: OptionsType;
  customOptions?: any;
  label?: string;
  placeholder?: string;
  placeholderValue?: string;
  classes?: Partial<TCustomSelectClasses>;
}

const CustomSelect: ForwardRefRenderFunction<
  HTMLSelectElement,
  ICustomSelectProps
> = (
  {
    optionsType,
    label,
    customOptions,
    classes = { container: "" },
    placeholder = " ",
    placeholderValue = "",
    error,
    ...rest
  }: ICustomSelectProps,
  ref
) => {
    let options: any[] = [];

    if (customOptions) {
      options = customOptions;
    } else {
      switch (optionsType) {
        case OptionsType.NUMBERS_CODE:
          options = numbersCode;
          break;
        case OptionsType.BUSSNIESS_DIRECTION:
          options = businessDirections;
          break;
        case OptionsType.LANGUAGE_OPTIONS:
          options = languageData;
          break;
        case OptionsType.SYSTEM_LANGUAGE_OPTIONS:
          options = systemLanguage;
          break;
        default:
          break;
      }
    }

    return (
      <div className={`relative ${classes.container}`}>
        {label && <Label text={label} className="whitespace-nowrap" />}
        <select
          {...rest}
          ref={ref}
          className={`border-[1px] appearance-none border-inputborder rounded-[8px] h-[40px] 2xl:h-[46px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular ${rest.className}`}
        >
          {placeholder.length >= 1 && (
            <option value={placeholderValue}>{placeholder}</option>
          )}
          {options.map((option, index: number) => (
            <option
              key={`option-key-${index}`}
              className="capitalize font-rubik"
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
        <ErrorMessage error={error} />
      </div>
    );
  };

export default forwardRef(CustomSelect);
