import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import Button from "components/atoms/button/Button";
import Headline from "components/atoms/headline/Headline";
import DroppableColumn from "components/molecules/card/overview-card/DroppableColumn";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useTranslation } from "react-i18next";

const OverviewView = () => {
  const { themeColors } = useThemeContext();
  const {t} = useTranslation();
  const [leftCards, setLeftCards] = useState([
    { id: 1, themeColors },
    { id: 2, themeColors },
  ]);
  const [rightCards, setRightCards] = useState([
    { id: 3, themeColors },
    { id: 4, themeColors },
  ]);

  const moveCard = (
    dragIndex: number,
    hoverIndex: number,
    source: string,
    destination: string
  ) => {
    const sourceCards = source === "left" ? [...leftCards] : [...rightCards];
    const destCards = destination === "left" ? [...leftCards] : [...rightCards];

    const [draggedCard] = sourceCards.splice(dragIndex, 1);
    destCards.splice(hoverIndex, 0, draggedCard);

    if (source === "left" && destination === "left") {
      setLeftCards(destCards);
    } else if (source === "left" && destination === "right") {
      setLeftCards(sourceCards);
      setRightCards(destCards);
    } else if (source === "right" && destination === "right") {
      setRightCards(destCards);
    } else if (source === "right" && destination === "left") {
      setRightCards(sourceCards);
      setLeftCards(destCards);
    }
  };

  const addCard = () => {
    const newCardId = leftCards.length + rightCards.length + 1;
    setLeftCards((prevCards) => [...prevCards, { id: newCardId, themeColors }]);
  };

  const addCardRight = () => {
    const newCardId = leftCards.length + rightCards.length + 1;
    setRightCards((prevCards) => [
      ...prevCards,
      { id: newCardId, themeColors },
    ]);
  };

  const removeCard = (id: number, source: string) => {

    if (source === "left") {
      setLeftCards((prevCards) => {
        const updatedCards = prevCards.filter((card) => card.id !== id);
        return updatedCards;
      });
    } else if (source === "right") {
      setRightCards((prevCards) => {
        const updatedCards = prevCards.filter((card) => card.id !== id);
        return updatedCards;
      });
    } else {
      console.error("Invalid source for removeCard:", source);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Headline title={t("Dashboard")} />
      <div className="grid md:grid-cols-[50%_50%] xl:grid-cols-[70%_30%] gap-[20px] pr-[20px]">
        <DroppableColumn
          id="left"
          cards={leftCards}
          moveCard={moveCard}
          themeColors={themeColors}
          removeCard={removeCard}
        />
        <DroppableColumn
          id="right"
          cards={rightCards}
          moveCard={moveCard}
          themeColors={themeColors}
          removeCard={removeCard}
        />
        <Button className="ml-auto" onClick={addCard}>
          <Icon
            iconType={IconType.INCRASE_ICON}
            className="w-[24px] h-[24px]"
            color={themeColors.primary}
          />
        </Button>
        <Button className="ml-auto" onClick={addCardRight}>
          <Icon
            iconType={IconType.INCRASE_ICON}
            className="w-[24px] h-[24px]"
            color={themeColors.primary}
          />
        </Button>
      </div>
    </DndProvider>
  );
};

export default OverviewView;
