import { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}

function StatisticIcon({ className, color }: Props) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 25C19.6274 25 25 19.6274 25 13H13V1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
        stroke={color || '#7D8592'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.332 8.6673V1.65723C19.6051 2.81916 22.1802 5.39426 23.3421 8.6673H16.332Z"
        stroke={color || '#7D8592'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default memo(StatisticIcon);
