import React, { FC, useCallback } from 'react'
import Form from '../../../atoms/form/Form'
import { useMultiStepFormContext } from '../../../../lib/context/MultiStepFormContext/MultiStepFormContext';
import FormStepNavigationButtons from '../../../molecules/form-step-navigation-buttons/FormStepNavigationButtons';

import CustomNode from './CustomNode';
import ReactFlow, { Controls, addEdge, useEdgesState, useNodesState } from 'reactflow';

import 'reactflow/dist/base.css';



export interface IOrganizationChartProps { }

const nodeTypes = {
    custom: CustomNode,
};

const initNodes = [
    {
        id: '1',
        type: 'custom',
        data: { id: 1, name: 'Jane Doe', job: 'CEO', team: 'Managment' },
        position: { x: 0, y: 50 },
    },
    {
        id: '2',
        type: 'custom',
        data: { id: 2, name: 'Tyler Weary', job: 'Designer', team: 'Finance' },

        position: { x: -200, y: 250 },
    },
    {
        id: '3',
        type: 'custom',
        data: { id: 3, name: 'Kristi Price', job: 'Developer', team: 'Finance' },
        position: { x: 200, y: 250 },
    },
    {
        id: '4',
        type: 'custom',
        data: { id: 4, name: 'Kristi Price', job: 'Developer', team: 'Finance' },
        position: { x: 200, y: 500 },
    },
];

const initEdges = [
    {
        id: 'e1-2',
        source: '1',
        target: '2',
    },
    {
        id: 'e1-3',
        source: '1',
        target: '3',
    },
    {
        id: 'e3-4',
        source: '3',
        target: '4',
    },
];

const OrganizationChart: FC<IOrganizationChartProps> = () => {
    const { currentStep  , nextFormStep} =
        useMultiStepFormContext();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);


    const onConnect = useCallback(
        (params: any) => setEdges((eds) => addEdge(params, eds)),
        [setEdges]
    );







    return (
        <Form>
            <div>
                <h1 className="text-bluebutton uppercase font-bold text-[14px] text-center mt-[40px] mb-[8px]">
                    STEP {currentStep}/6
                </h1>
                <span className="text-[#282D46] font-inter-bold text-[25px] flex justify-center">
                    Organigramm definieren
                </span>
            </div>
            <div style={{ height: 'calc(100vh - 350px)' }}>

                <ReactFlow

                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    className="bg-white"
                    fitView
                    fitViewOptions={{ maxZoom: 0.8 }}
                >
                    <Controls />
                </ReactFlow>
            </div>
            <FormStepNavigationButtons onNextStep={nextFormStep} />
        </Form>
    );
}

export default OrganizationChart