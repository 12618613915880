import { FC } from "react";
import Icon from "../../../atoms/icon/Icon";
import { IconType } from "../../../../lib/helpers/constants/iconTypes";
import Paragraph from "../../../atoms/paragraph/Paragraph";
import DefaultContainer from "../../../templates/default-container/DefaultContainer";
import FormStepNavigationButtons from "../../../molecules/form-step-navigation-buttons/FormStepNavigationButtons";
import { useMultiStepFormContext } from "../../../../lib/context/MultiStepFormContext/MultiStepFormContext";

export interface ICongratulationsStepProps {}

const CongratulationsStep: FC<ICongratulationsStepProps> = () => {
  const { nextFormStep } = useMultiStepFormContext();
  return (
    <div className="flex justify-between h-full flex-col">
      <DefaultContainer className="m-auto">
        <div className="text-center flex flex-col gap-[60px]">
          <h1 className="text-[#282D46] font-inter-bold text-[18px] mt-[40px]">
            <span className="text-[#282D46] font-inter-bold text-[25px] block">
              Congratulations!
            </span>
            Your e-mail address has been confirmed
          </h1>
          <Icon iconType={IconType.CONGRATS_ICON} className="mx-auto" />
          <Paragraph
            className="text-[#282D46] font-inter-regular text-[14px]"
            paragraph="Now you can complete entering the company information."
          />
        </div>
      </DefaultContainer>
      <FormStepNavigationButtons onNextStep={nextFormStep} />
    </div>
  );
};

export default CongratulationsStep;
