import { createCustomer } from "api/Customers";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useCreateCustomer = () => {
  const queryClient = useQueryClient();

        return useMutation(createCustomer, {
    onSuccess: () => {
        toast.success('Created Successfully')
        queryClient.invalidateQueries('coustomers');

    },
    onError: () => {
      toast.error('Something went wrong')
    }
  });
};
