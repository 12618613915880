import React from "react";
import { useDrop, DropTargetMonitor } from "react-dnd";
import DraggableOverviewCard from "./DraggableOverviewCard";

const ItemType = {
  CARD: "card",
};

interface DroppableColumnProps {
  id: string;
  cards: any[];
  moveCard: (
    dragIndex: number,
    hoverIndex: number,
    source: string,
    destination: string
  ) => void;
  themeColors: {
    primary: string;
  };
  removeCard: (id: number, source: string) => void;
}

interface DragItem {
  id: number;
  index: number;
  source: string;
}

const DroppableColumn: React.FC<DroppableColumnProps> = ({
  id,
  cards,
  moveCard,
  themeColors,
  removeCard,
}) => {
  const [, drop] = useDrop<DragItem>({
    accept: ItemType.CARD,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (item.source === id) return;

      const dragIndex = item.index;
      const hoverIndex = cards.length;
      moveCard(dragIndex, hoverIndex, item.source, id);
      item.index = hoverIndex;
      item.source = id;
    },
  });

  return (
    <div ref={drop} className="flex flex-col gap-[15px]">
      {cards.map((card, index) => (
        <DraggableOverviewCard
          removeCard={removeCard}
          key={card?.id}
          id={card?.id}
          index={index}
          source={id}
          themeColors={themeColors}
          moveCard={moveCard as any}
        />
      ))}
    </div>
  );
};

export default DroppableColumn;
