import { FunctionComponent } from "react";

export interface ILoadingScreen {
  className?: string;
}

const LoadingScreen: FunctionComponent<ILoadingScreen> = ({ className }) => {
  return (
    <div
      className={`flex justify-center items-center h-full absolute bg-white top-0 z-[9999999999999999999999] left-0 !bottom-0 right-0 w-full ${className}`}
    >
      <div className="loader"></div>
    </div>
  );
};

export default LoadingScreen;
  