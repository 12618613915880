import { storeUploadedLeads } from "api/Leads";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useUploadLeadStoreFile = () => {
  const queryClient = useQueryClient();

    return useMutation(storeUploadedLeads, {
        onSuccess: () => {
            queryClient.invalidateQueries('leads');
            toast.success('Leads stored successfully')
        },
        onError: (error) => {
            console.error(error);
        }
    });
};