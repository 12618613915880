import Table from 'components/molecules/table/Table';
import {FC, useMemo} from 'react'
import { Column } from 'react-table';

export interface  IEmployeesManagementListProps<T>{
    data: T;
    dataCols: any;
  }

const EmployeesManagementList:FC<IEmployeesManagementListProps<any>> = ({dataCols,data}) => {
  const columns = useMemo<Column<any>[]>(
    () =>

      dataCols?.filter((cols: any) => cols?.isChecked)

    , [dataCols]

  ) as Column<object>[];
  return (
    <Table columns={columns} data={data} isColumnSorted={true} search={''} />
  )
}

export default EmployeesManagementList