import { FC } from "react";
import Button from "../../atoms/button/Button";
import Icon from "../../atoms/icon/Icon";
import { IconType } from "../../../lib/helpers/constants/iconTypes";
import { useMultiStepFormContext } from "../../../lib/context/MultiStepFormContext/MultiStepFormContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Titles = {
  prevsteptitle: string;
  nextsteptitle: string;
};

interface FormStepNavigationButtonsProps {
  onNextStep?: () => void;
  onDraftButton?: boolean;
  onPrevStep?: boolean;
  isLoading?: boolean;
  titles?: Partial<Titles>;
  isDisable?: boolean;
  navigateToLogin?: boolean;
}
const FormStepNavigationButtons: FC<FormStepNavigationButtonsProps> = ({
  onDraftButton,
  onNextStep,
  onPrevStep = true,
  isLoading = false,
  isDisable = false,
  titles = { prevsteptitle: "Previous", nextsteptitle: "Continue" },
  navigateToLogin = false,
}) => {
  const navigate = useNavigate();
  const { prevFormStep, currentStep, removeKeyFromFormData } =
    useMultiStepFormContext();

  const handleNextClick = () => {
    if (!isDisable && onNextStep) {
      onNextStep();
    }
  };
  const handlePrevStep = () => {
    if (navigateToLogin) {
      navigate("/");
    }
    if (currentStep) {
      if (currentStep === 7) {
        removeKeyFromFormData("grouproles");
      }
      prevFormStep();
    }
  };
  const {t} = useTranslation();

  return (
    <div className="flex items-center justify-between w-full p-[25px] 2xl:p-[40px] border-t-[1px] border-border mt-[20px]">
      <div>
        {onPrevStep && (
          <Button
            onClick={handlePrevStep}
            className="flex items-center font-inter-medium gap-2 text-bluebutton "
          >
            <Icon iconType={IconType.LEFT_ARROW_ICON} />
            {titles.prevsteptitle}
          </Button>
        )}
      </div>
      <div className="flex items-center gap-5">
        {onDraftButton && (
          <Button
            onClick={onNextStep}
            type="submit"
            className="flex items-center justify-center gap-2 bg-[#F4F9FD] text-[#91929E] p-[15px] w-[269px] rounded-[8px] font-inter-bold"
          >
            <Icon iconType={IconType.DRAFT_ICON} />
            {t('Save as draft')}
          </Button>
        )}
        <Button
          disabled={isLoading || isDisable}
          onClick={handleNextClick}
          type="submit"
          className={`flex items-center font-inter-medium justify-center gap-2 bg-bluebutton text-white p-[15px] w-[269px] rounded-[8px] ${
            isLoading ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          {titles.nextsteptitle || t("Continue")}
          <Icon iconType={IconType.RIGHT_ARROW_ICON} />
        </Button>
      </div>
    </div>
  );
};
export default FormStepNavigationButtons;
