

export interface ISideSteps {
    label: string;
}



export const sideSteps:Array<ISideSteps> = [
    {
      label: "Create account",
    },
    {
      label: "Company informations",
    },
    {
      label: "Create org chart",
    },
    {
      label: "Customize your plan",
    },
    {
      label: "Create user roles",
    },
    {
      label: "Plan cost & payment",
    }
  ]