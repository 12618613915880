import { useMutation } from "react-query";
import { login } from "../../../../api/User";
import { useMultiStepFormContext } from "../../../context/MultiStepFormContext/MultiStepFormContext";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "lib/context/AuthContextContext/AuthContext";


export interface IUseTwoFAProps {
    showError?: boolean;
}

export const useTwoFactorAuth = ({ showError = false }: IUseTwoFAProps = {}) => {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState<string>('');
    const { nextFormStep } = useMultiStepFormContext();
    const { setIsAuthenticated, setUser } = useAuthContext();

    return {
        ...useMutation(login, {
            onSuccess: (data) => {
                nextFormStep();
                if (data.user !== undefined) {

                    localStorage.setItem('token', data.user.token);
                    axios.defaults.headers.common.Authorization = `Bearer ${data.user.token}`;

                    setIsAuthenticated(true)
                    setUser(data.user)
                    navigate("/leads");
                }
            },
            onError: () => {
                if (showError) {
                    setErrorMessage('Error');
                }
            },
        }),
        errorMessage
    };
};