import Form from "components/atoms/form/Form";
import CSVIcon from "components/atoms/icon/icon-type/CSVIcon";
import CopyIcon from "components/atoms/icon/icon-type/CopyIcon";
import TutorialCamIcon from "components/atoms/icon/icon-type/TutorialCamIcon";
import ZapierToolIcon from "components/atoms/icon/icon-type/ZapierToolIcon";
import { FC } from "react";
import { useTranslation } from "react-i18next";


export interface IStep2Props {
    setStep: (arg: number) => void
    themeColors: {
        primary: string;
    }
}

const Step2: FC<IStep2Props> = ({ setStep ,themeColors}) => {

    const {t} = useTranslation();

    return (
        <Form
            className="mb-0 flex flex-col gap-5"
        >
            <div className="flex flex-col gap-0">
                <div className="text-[16px] font-inter-medium pb-5">
                    {t('Automized integration')}
                </div>
                <div className="flex flex-row border border-solid border-[#D0D5DD] justify-between rounded-t-[20px] p-5">
                    <div className="px-3 text-[16px] font-inter-semibold">{t('Tool')}</div>
                    <div className="px-3 text-[16px] font-inter-semibold">{t('Tutorial')}</div>
                    <div className="px-3 text-[16px] font-inter-semibold">{t('Webhook')}</div>
                    <div className="px-3 text-[16px] font-inter-semibold">{t('Status')}</div>
                </div>
                <div className="flex flex-row border border-t-0 border-solid border-[#D0D5DD] rounded-b-[20px] p-5 items-center">
                    <div className="flex flex-row gap-2 pr-20 text-[16px] font-inter-medium items-center">
                        <div>
                            <ZapierToolIcon />
                        </div>
                        <div>
                            Zapier
                        </div>
                    </div>
                    <div className="flex flex-row gap-2  text-[16px] font-inter-medium">
                        <div>
                            <TutorialCamIcon />
                        </div>
                        <div>
                            {t('Watch Tutorial')}
                        </div>
                    </div>
                    <div className={`flex flex-row gap-2 text-[16px] pl-14 font-inter-medium text-[--theme]`}>
                        <div>
                            <CopyIcon color={`${themeColors.primary}`} />
                        </div>
                        <div>
                            {t('Copy Webhook')}
                        </div>
                    </div>
                </div>
                <div className="text-[16px] font-inter-medium pt-10 pb-5">
                    {t('Upload CSV')}
                </div>
                <div className="flex flex-row border border-solid border-[#D0D5DD] justify-between rounded-[20px] p-5">
                    <div className="flex flex-row gap-1 px-3 text-[16px] font-inter-semibold">
                        <div>
                            <CSVIcon />
                        </div>
                        <div>
                            {t("CSV Upload")}
                        </div>
                    </div>
                    <div className="px-3 text-[16px] font-inter-medium">
                        <div>
                            ( max . 10mb)
                        </div>
                    </div>
                    <button type="button" className={`px-3 text-[16px] font-inter-medium text-[${themeColors.primary}] cursor-pointer`} onClick={() => { setStep(3) }}>
                        <div>
                            {t('Click for upload')}
                        </div>
                    </button>
                </div>

            </div>
        </Form>
    );
};

export default Step2;
