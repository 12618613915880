import { useState, useEffect } from "react";

export const useHandleDropZoneFileChange = (
  defaultValue?: string | undefined
) => {
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    if (defaultValue) {
      setPreview(defaultValue);
    }
  }, [defaultValue]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    
    
    if (files?.length !== 0 && files) {
      setPreview(URL.createObjectURL(files[0]));
    }
  };

  return { preview, handleFileChange ,setPreview};
};
