import React, { Suspense } from "react";
import ErrorBoundary from "./components/atoms/error-boundary/ErrorBoundary";
import { useAuthContext } from "./lib/context/AuthContextContext/AuthContext";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes/AuthenticatedRoutes";
import UnAuthenticatedRoutes from "./routes/UnauthenticatedRoutes/UnauthenticatedRoutes";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// i18n.js
import HttpApi from 'i18next-http-backend'; // Add this line to load files

i18n
  .use(HttpApi)  // Use Backend plugin to load translations
  .use(initReactI18next)
  .init({
    lng: 'en',
    supportedLngs: ['en', 'de'], // Add the languages you want to support
    fallbackLng: 'en',  // Fallback language if the detected language is not available
    interpolation: {
      escapeValue: false, // React already handles XSS
    },
    backend: {
      loadPath: 'locales/{{lng}}.json'  // Path to your translation files
    }
  });

function App() {

  const { isAuthenticated, isLoading } = useAuthContext();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <ErrorBoundary >
      <Suspense fallback={<LoadingScreen />}>
        {isAuthenticated ? <AuthenticatedRoutes /> : <UnAuthenticatedRoutes />}
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
