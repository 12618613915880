import IconButton from "components/atoms/button/IconButton";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import React, { Dispatch, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Creatable from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import Label from "components/atoms/input/input-label/InputLabel";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useTranslation } from "react-i18next";

export interface IFilterProps {
  status?: string;
  date_of_creation?: any;
}

export interface IFilterTableDropdownProps {
  setFilterData?: (args: any) => void;
  filterData?: IFilterProps;
  setIsOpen?: Dispatch<React.SetStateAction<boolean>>;
  themeColors: {
    primary: string;
  };
}

const FilterTableDropdown: React.FC<IFilterTableDropdownProps> = ({
  setFilterData,
  filterData,
  setIsOpen,
  themeColors,
}) => {
  const { control, reset } = useForm({
    defaultValues: {
      status: filterData?.status || "",
      date_of_creation: filterData?.date_of_creation || null,
    },
  });
  const [dateOfCreation, setDateOfCreation] = useState<Date | null>(
    filterData?.date_of_creation
  );
  const [insideData, setInsideData] = useState<IFilterProps>(
    filterData
      ? filterData
      : {
          status: "",
          date_of_creation: undefined,
        }
  );
  const [key, setKey] = useState(0); // Key state to force re-render
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setInsideData((prevFormData: any) => ({
      ...prevFormData,
      date_of_creation: dateOfCreation,
    }));
  }, [dateOfCreation]);

  const handleButtonClick = () => {
    setIsLoading(true);
    if (setFilterData) {
      setFilterData(insideData);
    }
    setIsOpen && setIsOpen(false);
    setIsLoading(false);
  };

  const resetData = () => {
    reset({
      status: "",
      date_of_creation: null,
    });
    setInsideData({ status: "", date_of_creation: undefined });
    setDateOfCreation(null);
    setKey((prevKey) => prevKey + 1); // Change key to force re-render
  };

  const {t} = useTranslation();

  return (
    <div className="flex flex-col gap-3">
      {isLoading && <LoadingScreen />}
      <div key={key}>
        <Label text={t('Status')} />
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder={t('Choose Status')}
              options={[
                { label: `${t('Active')}`, value: "1" },
                { label: `${t('Inactive')}`, value: "0" },
              ]}
              value={
                field.value
                  ? {
                      value: field.value,
                      label: field.value === "1" ? `${t('Active')}` : `${t('Inactive')}`,
                    }
                  : null
              }
              className="cursor-pointer font-inter-regular react-select-container"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                field.onChange(selectedValue);
                setInsideData((prevState) => ({
                  ...prevState,
                  status: selectedValue,
                }));
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>
      <PrimaryDatePicker
        label={t('Date of creation')}
        setStartDate={setDateOfCreation}
        startDate={dateOfCreation}
        wrapperClassName="!left-[2px]"
      />

      <div className="pt-5">
        <div className="flex flex-row justify-end gap-3">
          <IconButton
            secondary={true}
            className="w-[110px]"
            onClick={resetData}
          >
            {t('Reset')}
          </IconButton>
          <IconButton className="w-[110px]" onClick={handleButtonClick}>
            {t('Confirm')}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default FilterTableDropdown;
