import { FC, HTMLAttributes } from "react";

export interface IFormWrapperProps extends HTMLAttributes<HTMLFormElement> {
  children: React.ReactNode;
}

const Form: FC<IFormWrapperProps> = ({ children, ...rest }) => {
  return <form {...rest}>{children}</form>;
};

export default Form;