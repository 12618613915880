import { deleteAppointmentDocument } from "api/Appointment";
import { queryClient } from "lib/helpers/configs/queryClient";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useDeleteAppointmentDocument = () => {
  return useMutation(deleteAppointmentDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries("appointmentDocument");
      queryClient.refetchQueries("appointmentDocument", { exact: true });
      toast.success("Document deleted Successfully");
    },
    onError: () => {
      toast.error(`Failed to delete document`);
    },
  });
};
