import { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}

const DeletePartnersIcon = ({ className, color }: Props) => {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.2405 4.05778L12.8389 5.31708C12.8341 5.31867 12.8292 5.32027 12.8243 5.32195C12.3537 5.48569 12.0374 5.93048 12.0374 6.42886V7.70494H7.68786L12.659 2.73384C12.9336 2.45925 12.9336 2.01408 12.659 1.73948L11.3994 0.479813C10.7597 -0.159937 9.71876 -0.159937 9.07906 0.479813L7.78386 1.77502L7.12086 1.11202C6.38979 0.380906 5.2002 0.380906 4.46904 1.11202L1.88346 3.69759C1.1524 4.4287 1.1524 5.6183 1.88346 6.34941L2.54646 7.01241L1.25126 8.30761C0.611559 8.94731 0.611559 9.98822 1.25126 10.6279L2.51089 11.8875C2.78543 12.1621 3.2307 12.1622 3.50529 11.8875L5.28757 10.1053V20.9529C5.28757 22.6331 6.6545 24 8.33464 24H15.7414C17.4216 24 18.7885 22.6331 18.7885 20.9529C18.7885 20.4577 18.7885 5.39991 18.7885 5.16773C18.7886 4.36711 18.0003 3.80034 17.2405 4.05778ZM2.87787 4.69195L5.4634 2.10642C5.64617 1.92366 5.94359 1.92361 6.12645 2.10642L6.7894 2.76942L3.54087 6.01795L2.87787 5.355C2.69511 5.17219 2.69511 4.87477 2.87787 4.69195ZM3.00814 10.3959L2.24567 9.63352C2.15426 9.54211 2.15426 9.39338 2.24567 9.30197C2.73762 8.81002 9.82142 1.72627 10.0735 1.47422C10.1649 1.38281 10.3136 1.38281 10.405 1.47422L11.1674 2.23669L3.00814 10.3959ZM17.3823 20.9529C17.3823 21.8577 16.6462 22.5937 15.7415 22.5937H8.33468C7.42995 22.5937 6.69392 21.8576 6.69392 20.9529V9.11128H17.3823V20.9529ZM17.3823 7.74281L13.4437 7.70498V6.59953L17.3823 5.49V7.74281Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M8.91016 20.7672C9.29847 20.7672 9.61328 20.4524 9.61328 20.0641V11.6445C9.61328 11.2562 9.29847 10.9414 8.91016 10.9414C8.52184 10.9414 8.20703 11.2562 8.20703 11.6445V20.064C8.20698 20.4524 8.5218 20.7672 8.91016 20.7672Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M12.0391 20.7672C12.4274 20.7672 12.7422 20.4524 12.7422 20.0641V11.6445C12.7422 11.2562 12.4274 10.9414 12.0391 10.9414C11.6507 10.9414 11.3359 11.2562 11.3359 11.6445V20.064C11.3359 20.4524 11.6507 20.7672 12.0391 20.7672Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M15.166 20.7672C15.5543 20.7672 15.8691 20.4524 15.8691 20.0641V11.6445C15.8691 11.2562 15.5543 10.9414 15.166 10.9414C14.7777 10.9414 14.4629 11.2562 14.4629 11.6445V20.064C14.4629 20.4524 14.7777 20.7672 15.166 20.7672Z"
        fill={color || '#50B8E7'}
      />
    </svg>
  );
};

export default memo(DeletePartnersIcon);
