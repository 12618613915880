import {
  IModulesResponse,
  IPermissionsModuleResponse,
} from "../lib/types/permission.module.types";
import {
  ICheckEmailVerification,
  ILoginRequest,
  IRequestVerificationEmail,
  ITwoFactor,
  IUserRegiserRequest,
  IUserResponse,
  IVerifyExistingEmailResponse,
  IVerifyToken,
  IVerifyWorkersEmailResponse,
} from "../lib/types/user.types";
import { apiRequest } from "./Api";

export const getPermissionsModule = () =>
  apiRequest<IModulesResponse, any>("GET", "modules");

export const createAccount = (data: any) =>
  apiRequest<IUserRegiserRequest, IUserResponse>(
    "POST",
    "auth/register",
    data,
    {
      "content-type": "multipart/form-data",
    }
  );

export const emailVerificationRequest = (token: any) =>
  apiRequest<string, any>("post", "auth/verify-email", token);

export const requestVerificationEmail = (data: IRequestVerificationEmail) =>
  apiRequest<IRequestVerificationEmail, any>(
    "POST",
    "auth/request-verify-email",
    data
  );

export const checkEmailVerification = (data: ICheckEmailVerification) =>
  apiRequest<ICheckEmailVerification, any>(
    "POST",
    "auth/check-email-verification",
    data
  );

export const getPermissionsOfModule = (search: string) =>
  apiRequest<IPermissionsModuleResponse, any>(
    "GET",
    `modules/permissions-of-module?module=${search}`
  );

export const login = (data: ILoginRequest) =>
  apiRequest<ILoginRequest, any>("POST", "auth/login", data);

export const twoFactor = (data: ITwoFactor) =>
  apiRequest<ITwoFactor, any>("POST", "auth/2fa", data);

export const forgotPassowrd = (data: ITwoFactor) =>
  apiRequest<ITwoFactor, any>("POST", "auth/request-forget-password", data);

export const verifyPasswordToken = (data: any) =>
  apiRequest<any, any>("POST", "auth/verify-password-token", data);

export const resetPassword = (data: any) =>
  apiRequest<any, any>("POST", "auth/change-password", data);

export const verifyEmail = (email?: IVerifyExistingEmailResponse) =>
  apiRequest<IVerifyExistingEmailResponse, any>(
    "POST",
    "auth/verify-email-exist",
    email
  );

export const verifyToken = (token: IVerifyToken) =>
  apiRequest<IVerifyToken, any>("POST", "auth/verify-auth-token", token, {
    Authorization: `Bearer ${token}`,
  });

export const logout = (token: IVerifyToken) =>
  apiRequest<IVerifyToken, any>("POST", "logout", token, {
    Authorization: `Bearer ${token}`,
  });


export const getUsersByPermission = (data: any) =>
  apiRequest<any, any>("GET", "users-by-permissions", null, undefined, data);


export const verifyWorkersEmail = (emails?: IVerifyWorkersEmailResponse) =>
  apiRequest<IVerifyWorkersEmailResponse, any>(
    "POST",
    "auth/verify-email-exist",
    emails
  );

export const changeLanguage = (lang?: any) =>
  apiRequest<any, any>(
    "POST",
    "lang",
    lang
  );