import Icon from "components/atoms/icon/Icon";
import { ILead } from "lib/types/leads.types";
import { useState } from "react";
import React from "react";
import { IconType } from "../iconTypes";
import { useTranslation } from "react-i18next";

export const leadsTableHeaders = [
  {
    id: 1,
    Header: "Name",
    accessor: (row: ILead) =>
      row.info?.first_name && row.info?.last_name
        ? `${row.info.first_name} ${row.info.last_name}`
        : "Not available",
    isChecked: true,
  },
  {
    Header: "Source",
    accessor: (row: ILead) => row.source || "Not available",
    id: 2,
    isChecked: true,
  },
  {
    Header: "Email",
    accessor: (row: ILead) => row.info?.email || "Not available",
    id: 3,
    isChecked: true,
  },
  {
    Header: "Phone",
    accessor: (row: ILead) => row.info?.phone || "Not available",
    id: 4,
    isChecked: true,
  },
  {
    Header: "Status",
    accessor: (row: ILead) => row.feedback_status || "Not available",
    id: 5,
    Cell: ({ value }: any) => <FeedbackStatus value={value} />,
    isChecked: true,
  },
  {
    Header: <span className="sr-only"> Actions </span>,
    accessor: "actions",
    id: 6,
    Cell: ({ row: { original } }: any) => <ActionButton original={original} />,
    isChecked: true,
  },
];

export const ActionButton = ({
  original,
  setIsUpdateModalOpen,
  setUpdateData,
}: any) => {
  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        setIsUpdateModalOpen(true);
        setUpdateData(original);
      }}
    >
      <Icon iconType={IconType.OPTIONS_ICON} />
    </div>
  );
};

export const FeedbackStatus = ({ value }: any) => {
  const [Color, setColor] = useState<string>("");
  const {t} = useTranslation();

  React.useEffect(() => {
    switch (value) {
      case t('Unprocessed'):
        setColor("#DE92EB");
        break;
      case t('Not Reached'):
        setColor("#CC7272");
        break;
      case t('In Contact'):
        setColor("#EFBE12");
        break;
      case t('Appointment'):
        setColor("#70DDE4");
        break;
      case t('Lost'):
        setColor("#DC4D4D");
        break;
      case t('New Customer'):
        setColor("#2DE28B");
        break;
    }

  }, [value,t]);

  return (
    <span
      className={`text-[12px] bg-opacity-10 font-inter-light px-[8px] py-[3px] rounded-[4px] whitespace-nowrap`}
      style={{ background: Color + "1A", color: Color }}
    >
      {value}
    </span>
  );
};
