import React, { FC } from "react";

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  icon?: React.ReactNode;
  secondary?: boolean;
  disabled?: boolean;
}

const IconButton: FC<IButtonProps> = ({
  icon,
  children,
  secondary,
  disabled,
  ...rest
}) => {
  return (
    <button
      {...rest}
      disabled={disabled}
      className={`${
        secondary
          ? `transparent text-[--theme]`
          : `bg-[--theme] text-white !border-none
            }]`
      }  border rounded-[8px] border-[--theme] py-2 lg:py-3 px-5  font-inter-medium text-sm xl:text-base disabled:opacity-50 ${
        rest.className
      } `}
    >
      <div className="flex flex-row gap-2 justify-center items-center">
        {icon && icon}
        <div>{children}</div>
      </div>
    </button>
  );
};

export default IconButton;
