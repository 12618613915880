import React, {
  useState,
  useRef,
  InputHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
import Label from "../input-label/InputLabel";
import Input from "../Input";
import Icon from "components/atoms/icon/Icon";
import { DeepMap, FieldError } from "react-hook-form";
import ErrorMessage from "components/atoms/error-message/ErrorMessage";

type TypeAutoCompletInputClass = {
  container?: string;
  icon?: string;
  label?: string;
  fillcolor?: string;
};

interface IAutocompleteInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  suggestions: string[];
  classes?: Partial<TypeAutoCompletInputClass>;
  label?: string;
  icon?: string;
  value?: string;
  name: string;
  clearErrors?: () => void;
  onKeyDown?: any;
  error?: DeepMap<any, FieldError>;
  setValue?: (name: string, value: string) => void;
}

const AutocompleteInput: ForwardRefRenderFunction<
  HTMLInputElement,
  IAutocompleteInputProps
> = (
  {
    suggestions,
    label,
    icon,
    value: propValue,
    name,
    onKeyDown,
    clearErrors,
    setValue: setFormValue,
    error,
    classes = { container: "", icon: "", label: "", fillcolor: "" },
    ...rest
  },
  ref
) => {
  const [value, setValue] = useState(propValue || "");
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] =
    useState<number>(-1);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setValue(inputValue);
    // Filter suggestions based on user input
    const filtered =
      inputValue.trim() === ""
        ? suggestions
        : suggestions.filter((suggestion) =>
            suggestion.toLowerCase().includes(inputValue.trim().toLowerCase())
          );
    setFilteredSuggestions(filtered);
    // Update form value
    if (setFormValue) {
      setFormValue(name, inputValue);
    }
    // Clear errors only when the user input is not empty and it doesn't match any suggestion
    if (inputValue.trim() !== "" && filtered.length === 0) {
      clearErrors && clearErrors();
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setValue(suggestion);
    setFilteredSuggestions([]);
    if (setFormValue) {
      setFormValue(name, suggestion);
      clearErrors && clearErrors();
    }
    if (onKeyDown) {
      const fakeEvent = {
        key: "Enter",
        target: { value: suggestion },
        preventDefault: () => {},
      };
      onKeyDown(fakeEvent as any);
    }
  };

  const handleFocus = () => {
    setFilteredSuggestions(suggestions);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setFilteredSuggestions([]);
    }, 200);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      const nextIndex = selectedSuggestionIndex + 1;
      if (nextIndex < filteredSuggestions.length) {
        setSelectedSuggestionIndex(nextIndex);
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      const nextIndex = selectedSuggestionIndex - 1;
      if (nextIndex >= 0) {
        setSelectedSuggestionIndex(nextIndex);
      }
    } else if (event.key === "Enter" && selectedSuggestionIndex !== -1) {
      event.preventDefault();
      handleSuggestionClick(filteredSuggestions[selectedSuggestionIndex]);
    }
  };

  const renderSuggestions = () => {
    return (
      <ul
        className="absolute z-10 max-h-[150px] 2xl:max-h-60 left-0 w-full bg-white border border-gray-300 rounded-b shadow-lg overflow-y-auto  mt-1 rounded-lg"
        style={{
          display: filteredSuggestions.length > 0 ? "block" : "none",
          top: "100%",
          boxSizing: "border-box",
          borderRadius: "8px",
        }}
      >
        {filteredSuggestions.map((suggestion, index) => (
          <li
            key={index}
            onClick={() => handleSuggestionClick(suggestion)}
            className={`px-4 py-2 cursor-pointer hover:bg-gray-200 text-[14px] text-[#667085] font-inter-regular ${
              index === selectedSuggestionIndex ? "bg-gray-200" : ""
            }`}
          >
            {suggestion}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={`relative ${classes.container}`}>
      {label && <Label text={label} className={classes.label} />}
      <div className="relative">
        <Input
          ref={inputRef || ref}
          {...rest}
          type="text"
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className="w-full"
        />
        {renderSuggestions()}
        {icon && (
          <Icon
            iconType={icon as any}
            className={`absolute right-3 top-[55%] transform -translate-y-1/2 ${classes?.icon}`}
          />
        )}
      </div>
      <ErrorMessage error={error} />
    </div>
  );
};

export default forwardRef(AutocompleteInput);
