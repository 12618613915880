import { FunctionComponent, useEffect, useState } from "react";
import { ConfirmationData } from "../../../lib/context/ModalContext/types";
import Button from "../../atoms/button/Button";
import Icon from "../../atoms/icon/Icon";
import { IconType } from "../../../lib/helpers/constants/iconTypes";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import React from "react";
import { useModalContext } from "lib/context/ModalContext/ModalContext";



interface IDynamicModal {
  confirmationData: ConfirmationData;
}

const DynamicModal: FunctionComponent<IDynamicModal> = (props) => {
  const {
    confirmationData: {
      text,
      description,
      isOpen,
      onCancel,
      customComponent,
      clearData = false,
      classes,
      title,
      statusBtn,
      showCloseButton,
      clearSignupUserGroupData = false,
      clearSignupUserGroupFunction,
      onCancelComponent,
    },
  } = props;
  const { clearFormData } = useMultiStepFormContext();
  const {hasChanges,setHasChanges} = useModalContext();
  const [showCancelComponent, setShowCancelComponent] = useState(false);

const cancel = () => {
  if (hasChanges && onCancelComponent) {
    setShowCancelComponent(true);
  } else {
    onCancel?.();
    if (clearData) {
      clearFormData();
    }
    if (clearSignupUserGroupData) {
      clearSignupUserGroupFunction && clearSignupUserGroupFunction();
    }
  }
};
  
  const stay = () => {
    setShowCancelComponent(false);
  };

  const leave = () => {
    setShowCancelComponent(false);
    setHasChanges(false)
    onCancel?.();
    if (clearData) {
      clearFormData();
    }
    if (clearSignupUserGroupData) {
      clearSignupUserGroupFunction && clearSignupUserGroupFunction();
    }
  };

  useEffect(() => {
    if (clearData) {
      window.onbeforeunload = () => {
        clearFormData();
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [clearData, clearFormData]);

  const renderCustomComponent = () => {
    const {
      confirmationData: { customComponent, ...rest },
    } = props;
    return customComponent?.(rest);
  };

  return (
    <>
      {isOpen && (
        <div className={`${isOpen ? "block" : "hidden"} fixed z-10 inset-0`}>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
              onClick={cancel}
            >
              <div className="absolute inset-0 bg-[--theme] opacity-[0.16]"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div
              className={`${
                isOpen ? "block" : "hidden"
              } inline-block align-bottom bg-white rounded-[24px] text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[570px] sm:w-full w-full ${
                classes?.modal
              }`}
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 md:p-[35px] ">
                <div className="flex gap-3 justify-between items-center pb-5">
                  <div className="flex flex-row items-center gap-3">
                    {title && (
                      <h3
                        className={`text-[16px] sm:text-[18px] md:text-[20px] xl:text-[22px] font-inter-bold text-[#282D46] capitalize ${classes?.title}`}
                        id="modal-title"
                      >
                        {title}
                      </h3>
                    )}
                  </div>
                  {statusBtn && (
                    <div className="flex-1">
                      {React.cloneElement(
                        props.confirmationData.statusBtn as any,
                        { confirm: props.confirmationData.onConfirm }
                      )}
                    </div>
                  )}
                  {showCloseButton && (
                    <Button
                      className={`flex justify-center items-center bg-[#F4F9FD] size-[35px] md:size-[44px] rounded-[14px] flex-none ${classes?.button}`}
                      onClick={cancel}
                    >
                      <Icon iconType={IconType.CLOSE_ICON} />
                    </Button>
                  )}
                </div>
               <div className="max-h-[calc(100vh_-_13rem)] verticalOverflowScrollStyle">
                {onCancelComponent && (
                  <div style={{ display: showCancelComponent ? 'block' : 'none' }}>
                    {onCancelComponent({ ...props.confirmationData, stay, leave })}
                  </div>
                )}
                <div style={{ display: showCancelComponent ? 'none' : 'block' }}>
                  {(text || description) && (
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{text}</p>
                      {description && (
                        <p className="mt-2 text-sm text-gray-500">
                          {description}
                        </p>
                      )}
                    </div>
                  )}
                  {customComponent && renderCustomComponent()}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default DynamicModal;
