import { useInfiniteQuery } from "react-query";
import { toast } from "react-toastify";
import { ILead } from "lib/types/leads.types";
import { usePagination } from "lib/hooks/shared/usePagination";
import { getCustomers } from "api/Customers";

export const useCoustomers = (
  filter_search: string,
  filter_status: string,
  filter_product: string,
  filter_company: string,
  filter_commission: string,
  filter_mandated: string,
  filter_created_at: string
) => {
  const {
    currentPage,
    handleItemsPerPageChange,
    handleNextPage, 
    handlePreviousPage,
    itemsPerPage
  } = usePagination(1, 10);

  const { data, isLoading, hasNextPage, hasPreviousPage } = useInfiniteQuery<Array<ILead> | any>(
    ["coustomers", currentPage, itemsPerPage, filter_search, filter_status, filter_product, filter_company, filter_commission, filter_mandated, filter_created_at],
    ({ pageParam = currentPage }) => {
      return getCustomers({
        page: pageParam,
        number_per_page: itemsPerPage,
        filter_search: filter_search,
        filter_status: filter_status,
        filter_product: filter_product,
        filter_company: filter_company,
        filter_commission: filter_commission,
        filter_mandated: filter_mandated,
        filter_created_at: filter_created_at
      } as any);
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data?.next_page_url
          ? new URL(lastPage.data.next_page_url).searchParams.get("page")
          : false;
      },
      getPreviousPageParam: (firstPage, allPages) => {
        const currentPage = allPages[allPages.length - 1];
        const prevPageUrl = currentPage?.data?.prev_page_url;
        return prevPageUrl
          ? new URL(prevPageUrl).searchParams.get("page")
          : false;
      },
      onError: () => {
        toast.error(`Something went wrong`);
      }
    }
  );

  return {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    itemsPerPage,
    handleNextPage,
    handlePreviousPage,
    handleItemsPerPageChange
  };
};
