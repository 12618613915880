import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function SignedContract({ className, color }: Props) {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.162109"
        width="39.2346"
        height="40"
        rx="5"
        fill={color || '#50B8E7'}
        fillOpacity="0.05"
      />
      <g clipPath="url(#clip0_4152_76156)">
        <path
          d="M30.9555 15.0919C29.859 13.9954 28.0747 13.9954 26.978 15.0919L19.9472 22.1227C18.7846 23.2854 18.0603 24.7696 17.8569 26.3748H16.9937C16.6067 25.2835 15.5641 24.4998 14.3418 24.4998C12.791 24.4998 11.5293 25.7614 11.5293 27.3123C11.5293 27.83 11.949 28.2498 12.4668 28.2498C12.9846 28.2498 13.4043 27.83 13.4043 27.3123C13.4043 26.7953 13.8249 26.3748 14.3418 26.3748C14.8587 26.3748 15.2793 26.7953 15.2793 27.3123C15.2793 27.83 15.699 28.2498 16.2168 28.2498H18.7352C20.6956 28.2498 22.5386 27.4863 23.9248 26.1002C24.3289 25.696 30.5536 19.4714 30.9556 19.0694C32.0521 17.9728 32.0521 16.1885 30.9555 15.0919ZM22.5989 24.7744C21.8152 25.5581 20.8352 26.0745 19.7699 26.2776C19.973 25.2123 20.4894 24.2323 21.2731 23.4486L25.3643 19.3574L26.6901 20.6832L22.5989 24.7744ZM29.6297 17.7436L28.016 19.3573L26.6902 18.0315L28.3039 16.4178C28.6695 16.0522 29.2642 16.0522 29.6297 16.4178C29.9961 16.7841 29.9961 17.3771 29.6297 17.7436Z"
          fill={color || '#50B8E7'}
          />
        <path
          d="M25.5918 27.4594C25.074 27.4594 24.6543 27.8791 24.6543 28.3969V29.1875C24.6543 29.7044 24.2337 30.125 23.7168 30.125H10.5918C10.0749 30.125 9.6543 29.7044 9.6543 29.1875V10.8125C9.6543 10.2956 10.0749 9.875 10.5918 9.875H21.0652C21.3156 9.875 21.551 9.97255 21.7281 10.1496L24.3797 12.8013C24.4927 12.9142 24.5754 13.0551 24.6189 13.2089C24.76 13.7071 25.2782 13.9966 25.7763 13.8556C26.2745 13.7145 26.5641 13.1964 26.423 12.6982C26.2925 12.237 26.0444 11.8142 25.7056 11.4755L23.0539 8.82378C22.5227 8.29255 21.8163 8 21.0652 8H10.5918C9.04098 8 7.7793 9.26169 7.7793 10.8125V29.1875C7.7793 30.7383 9.04098 32 10.5918 32H23.7168C25.2676 32 26.5293 30.7383 26.5293 29.1875V28.3969C26.5293 27.8791 26.1096 27.4594 25.5918 27.4594Z"
          fill={color || '#50B8E7'}
          />
        <path
          d="M18.0918 15.5H12.4668C11.949 15.5 11.5293 15.0803 11.5293 14.5625C11.5293 14.0447 11.949 13.625 12.4668 13.625H18.0918C18.6096 13.625 19.0293 14.0447 19.0293 14.5625C19.0293 15.0803 18.6096 15.5 18.0918 15.5Z"
          fill={color || '#50B8E7'}
          />
        <path
          d="M18.0918 19.25H12.4668C11.949 19.25 11.5293 18.8303 11.5293 18.3125C11.5293 17.7947 11.949 17.375 12.4668 17.375H18.0918C18.6096 17.375 19.0293 17.7947 19.0293 18.3125C19.0293 18.8303 18.6096 19.25 18.0918 19.25Z"
          fill={color || '#50B8E7'}
          />
      </g>
      <defs>
        <clipPath id="clip0_4152_76156">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(7.7793 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default memo(SignedContract);
