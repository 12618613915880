import { useInfiniteQuery } from "react-query";
import { toast } from "react-toastify";
import { ILead, PaginationParams } from "lib/types/leads.types";
import { usePagination } from "lib/hooks/shared/usePagination";
import { getCustomersPendings } from "api/Pendings";

export const useCustomerPendings = (
  filter_search?: string,
  status?: string,
  deadline?: string,
  created_at?: string
) => {
  const {
    currentPage,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage
  } = usePagination(1, 10);

  const { data, isLoading, hasNextPage, hasPreviousPage } = useInfiniteQuery<
    Array<ILead> | any
  >(
    ["customerPending", currentPage, itemsPerPage, filter_search, status,deadline,created_at],
    ({ pageParam = currentPage }) => {
      return getCustomersPendings({
        page: pageParam,
        number_per_page: itemsPerPage,
        filter_search: filter_search,
        status: status,
        deadline: deadline,
        created_at: created_at
      } as PaginationParams);
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data?.next_page_url
          ? new URL(lastPage.data.next_page_url).searchParams.get("page")
          : false;
      },
      getPreviousPageParam: (firstPage, allPages) => {
        const currentPage = allPages[allPages.length - 1];
        const prevPageUrl = currentPage?.data?.prev_page_url;
        return prevPageUrl
          ? new URL(prevPageUrl).searchParams.get("page")
          : false;
      },
      onError: () => {
        toast.error(`Something went wrong`);
      }
    }
  );

  return {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    itemsPerPage,
    handleNextPage,
    handlePreviousPage,
    handleItemsPerPageChange
  };
};
