import { memo } from "react";

interface Props {
    className?: string;
}

const TutorialCamIcon = ({ className }: Props) => {
    return (
        <svg className={className} width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2925_61732)">
                <path d="M20.8959 6.14568C20.7259 6.0149 20.5099 5.98846 20.3192 6.07509L14.54 8.70187V6.72791C14.54 5.50968 13.6678 4.51855 12.5957 4.51855H1.99124C0.919145 4.51855 0.046875 5.50968 0.046875 6.72791V17.2717C0.046875 18.4899 0.919145 19.4811 1.99124 19.4811H12.5957C13.6678 19.4811 14.54 18.4899 14.54 17.2717V15.2977L20.3192 17.9245C20.3934 17.9582 20.4714 17.9748 20.549 17.9748C20.6709 17.9748 20.7921 17.9339 20.8959 17.8539C21.066 17.7231 21.1678 17.505 21.1678 17.2717V6.72791C21.1678 6.49462 21.066 6.27651 20.8959 6.14568ZM13.3025 17.2717C13.3025 17.7145 12.9854 18.0748 12.5957 18.0748H1.99124C1.60153 18.0748 1.28443 17.7145 1.28443 17.2717V6.72791C1.28443 6.28509 1.60153 5.9248 1.99124 5.9248H12.5957C12.9854 5.9248 13.3025 6.28509 13.3025 6.72791V17.2717ZM19.9303 16.2332L14.54 13.7832V10.2164L19.9303 7.76643V16.2332Z" fill="#282D46" />
            </g>
            <defs>
                <clipPath id="clip0_2925_61732">
                    <rect width="21.1209" height="24" fill="white" transform="translate(0.046875)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default memo(TutorialCamIcon)