import { FC } from "react";
import Card, { ICard } from "components/molecules/card/Card";
import { CardType } from "lib/helpers/constants/cardTypes";
import { IInfoCardProps } from "components/molecules/card/info-card/InfoCard";
import { JSX } from "react/jsx-runtime";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useStatisticInfoCard } from "lib/hooks/queries/Coustomer/useStatisticInfoCard";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useTranslation } from "react-i18next";

export interface ICostumersCardProps {}

const CostumersCard: FC<ICostumersCardProps> = () => {
  const { data } = useStatisticInfoCard();
  const {themeColors} = useThemeContext();
  const {t} = useTranslation();

  const cardsData = [
    {
      title: `${t('Won Costumers')}`,
      count: data?.won,
      infoBox:`${t('Products that have not been Retreat,Rejected and Canceled')}`,
      icon: IconType.WON_APPOINTMENT_ICON,
      color: themeColors.primary,
      percentageup:
        data?.won_percent >= 0 ? IconType?.UP_PERCENTAGE_ICON : null,
      percentagedown:
        data?.won_percent < 0 ? IconType?.DOWN_PERCENTAGE_ICON : null,
      percentage: data?.won_percent,
      updatedate: new Date().toLocaleDateString(),
    },
    {
      title: `${t('Lost Costumers')}`,
      count: data?.lost,
      infoBox:`${t('Products that have been Retreat,Rejected and Canceled')}`,
      icon: IconType.LOST_CUSTOMER_ICON,
      color:themeColors.primary,
      percentageup:
        data?.lost_percent >= 0 ? IconType?.UP_PERCENTAGE_ICON : null,
      percentagedown:
        data?.lost_percent < 0 ? IconType?.DOWN_PERCENTAGE_ICON : null,
        updatedate: new Date().toLocaleDateString(),
        percentage: data?.lost_percent
    },
    {
      title: `${t('Pending Costumers')}`,
      count: data?.pending,
      icon: IconType.CUSTOMER_PENDING_ICON,
      color: themeColors.primary,
      percentageup:
        data?.pending_percent >= 0 ? IconType?.UP_PERCENTAGE_ICON : null,
      percentagedown:
        data?.pending_percent < 0 ? IconType?.DOWN_PERCENTAGE_ICON : null,
        updatedate: new Date().toLocaleDateString(),
        percentage: data?.pending_percent
    },
    {
      title: `${t('Signed Contracts')}`,
      count: data?.signedContracts,
      infoBox: `${t('Products that have been accepted and provisioned')}`,
      icon: IconType.SIGNED_CONTRACT,
      color: themeColors.primary,
      percentageup:
        data?.signedContractsPercent >= 0 ? IconType?.UP_PERCENTAGE_ICON : null,
      percentagedown:
        data?.signedContractsPercent < 0 ? IconType?.DOWN_PERCENTAGE_ICON : null,
      updatedate: new Date().toLocaleDateString(),
      percentage: data?.signedContractsPercent
    }
  ];

  

  return (
    <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 2xl:grid-cols-4 items-center justify-between gap-5 ">
      <NoDataBoundary condition={
        !data?.length
      }>
      {cardsData?.map(
            (
              item: JSX.IntrinsicAttributes & ICard<IInfoCardProps>,
              index: number
            ) => (
              <Card<IInfoCardProps>
                cardType={CardType.INFO_CARD}
                {...item}
                key={`appointments-cards-key-${index}`}

              />
            )
          )}
      </NoDataBoundary>
    </div>
  );
};

export default CostumersCard;
