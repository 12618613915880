import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import React from "react";

interface InputsFolderProps {
  children: React.ReactElement;
  title: string;
  childrenClassName?: string;
  themeAsProps?: {
    primary: string;
  };
}

const InputsFolder: React.FC<InputsFolderProps> = ({
  title,
  children,
  childrenClassName,
  themeAsProps,
}) => {
  const { themeColors } = useThemeContext();

  return (
    <div>
      <div
        className="p-3 rounded-t-[20px] ext-primaryTextColor font-inter-semibold"
        style={{
          width: "fit-content",
          backgroundColor: `${themeColors.primary || themeAsProps?.primary}0D`,
        }}
      >
        {title}
      </div>
      <div
        className={`p-4 rounded-b-[20px] rounded-r-[20px]  ${childrenClassName}`}
        style={{
          backgroundColor: `${themeColors.primary || themeAsProps?.primary}0D`,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default InputsFolder;
