import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import Error404 from "../pages/Error404";

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED,
}

export interface IAppRoute {
  path: string;
  element: React.ReactNode;
  children: Array<{}>;
}

export interface IRoutes {
  appRoutes: any;
}

const RoutesApp: FunctionComponent<IRoutes> = ({ appRoutes }) => {
  return (
    <Routes>
      {appRoutes.routes.map((route: any) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
      <Route key="page404" path="*" element={<Error404 />} />
    </Routes>
  );
};

export default RoutesApp;