import { memo } from "react";
interface Props {
    className?: string;
    color?: string;
}
function LeaderboardFirstIcon({ className, color }: Props) {
    return (
        <svg width="37" height="44" viewBox="0 0 37 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6133 4.28125L9.87195 19.7049L22.1148 14.4646L10.6133 4.28125Z" fill="#FBB500" fill-opacity="0.944124"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.51437 13.8424L22.0742 14.3731L9.83138 19.6134L2.51437 13.8424Z" fill="#FFC700" fill-opacity="0.7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7502 5.45784L9.83035 19.6138L22.0732 14.3735L22.7502 5.45784Z" fill="#FFC700" fill-opacity="0.7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4532 4.12463C10.9705 3.90322 11.2103 3.30441 10.9889 2.78715C10.7675 2.26989 10.1687 2.03005 9.65143 2.25145C9.13417 2.47286 8.89433 3.07167 9.11573 3.58893C9.33714 4.10619 9.93595 4.34603 10.4532 4.12463Z" fill="#F6AE0D"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1432 5.21663C23.6992 5.30298 24.22 4.92226 24.3063 4.36627C24.3926 3.81028 24.0119 3.28956 23.4559 3.20322C22.8999 3.11687 22.3792 3.49759 22.2929 4.05358C22.2065 4.60957 22.5873 5.13029 23.1432 5.21663Z" fill="#F6AE0D"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.55731 14.7518C2.1133 14.8381 2.63401 14.4574 2.72036 13.9014C2.8067 13.3454 2.42598 12.8247 1.86999 12.7384C1.314 12.652 0.793285 13.0328 0.70694 13.5887C0.620596 14.1447 1.00132 14.6654 1.55731 14.7518Z" fill="#F6AE0D"/>
        <circle cx="22.0039" cy="29" r="14.5" fill="white" stroke="#F6AE0D"/>
        <path d="M23.9329 23.3633V34.9996H21.8249V25.4144H21.7567L19.0352 27.1531V25.2212L21.9272 23.3633H23.9329Z" fill="#282D46"/>
        </svg>
        

    );
}
export default memo(LeaderboardFirstIcon);
