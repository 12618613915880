import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useHover } from "./useHover";
import { useShowPassowrd } from "./useShowPasswod";
import { useSignupForm } from "../forms/useSignupForm";
import { useModalContext } from "lib/context/ModalContext/ModalContext";
import { useToolTip } from "./useToolTip";

interface IProps {
  initalStep?: number;
  defaultValues?: Record<string, unknown>;
}

export const useSignupCustomHooks = ({ initalStep, defaultValues }: IProps) => {
  const { isHovered, onMouseEnter, onMouseLeave } = useHover();
  const {
    handleCloseTooltip,
    handleOpenTooltip,
    openTooltip,
    handleCloseTooltipbyIndex,
    handleOpenTooltipbyIndex,
    openTooltipIndex,
  } = useToolTip();
  const store = JSON.parse(
    localStorage.getItem("multi_step_form_state") || "{}"
  );

  const {
    showPassword,
    toggleShowPassword,
    toggleShowPasswordByIndex,
    showPasswordByIndex,
  } = useShowPassowrd();

  const {
    nextFormStep,
    updateFormData,
    currentStep,
    formData,
    prevFormStep,
    setCurrentStep,
  } = useMultiStepFormContext<any>();

  const { confirm } = useModalContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    setValue,
    control,
    clearErrors,
    setError,
  } = useSignupForm(initalStep ?? 0, (defaultValues = defaultValues || store));

  return {
    isHovered,
    onMouseEnter,
    onMouseLeave,
    showPassword,
    toggleShowPassword,
    currentStep,
    nextFormStep,
    updateFormData,
    formData,
    prevFormStep,
    setCurrentStep,
    register,
    handleSubmit,
    errors,
    getValues,
    watch,
    formState: { errors },
    control,
    toggleShowPasswordByIndex,
    showPasswordByIndex,
    confirm,
    handleCloseTooltip,
    handleOpenTooltip,
    openTooltip,
    setValue,
    store,
    handleCloseTooltipbyIndex,
    handleOpenTooltipbyIndex,
    openTooltipIndex,
    clearErrors,
    setError,
  };
};
