import React, { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}
function LockIcon({ className, color }: Props) {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="1"
        y="1.71196"
        width="18.6772"
        height="18.5338"
        rx="9.26689"
        fill="#50B8E7"
        fillOpacity="0.3"
      />
      <rect
        x="1"
        y="1.71196"
        width="18.6772"
        height="18.5338"
        rx="9.26689"
        stroke="#F9F5FF"
        strokeWidth="1.55643"
      />
      <g clipPath="url(#clip0_958_19869)">
        <path
          d="M8.39237 10.6614V9.10501C8.39237 8.58902 8.59734 8.09416 8.9622 7.7293C9.32706 7.36445 9.82192 7.15947 10.3379 7.15947C10.8539 7.15947 11.3488 7.36445 11.7136 7.7293C12.0785 8.09416 12.2834 8.58902 12.2834 9.10501V10.6614M7.61415 10.6614H13.0617C13.4915 10.6614 13.8399 11.0099 13.8399 11.4397V14.1634C13.8399 14.5932 13.4915 14.9416 13.0617 14.9416H7.61415C7.18436 14.9416 6.83594 14.5932 6.83594 14.1634V11.4397C6.83594 11.0099 7.18436 10.6614 7.61415 10.6614Z"
          stroke="#282D46"
          strokeWidth="0.778215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_958_19869">
          <rect
            width="9.33858"
            height="9.33858"
            fill="white"
            transform="translate(5.66992 6.38125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(LockIcon);
