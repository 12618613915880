import { storeAdditionalFile } from "api/Campaign";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useStoreAdditionalCampaign = () => {
  const { updateFormData } = useMultiStepFormContext();
  return useMutation(storeAdditionalFile, {
    onSuccess: (res) => {
      updateFormData(res);      
    },
    onError: (error) => {
      toast.error('Something went wrong');
    }
  });
};
