import { FC, useEffect, useState } from "react";
import {
  IMultiStepFormContext,
  MultiStepFormContext,
} from "./MultiStepFormContext";
import { useLocation } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IMultiStepFormProviderProps<T> {
  children: React.ReactNode;
}

const loadFromLocalStorage = (key: string) => {
  const storedData = localStorage.getItem(key);
  return storedData ? JSON.parse(storedData) : null;
};

const sanitizeData = (data: any): any => {
  if (typeof data !== "object" || data === null) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((item) => sanitizeData(item));
  }

  const sanitizedObject: any = {};
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      sanitizedObject[key] = sanitizeData(data[key]);
    }
  }
  return sanitizedObject;
};

const saveToLocalStorage = (key: string, data: any) => {
  const sanitizedData = sanitizeData(data);
  localStorage.setItem(key, JSON.stringify(sanitizedData));
};

const MultiStepFormProvider: FC<
  IMultiStepFormProviderProps<Record<string, unknown>>
> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const localStorageKey = "multi_step_form_state";
  const initialFormData = loadFromLocalStorage(localStorageKey) || {};
  const [formData, setFormData] =
    useState<Record<string, unknown>>(initialFormData);
  const location = useLocation();

  useEffect(() => {
    saveToLocalStorage(localStorageKey, formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData ?? {}]);

  const removeKeyFromFormData = (key: string) => {
    setFormData((prevData) => {
      const newData = { ...prevData };
      delete newData[key];

      // Update the local storage directly
      saveToLocalStorage(localStorageKey, newData);

      return newData;
    });
  };

  useEffect(() => {
    const paths = ["/signup"];
    if (location && paths.includes(location.pathname)) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [location]);

  const nextFormStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevFormStep = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const updateFormData = (newData: Partial<Record<string, unknown>>) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearFormData = () => {
    localStorage.removeItem(localStorageKey);
    setFormData({});
  };
  const ContextValues: IMultiStepFormContext<Record<string, unknown>> = {
    nextFormStep,
    prevFormStep,
    currentStep,
    updateFormData,
    formData,
    setCurrentStep,
    clearFormData,
    removeKeyFromFormData,
  };

  return (
    <MultiStepFormContext.Provider value={ContextValues}>
      {children}
    </MultiStepFormContext.Provider>
  );
};

export default MultiStepFormProvider;
