import React, { Component, ErrorInfo, ReactNode } from "react";
import Button from "../button/Button";

interface IErrorBoundaryProps {
  children: ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <h2 className="mt-6 text-center font-inter-semibold text-3xl text-gray-900">
                Oops, Something went wrong.
              </h2>
              <p className="mt-2 text-center font-inter-regular text-sm text-gray-600">
                Please check your connection and try again. If the problem still persists, please contact us. We are here to help! 🤗
              </p>
            </div>
            <div>
              <Button
                onClick={() => window.location.reload()}
                className="w-full flex justify-center uppercase font-inter-regular py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[--theme]"
              >
                Retry
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
