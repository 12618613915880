import { memo } from "react";

interface Props {
  className?: string;
  color?:string;
}

function LostLeadsIcon({ className,color }: Props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.80508 20.8399C9.3491 21.7331 11.1131 22.2052 12.9064 22.2052H12.9065C15.6379 22.2052 18.2024 21.1453 20.1267 19.2209C22.0512 17.2965 23.111 14.7322 23.111 12.0006C23.111 10.2073 22.639 8.4433 21.7458 6.89924C21.4804 6.44066 21.0273 6.13818 20.5026 6.06932C19.9792 6.00074 19.465 6.17539 19.0918 6.54866L17.9516 7.6888C17.8143 7.8261 17.8143 8.04871 17.9516 8.18596C18.0889 8.32321 18.3115 8.3233 18.4488 8.18596L19.589 7.04582C19.8065 6.82827 20.106 6.72641 20.4111 6.76649C20.7174 6.80666 20.9821 6.98338 21.137 7.25132C21.9684 8.6885 22.4078 10.3308 22.4078 12.0006C22.4078 14.5444 21.4211 16.9321 19.6295 18.7237C17.8378 20.5154 15.4502 21.5021 12.9063 21.5021C11.2365 21.5021 9.59425 21.0626 8.15711 20.2312C7.88917 20.0763 7.71246 19.8117 7.67224 19.5053C7.63221 19.2004 7.73402 18.9007 7.95157 18.6832L9.26838 17.3664C9.40567 17.2291 9.40567 17.0065 9.26838 16.8693C9.13108 16.732 8.90852 16.7319 8.77118 16.8693L7.45436 18.186C7.0811 18.5593 6.90644 19.0735 6.97511 19.5969C7.04407 20.1216 7.34655 20.5746 7.80508 20.8399Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M21.3916 3.51469C19.1251 1.24823 16.1116 0 12.9062 0C9.70094 0 6.68744 1.24823 4.42094 3.51469C2.15448 5.78123 0.90625 8.79469 0.90625 12C0.90625 15.2053 2.15448 18.2188 4.42094 20.4853C6.68744 22.7518 9.70094 24 12.9062 24C14.3589 24 15.7789 23.7432 17.1267 23.2367C17.3085 23.1684 17.4005 22.9657 17.3322 22.7839C17.2639 22.6021 17.061 22.5102 16.8795 22.5785C15.6109 23.0552 14.2742 23.2969 12.9062 23.2969C6.67712 23.2969 1.60938 18.2291 1.60938 12C1.60938 5.77087 6.67712 0.703125 12.9062 0.703125C19.1354 0.703125 24.2031 5.77087 24.2031 12C24.2031 16.1801 21.9104 20.0011 18.2196 21.972C18.0483 22.0634 17.9836 22.2764 18.0751 22.4476C18.1665 22.6189 18.3796 22.6837 18.5507 22.5922C22.471 20.4989 24.9062 16.4402 24.9062 12C24.9062 8.79469 23.658 5.78123 21.3916 3.51469Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M18.8375 4.40528C18.7686 3.88056 18.4661 3.42752 18.0076 3.1622C16.4636 2.269 14.6995 1.79692 12.9063 1.79688C12.9061 1.79688 12.9064 1.79688 12.9062 1.79688C10.1748 1.79688 7.61032 2.85681 5.68596 4.78117C3.76146 6.70567 2.70166 9.26988 2.70166 12.0015C2.70166 13.7948 3.17374 15.5588 4.06694 17.1029C4.33225 17.5615 4.78535 17.8639 5.31011 17.9328C5.38493 17.9426 5.45955 17.9474 5.53361 17.9474C5.97752 17.9474 6.40099 17.7734 6.72091 17.4535L8.29216 15.8822C8.42946 15.7449 8.42946 15.5223 8.29216 15.385C8.15472 15.2477 7.93216 15.2478 7.79496 15.385L6.22375 16.9563C6.00616 17.1738 5.70668 17.2756 5.40161 17.2356C5.09528 17.1954 4.83063 17.0187 4.67566 16.7508C3.84433 15.3136 3.40488 13.6713 3.40488 12.0015C3.40488 9.45766 4.39155 7.06998 6.18321 5.27833C7.97486 3.48667 10.3625 2.49995 12.9063 2.49995C14.5762 2.49995 16.2184 2.93941 17.6556 3.77078C17.9235 3.92575 18.1002 4.19036 18.1405 4.49669C18.1805 4.80161 18.0787 5.10128 17.8611 5.31883L17.0214 6.15859C16.8841 6.29589 16.8841 6.51845 17.0214 6.65575C17.1587 6.79305 17.3813 6.79309 17.5185 6.65575L18.3583 5.81598C18.7315 5.44281 18.9062 4.92859 18.8375 4.40528Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M11.8382 13.1914C11.8382 12.9972 11.6808 12.8398 11.4866 12.8398H11.3237C11.1295 12.8398 10.9722 12.9972 10.9722 13.1914C10.9722 13.3856 11.1295 13.543 11.3237 13.543H11.4866C11.6808 13.543 11.8382 13.3856 11.8382 13.1914Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M14.4886 12.8398H14.3257C14.1315 12.8398 13.9741 12.9972 13.9741 13.1914C13.9741 13.3856 14.1315 13.543 14.3257 13.543H14.4886C14.6828 13.543 14.8401 13.3856 14.8401 13.1914C14.8401 12.9972 14.6827 12.8398 14.4886 12.8398Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M13.8291 15.3234L13.7486 15.28C13.1569 14.9615 12.4334 14.9819 11.8607 15.3332C11.6952 15.4348 11.6433 15.6512 11.7448 15.8167C11.8463 15.9822 12.0628 16.0342 12.2282 15.9326C12.5885 15.7117 13.0433 15.6989 13.4152 15.8991L13.4956 15.9424C13.5487 15.971 13.6058 15.9845 13.6621 15.9845C13.7872 15.9845 13.9084 15.9175 13.9719 15.7996C14.064 15.6287 14.0001 15.4155 13.8291 15.3234Z"
        fill={color || '#50B8E7'}
      />
      <path
        d="M15.0698 6.26953H10.7427C9.48285 6.26953 8.45793 7.29445 8.45793 8.55431V11.8759C7.97212 12.0263 7.61816 12.4797 7.61816 13.0143V13.3574C7.61816 14.0143 8.15259 14.5487 8.80949 14.5487H9.16162C9.25471 16.3204 10.7253 17.7331 12.5197 17.7331H13.2929C15.0873 17.7331 16.5579 16.3204 16.651 14.5487H17.0031C17.6601 14.5487 18.1945 14.0143 18.1945 13.3574V13.0143C18.1945 12.4797 17.8405 12.0263 17.3547 11.8759V8.55431C17.3546 7.29445 16.3297 6.26953 15.0698 6.26953ZM9.16106 8.55427C9.16106 7.68216 9.8706 6.97261 10.7427 6.97261H15.0698C15.9419 6.97261 16.6515 7.68211 16.6515 8.55427V11.8229H16.0488C15.931 11.8229 15.8251 11.7485 15.7851 11.6378L15.4413 10.6854C15.2777 10.2322 14.844 9.92766 14.3622 9.92766H11.4503C10.9685 9.92766 10.5348 10.2322 10.3712 10.6854L10.0274 11.6378C9.98741 11.7485 9.88148 11.8229 9.76377 11.8229H9.16101V8.55427H9.16106ZM8.32129 13.3573V13.0142C8.32129 12.745 8.54029 12.526 8.80949 12.526H9.15679V13.8455H8.80949C8.54024 13.8455 8.32129 13.6265 8.32129 13.3573ZM13.2929 17.03H12.5197C11.0531 17.03 9.85991 15.8368 9.85991 14.3702V12.5207C10.2335 12.4838 10.5596 12.2346 10.6889 11.8765L11.0327 10.9241C11.096 10.7487 11.2639 10.6308 11.4504 10.6308H14.3622C14.5487 10.6308 14.7167 10.7487 14.78 10.9242L15.1237 11.8765C15.253 12.2346 15.579 12.4838 15.9527 12.5208V14.3702C15.9526 15.8368 14.7595 17.03 13.2929 17.03ZM17.4913 13.3573C17.4913 13.6265 17.2723 13.8455 17.0031 13.8455H16.6558V12.526H17.0031C17.2723 12.526 17.4913 12.745 17.4913 13.0142V13.3573Z"
        fill={color || '#50B8E7'}
      />
    </svg>
  );
}
export default memo(LostLeadsIcon);
