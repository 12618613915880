import { storeUploadedCustomerData } from "api/Customers";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useUploadCustomersStoreFile = () => {
  const queryClient = useQueryClient();

  return useMutation(storeUploadedCustomerData, {
    onSuccess: () => {
      queryClient.invalidateQueries("coustomers");
      toast.success("Appointment stored successfully");
    },
    onError: (error) => {
      toast.error("Failed to store appointment");
    },
  });
};
