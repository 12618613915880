import React, { FC } from "react";
import LoginContainer from "../default-container/LoginContainer";
import Icon from "../../atoms/icon/Icon";
import { IconType } from "../../../lib/helpers/constants/iconTypes";

export interface ILoginLayoutProps {
  children: React.ReactNode;
}

const LoginLayout: FC<ILoginLayoutProps> = ({ children }) => {
  return (
    <div className="lg:bg-[url('assets/images/loginBg.svg')] bg-cover min-h-screen bg-right-top">
      <div className="flex flex-col justify-center items-center h-full py-10 2xl:py-20 min-h-screen">
        <LoginContainer>
          <Icon
            iconType={IconType.COLORFUL_LOGO_ICON}
            className="max-w-[250px] w-full h-auto m-auto"
          />
          <div>{children}</div>
        </LoginContainer>
      </div>
    </div>
  );
};

export default LoginLayout;
